import dayjs from "dayjs";

const validate = values => {
	const errors = {};

	if (!values.value) {
		errors.value = "Informe o valor do imposto.";
	}

	if (!values.company) {
		errors.company = "Selecione uma empresa.";
	}

	if (!values.periodDate) errors.periodDate = "Selecione uma empresa.";
	else if (!dayjs(values.periodDate).isValid())
		errors.periodDate = "Data inválida.";

	return errors;
};

export default validate;
