import styled from "styled-components";

export const TitleSC = styled.span`
	font-weight: 700;
	font-size: 16px;

	margin-bottom: 16px;
`;
export const SubTitleSC = styled.span`
	font-weight: 700;
	font-size: 14px;
`;
export const ContainerFiltersSC = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	background: #fff;

	border-radius: 5px;
	padding: 16px;
`;
export const ContainerInfoSC = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ButtonGroupSC = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
`;
export const ButtonSC = styled.button`
	display: flex;
	align-items: center;
	gap: 10px;

	align-self: end;

	padding: ${({ padding }) => (padding ? padding : "8px 28px")};
	background: ${({ background }) => (background ? background : "#584c9c")};
	color: ${({ color }) => (color ? color : "#fff")};
	border: ${({ border }) => (border ? border : "none")};
	border-radius: 5px;
	width: fit-content;

	&:disabled {
		cursor: not-allowed;
		background-color: #d3d3d3;
	}
`;
export const DateRangePickerContainer = styled.div`
	border: 1px solid rgba(0, 0, 0, 0.2);
	padding: 3px;
	border-radius: 5px;

	&:hover {
		border: 1px solid #000;
	}
`;
export const CellText = styled.span`
	font-size: 14px;
	color: #31475a;
`;
export const ActionCellContainer = styled.div`
	display: flex;
	justify-content: center;

	font-size: 18px;
	color: #31475a;
`;
export const ActionCellButton = styled.button`
	background: transparent;
	color: #31475a;
	cursor: pointer;
	border: 1px solid transparent;

	transition: filter 0.2s;
	padding: 4px;

	&:hover {
		border: 1px solid #31475a;
		border-radius: 5px;
	}
`;
