import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import { isMobileOnly } from "react-device-detect";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Portal } from "react-overlays";

const CalendarContainer = ({ children }) => {
	const el = document.getElementById("calendar-portal");

	return <Portal container={el}>{children}</Portal>;
};

class DateTimePickerField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			startDate: null
		};
	}

	handleChange = date => {
		const { onChange } = this.props;
		this.setState({
			startDate: date
		});
		onChange(date);
	};

	componentDidMount() {
		const { value } = this.props;

		if (value) {
			this.setState({ startDate: dayjs(value).toDate() });
		}
	}

	render() {
		const { startDate } = this.state;
		const {
			placeholderText,
			datePickerProps,
			meta: { error, touched }
		} = this.props;

		return (
			<div className="date-picker">
				<DatePicker
					popperContainer={CalendarContainer}
					timeFormat="HH:mm"
					className="form__form-group-datepicker"
					selected={startDate}
					onChange={this.handleChange}
					showTimeSelect
					timeIntervals={15}
					dateFormat="dd/MM/yyyy - HH:mm"
					dropDownMode="select"
					withPortal={isMobileOnly}
					placeholderText={placeholderText}
					{...(datePickerProps || {})}
				/>
				{touched && error && (
					<span className="form__form-group-error">{error}</span>
				)}
			</div>
		);
	}
}

const renderDateTimePickerField = props => {
	const { input, placeholderText, datePickerProps, meta } = props;

	return (
		<DateTimePickerField
			placeholderText={placeholderText}
			{...input}
			datePickerProps={datePickerProps}
			meta={meta}
		/>
	);
};

renderDateTimePickerField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired
};

export default renderDateTimePickerField;
