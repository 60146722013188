import ReportTable from "components/Report";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	Col,
	Container,
	Row,
	ButtonGroup,
	ButtonToolbar,
	Button
} from "reactstrap";
import * as actions from "./actions";
import { setValue as setValueEdit } from "../Page/Edit/actions";
import EditForm from "../Page/Edit";

let getDataDebounced = null;

function PermissionReport() {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.permissions);

	const handleFetchData = tableState => {
		if (!tableState) {
			return;
		}
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getDataDebounced) {
			getDataDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}
		getDataDebounced = _.debounce(
			() =>
				dispatch(
					actions.getPermissions(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => {
							return p + `&filters[${c.id}]=${c.value}`;
						}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getDataDebounced();
	};

	const handleShowEdit = item => {
		dispatch(
			setValueEdit({
				item
			})
		);
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	useEffect(() => {
		load();
	}, []);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Permissões"}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						// onRowClicked={row => history.push(`/nfses/${row._id}`)}
						columns={[
							{
								Header: "Nome",
								id: "name",
								accessor: "name",
								width: 200
							},
							{
								Header: "Descrição",
								id: "description",
								accessor: "description"
							},
							{
								Header: "Ações",
								id: "actions",
								filterable: false,
								Cell: props => (
									<ButtonToolbar>
										<ButtonGroup className="btn-group--icons" dir="ltr">
											<>
												<Button
													color="primary"
													onClick={() => handleShowEdit(props.original)}
												>
													<MdEdit />
												</Button>
											</>
										</ButtonGroup>
									</ButtonToolbar>
								),
								width: 114
							}
						]}
					/>

					<EditForm load={load} />
				</Col>
			</Row>
		</Container>
	);
}

export default PermissionReport;
