import React, { createContext, useContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
	const [modalVisible, setModalVisible] = useState(false);

	const showModal = () => {
		setModalVisible(true);
	};
	const hideModal = () => setModalVisible(false);

	return (
		<GlobalContext.Provider
			value={{
				showModal,
				hideModal,
				modalVisible
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => {
	const context = useContext(GlobalContext);

	if (!context) {
		throw Error("useGlobalContext must be in GlobalContextProvider");
	}

	return context;
};
