import styled from "styled-components";

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	label {
		font-size: 14px;
		color: #000;
		line-height: 23px;
		margin-bottom: 0;
	}

	input {
		padding: 8px 16px;
		border: 1px solid #31475a;
		border-radius: 4px;

		font-size: 14px;
		line-height: 23px;

		&::placeholder {
			color: #6c757d;
		}
	}
`;
