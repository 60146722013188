import React from "react";
import { Route, Switch } from "react-router-dom";

import { DigisacIntegration } from "./containers";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/digisac" component={DigisacIntegration} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
