import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_COMPANY = "[COMPANY] GET_COMPANY";
export const GET_COMPANY_SUCCESS = "[COMPANY] GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILED = "[COMPANY] GET_COMPANY_FAILED";

export const GET_HIREDS = "[HIREDS] GET_HIREDS";
export const GET_HIREDS_SUCCESS = "[HIREDS] GET_HIREDS_SUCCESS";
export const GET_HIREDS_FAILED = "[HIREDS] GET_HIREDS_FAILED";

export const IMPORT_COMPANY = "[COMPANY] IMPORT_COMPANY";
export const IMPORT_COMPANY_SUCCESS = "[COMPANY] IMPORT_COMPANY_SUCCESS";
export const IMPORT_COMPANY_FAILED = "[COMPANY] IMPORT_COMPANY_FAILED";

export const SET_VALUE = "[COMPANY] SET_VALUE";
export const CLEAR_VALUES = "[COMPANY] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function importCompanies(files, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_COMPANY });

		try {
			const { login } = getState();

			var response = await api.sendPostFiles("/company/Import", files, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_COMPANY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_COMPANY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_COMPANY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getCompanies(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_COMPANY });
		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/company/all-companies?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_COMPANY_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_COMPANY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getHiredList(hirer_id, filter, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_HIREDS });
		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/companyProvider/hired-list/${hirer_id}?${filter || "&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_HIREDS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_HIREDS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_HIREDS_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
