import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const validate = (values = {}) => {
  const errors = {};

  if (!values.user) errors.user = "Obrigatório";

  if (!values.value) errors.value = "Obrigatório";

  if (!values.installment) errors.installment = "Obrigatório";

  if (!values.plan) errors.plan = "Obrigatório";

  if (!values.paymentMethod) errors.paymentMethod = "Obrigatório";

  if (!values.status) errors.status = "Obrigatório";

  if (!values.firstPayment) {
    errors.firstPayment = "Obrigatório";
  } else if (!dayjs(values.firstPayment, 'DD/MM/YYYY').isValid()) {
    errors.firstPayment = "Data inválida";
  }

  return errors;
};

export default validate;
