import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_GROUPS = "[GROUPS] GET_GROUPS";
export const GET_GROUPS_SUCCESS = "[GROUPS] GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "[GROUPS] GET_GROUPS_FAILED";

export const GET_GROUP = "[GROUP] GET_GROUP";
export const GET_GROUP_SUCCESS = "[GROUP] GET_GROUP_SUCCESS";
export const GET_GROUP_FAILED = "[GROUPS] GET_GROUPS_FAILED";

export const SET_VALUE = "[GROUPS] SET_VALUE";
export const CLEAR_VALUES = "[GROUPS] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getGroups(skip, take, filter, sort, desc, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_GROUPS });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/group?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_GROUPS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_GROUPS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_GROUPS_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getGroupById(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_GROUP
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/group/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_GROUP_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_GROUP_FAILED,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_GROUP_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
