import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_NFSE = "[NFSE_DETAIL] GET_NFSE";
export const GET_NFSE_ERROR = "[NFSE_DETAIL] GET_NFSE_ERROR";
export const GET_NFSE_SUCCESS = "[NFSE_DETAIL] GET_NFSE_SUCCESS";

export const DELETE_NFSE = "[NFSE DETAIL] DELETE_NFSE";
export const DELETE_NFSE_ERROR = "[NFSE DETAIL] DELETE_NFSE_ERROR";
export const DELETE_NFSE_SUCCESS = "[NFSE DETAIL] DELETE_NFSE_SUCCESS";

export const UPDATE_NFSE = "[NFSE UPDATE] UPDATE_NFSE";
export const UPDATE_NFSE_ERROR = "[NFSE UPDATE] UPDATE_NFSE_ERROR";
export const UPDATE_NFSE_SUCCESS = "[NFSE UPDATE] UPDATE_NFSE_SUCCESS";

export const GENERATE_NFSE = "[NFSE GENERATE] GENERATE_NFSE";
export const GENERATE_NFSE_ERROR = "[NFSE GENERATE] GENERATE_NFSE_ERROR";
export const GENERATE_NFSE_SUCCESS = "[NFSE GENERATE] GENERATE_NFSE_SUCCESS";

export const UPLOAD_FILE = "[NFSE UPDATE] UPLOAD_FILE";
export const UPLOAD_FILE_ERROR = "[NFSE UPDATE] UPLOAD_FILE_ERROR";
export const UPLOAD_FILE_SUCCESS = "[NFSE UPDATE] UPLOAD_FILE_SUCCESS";

export const SET_VALUE = "[NFSE_DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[NFSE_DETAIL] CLEAR_VALUES";

export const GET_CONTACT_COMPANY = "[NFSE UPDATE] GET_CONTACT_COMPANY";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getNFSeById(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_NFSE
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/nfse/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_NFSE_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_NFSE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_NFSE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateNfse(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_NFSE
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/nfse/${id} `, model, {
				Authorization: "Bearer " + login.token
			});
			let result = await response.json();
			console.log(result);

			if (response.ok) {
				dispatch({
					type: UPDATE_NFSE_SUCCESS
				});

				callback && callback();
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_NFSE_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_NFSE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function generateNfse(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GENERATE_NFSE
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/nfse/${id}/generate`, null, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (response.ok) {
				dispatch({
					type: GENERATE_NFSE_SUCCESS
				});

				callback && callback();
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GENERATE_NFSE_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: GENERATE_NFSE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteNfse(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_NFSE
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/nfse/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_NFSE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_NFSE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_NFSE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
export function uploadFile(id, file, callback) {
	return async (dispatch, getState) => {
		// dispatch({
		// 	type: UPLOAD_FILE
		// });

		try {
			const { login } = getState();

			if (!id) {
				id = login.user.id;
			}

			let response = await api.sendPostFile(`/nfse/${id}/file`, file, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				// dispatch({
				// 	type: UPLOAD_FILE_SUCCESS
				// });

				callback && callback();
			} else {
				// let result = await response.json();
				// dispatch({
				// 	type: UPLOAD_FILE_ERROR,
				// 	payload: getErrors(result.errors)
				// });
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPLOAD_FILE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
