import React from "react";
import PropTypes from "prop-types";

const TextArea = ({ input, placeholder, errors, meta: { touched, error } }) => (
	<div className="form__form-group-input-wrap">
		<textarea {...input} placeholder={placeholder}></textarea>
		{((touched && error) || errors) && (
			<span className="form__form-group-error">{error || errors}</span>
		)}
	</div>
);

TextArea.propTypes = {
	input: PropTypes.shape().isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	})
};

TextArea.defaultProps = {
	placeholder: "",
	meta: null,
	type: "text"
};

export default TextArea;
