import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { clearValues, getSubscription } from "./actions";

import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import DetailsSubscription from "containers/Subscription/components/DetailsSubscription";

function Details({
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();

	const { item } = useSelector(s => s.subscriptionDetail);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getSubscription(id));
	}, [dispatch, id]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Dados da assinatura</h5>
								</div>

								<Link to={`/subscriptions/${item._id}/edit`}>
									<Button className="mb-0" color="primary">
										Editar
									</Button>
								</Link>
							</Row>

							<DetailsSubscription subscription={item} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Details;
