import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const validate = (values = {}) => {
	const errors = {};

	if (!values.name) errors.name = "Obrigatório";

	if (!values.pushMessage) errors.pushMessage = "Obrigatório";

	if (values.startAt && !dayjs(values.startAt).isValid()) {
		errors.startAt = "Data inválida";
	}

	return errors;
};

export default validate;
