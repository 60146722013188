import validate from "./validate";
import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { CNPJ } from "components/NumberFormat";
import renderCheckBoxField from "shared/components/form/CheckBox";
import { useSelector } from "react-redux";
import Input from "components/ReduxForm/Input";
import renderSelectField from "shared/components/form/Select";
import { ditStatus } from "types/DITStatus";
import ls from "Localization";

const CompanyCreate = ({
	handleSubmit,
	reset,
	loading,
	errors,
	submitting,
	isInvalid,

	salers,
	onSelectSaler,
	onSearchSaler,

	accountManagers,
	onSelectAccountManager,
	onSearchAccountManager,

	connectionTypesData,
	digisacAttendants
}) => {
	const { values } = useSelector(s => s.form.company_create);

	const ditData = Object.keys(ditStatus).map(c => ({
		value: c === "None" ? "" : c,
		label: ls[ditStatus[c]]
	}));

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<div className="card__title border-bottom">
					<h5 className="bold-text">Identificação</h5>
				</div>
				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="bold-text">CNPJ</span>
						<div className="form__form-group-field">
							<Field
								name="cnpj"
								component={CNPJ}
								type="text"
								placeholder="Ex.: 00.000.000/0000-00"
								props={{ errors: errors && errors.cnpj }}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="bold-text">Empresa é contratante?</span>
						<div className="form__form-group-field">
							<Field name="isHired" component={renderCheckBoxField} />
						</div>
					</Col>
				</Row>

				{values.isHired && (
					<>
						<div className="topbar__menu-divider" />

						<Row>
							<Col xs={12} sm={12} md={4} lg={2} className="form__form-group">
								<span className="bold-text">Quantidade Assinaturas</span>
								<div className="form__form-group-field">
									<Field
										name="purchasedProviders"
										component={Input}
										type={"number"}
										props={{ errors: errors && errors.purchasedProviders }}
									/>
								</div>
							</Col>

							<Col xs={12} sm={12} md={4} lg={2} className="form__form-group">
								<span className="bold-text">DIT</span>
								<div className="form__form-group-field">
									<Field
										name="dit"
										placeholder="Selecione"
										component={renderSelectField}
										type="text"
										options={ditData}
									/>
								</div>
							</Col>

							<Col xs={12} sm={12} md={4} lg={2} className="form__form-group">
								<span className="bold-text">Seguro de vida</span>
								<div className="form__form-group-field">
									<Field
										name="lifeInsurance"
										placeholder="Selecione"
										component={renderSelectField}
										type="text"
										options={ditData}
									/>
								</div>
							</Col>

							<Col xs={12} sm={12} md={4} lg={2} className="form__form-group">
								<span className="bold-text">ID do Pipefy</span>
								<div className="form__form-group-field">
									<Field name="pipefyId" component={Input} type="text" />
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
								<span className="bold-text">
									Possui Mensagens Automáticas Bloqueadas?
								</span>
								<div className="form__form-group-field">
									<Field
										name="hasMessageAutomationBlocked"
										component={renderCheckBoxField}
										defaultChecked={true}
									/>
								</div>
							</Col>

							<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
								<span className="bold-text">Tipo de Conexão:</span>
								<div className="form__form-group-field">
									<Field
										name="connectionType"
										component={renderSelectField}
										type="text"
										placeholder="Selecione"
										options={connectionTypesData}
									/>
								</div>
							</Col>

							<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
								<span className="bold-text">Atendente de Onboarding:</span>
								<div className="form__form-group-field">
									<Field
										name="onboardAttendants"
										component={renderSelectField}
										type="text"
										placeholder="Selecione"
										options={digisacAttendants}
										props={{ errors: errors && errors.onboardAttendants }}
									/>
								</div>
							</Col>

							<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
								<span className="bold-text">Atendente de Ongoing:</span>
								<div className="form__form-group-field">
									<Field
										name="ongoingAttendants"
										component={renderSelectField}
										type="text"
										placeholder="Selecione"
										options={digisacAttendants}
										props={{ errors: errors && errors.ongoingAttendants }}
									/>
								</div>
							</Col>
						</Row>
					</>
				)}
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading.create}
				>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "company_create",
	validate
})(CompanyCreate);
