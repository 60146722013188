import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Doughnut } from "react-chartjs-2";

const UsersStatusChart = ({ data, loading }) => {
	const displayData = {
		labels: ["Ativos", "Inativos"],
		datasets: [
			{
				data: [data.enabled, data.disabled],
				backgroundColor: ["#36A2EB", "#D50000"],
				hoverBackgroundColor: ["#36A2EB", "#D50000"],
				borderColor: "rgba(255,255,255,0.54)"
			}
		]
	};

	return (
		<Col xs={12} md={6} lg={4}>
			<Card>
				<CardBody>
					<Row>
						<div className="card__title">
							<h5 className="bold-text">Usuários ativos x Usuários Inativos</h5>
						</div>
					</Row>
					<Doughnut data={displayData} />
				</CardBody>
			</Card>
		</Col>
	);
};

export default UsersStatusChart;
