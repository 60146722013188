import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const GET_CLIENT = "[CLIENT DETAIL] GET_CLIENT";
export const GET_CLIENT_ERROR = "[CLIENT DETAIL] GET_CLIENT_ERROR";
export const GET_CLIENT_SUCCESS = "[CLIENT DETAIL] GET_CLIENT_SUCCESS";

export const QUERY_CLIENT_MEI = "[CLIENT DETAIL] QUERY_CLIENT_MEI";
export const QUERY_CLIENT_MEI_SUCCESS =
	"[CLIENT DETAIL] QUERY_CLIENT_MEI_SUCCESS";
export const QUERY_CLIENT_MEI_FAILED =
	"[CLIENT DETAIL] QUERY_CLIENT_MEI_FAILED";

export const SET_VALUE = "[CLIENT DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CLIENT DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getClient(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CLIENT
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/user/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CLIENT_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CLIENT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CLIENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function queryMei(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: QUERY_CLIENT_MEI
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/user/Mei/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.status === 200) {
				dispatch({
					type: QUERY_CLIENT_MEI_SUCCESS
				});

				callback && callback(null, response.status);
			} else {
				let result = await response.json();
				dispatch({
					type: QUERY_CLIENT_MEI_FAILED,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: QUERY_CLIENT_MEI_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
