import React from "react";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";

export function StatusCards({ cardsData }) {
	return (
		<Row className={"d-flex justify-content-between"}>
			{cardsData.map((el, index) => {
				return (
					<Col key={index} xs={12} sm={12} md={6} lg={2}>
						<Card>
							<CardBody
								style={{
									cursor: "pointer",
									background: "#f5f5f5",
									borderRadius: "10px"
								}}
								onClick={() => el.onClick(el.title)}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
										gap: "1.5rem",
										paddingRight: "20px",
										paddingLeft: "20px"
									}}
								>
									<div
										style={{
											fontSize: "1rem",
											display: "flex",
											flexDirection: "column"
										}}
									>
										<span className="bold-text">Total</span>
										<Badge
											style={{ width: "6rem", textTransform: "uppercase" }}
											color={el.color}
										>
											{el.title}
										</Badge>
									</div>
									<div
										style={{
											width: 2,
											height: 60,

											opacity: 0.1,
											border: "2px solid #000000"
										}}
									/>
									<h1 color={"info"}>{el.count}</h1>
								</div>
							</CardBody>
						</Card>
					</Col>
				);
			})}
		</Row>
	);
}
