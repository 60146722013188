import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import EditForm from "../../components/editForm";
import { getClient } from "../Details/actions";
import { useDispatch, useSelector } from "react-redux";
import { clearValues, updateClient } from "./actions";
import dayjs from "dayjs";
import ls from "Localization";
import {
	MeiStatus,
	UserGender,
	UserMaritalStats,
	UserRoles,
	UserStatus
} from "types/userStatus";
import _ from "lodash";

import { getCompanies } from "../../../Company/containers/Report/actions";
import { stateOptions } from "types/statesList";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

let getCompaniesDebounced = null;

function Edit({
	history,
	match: {
		params: { id }
	}
}) {
	const { item } = useSelector(state => state.clientDetail);
	const dispatch = useDispatch();
	const { loading, errors } = useSelector(s => s.clientEdit);
	const client_edit = useSelector(s => s.form.client_edit);
	const { items: companies } = useSelector(s => s.company);

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getClient(id));
	}, [dispatch]);

	if (!item) return null;

	const onSubmit = data => {
		const {
			street,
			number,
			complement,
			neighborhood,
			city,
			state,
			zipCode
		} = data;

		const model = {
			...item,
			name: data.name,
			lastname: data.lastName || "",
			email: data.email,
			phoneNumber: data.phoneNumber
				.match(/\d/g)
				.join("")
				.trim(),
			roles: data.role ? ["user", UserRoles[data.role.value]] : undefined,
			cpf:
				String(data.cpf || "")
					.match(/\d/g)
					?.join("") || "",
			govPassword: data.govPassword ? data.govPassword : undefined,
			birthDate: dayjs(data.birthDate, "DD/MM/YYYY").toDate(),
			gender: (data.gender || {}).value,
			maritalStatus: (data.maritalStatus || {}).value,
			rg: data.rg,
			rgEmissor: data.rgEmissor,
			rgEmissorState: data.rgEmissorState?.value,
			titulo: data.titulo,
			userStatus: (data.status || {}).value,
			status: (data.statusMei || {}).value,
			mei: (data.mei || {}).value,
			dados: undefined,
			pushTokens: undefined,
			codSimei: data.codSimei || undefined
		};

		if (
			street ||
			number ||
			complement ||
			neighborhood ||
			city ||
			state ||
			zipCode
		) {
			model.address = {
				...item.address,
				street,
				number,
				complement,
				neighborhood,
				city,
				state,
				zipCode
			};
		}

		dispatch(
			updateClient(id, model, err => {
				if (err) {
					if (err.code) {
						alert(err.code);
					} else if (err.errors?.default) alert(err.errors?.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					history.push(`/client/${id}`);
				}
			})
		);
	};

	const handleChangeMei = name => {
		if (name.length >= 3) {
			if (getCompaniesDebounced) {
				getCompaniesDebounced.cancel();
			}

			getCompaniesDebounced = _.debounce(() => {
				dispatch(
					getCompanies(
						0,
						10,
						`&filters[searchText]=${name}&filters[hasMei]=true`,
						null,
						null,
						false,
						err => {
							if (err) {
								alert("Não foi possível carregar as empresas");
							}
						}
					)
				);
			}, 500);

			getCompaniesDebounced();
		}
	};

	const userStatusData = Object.keys(UserStatus).map(c => ({
		value: c,
		label: ls[UserStatus[c]]
	}));

	const userRolesData = Object.keys(UserRoles).map(c => ({
		value: c,
		label: ls[UserRoles[c]]
	}));

	const meiStatus = Object.keys(MeiStatus).map(c => ({
		value: c,
		label: ls[MeiStatus[c]]
	}));

	const userGenders = Object.keys(UserGender).map(c => ({
		value: c,
		label: ls[UserGender[c]]
	}));

	const userMaritalStats = Object.keys(UserMaritalStats).map(c => ({
		value: c,
		label: ls[UserMaritalStats[c]]
	}));

	const stateSelectOptions = stateOptions.map(item => ({
		value: item.sigla,
		label: `${item.sigla} - ${item.name}`
	}));

	let address = item.address || {
		street: "",
		number: "",
		complement: "",
		neighborhood: "",
		city: "",
		state: "",
		country: "BR"
	};

	const role = item.roles.find(c => c !== "user");

	const initialValues = {
		name: item.name || "",
		lastName: item.lastname || "",
		email: item.email || "",
		phoneNumber:
			item.internationalPhoneNumber ||
			item.normalizedPhoneNumber ||
			item.phoneNumber ||
			"",
		role: role ? userRolesData.find(c => c.label === ls[role]) : undefined,
		cpf: item.cpf || "",
		gender: userGenders.find(c => c.value === String(item.gender)),
		maritalStatus: userMaritalStats.find(
			c => c.value === String(item.maritalStatus)
		),
		rg: item.rg || "",
		rgEmissor: item.rgEmissor || "",
		rgEmissorState:
			stateSelectOptions.find(c => c.value === String(item.rgEmissorState)) ||
			"",
		titulo: item.titulo || "",
		status: userStatusData.find(c => c.value === String(item.userStatus)),
		statusMei: meiStatus.find(c => c.value === String(item.status)),
		mei: item.mei
			? {
					value: item.mei._id,
					label: `${item.mei.businessName}`
			  }
			: undefined,
		zipCode: address.zipCode || "",
		street: address.street || "",
		number: address.number || "",
		complement: address.complement || "",
		neighborhood: address.neighborhood || "",
		city: address.city || "",
		state: address.state,
		govPassword: item.govPassword,
		codSimei: item.codSimei || "",
		birthDate: item?.birthDate
			? dayjs.utc(item.birthDate).format("DD/MM/YYYY")
			: ""
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Alterar dados do usuário</h5>
							</div>
							<EditForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								userRolesData={userRolesData}
								userGenders={userGenders}
								userMaritalStats={userMaritalStats}
								meiStatus={meiStatus}
								userStatusData={userStatusData}
								stateSelectOptions={stateSelectOptions}
								loading={loading}
								errors={errors}
								isInvalid={client_edit && !!client_edit.syncErrors}
								companies={companies}
								onChangeMei={handleChangeMei}
								addressId={item.address?._id}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
