import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import renderSelectField from "shared/components/form/Select";
import renderCheckBoxField from "shared/components/form/CheckBox";

const GroupPermissionsAddForm = ({
	handleSubmit,
	loading,
	permissions,
	onChange,
	submitting,
	isInvalid,
	reset
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Permissão</span>
						<div className="form__form-group-field">
							<Field
								name="permission"
								component={renderSelectField}
								type="text"
								onChange={onChange}
								placeholder="Pesquise uma permissão"
								onInputChange={permissionSearch =>
									onChange({ permissionSearch })
								}
								options={[
									...permissions.map(c => ({
										value: c._id,
										label: c.name
									}))
								]}
							/>
						</div>
					</Col>
					<Col xs={12} className="form__form-group">
						<Row>
							<Col className="form__form-group">
								<span className="form__form-group-label">Usuário:</span>
								<div className="form__form-group-field">
									<Field
										name="user.read"
										label="Ler"
										component={renderCheckBoxField}
									/>
								</div>
								<div className="form__form-group-field">
									<Field
										name="user.write"
										label="Escrever"
										component={renderCheckBoxField}
									/>
								</div>
								<div className="form__form-group-field">
									<Field
										name="user.delete"
										label="Excluir"
										component={renderCheckBoxField}
									/>
								</div>
							</Col>
							<Col className="form__form-group">
								<span className="form__form-group-label">Empresa:</span>
								<div className="form__form-group-field">
									<Field
										name="group.read"
										label="Ler"
										component={renderCheckBoxField}
									/>
								</div>
								<div className="form__form-group-field">
									<Field
										name="group.write"
										label="Escrever"
										component={renderCheckBoxField}
									/>
								</div>
								<div className="form__form-group-field">
									<Field
										name="group.delete"
										label="Excluir"
										component={renderCheckBoxField}
									/>
								</div>
							</Col>
							<Col className="form__form-group">
								<span className="form__form-group-label">Geral:</span>
								<div className="form__form-group-field">
									<Field
										name="all.read"
										label="Ler"
										component={renderCheckBoxField}
									/>
								</div>
								<div className="form__form-group-field">
									<Field
										name="all.write"
										label="Escrever"
										component={renderCheckBoxField}
									/>
								</div>
								<div className="form__form-group-field">
									<Field
										name="all.delete"
										label="Excluir"
										component={renderCheckBoxField}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading}
				>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "group_permission_add_form",
	validate
})(GroupPermissionsAddForm);
