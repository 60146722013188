import styled, { css } from "styled-components";

export const TextWrapper = styled.p`
	${({ marginTop }) =>
		marginTop
			? css`
					margin-top: ${marginTop} !important;
			  `
			: css`
					margin-top: 0;
			  `}
	${({ marginBottom }) =>
		marginBottom
			? css`
					margin-bottom: ${marginBottom} !important;
			  `
			: css`
					margin-bottom: 0;
			  `}
  ${({ fontSize }) =>
		fontSize &&
		css`
			font-size: ${fontSize};
		`}
  ${({ fontWeight }) =>
		fontWeight &&
		css`
			font-weight: ${fontWeight};
		`}
  ${({ fontStyle }) =>
		fontStyle &&
		css`
			font-style: ${fontStyle};
		`}
  ${({ lineHeight }) =>
		lineHeight &&
		css`
			line-height: ${lineHeight} !important;
		`}
  ${({ textAlign }) =>
		textAlign &&
		css`
			text-align: ${textAlign};
		`}
  ${({ textTransform }) =>
		textTransform &&
		css`
			text-transform: ${textTransform};
		`}
  ${({ textDecoration }) =>
		textDecoration &&
		css`
			text-decoration: ${textDecoration} !important;
		`}
  ${({ alignSelf }) =>
		alignSelf &&
		css`
			align-self: ${alignSelf};
		`}
  ${({ color }) =>
		color
			? css`
					color: ${color}!important;
			  `
			: css`
					color: inherit !important;
			  `}
        margin: 0;
        line-height: 100%;
`;
