import Input from "components/ReduxForm/Input";
import validate from "./validate";
import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";
import renderDatePickerField from "shared/components/form/DatePicker";

const CreateObligation = ({
	handleSubmit,
	reset,
	loading,
	errors,
	submitting,
	isInvalid,
	obligationStatusData,
	periodStatusData
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.:123"
								props={{ errors: errors && errors.name }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Vencimento</span>
						<div className="form__form-group-field">
							<Field
								name="due"
								component={renderDatePickerField}
								type="text"
								placeholder="Ex.: 10/08/2020"
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Ciclo</span>
						<div className="form__form-group-field">
							<Field
								name="period"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={periodStatusData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Intervalo</span>
						<div className="form__form-group-field">
							<Field
								name="interval"
								component={Input}
								type="number"
								min={1}
								max={31}
								placeholder="Ex.: 1, 2, ... ou 31"
								props={{ errors: errors && errors.interval }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Status</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={obligationStatusData}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading.create}
				>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "obligation_create",
	validate
})(CreateObligation);
