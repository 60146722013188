import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const CREATE_CLIENT = "[CLIENT CREATE] CREATE_CLIENT";
export const CREATE_CLIENT_ERROR = "[CLIENT CREATE] CREATE_CLIENT_ERROR";
export const CREATE_CLIENT_SUCCESS = "[CLIENT CREATE] CREATE_CLIENT_SUCCESS";

export const UPDATE_CLIENT_PASSWORD =
	"[UPDATE PASSWORD] UPDATE_CLIENT_PASSWORD";

export const UPLOAD_CLIENT_AVATAR = "[CLIENT CREATE] UPLOAD_CLIENT_AVATAR";
export const UPLOAD_CLIENT_AVATAR_ERROR =
	"[CLIENT CREATE] UPLOAD_CLIENT_AVATAR_ERROR";
export const UPLOAD_CLIENT_AVATAR_SUCCESS =
	"[CLIENT CREATE] UPLOAD_CLIENT_AVATAR_SUCCESS";

export const SET_VALUE = "[CLIENT CREATE] SET_VALUE";
export const CLEAR_VALUES = "[CLIENT CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createClient(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CLIENT
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/user/admin`, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: CREATE_CLIENT_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();
				const errors = getErrors(result);

				dispatch({
					type: CREATE_CLIENT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);
			callback && callback(error);

			return dispatch({
				type: CREATE_CLIENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function uploadAvatar(id, file, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPLOAD_CLIENT_AVATAR
		});

		try {
			const { login } = getState();

			if (!id) {
				id = login.user.id;
			}

			let response = await api.sendPostFile(`/user/${id}/avatar`, file, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPLOAD_CLIENT_AVATAR_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();
				dispatch({
					type: UPLOAD_CLIENT_AVATAR_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPLOAD_CLIENT_AVATAR_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
