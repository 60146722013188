import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_PLANS = "[PLAN] GET_ALL";
export const GET_PLANS_SUCCESS = "[PLAN] GET_ALL_SUCCESS";
export const GET_PLANS_FAILED = "[PLAN] GET_ALL_FAILED";

export const SET_VALUE = "[PLAN] SET_VALUE";
export const CLEAR_VALUES = "[PLAN] CLEAR_VALUES";

export function setValue(payload) {
	return (dispatch) => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getPlans(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PLANS });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Plan", {
				Authorization: "Bearer " + login.token,
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PLANS_SUCCESS,
					payload: result.model,
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PLANS_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_PLANS_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}
