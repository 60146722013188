import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_CONTRACT_TEMPLATE = "[CONTRACT_DETAIL] GET_CONTRACT_TEMPLATE";
export const GET_CONTRACT_TEMPLATE_ERROR =
	"[CONTRACT_DETAIL] GET_CONTRACT_TEMPLATE_ERROR";
export const GET_CONTRACT_TEMPLATE_SUCCESS =
	"[CONTRACT_DETAIL] GET_CONTRACT_TEMPLATE_SUCCESS";

export const DELETE_CONTRACT_TEMPLATE =
	"[CONTRACT_TEMPLATE DETAIL] DELETE_CONTRACT_TEMPLATE";
export const DELETE_CONTRACT_TEMPLATE_ERROR =
	"[CONTRACT_TEMPLATE DETAIL] DELETE_CONTRACT_TEMPLATE_ERROR";
export const DELETE_CONTRACT_TEMPLATE_SUCCESS =
	"[CONTRACT_TEMPLATE DETAIL] DELETE_CONTRACT_TEMPLATE_SUCCESS";

export const SET_VALUE = "[CONTRACT_DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CONTRACT_DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getContractTemplateById(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_CONTRACT_TEMPLATE
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/templates/contract/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONTRACT_TEMPLATE_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_CONTRACT_TEMPLATE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CONTRACT_TEMPLATE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteContractTemplate(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_CONTRACT_TEMPLATE
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/templates/contract/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_CONTRACT_TEMPLATE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_CONTRACT_TEMPLATE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_CONTRACT_TEMPLATE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
