const validate = values => {
	const errors = {};

	console.log(values);

	if (!values.password) errors.password = "Informe sua senha atual";
	if (!values.newPassword) errors.newPassword = "Informe uma nova senha";
	if (!values.confirmPassword)
		errors.confirmPassword = "Confirme a sua nova senha";

	if (String(values.newPassword).length < 6)
		errors.newPassword = "Senha deve ter pelo menos 6 caracteres";

	if (values.newPassword != values.confirmPassword) {
		errors.confirmPassword =
			"Confirmação de senha não é igual a nova senha informada";
	}

	return errors;
};

export default validate;
