import React, { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import ls from "Localization";
import { toStringCurrency } from "helpers/string";
import { dateSort } from "components/Report/helpers";
import dayjs from "dayjs";
import subscriptionStatus from "types/subscriptionStatus";

import { Col, Container, Row, Button } from "reactstrap";
import ReportTable from "components/Report";
import { MdAttachMoney } from "react-icons/md";

let getSubscriptionsDebounded = null;

function Subscription({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.subscription);

	const handleFetchData = (tableState, instance) => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getSubscriptionsDebounded) {
			getSubscriptionsDebounded.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate && creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate && creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getSubscriptionsDebounded = _.debounce(
			() =>
				dispatch(
					actions.getSubscriptions(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getSubscriptionsDebounded();
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						headerRightComponent={
							<Button
								// onClick={() => history.push("/Dashboard/Subscriptions/Pay")}
								className="mr-2"
								outline
								onClick={() => {}}
							>
								<MdAttachMoney />
							</Button>
						}
						title={ls.subscriptions}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						onRowClicked={row => history.push(`/subscriptions/${row._id}`)}
						columns={[
							{
								Header: ls.creationDate,
								id: "createdAt",
								accessor: c =>
									dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds),
								show: false,
								sortMethod: dateSort
							},
							{
								Header: ls.user,
								id: "user",
								accessor: c =>
									`${c.user && c.user.name ? c.user.name : ""} ${
										c.user && c.user.lastname ? c.user.lastname : ""
									}`
							},
							{
								Header: ls.plan,
								id: "plan",
								show: true,
								accessor: c => (c.plan ? c.plan.name : undefined)
							},
							{
								Header: ls.paymentMethod,
								id: "paymentMethod",
								show: true,
								accessor: c =>
									c.paymentMethod ? c.paymentMethod.name : undefined
							},
							{
								Header: ls.value,
								id: "value",
								filterable: false,
								accessor: c => toStringCurrency(+c.value / 100)
							},
							{
								Header: ls.discount,
								id: "discount",
								filterable: false,
								accessor: c => toStringCurrency(+c.discount || 0 / 100)
							},
							{
								Header: ls.installment,
								id: "installment",
								show: false,
								filterable: false,
								accessor: c => c.installment
							},
							{
								Header: ls.description,
								accessor: "description",
								show: false
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[subscriptionStatus[c.status]]
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Subscription;
