import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Container } from "reactstrap";
import { getCompanies } from "../../../../Company/containers/Report/actions";
import { clearValues } from "../Create/CompanyProvider/actions";
import * as actions from "./actions";
import { stringToMask } from "../../../../../helpers/string";

let findCompanyDebounced = null;

dayjs.extend(customDateFormat);

let getCompanyProviderDebounced = null;

function CompanyProviderListView({ history, companyId }) {
	const dispatch = useDispatch();

	const { items, pages, loading } = useSelector(
		state => state.companyProviderList
	);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getCompanyProviderDebounced) {
			getCompanyProviderDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getCompanyProviderDebounced = _.debounce(
			() =>
				dispatch(
					actions.fetchCompanyProviders(
						page * pageSize,
						pageSize,
						`&filters[provider]=${companyId}` +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);
		getCompanyProviderDebounced();
	};

	useEffect(() => {
		dispatch(clearValues());
		handleFindCompany();
	}, [dispatch]);

	const handleFindCompany = user => {
		if (findCompanyDebounced) {
			findCompanyDebounced.cancel();
		}

		findCompanyDebounced = _.debounce(
			() =>
				dispatch(
					getCompanies(
						0,
						10,
						`&filters[hasMei]=true${
							user ? `&filters[searchText]=${user}` : ""
						}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar os usuários, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findCompanyDebounced();
	};

	const createBadge = status => {
		if (status == 0) {
			return (
				<Badge color={"secondary"} className={"w-100"}>
					Pendente
				</Badge>
			);
		} else if (status == 1) {
			return (
				<Badge color={"info"} className={"w-100"}>
					OnBoard
				</Badge>
			);
		} else if (status == 2) {
			return (
				<Badge color={"success"} className={"w-100"}>
					Ativo
				</Badge>
			);
		} else if (status == 3) {
			return (
				<Badge color={"danger"} className={"w-100"}>
					Inativo
				</Badge>
			);
		} else {
			return "PENDENTE";
		}
	};

	return (
		<Container className="dashboard">
			<ReportTable
				manual
				title={"Clientes"}
				data={items}
				pages={pages}
				pageSize={items.length}
				loading={loading.getAll}
				filterable
				onFetchData={handleFetchData}
				defaultSorted={[
					{
						id: "createdAt",
						desc: true
					}
				]}
				onRowClicked={row =>
					history.push(`/companies/${companyId}/companyprovider/${row._id}`)
				}
				columns={[
					{
						Header: "Data criação",
						id: "createdAt",
						accessor: ({ createdAt }) =>
							dayjs(createdAt).format(ls.dateFormatWithoutSeconds),
						width: 150
					},
					{
						Header: "Nome",
						id: "business.businessName",
						accessor: ({ business }) => business?.businessName
					},
					{
						Header: "CNPJ",
						id: "business.cnpj",
						accessor: ({ business }) =>
							stringToMask(business?.cnpj, "##.###.###/####-##"),
						width: 300
					},
					{
						Header: "Status",
						id: "status",
						accessor: ({ status }) => createBadge(status),
						width: 100
					}
				]}
			/>
		</Container>
	);
}

export default CompanyProviderListView;
