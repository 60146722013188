import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_VERSION_REPORT = "[MARKETING REPORT] GET_VERSION_REPORT";
export const GET_VERSION_REPORT_SUCCESS =
	"[MARKETING REPORT] GET_VERSION_REPORT_SUCCESS";
export const GET_VERSION_REPORT_FAILED =
	"[MARKETING REPORT] GET_VERSION_REPORT_FAILED";

export const CLEAR_VALUES = "[MARKETING REPORT] CLEAR_VALUES";
export const SET_VALUE = "[MARKETING REPORT] SET_VALUE";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getVersionReport(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_VERSION_REPORT });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/v2/version-report?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_VERSION_REPORT_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_VERSION_REPORT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_VERSION_REPORT_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
