import React from "react";
import { Spinner } from "reactstrap";

import { ContainerSpinner } from "./styles";

export default function LoadingComponent() {
	return (
		<ContainerSpinner>
			<Spinner color="success" size="lg" />
		</ContainerSpinner>
	);
}
