import React from "react";

import { createMarketing } from "../../containers/Create/actions";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CreateForm from "../../components/CreateForm";

dayjs.extend(customParseFormat);

function Create({ history }) {
	const dispatch = useDispatch();

	const onSubmit = data => {
		dispatch(
			createMarketing(
				{
					name: data.name,
					startAt: dayjs(data.startAt, "DD/MM/YYYY HH:mm"),
					pushTitle: data.pushTitle,
					pushMessage: data.pushMessage,
					pushAction: data.pushAction ? data.pushAction.value : "",
					pushValue: data.pushValue
				},
				(err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push(`/marketing/${model._id ? model._id : model}`);
					}
				}
			)
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Criar nova campanha</h5>
							</div>
							<CreateForm onSubmit={onSubmit} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
