import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import EditForm from "../../../components/EditForm/editRegularForm";
import { getContractRegularById } from "../DetaillAll/actions";
import { replaceStringCurrencyToNumber, stringToMask } from "helpers/string";
import { getHiredList, updateRegularContract, uploadFile } from "./actions";
import ls from "Localization";
import {
	contractStatus,
	contractTemplateTypes,
	signerStatus
} from "types/contractStatus";
import periodType from "types/periodType";
import { getCompanies } from "../../../../Company/containers/Report/actions";
import _ from "lodash";
import { getDepartmentList } from "../../ReportDepartment/actions";
import dayjs from "dayjs";

let findHirerList = null;
let findHiredList = null;
let getDepartmentDebounced = null;

function Edit({
	history,
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();
	const { item } = useSelector(s => s.contractsDetail);
	const { loading, errors, hireds } = useSelector(s => s.contractEdit);
	const { items: companies } = useSelector(s => s.company);
	const { linkedContracts } = useSelector(s => s.contracts);
	const { items: departmentList } = useSelector(s => s.departmentList);

	const contract_regular_edit = useSelector(s => s.form.contract_regular_edit);

	const onSubmit = data => {
		dispatch(
			updateRegularContract(
				id,
				{
					hired: data.hired.value,
					mei: data.mei.value,

					name: data.name,
					type: data.type?.value,
					department: data.department?.value,

					externalId: data.externalId,
					status:
						data.status && data.status.value ? data.status.value : undefined,
					hirerToken: data.hirerToken,
					hirerStatus: data.hirerStatus ? data.hirerStatus.value : undefined,
					hiredToken: data.hiredToken,
					hiredStatus: data.hiredStatus ? data.hiredStatus.value : undefined,

					startDate: dayjs(data.startDate, "DD/MM/YYYY"),
					selfRenewable: data.selfRenewable,
					lifespan: data.selfRenewable ? data.lifespan : "",
					due: !data.selfRenewable ? dayjs(data.due, "DD/MM/YYYY") : "",

					generateFinancial: data.generateFinancial,
					paymentValue: replaceStringCurrencyToNumber(
						"R$",
						data.paymentValue || "0"
					),
					paymentPeriod: data.paymentPeriod.value,
					paymentDate: dayjs(data.paymentDate, "DD/MM/YYYY"),
					paymentInterval: data.paymentInterval,

					template: item.template,
					note: data.note
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") {
							alert(err);
						} else {
							alert(JSON.stringify(err));
						}
					} else {
						if (data.file) {
							dispatch(
								uploadFile(item._id, data.file, err => {
									if (err) {
										if (typeof err === "string") {
											alert(err);
										} else {
											alert(JSON.stringify(err));
										}
									} else {
										history.goBack();
									}
								})
							);
						} else {
							history.goBack();
						}
					}
				}
			)
		);
	};

	const handleFindHirerList = businessName => {
		console.log("handleFindHirerList");
		if (findHirerList) {
			findHirerList.cancel();
		}

		let filterStr = "&filters[isHired]=true";

		if (businessName) filterStr += `&filters[businessName]=${businessName}`;

		findHirerList = _.debounce(
			() =>
				dispatch(
					getCompanies(0, 10, filterStr, "createdAt", true, false, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: JSON.stringify(err)
							);
					})
				),
			500
		);

		findHirerList();
	};

	const handleSelectHirer = el => {
		console.log("handleSelectHirer");
		handleFindHiredList(el.value);
	};

	const handleFindHired = el => {
		console.log("handleFindHired");
		handleFindHiredList(el);
	};

	const handleSelectHired = () => {
		console.log("handleSelectHired");
	};

	const handleFindHiredList = businessName => {
		console.log("handleFindHiredList");
		if (findHiredList) {
			findHiredList.cancel();
		}

		let filterStr = "";

		if (businessName) filterStr += `filters[businessName]=${businessName}`;

		findHiredList = _.debounce(
			() =>
				dispatch(
					getHiredList(item.mei?._id, filterStr, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: "Erro: " + JSON.stringify(err)
							);
					})
				),
			500
		);

		findHiredList();
	};

	const handleFindDepartment = name => {
		if (getDepartmentDebounced) {
			getDepartmentDebounced.cancel();
		}

		let filterStr = "";

		if (name) filterStr += `&filters[name]=${name}`;

		getDepartmentDebounced = _.debounce(
			() =>
				dispatch(
					getDepartmentList(0, 10, filterStr, "createdAt", true, false, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: JSON.stringify(err)
							);
					})
				),
			500
		);

		getDepartmentDebounced();
	};

	const periodTypeData = Object.keys(periodType).map(c => ({
		value: c,
		label: ls[periodType[c]]
	}));

	const statusData = Object.keys(contractStatus).map(c => ({
		value: c,
		label: ls[contractStatus[c]]
	}));

	const signerStatusData = Object.keys(signerStatus).map(c => ({
		value: c,
		label: ls[signerStatus[c]]
	}));

	const departmentData = departmentList.map(c => ({
		value: c._id,
		label: c.name
	}));

	const typeData = Object.keys(contractTemplateTypes).map(c => ({
		value: c,
		label: ls[contractTemplateTypes[c]]
	}));

	const initialValues = {
		mei: item.mei
			? {
					value: item.mei?._id,
					label: `${item.mei?.businessName}${
						item.mei?.cnpj
							? " - " + stringToMask(item.mei?.cnpj, "##.###.###/####-##")
							: ""
					} - ID: ${item.mei?._id}`
			  }
			: undefined,
		hired: item.hired
			? {
					value: item.hired?._id,
					label: `${item.hired?.businessName}${
						item.hired?.cnpj
							? " - " + stringToMask(item.hired?.cnpj, "##.###.###/####-##")
							: ""
					} - ID: ${item.hired?._id}`
			  }
			: undefined,
		externalId: item.externalId,
		hirerToken: item.hirerToken,
		hirerStatus: signerStatusData.find(
			c => c.value === String(item.hirerStatus)
		),
		hiredToken: item.hiredToken,
		hiredStatus: signerStatusData.find(
			c => c.value === String(item.hiredStatus)
		),
		startDate: dayjs(item.startDate).format("DD/MM/YYYY") || "",
		selfRenewable: item.selfRenewable,
		paymentDate: dayjs(item.paymentDate).format("DD/MM/YYYY") || "",
		due: dayjs(item.due).format("DD/MM/YYYY") || "",
		status: statusData.find(c => c.value === String(item.status)),
		paymentValue: ((item.paymentValue || 0) / 100.0)
			.toFixed(2)
			.replace(".", ","),
		paymentInterval: Number(item.paymentInterval),
		paymentPeriod: periodTypeData.find(
			p => Number(p.value) === item.paymentPeriod
		),
		mainContract: {
			value: item?.mainContract?._id,
			label: `${item?.mainContract?.template.name}${
				item?.mainContract?.sequence ? " - " + item?.mainContract?.sequence : ""
			}`
		},
		linkedContract: {
			value: item?.linkedContract?._id,
			label: `${item?.linkedContract?.template.name}${
				item?.linkedContract?.sequence
					? " - " + item?.linkedContract?.sequence
					: ""
			}`
		},
		sequence: item?.sequence,
		name: item.template?.name,
		department: {
			value: item?.department?._id,
			label: item?.department?.name
		},
		type: {
			value: item.template?.type,
			label: ls[contractTemplateTypes[item.template?.type]]
		},
		lifespan: item.lifespan,
		isMainContract: item.isMainContract,
		note: item.note,
		filename: item.file
	};

	useEffect(() => {
		dispatch(getContractRegularById(id));
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<EditForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={
									contract_regular_edit && !!contract_regular_edit.syncErrors
								}
								errors={errors}
								typeStatus={statusData}
								signerStatus={signerStatusData}
								periodTypeData={periodTypeData}
								typeData={typeData}
								departmentData={departmentData}
								meis={companies}
								hireds={hireds}
								linkedContractList={linkedContracts}
								onSearchMei={handleFindHirerList}
								onSelectMei={handleSelectHirer}
								onSearchHired={handleFindHired}
								onSelectHired={handleSelectHired}
								onSearchDepartment={handleFindDepartment}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
