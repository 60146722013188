import React, { PureComponent } from "react";
import Select from "react-select";
import PropTypes from "prop-types";

class SelectField extends PureComponent {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		onInputChange: PropTypes.func,
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		options: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string
			})
		),
		value: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string
			})
		]).isRequired,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		placeholder: "",
		options: [],
		disabled: false
	};

	handleChange = selectedOption => {
		const { onChange } = this.props;
		onChange(selectedOption);
	};

	render() {
		const {
			value,
			name,
			placeholder,
			options,
			onInputChange,
			disabled
		} = this.props;

		const customStyles = {
			control: base => ({
				...base,
				height: 33,
				minHeight: 33
			})
		};

		return (
			<Select
				name={name}
				value={value}
				onChange={this.handleChange}
				onInputChange={onInputChange}
				options={options}
				clearable={false}
				className="react-select"
				placeholder={placeholder}
				classNamePrefix="react-select"
				styles={customStyles}
				isDisabled={disabled}
			/>
		);
	}
}

const renderSelectField = props => {
	const {
		input,
		onInputChange,
		meta,
		options,
		placeholder,
		className,
		disabled
	} = props;
	return (
		<div className={`form__form-group-input-wrap ${className}`}>
			<SelectField
				{...input}
				onInputChange={onInputChange}
				options={options}
				placeholder={placeholder}
				disabled={disabled}
			/>
			{meta.touched && meta.error && (
				<span className="form__form-group-error">{meta.error}</span>
			)}
		</div>
	);
};

renderSelectField.propTypes = {
	input: PropTypes.shape({
		onChange: PropTypes.func,
		name: PropTypes.string
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		})
	),
	placeholder: PropTypes.string,
	className: PropTypes.string
};

renderSelectField.defaultProps = {
	meta: null,
	options: [],
	placeholder: "",
	className: ""
};

export default renderSelectField;
