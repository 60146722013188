import React from "react";
import Input from "../../../../../../components/ReduxForm/Input";
import { Field, reduxForm } from "redux-form";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import EyeIcon from "mdi-react/EyeIcon";
import PropTypes from "prop-types";
import validate from "../../validate";
import { PureComponent } from "react";
import Spinner from "reactstrap/lib/Spinner";

class ChangePasswordForm extends PureComponent {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired
	};

	constructor() {
		super();
		this.state = {
			showOldPassword: false,
			showNewPassword: false,
			showConfirmationPassword: false
		};
	}

	showOldPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({
			showOldPassword: !prevState.showOldPassword
		}));
	};

	showNewPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({
			showNewPassword: !prevState.showNewPassword
		}));
	};

	showConfirmationPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({
			showConfirmationPassword: !prevState.showConfirmationPassword
		}));
	};

	render() {
		const { handleSubmit, loading } = this.props;
		const {
			showOldPassword,
			showConfirmationPassword,
			showNewPassword
		} = this.state;

		return (
			<form className="form" onSubmit={handleSubmit}>
				<div className="form__form-group">
					<span className="form__form-group-label">Senha Atual</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field
							name="password"
							component={Input}
							type={showOldPassword ? "text" : "password"}
							placeholder="Senha atual"
						/>
						<button
							className={`form__form-group-button${
								showOldPassword ? " active" : ""
							}`}
							onClick={ev => this.showOldPassword(ev)}
							type="button"
						>
							<EyeIcon />
						</button>
					</div>
				</div>

				<div className="form__form-group">
					<span className="form__form-group-label">Nova Senha</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field
							name="newPassword"
							component={Input}
							type={showNewPassword ? "text" : "password"}
							placeholder="Nova senha"
						/>
						<button
							className={`form__form-group-button${
								showNewPassword ? " active" : ""
							}`}
							onClick={ev => this.showNewPassword(ev)}
							type="button"
						>
							<EyeIcon />
						</button>
					</div>
				</div>

				<div className="form__form-group">
					<span className="form__form-group-label">Repetir Nova Senha</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field
							name="confirmPassword"
							component={Input}
							type={showConfirmationPassword ? "text" : "password"}
							placeholder="Repetir nova senha"
						/>
						<button
							className={`form__form-group-button${
								showConfirmationPassword ? " active" : ""
							}`}
							onClick={ev => this.showConfirmationPassword(ev)}
							type="button"
						>
							<EyeIcon />
						</button>
					</div>
				</div>

				<div className="form_footer">
					<button
						className="btn btn-primary account__btn account__btn--small"
						type="submit"
					>
						{loading ? (
							<Spinner type="grow" size="sm" color="light" />
						) : (
							"Alterar Senha"
						)}
					</button>
				</div>
			</form>
		);
	}
}

export default reduxForm({
	form: "change_password_form",
	validate
})(ChangePasswordForm);
