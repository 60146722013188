import React from "react";
import PropTypes from "prop-types";

const Input = ({
	input,
	placeholder,
	type,
	errors,
	meta: { touched, error },
	...props
}) => (
	<div className="form__form-group-input-wrap">
		<input {...input} {...props} placeholder={placeholder} type={type} />
		{((touched && error) || errors) && (
			<span className="form__form-group-error">{error || errors}</span>
		)}
	</div>
);

Input.propTypes = {
	input: PropTypes.shape().isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	})
};

Input.defaultProps = {
	placeholder: "",
	type: "text",
	meta: { touched: false, error: "" }
};

export default Input;
