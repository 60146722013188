export const dasStatus = {
	0: "toExpire",
	1: "liquidated",
	2: "notOpting",
	3: "debtor",
	4: "PENDENTE"
};

export const dasStatusConst = {
	pending: 0,
	paid: 1,
	notOpting: 2,
	overDue: 3,
	"PENDENTE": 4
};
