import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { NavLink } from "react-router-dom";
import getIcon from "./Icon";
import { isInRole } from "helpers/auth";

const SidebarLink = ({ title, icon, newLink, route, onClick, user, roles }) => {
	if (roles && roles.length) {
		if (!isInRole(user, roles)) return null;
	}

	return (
		<NavLink
			to={route}
			onClick={onClick}
			activeClassName="sidebar__link-active"
		>
			<li className="sidebar__link">
				{getIcon(icon)}
				<p className="sidebar__link-title">
					{title}
					{newLink ? (
						<Badge className="sidebar__link-badge">
							<span>New</span>
						</Badge>
					) : (
						""
					)}
				</p>
			</li>
		</NavLink>
	);
};

SidebarLink.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.any,
	newLink: PropTypes.bool,
	route: PropTypes.string,
	roles: PropTypes.array,
	user: PropTypes.any,
	onClick: PropTypes.func
};

SidebarLink.defaultProps = {
	icon: "",
	newLink: false,
	route: "/",
	roles: [],
	user: null,
	onClick: () => {}
};

export default SidebarLink;
