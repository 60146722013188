import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyGroups from "./containers/CompanyGroup";
import Dashboard from "./containers/Dashboard";
import CreateCompany from "./containers/Page/Create";
import CreateObligation from "./containers/Page/Create/Obligation";
import DetailCompany from "./containers/Page/Detail";
import DetailCompanyProvider from "./containers/Page/Detail/CompanyProvider";
import DetailObligationCompany from "./containers/Page/Detail/obligation";
import EditCompany from "./containers/Page/Edit";
import EditObligation from "./containers/Page/Edit/Obligation";
import Report from "./containers/Report";
import ReportAllCompanies from "./containers/ReportAllCompanies";
import HiredsDashboard from "./containers/Dashboard";
import NewCompanyGroup from "./containers/CompanyGroup/newCompanyGroup";
import DetailsCompanyGroup from "./containers/CompanyGroup/detailsCompanyGroup";
import EditCompanyGroup from "./containers/CompanyGroup/editCompanyGroup";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/companies" component={Report} />
				<Route exact path="/companies/new" component={CreateCompany} />
				<Route exact path="/companies/dashboard" component={HiredsDashboard} />
				<Route
					exact
					path="/companies/report-all"
					component={ReportAllCompanies}
				/>
				<Route
					exact
					path="/companies/company-groups"
					component={CompanyGroups}
				/>
				<Route
					exact
					path="/companies/company-group/new"
					component={NewCompanyGroup}
				/>
				<Route
					exact
					path="/companies/company-group/:id"
					component={DetailsCompanyGroup}
				/>
				<Route
					exact
					path="/companies/company-group/:id/edit"
					component={EditCompanyGroup}
				/>

				<Route exact path="/companies/:id/edit" component={EditCompany} />
				<Route exact path="/companies/:id" component={DetailCompany} />

				<Route exact path="/client/new" component={CreateObligation} />
				<Route
					exact
					path="/companies/:business/companyProvider/:id"
					component={DetailCompanyProvider}
				/>
				<Route
					exact
					path="/companies/:business/obligations/new"
					component={CreateObligation}
				/>
				<Route
					exact
					path="/companies/:business/obligations/:id"
					component={DetailObligationCompany}
				/>
				<Route
					exact
					path="/companies/:business/obligations/:id/edit"
					component={EditObligation}
				/>
			</Switch>
		</React.Fragment>
	);
};

export default routes;
