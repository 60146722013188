import React from "react";
import { Route, Switch } from "react-router-dom";

import Report from "./containers/Report";
import Create from "./containers/Page/Create"
import Details from "./containers/Page/Details";
import Edit from "./containers/Page/Edit";

const routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/tax" component={Report} />
        <Route exact path="/tax/new" component={Create} />
        <Route exact path="/tax/:id" component={Details} />
        <Route exact path="/tax/:id/edit" component={Edit} />
      </Switch>
    </React.Fragment>
  );
};

export default routes;
