import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_DAS = "[DAS] GET_DAS";
export const GET_DAS_SUCCESS = "[DAS] GET_DAS_SUCCESS";
export const GET_DAS_FAILED = "[DAS] GET_DAS_FAILED";

export const RELOAD_DAS = "[DAS] RELOAD_DAS";
export const RELOAD_DAS_SUCCESS = "[DAS] RELOAD_DAS_SUCCESS";
export const RELOAD_DAS_FAILED = "[DAS] RELOAD_DAS_FAILED";

export const GET_INSTALLMENT_DAS = "[DAS] GET_INSTALLMENT_DAS";
export const GET_INSTALLMENT_DAS_SUCCESS = "[DAS] GET_INSTALLMENT_DAS_SUCCESS";
export const GET_INSTALLMENT_DAS_FAILED = "[DAS] GET_INSTALLMENT_DAS_FAILED";

export const SET_VALUE = "[DAS] SET_VALUE";
export const CLEAR_VALUES = "[DAS] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getTaxes(skip, take, filter, sort, desc, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_DAS });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/das?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_DAS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_DAS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_DAS_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function reloadTaxes(cnpj, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: RELOAD_DAS });

		try {
			const { login } = getState();

			var response = await api.sendGet(`/das/consult-das-cnpj/${cnpj}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: RELOAD_DAS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: RELOAD_DAS_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: RELOAD_DAS_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getInstallmentDas(cnpj, codSimei, cpf, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_INSTALLMENT_DAS });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/das/get-installment-das?cnpj=${cnpj}&codSimei=${codSimei}&cpf=${cpf}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_INSTALLMENT_DAS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_INSTALLMENT_DAS_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_INSTALLMENT_DAS_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
