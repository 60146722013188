import * as actions from "./actions";

const initialState = {
	item: null,
	users: [],
	contactCompany: {},
	loading: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_COMPANY: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_COMPANY_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: { get: false }
			};
		}
		case actions.GET_COMPANY_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}

		case actions.GET_USERS_BY_COMPANY: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_USERS_BY_COMPANY_SUCCESS: {
			return {
				...state,
				users: payload || [],
				loading: { get: false }
			};
		}
		case actions.GET_USERS_BY_COMPANY_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
