const validate = values => {
	const errors = {};

	if (!values.status) {
		errors.status = "Selecione o status da obrigação";
	}

	if (!values.period) {
		errors.period = "Selecione o ciclo da obrigação";
	}

	if (!values.due) {
		errors.period = "Data de vencimento da obrigação";
	}

	return errors;
};

export default validate;
