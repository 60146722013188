import * as api from "../../../../services/api";
import { getErrors } from "../../../../helpers/error";

export const UPDATE_CLIENT = "[CLIENT UPDATE] UPDATE_CLIENT";
export const UPDATE_CLIENT_ERROR = "[CLIENT UPDATE] UPDATE_CLIENT_ERROR";
export const UPDATE_CLIENT_SUCCESS = "[CLIENT UPDATE] UPDATE_CLIENT_SUCCESS";

export const LINK_COMPANY = "[LINK COMPANY] LINK_COMPANY";
export const LINK_COMPANY_ERROR = "[LINK COMPANY] LINK_COMPANY_ERROR";
export const LINK_COMPANY_SUCCESS = "[LINK COMPANY] LINK_COMPANY_SUCCESS";

export const SET_VALUE = "[CLIENT UPDATE] SET_VALUE";
export const CLEAR_VALUES = "[CLIENT UPDATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function linkUserCompany(id, company_id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: LINK_COMPANY });

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/user/link/company`,
				{ user_id: id, company_id },
				{
					Authorization: "Bearer " + login.token
				}
			);

			if (response.ok) {
				dispatch({
					type: LINK_COMPANY_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: LINK_COMPANY_ERROR
				});
				callback && callback();
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CLIENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateClient(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_CLIENT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/user/${id} `, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPDATE_CLIENT_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CLIENT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CLIENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
