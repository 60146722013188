import React from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Spinner
} from "reactstrap";

import { useGlobalContext } from "contexts/GlobalContext";

const GlobalModal = ({
	children,
	title,
	onOk,
	labelButton,
	onCancel,
	loading
}) => {
	const { modalVisible, hideModal } = useGlobalContext();

	return (
		<Modal isOpen={modalVisible} toggle={hideModal}>
			<ModalHeader toggle={hideModal}>{title}</ModalHeader>
			<ModalBody>{children}</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={onOk}>
					{loading ? (
						<Spinner type="grow" size="sm" color="light" />
					) : labelButton ? (
						labelButton
					) : (
						"Continuar"
					)}
				</Button>{" "}
				<Button color="secondary" onClick={onCancel ? onCancel : hideModal}>
					Cancelar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default GlobalModal;
