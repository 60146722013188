const validate = values => {
	const errors = {};

	if (!values.cnpj) {
		errors.cnpj = "Preencha o campo CNPJ";
	}

	if (values.isHired === true) {
		if (!values.purchasedProviders) {
			errors.purchasedProviders = "Preencha o campo Quantidade Assinaturas";
		}

		if (!values.onboardAttendants) {
			errors.onboardAttendants = "Selecione o Atendente de Onboarding";
		}

		if (!values.ongoingAttendants) {
			errors.ongoingAttendants = "Selecione o Atendente de Ongoing";
		}
	}

	return errors;
};

export default validate;
