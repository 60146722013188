export default {
	0: "pending",
	1: "paid",
	2: "failed",
	3: "canceled",
	4: "notPaid"
};

export const bankStatus = {
	0: "NoAccount",
	1: "Pending",
	2: "Enabled",
	3: "Active",
	4: "Deleted"
};
