/* eslint-disable no-unused-vars */
import classNames from "classnames";
import {
	getCompanyProviderById,
	clearValues,
	getProviderHistory
} from "containers/Company/containers/Page/Detail/CompanyProvider/actions";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import CompanyDetails from "./companyDetails";
import HistoryDetails from "./historyDetails";
import ProviderDetails from "./providerDetails";

dayjs.extend(customDateFormat);
let getCompanyProviderHistoryDebounced = null;

function DetailCompanyProviderComp({ companyProvider }) {
	const dispatch = useDispatch();

	const { history: historyData } = useSelector(
		state => state.companyProviderDetail
	);

	const [activeTab, setActiveTab] = useState("details");
	const toggleTabs = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const handleFetchData = () => {
		if (getCompanyProviderHistoryDebounced) {
			getCompanyProviderHistoryDebounced.cancel();
		}

		getCompanyProviderHistoryDebounced = _.debounce(
			() =>
				dispatch(
					getProviderHistory(
						companyProvider._id,
						0,
						0,
						"",
						"createdAt",
						true,
						null,
						null
					)
				),
			500
		);
		getCompanyProviderHistoryDebounced();
	};

	const load = () => {
		dispatch(clearValues());
		handleFetchData();
	};

	useEffect(() => {
		load();
	}, [activeTab]);

	return (
		<Container className="dashboard">
			<Row className="shadow-sm">
				<Nav tabs className="ml-1" style={{ cursor: "pointer" }}>
					<NavItem>
						<NavLink
							className={classNames({ active: activeTab === "details" })}
							onClick={() => {
								toggleTabs("details");
							}}
						>
							Usuário
						</NavLink>
					</NavItem>
					{companyProvider?.provider && (
						<NavItem>
							<NavLink
								className={classNames({ active: activeTab === "company" })}
								onClick={() => {
									toggleTabs("company");
								}}
							>
								Empresa
							</NavLink>
						</NavItem>
					)}

					<NavItem>
						<NavLink
							className={classNames({ active: activeTab === "history" })}
							onClick={() => {
								toggleTabs("history");
							}}
						>
							Histórico
						</NavLink>
					</NavItem>
				</Nav>
			</Row>
			{activeTab == "details" && (
				<ProviderDetails companyProvider={companyProvider} />
			)}

			{activeTab == "company" && (
				<CompanyDetails companyProvider={companyProvider} />
			)}

			{activeTab == "history" && (
				<div className="mt-3">
					<HistoryDetails data={historyData} />
				</div>
			)}
		</Container>
	);
}

export default DetailCompanyProviderComp;
