import React, { useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import {
	FlexibleWidthXYPlot,
	HorizontalGridLines,
	LineSeries,
	VerticalRectSeries,
	XAxis,
	YAxis,
	DiscreteColorLegend
} from "react-vis";
import dayjs from "dayjs";
import customParser from "dayjs/plugin/customParseFormat";
import _ from "lodash";

dayjs.extend(customParser);

const sort = (a, b) => {
	if (!a && !b) return 0;
	if (!a) return -1;
	if (!b) return 1;

	a = dayjs(`${a._id.year}${String(a._id.month).padStart(2, "0")}`, "YYYYMM");
	b = dayjs(`${b._id.year}${String(b._id.month).padStart(2, "0")}`, "YYYYMM");

	return a.isAfter(b) ? 1 : -1;
};

const populate = data => {
	data = data.sort(sort);
	const firstMei = data[0];
	const lastMei = data[data.length - 1];

	if (firstMei) {
		let startMei = dayjs(
			`${firstMei._id.year}${String(firstMei._id.month).padStart(2, "0")}`,
			"YYYYMM"
		);

		const endMei = dayjs(
			`${lastMei._id.year}${String(lastMei._id.month).padStart(2, "0")}`,
			"YYYYMM"
		);

		while (startMei.isBefore(endMei)) {
			startMei = startMei.add(1, "month");

			const mei = data.find(
				c =>
					c._id.year === startMei.year() && c._id.month === startMei.month() + 1
			);

			if (!mei) {
				data.push({
					_id: {
						year: startMei.year(),
						month: startMei.month() + 1
					},
					count: 0
				});
			}
		}
	}

	return data;
};

const UsersVersusMei = ({ contentData }) => {
	const xDomain = [];

	const handleMap = c => {
		let key =
			dayjs(
				`${c._id.year}${String(c._id.month).padStart(2, "0")}`,
				"YYYYMM"
			).diff(dayjs().subtract(1, "year"), "days") / 30;

		key = Math.round(key);

		if (!xDomain.includes(key)) {
			xDomain.push(key);
		}

		return {
			x: key,
			y: c.count,
			date: dayjs(key, "YYYYMM").format()
		};
	};

	const meis = populate(contentData.meis);
	const users = populate(contentData.users);

	const mountData = {
		series: [
			{
				title: "Empresas",
				disabled: false,
				data: meis.map(handleMap)
			},
			{
				title: "Usuários",
				disabled: false,
				data: users.map(handleMap)
			}
		]
	};

	const [data, setData] = useState(mountData);

	const toggleLegend = title => {
		const index = data.series.indexOf(data.series.find(s => s.title === title));
		const newData = _.cloneDeep(data);
		newData.series[index].disabled = !newData.series[index].disabled;
		setData(newData);
	};

	return (
		<Col xs={12} md={12} lg={12}>
			<Card>
				<CardBody>
					<div className="card__title">
						<h5 className="bold-text">Usuários x Empresas</h5>
					</div>
					<div className="react-vis" dir="ltr" style={{ display: "flex" }}>
						<FlexibleWidthXYPlot
							animation
							// getX={d => d.left}
							// getY={d => d.top}
							height={300}
						>
							<HorizontalGridLines />
							<YAxis
								title="Quantidade"
								className="cool-custom-name"
								tickSizeInner={0}
								tickSizeOuter={8}
								orientation={"left"}
							/>
							<XAxis
								className="even-cooler-custom-name"
								tickSizeInner={0}
								tickSizeOuter={8}
								tickValues={xDomain}
								tickFormat={c =>
									dayjs()
										.subtract(1, "year")
										.add(c, "month")
										.format("MM/YYYY")
								}
							/>
							<VerticalRectSeries
								data={data.series[1].data.map(({ x, y }) => {
									const serie = {
										x,
										x0: x - 1,
										y,
										y0: 0
									};

									return serie;
								})}
								stroke="white"
								{...(data.series[1].disabled ? { opacity: 0.2 } : null)}
								color="#36A2EB"
							/>
							<LineSeries
								className="react-vis__svg-line"
								data={data.series[0].data}
								curve="curveMonotoneX"
								{...(data.series[0].disabled ? { opacity: 0.2 } : null)}
								color="#D50000"
							/>
						</FlexibleWidthXYPlot>
						<DiscreteColorLegend
							items={[
								{
									title: "Usuários",
									color: "#D50000"
								},
								{
									title: "Empresas",
									color: "#36A2EB"
								}
							]}
							onItemClick={eve => toggleLegend(eve.title)}
						/>
					</div>
				</CardBody>
			</Card>
		</Col>
	);
};

export default UsersVersusMei;
