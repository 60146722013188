import XLSX from "xlsx-js-style";

import { saveAs } from "file-saver";
import dayjs from "dayjs";

export default function ExportExcel(dataSet, title, type = "xlsx") {
	try {
		const workbook = XLSX.utils.book_new();

		const ws = XLSX.utils.json_to_sheet(dataSet.data, {
			header: dataSet.columns
		});

		ws["!cols"] = fitToColumn(dataSet.data);

		for (const w of Object.keys(ws).filter(el => el.match(/^[A-Z]1$/))) {
			ws[w].s = {
				fill: {
					fgColor: { rgb: "707070" }
				},
				font: {
					bold: true,
					color: { rgb: "ffffff" }
				}
			};
		}

		XLSX.utils.book_append_sheet(workbook, ws, title);

		const wbout = XLSX.write(workbook, {
			bookType: type,
			type: "array"
		});

		saveAs(
			new Blob([wbout], { type: "application/octet-stream" }),
			title + "_" + dayjs().format("YYYY-MM-DD-hh-mm-ss") + "." + type
		);
	} catch (error) {
		console.log("error", error);
	}
}

const fitToColumn = data => {
	const columnWidths = [];
	for (const property in data[0]) {
		columnWidths.push({
			wch:
				Math.max(
					property ? property.toString().length : 0,
					...data.map(obj =>
						obj[property] ? obj[property].toString().length : 0
					)
				) + 1
		});
	}

	return columnWidths;
};
