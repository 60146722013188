import * as api from "services/api/index";
import { getErrors } from "helpers/error";

export const GET_TAX = "[TAX DETAIL] GET_TAX";
export const GET_TAX_ERROR = "[TAX DETAIL] GET_TAX_ERROR";
export const GET_TAX_SUCCESS = "[TAX DETAIL] GET_TAX_SUCCESS";

export const DELETE_TAX = "[TAX DETAIL] DELETE_TAX";
export const DELETE_TAX_ERROR = "[TAX DETAIL] DELETE_TAX_ERROR";
export const DELETE_TAX_SUCCESS = "[TAX DETAIL] DELETE_TAX_SUCCESS";

export const SET_VALUE = "[TAX DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[TAX DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getTax(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_TAX
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/das/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_TAX_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_TAX_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_TAX_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteTax(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_TAX
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/das/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_TAX_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_TAX_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_TAX_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
