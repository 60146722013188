import NoteCard from "../../../../components/NoteCard/NoteCard";
import React from "react";
import { useState } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { Button } from "reactstrap";

const HistoryDetails = ({ data }) => {
	const [page, setPage] = useState(0);
	const take = 7;

	const changePage = step => {
		if (step >= 0 && step < data.length) setPage(step);
	};

	return (
		<>
			<div>
				<span>Total: {data.length}</span>
				<div className="d-flex justify-content-center">
					<Button
						size={"sm"}
						color={"primary"}
						onClick={() => changePage(page - take)}
					>
						<AiOutlineDoubleLeft />
					</Button>

					<Button
						color={"primary"}
						className="ml-3 mr-3 p-0"
						style={{ height: "20px", width: "3rem" }}
					>
						{page / take}
					</Button>

					<Button
						size={"sm"}
						color={"primary"}
						onClick={() => changePage(page + take)}
					>
						<AiOutlineDoubleRight />
					</Button>
				</div>
			</div>

			{data.slice(page, page + take).map((el, i) => (
				<NoteCard
					key={i}
					id={i}
					noteText={el.note}
					title={el.title}
					postDate={el.createdAt}
					authorName={el.createdByName}
				/>
			))}
		</>
	);
};

export default HistoryDetails;
