import React from "react";

import { useSelector } from "react-redux";
import pushAction from "types/pushAction";
import ls from "Localization";

import { Col, Row } from "reactstrap";
import { Field } from "redux-form";
import { MONEY } from "components/NumberFormat";
import Input from "components/ReduxForm/Input";
import TextArea from "components/ReduxForm/TextArea";
import renderSelectField from "shared/components/form/Select";

const PushForm = () => {
	const { errors } = useSelector(s => s.marketingCreate);

	const pushActions = Object.keys(pushAction).map(c => ({
		value: c,
		label: ls[pushAction[c]]
	}));

	return (
		<>
			<Row>
				<Col xs={12} sm={12} md={12} xl={12} className="form__form-group">
					<span className="form__form-group-label">Titulo</span>
					<div className="form__form-group-field">
						<Field
							name="pushTitle"
							component={Input}
							type="text"
							placeholder="Ex.: Postagem"
							props={{ errors: errors.pushTitle }}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={12} md={12} xl={12} className="form__form-group">
					<span className="form__form-group-label">Mensagem*</span>
					<div className="form__form-group-field">
						<Field
							name="pushMessage"
							component={TextArea}
							type="text"
							props={{ errors: errors.pushMessage }}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={6} md={6} xl={6} className="form__form-group">
					<span className="form__form-group-label">Ação</span>
					<div className="form__form-group-field">
						<Field
							name="pushAction"
							placeholder="Selecione"
							component={renderSelectField}
							type="text"
							options={pushActions}
						/>
					</div>
				</Col>

				<Col xs={12} sm={12} md={6} xl={6} className="form__form-group">
					<span className="form__form-group-label">Valor</span>
					<div className="form__form-group-field">
						<Field
							name="pushValue"
							component={MONEY}
							type="text"
							placeholder="EX.: R$ 120,00"
							props={{ errors: errors.pushValue }}
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default PushForm;
