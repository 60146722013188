import * as api from "services/api";
import { getErrors } from "helpers/error";

export const UPDATE_OBLIGATION = "[OBLIGATION UPDATE] UPDATE_OBLIGATION";
export const UPDATE_OBLIGATION_ERROR =
	"[OBLIGATION UPDATE] UPDATE_OBLIGATION_ERROR";
export const UPDATE_OBLIGATION_SUCCESS =
	"[OBLIGATION UPDATE] UPDATE_OBLIGATION_SUCCESS";

export const SET_VALUE = "[OBLIGATION UPDATE] SET_VALUE";
export const CLEAR_VALUES = "[OBLIGATION UPDATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function updateObligation(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_OBLIGATION
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/obligation/${id} `, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPDATE_OBLIGATION_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_OBLIGATION_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_OBLIGATION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
