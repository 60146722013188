import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import DetailNFSe from "../../../components/DetailForm/NFSeDetail";
import { getNFSeById, uploadFile, updateNfse, generateNfse } from "./actions";
import { toast } from "react-toastify";

function Details({
	match: {
		params: { id }
	},
	history
}) {
	const { item, loading } = useSelector(state => state.nfseDetail);
	const dispatch = useDispatch();

	const [fileUp, setFileUp] = useState(null);

	const onChangeUp = file => {
		setFileUp(file.target.files[0]);
	};

	const confirm = () => {
		item.status = 1;
		if (fileUp != null) {
			dispatch(uploadFile(id, fileUp));
		}
		dispatch(updateNfse(id, item));
		history.go(-1);
	};

	const generate = () => {
		dispatch(
			generateNfse(id, err => {
				if (err) {
					toast.error(err, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				} else {
					toast.success("Sucesso, a nota será gerada em alguns segundos.", {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				}
			})
		);
	};

	const excludeNfseFile = useCallback(() => {
		const update = {
			status: 0,
			file: ""
		};

		dispatch(
			updateNfse(id, update, err => {
				if (err) alert(err);
				else dispatch(getNFSeById(id));
			})
		);
	}, [dispatch, item, id]);

	const reject = () => {
		item.status = 2;
		dispatch(updateNfse(id, item));
		history.go(-1);
	};

	useEffect(() => {
		dispatch(getNFSeById(id));
	}, [dispatch, id]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h4 className="bold-text">Detalhes da NFS-e</h4>
								</div>
								<div>
									<Button color="danger" onClick={reject}>
										Cancelar
									</Button>

									{[0, 9].includes(item?.status) && (
										<Button color="danger" onClick={generate}>
											Emitir Nota Nacional
										</Button>
									)}

									<Button
										color="primary"
										onClick={() => history.push(`/nfses/${id}/edit`)}
									>
										Editar
									</Button>

									<Button color="primary" onClick={confirm}>
										Confirmar Emissão
									</Button>

									{item?.file && (
										<Button color="secondary" onClick={excludeNfseFile}>
											Excluir arquivo
										</Button>
									)}
								</div>
							</Row>
							<DetailNFSe
								nfse={{
									...item
								}}
								onChange={onChangeUp}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Details;
