const jsonwebtoken = require("jsonwebtoken");

export function isLogged(login) {
	const { token, user } = login;

	if (token) {
		const decodedToken = jsonwebtoken.decode(token, { complete: true });

		if (decodedToken && user && decodedToken.payload._id === user._id) {
			return true;
		}
	}
	return false;
}

export const isInRole = (user, roles) => {
	if (!user) return false;

	if (!user.roles) return false;

	return Boolean(
		user.roles.find(c => roles.find(r => r.toLowerCase() === c.toLowerCase()))
	);
};
