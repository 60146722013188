import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import GroupCreateForm from "../../components/GroupCreateForm";
import { clearValues, createGroup } from "./actions";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.permissionCreate);
	const group_create = useSelector(s => s.form.group_create);

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	const onSubmit = data => {
		dispatch(
			createGroup(
				{
					name: data.name,
					description: data.description
				},
				err => {
					if (err) {
						console.log(err);
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push("/group");
					}
				}
			)
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<div className="card__title">
							<h4 className="bold-text">Cadastrar novo grupo de permissões</h4>
						</div>
						<CardBody>
							<GroupCreateForm
								onSubmit={onSubmit}
								loading={loading}
								isInvalid={group_create && !!group_create.syncErrors}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
