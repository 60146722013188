import GlobalModal from "components/Modal";
import { useGlobalContext } from "contexts/GlobalContext";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import DetailsForm from "../../../components/DetailForm/detailForm";
import { getContractTemplateById, deleteContractTemplate } from "./actions";

function Details({
	match: {
		params: { id }
	},
	history
}) {
	const { showModal, hideModal } = useGlobalContext();
	const { item, loading } = useSelector(state => state.contractTemplateDetail);
	const dispatch = useDispatch();

	const handleDelete = () => {
		dispatch(
			deleteContractTemplate(id, err => {
				console.log(err);
				if (err) {
					alert(String(err));
				} else {
					history.push("/contracts/template");
				}
				hideModal();
			})
		);
	};

	useEffect(() => {
		dispatch(getContractTemplateById(id));
	}, [dispatch]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes Modelo Contrato</h5>
								</div>
								<div>
									<Button color="danger" onClick={showModal}>
										Excluir
									</Button>
									<Link to={`/contracts/template/${id}/edit`}>
										<Button color="primary">Editar</Button>
									</Link>
								</div>
							</Row>
							<DetailsForm
								contract={{
									...item
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<GlobalModal
				title="Deletar modelo de contrato"
				onOk={handleDelete}
				loading={loading.delete}
			>
				<p>
					Tem certeza que deseja excluir este modelo de contrato? Essa ação é
					irreversível
				</p>
			</GlobalModal>
		</Container>
	);
}

export default Details;
