import * as actions from "./actions";

const initialState = {
	items: [],
	filter: [],
	pages: null,
	count: 0,
	loading: { getAll: false, import: false },
	errors: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_BANK_BILLETS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors,
			};
		}
		case actions.GET_BANK_BILLETS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false },
			};
		}
		case actions.GET_BANK_BILLETS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload,
			};
		}
		case actions.IMPORT_BANK_BILLETS: {
			return {
				...state,
				loading: { import: true },
				errors: initialState.errors,
			};
		}
		case actions.IMPORT_BANK_BILLETS_SUCCESS: {
			return {
				...state,
				loading: { import: false },
			};
		}
		case actions.IMPORT_BANK_BILLETS_FAILED: {
			return {
				...state,
				loading: { import: false },
				errors: payload,
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload,
			};
		default: {
			return state;
		}
	}
};

export default reducer;
