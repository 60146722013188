import React from "react";
import { Button, ButtonToolbar, Col, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import TextArea from "components/ReduxForm/TextArea";

const ActivationForm = ({ handleSubmit, reset, action }) => {
	return (
		<form className="form d-flex flex-column" onSubmit={handleSubmit}>
			<Row>
				<Col xs={12} sm={12} md={12} lg={12} className="form__form-group">
					<span className="form__form-group-label">Comentário</span>
					<div className="form__form-group-field">
						<Field name="note" component={TextArea} type="text-area" />
					</div>
				</Col>
			</Row>

			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit">
					{action}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "activation_form"
})(ActivationForm);
