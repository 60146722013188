import ReportTable from "components/Report";
import _ from "lodash";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Container, Row } from "reactstrap";
import * as actions from "./actions";
import dayjs from "dayjs";
import ls from "Localization";
import {
	contractTemplateStatus,
	contractTemplateStatusConst,
	contractTemplateTypes,
	contractTemplateTypesConst
} from "types/contractStatus";

let getContractsDebounced = null;

function Contracts() {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.contractTemplateList);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getContractsDebounced) {
			getContractsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}
		getContractsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getContractTemplateList(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => {
							return p + `&filters[${c.id}]=${c.value}`;
						}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getContractsDebounced();
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Lista modelos"}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						onRowClicked={row => {
							let win = window.open(`/contracts/template/${row._id}`, "_blank");
							win.focus();
						}}
						columns={[
							{
								Header: "Data Cadastro",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatShort)
										: "",
								width: 85
							},
							{
								Header: "Última Atualização",
								id: "updatedAt",
								accessor: c =>
									c.updatedAt
										? dayjs(c.updatedAt).format(ls.dateFormatShort)
										: "",
								show: false
							},
							{
								Header: "Nome",
								id: "name",
								filterable: true,
								accessor: c => (c.name ? c.name : "Não definido")
							},
							{
								Header: "Empresa",
								id: "mei.businessName",
								filterable: true,
								accessor: c =>
									c.mei && c.mei.businessName
										? `${c.mei.businessName}`
										: "Não definido"
							},
							{
								Header: "ID Externo",
								id: "externalId",
								filterable: true,
								accessor: c => (c.externalId ? c.externalId : "Não definido"),
								width: 180,
								show: false
							},
							{
								Header: ls.statusContractTemplate,
								id: "status",
								show: true,
								accessor: ({ status }) => {
									switch (status) {
										case contractTemplateStatusConst.Active:
											return (
												<Badge color="success" className="w-100">
													ATIVO
												</Badge>
											);
										case contractTemplateStatusConst.Inactive:
											return (
												<Badge color="warning" className="w-100">
													INATIVO/SUSPENSO
												</Badge>
											);

										default:
											return (
												<Badge color="secondary" className="w-100">
													Pendente
												</Badge>
											);
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(contractTemplateStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[contractTemplateStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 130
							},
							{
								Header: "Tipo",
								id: "type",
								show: true,
								accessor: ({ type }) => {
									switch (type) {
										case contractTemplateTypesConst.Others:
											return (
												<Badge
													style={{ backgroundColor: "red" }}
													className="w-100"
												>
													OUTROS
												</Badge>
											);
										case contractTemplateTypesConst.Nda:
											return (
												<Badge
													style={{ backgroundColor: "brown" }}
													className="w-100"
												>
													NDA
												</Badge>
											);
										case contractTemplateTypesConst.Confidentiality:
											return (
												<Badge
													style={{ backgroundColor: "orange" }}
													className="w-100"
												>
													CONFIDENCIALIDADE
												</Badge>
											);
										case contractTemplateTypesConst.Lending:
											return (
												<Badge
													style={{ backgroundColor: "blue" }}
													className="w-100"
												>
													EMPRÉSTIMO
												</Badge>
											);
										case contractTemplateTypesConst.ServicesProvision:
											return (
												<Badge
													style={{ backgroundColor: "darkgreen" }}
													className="w-100"
												>
													PRESTAÇÃO DE SERVIÇOS
												</Badge>
											);
										case contractTemplateTypesConst.ContractAmendment:
											return (
												<Badge
													style={{ backgroundColor: "darkgreen" }}
													className="w-100"
												>
													ADITIVO CONTRATUAL
												</Badge>
											);
										case contractTemplateTypesConst.Dissolution:
											return (
												<Badge
													style={{ backgroundColor: "darkred" }}
													className="w-100"
												>
													DISTRATO
												</Badge>
											);

										default:
											return "";
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(contractTemplateTypes)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[contractTemplateTypes[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 130
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Contracts;
