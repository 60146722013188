export const nfseStatus = {
	0: "Pending",
	1: "Generated",
	2: "Canceled",
	3: "waitApproval",
	4: "rejected",
	9: "Error"
};

export const nfseStatusConst = {
	Pending: 0,
	Generated: 1,
	Canceled: 2,
	waitApproval: 3,
	rejected: 4,
	Error: 9
};

export const typePerson = {
	0: "individual",
	1: "company"
};

export const NfEmissionStatus = {
	0: "Pending",
	1: "Active"
};
