import React from "react";
import { Route, Switch } from "react-router-dom";

import Report from "./containers/Report";
import Create from "./containers/Create";
import Details from "./containers/Details";
import Edit from "./containers/Edit";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/version-report/new" component={Create} />
				<Route exact path="/version-report" component={Report} />
				<Route exact path="/version-report/:id" component={Details} />
				<Route exact path="/version-report/:id/edit" component={Edit} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
