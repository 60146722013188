import * as api from "../../../../../services/api";
import { getErrors } from "../../../../../helpers/error";

export const GET_PERMISSIONS = "[PERMISSIONS DETAIL] GET_PERMISSIONS";
export const GET_PERMISSIONS_ERROR =
	"[PERMISSIONS DETAIL] GET_PERMISSIONS_ERROR";
export const GET_PERMISSIONS_SUCCESS =
	"[PERMISSIONS DETAIL] GET_PERMISSIONS_SUCCESS";

export const CREATE_PERMISSION = "[PERMISSIONS DETAIL] CREATE_PERMISSION";
export const CREATE_PERMISSION_ERROR =
	"[PERMISSIONS DETAIL] CREATE_PERMISSION_ERROR";
export const CREATE_PERMISSION_SUCCESS =
	"[PERMISSIONS DETAIL] CREATE_PERMISSION_SUCCESS";

export const SET_VALUE = "[PERMISSIONS DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[PERMISSIONS DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getUserPermissions(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PERMISSIONS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/user/permission?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PERMISSIONS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_PERMISSIONS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_PERMISSIONS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function createUserPermission(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_PERMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/user/permission`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_PERMISSION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: CREATE_PERMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: CREATE_PERMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
