import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import EditForm from "../../../components/EditForm/NFSeEdit";
import { clearValues, getNFSeById } from "../Detail/actions";
import { updateNfse, uploadFile } from "./actions";
import ls from "Localization";
import { nfseStatus } from "types/nfseStatus";
import nfseServicetype from "../../../../../assets/nfseServicetype.json";

function Edit({
	history,
	match: {
		params: { id }
	}
}) {
	const { item } = useSelector(s => s.nfseDetail);
	const { loading, errors } = useSelector(s => s.nfseEdit);
	const nfse_edit = useSelector(s => s.form.nfse_edit);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getNFSeById(id));
	}, [dispatch]);

	if (!item) return "Sem dados da NFSe";

	const statusData = Object.keys(nfseStatus).map(c => ({
		value: c,
		label: ls[nfseStatus[c]]
	}));

	const nfseServiceTypes = () => {
		const types = [];

		nfseServicetype.forEach(({ items }) => {
			types.push(
				...items.map(({ value, code }) => ({
					value: code,
					label: value
				}))
			);
		});

		return types;
	};
	const nfseServiceTypesData = nfseServiceTypes();

	const onSubmit = data => {
		dispatch(
			updateNfse(
				id,
				{
					...item,
					status: data.status ? data.status.value : 0,
					service: data.service,
					totalValue:
						typeof data.totalValue === "string"
							? data.totalValue.match(/\d/g).join("")
							: Math.round(data.totalValue * 100),
					observations: data.observations,
					serviceType: data.serviceType?.value || null
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						if (data.file) {
							dispatch(
								uploadFile(item._id, data.file, err => {
									if (err) {
										if (typeof err === "string") alert(err);
										else alert(JSON.stringify(err));
									} else {
										history.push(`/nfses/${id}`);
									}
								})
							);
						} else {
							history.push(`/nfses/${id}`);
						}
					}
				}
			)
		);
	};

	const initialValues = {
		status: statusData.find(c => c.value === String(item.status)),
		service: item.service,
		totalValue: item.totalValue / 100.0,
		observations: item.observations,

		serviceType: nfseServiceTypesData.find(
			c => c.value === String(item.serviceType)
		)
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Alterar dados da nota fiscal</h5>
							</div>
							<EditForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={nfse_edit && !!nfse_edit.syncErrors}
								errors={errors}
								statusData={statusData}
								nfseServiceTypesData={nfseServiceTypesData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
