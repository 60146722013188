import * as actions from "./actions";

let initialState = {
	item: {},
	dashboard: {},
	hireds: [],
	loading: { get: false },
	errors: {},
	filter: [],
	pages: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_ONBOARD: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_ONBOARD_SUCCESS: {
			return {
				...state,
				item: payload.onboard,
				hireds: payload.hireds,
				loading: { get: false }
			};
		}
		case actions.GET_ONBOARD_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.UPDATE_ONBOARD: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_ONBOARD_SUCCESS: {
			return {
				...state,
				loading: { get: false }
			};
		}
		case actions.UPDATE_ONBOARD_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.IMPORT_ONBOARD_BATCH: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.IMPORT_ONBOARD_BATCH_SUCCESS: {
			return {
				...state,
				item: payload.onboard,
				hireds: payload.hireds,
				loading: { get: false }
			};
		}
		case actions.IMPORT_ONBOARD_BATCH_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}
		case actions.GET_COMPANY_PROVIDERS_STATUS:
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		case actions.GET_COMPANY_PROVIDERS_STATUS_SUCCESS:
			return {
				...state,
				dashboard: payload,
				loading: { get: false },
				errors: initialState.errors
			};
		case actions.GET_COMPANY_PROVIDERS_STATUS_ERROR:
			return {
				...state,
				loading: { get: false },
				errors: initialState.errors
			};
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		case actions.GET_COMPANY_PROVIDERS_PHASES:
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		default: {
			return state;
		}
	}
};

export default reducer;
