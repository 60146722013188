import * as actions from "./actions";

const initialState = {
	item: null,
	loading: false,
	errors: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_SUBSCRIPTION: {
			return {
				...state,
				loading: true,
				errors: initialState.errors,
			};
		}
		case actions.GET_SUBSCRIPTION_SUCCESS: {
			return {
				...initialState,
				item: payload,
			};
		}
		case actions.GET_SUBSCRIPTION_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload,
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload,
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
			};
		default: {
			return state;
		}
	}
};

export default reducer;
