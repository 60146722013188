import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";

import LoadingComponent from "components/LoadingComponent";
import { Text } from "components/Text";

import { InfoCard } from "./components/InfoCard";
import { ProgressBarFullScreen } from "./components/ProgressBarFullScreen";

import * as api from "services/api";

import {
	BarsContainer,
	DonutChartsContainer,
	InfoCardsContainer,
	InfosContainer
} from "./styles";

export default function HirerDashboard({ company, companyId }) {
	const [isLoading, setIsLoading] = useState(true);
	const [licenseData, setLicenseData] = useState(null);
	const [contractsData, setContractsData] = useState(null);
	const [cnpjData, setCnpjData] = useState(null);
	const [cnpjStatusGraphData, setCnpjStatusGraphData] = useState(null);
	const [cnpjFederalStatus, setCnpjFederalStatus] = useState(null);
	const [nfStatus, setNfStatus] = useState(null);
	const [dasData, setDasData] = useState(null);
	const [dasStatusGraphData, setDasStatusGraphData] = useState(null);
	const [dasnData, setDasnData] = useState(null);
	const [dasnStatusGraphData, setDasnStatusGraphData] = useState(null);
	const [cnpjFederalTexts, setCnpjFederalTexts] = useState([]);
	const [cnpjDataTexts, setCnpjDataTexts] = useState([]);
	const [dasTexts, setDasTexts] = useState([]);
	const [dasnTexts, setDasnTexts] = useState([]);

	const { token } = useSelector(s => s.login);

	const COLORS = [
		"#FF0000",
		"#0000FD",
		"#28a745",
		"#FF7F01",
		"#7F00FE",
		"#CECE00",
		"#FF33FD",
		"#33FFFF",
		"#33FF9A",
		"#6c757d",
		"#FF3333",
		"#CD6600",
		"#FFFF02",
		"#3131FF",
		"#0080FE",
		"#3299FF",
		"#9932FF",
		"#FF00FD",
		"#FF3398",
		"#FF007E",
		"#00FFFF",
		"#00FF02",
		"#33FF35",
		"#67CD00",
		"#80FF01",
		"#00FF81"
	];

	async function loadLicenseData() {
		try {
			const licenseResponse = await api.sendGet(
				`/companyProvider/get-status/${companyId}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const licenseResult = await licenseResponse.json();

			if (licenseResult.isValid) {
				setLicenseData(licenseResult.model);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function loadContractsData() {
		try {
			const contractStatusResponse = await api.sendGet(
				`/contracts/business-contract-status/${companyId}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const contractStatusResult = await contractStatusResponse.json();

			if (contractStatusResult.isValid) {
				setContractsData(contractStatusResult.model);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function loadCnpjStatusData() {
		try {
			const cnpjStatusResponse = await api.sendGet(
				`/company/providers-status/${companyId}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const cnpjStatusResult = await cnpjStatusResponse.json();

			if (cnpjStatusResult.isValid) {
				setCnpjData(cnpjStatusResult.model);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function loadNfStatusData() {
		try {
			const nfStatusResponse = await api.sendGet(
				`/company/nfse-status/${companyId}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const nfStatusResult = await nfStatusResponse.json();

			if (nfStatusResult.isValid) {
				setNfStatus(nfStatusResult.model);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function loadDasStatusData() {
		try {
			const dasStatusResponse = await api.sendGet(
				`/das/get-status-by-business/${companyId}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const dasStatusResult = await dasStatusResponse.json();

			if (dasStatusResult.isValid) {
				setDasData(dasStatusResult.model);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function loadDasnStatusData() {
		try {
			const dasnStatusResponse = await api.sendGet(
				`/dasn/get-status-by-business/${companyId}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const dasnStatusResult = await dasnStatusResponse.json();

			if (dasnStatusResult.isValid) {
				setDasnData(dasnStatusResult.model);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	}

	async function loadData() {
		setIsLoading(true);

		loadLicenseData();
		loadContractsData();
		loadCnpjStatusData();
		loadNfStatusData();
		loadDasStatusData();
		loadDasnStatusData();
	}

	function createCnpjStatusGraphData() {
		let data = [];

		if (cnpjData?.registeredProvidersStatus?.active !== 0) {
			data.push({
				subtitle: "Ativo",
				value: Number(
					(
						(cnpjData?.registeredProvidersStatus?.active * 100) /
						cnpjData?.registeredProvidersStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.registeredProvidersStatus?.active
			});
		}

		if (cnpjData?.registeredProvidersStatus?.pending !== 0) {
			data.push({
				subtitle: "Pendente",
				value: Number(
					(
						(cnpjData?.registeredProvidersStatus?.pending * 100) /
						cnpjData?.registeredProvidersStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.registeredProvidersStatus?.pending
			});
		}

		if (cnpjData?.registeredProvidersStatus?.inactive !== 0) {
			data.push({
				subtitle: "Inapto",
				value: Number(
					(
						(cnpjData?.registeredProvidersStatus?.inactive * 100) /
						cnpjData?.registeredProvidersStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.registeredProvidersStatus?.inactive
			});
		}

		if (cnpjData?.registeredProvidersStatus?.onboarding !== 0) {
			data.push({
				subtitle: "Baixado",
				value: Number(
					(
						(cnpjData?.registeredProvidersStatus?.onboarding * 100) /
						cnpjData?.registeredProvidersStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.registeredProvidersStatus?.onboarding
			});
		}

		setCnpjDataTexts(data);

		setCnpjStatusGraphData({
			labels: data.map(item => item.subtitle),
			datasets: [
				{
					data: data.map(item => item.value),
					backgroundColor: COLORS.slice(0, data.length),
					hoverBackgroundColor: COLORS.slice(0, data.length),
					borderColor: "rgba(255,255,255,0.54)"
				}
			]
		});

		let cnpjFederalStatusData = [];

		if (cnpjData?.cnpjStatus?.active !== 0) {
			cnpjFederalStatusData.push({
				subtitle: "Ativo",
				value: Number(
					(
						(cnpjData?.cnpjStatus?.active * 100) /
						cnpjData?.cnpjStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.cnpjStatus?.active
			});
		}

		if (cnpjData?.cnpjStatus?.pending !== 0) {
			cnpjFederalStatusData.push({
				subtitle: "Pendente",
				value: Number(
					(
						(cnpjData?.cnpjStatus?.pending * 100) /
						cnpjData?.cnpjStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.cnpjStatus?.pending
			});
		}

		if (cnpjData?.cnpjStatus?.unfit !== 0) {
			cnpjFederalStatusData.push({
				subtitle: "Inapto",
				value: Number(
					(
						(cnpjData?.cnpjStatus?.unfit * 100) /
						cnpjData?.cnpjStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.cnpjStatus?.unfit
			});
		}

		if (cnpjData?.cnpjStatus?.downloaded !== 0) {
			cnpjFederalStatusData.push({
				subtitle: "Baixado",
				value: Number(
					(
						(cnpjData?.cnpjStatus?.downloaded * 100) /
						cnpjData?.cnpjStatus?.total
					).toFixed(1)
				),
				absoluteValue: cnpjData?.cnpjStatus?.downloaded
			});
		}

		setCnpjFederalTexts(cnpjFederalStatusData);

		setCnpjFederalStatus({
			labels: cnpjFederalStatusData.map(item => item.subtitle),
			datasets: [
				{
					data: cnpjFederalStatusData.map(item => item.value),
					backgroundColor: COLORS.slice(0, cnpjFederalStatusData.length),
					hoverBackgroundColor: COLORS.slice(0, cnpjFederalStatusData.length),
					borderColor: "rgba(255,255,255,0.54)"
				}
			]
		});
	}

	function createDasGraphData() {
		let data = [];

		if (dasData?.paid !== 0) {
			data.push({
				subtitle: "Liquidado",
				value: Number(((dasData?.paid * 100) / dasData?.allDas).toFixed(1)),
				absoluteValue: dasData?.paid
			});
		}

		if (dasData?.overDue !== 0) {
			data.push({
				subtitle: "Devedor",
				value: Number(((dasData?.overDue * 100) / dasData?.allDas).toFixed(1)),
				absoluteValue: dasData?.overDue
			});
		}

		if (dasData?.pending !== 0) {
			data.push({
				subtitle: "A vencer",
				value: Number(((dasData?.pending * 100) / dasData?.allDas).toFixed(1)),
				absoluteValue: dasData?.pending
			});
		}

		if (dasData?.notOpting !== 0) {
			data.push({
				subtitle: "Não Optante",
				value: Number(
					((dasData?.notOpting * 100) / dasData?.allDas).toFixed(1)
				),
				absoluteValue: dasData?.notOpting
			});
		}

		if (dasData?.unavailable !== 0) {
			data.push({
				subtitle: "Indisponível",
				value: Number(
					((dasData?.unavailable * 100) / dasData?.allDas).toFixed(1)
				),
				absoluteValue: dasData?.unavailable
			});
		}

		if (dasData?.inInstallment !== 0) {
			data.push({
				subtitle: "Em parcelamento",
				value: Number(
					((dasData?.inInstallment * 100) / dasData?.allDas).toFixed(1)
				),
				absoluteValue: dasData?.inInstallment
			});
		}

		setDasTexts(data);

		setDasStatusGraphData({
			labels: data.map(item => item.subtitle),
			datasets: [
				{
					data: data.map(item => item.value),
					backgroundColor: COLORS.slice(0, data.length),
					hoverBackgroundColor: COLORS.slice(0, data.length),
					borderColor: "rgba(255,255,255,0.54)"
				}
			]
		});
	}

	function createDasnGraphData() {
		let data = [];

		if (dasnData?.pending) {
			data.push({
				subtitle: "Declarar",
				value: Number(((dasnData?.pending * 100) / dasnData?.all).toFixed(1)),
				absoluteValue: dasnData?.pending
			});
		}

		if (dasnData?.done) {
			data.push({
				subtitle: "Retificar",
				value: Number(((dasnData?.done * 100) / dasnData?.all).toFixed(1)),
				absoluteValue: dasnData?.done
			});
		}

		if (dasnData?.due) {
			data.push({
				subtitle: "Atrasado",
				value: Number(((dasnData?.due * 100) / dasnData?.all).toFixed(1)),
				absoluteValue: dasnData?.due
			});
		}

		if (dasnData?.canceled) {
			data.push({
				subtitle: "Cancelado",
				value: Number(((dasnData?.canceled * 100) / dasnData?.all).toFixed(1)),
				absoluteValue: dasnData?.canceled
			});
		}

		setDasnTexts(data);

		setDasnStatusGraphData({
			labels: data.map(item => item.subtitle),
			datasets: [
				{
					data: data.map(item => item.value),
					backgroundColor: COLORS.slice(0, data.length),
					hoverBackgroundColor: COLORS.slice(0, data.length),
					borderColor: "rgba(255,255,255,0.54)"
				}
			]
		});
	}

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		createCnpjStatusGraphData();
	}, [cnpjData]);

	useEffect(() => {
		createDasGraphData();
	}, [dasData]);

	useEffect(() => {
		createDasnGraphData();
	}, [dasnData]);

	return (
		<Container className="dashboard">
			{isLoading ? (
				<LoadingComponent />
			) : (
				<>
					<Card style={{ height: "fit-content" }}>
						<CardBody>
							<InfosContainer>
								<Text fontSize="20px" lineHeight="23px" fontWeight={700}>
									Minha empresa
								</Text>

								<InfoCardsContainer>
									<InfoCard
										type="basic"
										title="Licenças contratadas"
										totalValue={
											company?.byMeasurement
												? "Ilimitado"
												: licenseData?.purchasedProviders ||
												  licenseData?.onboard + licenseData?.enabled ||
												  "--"
										}
									/>
									<InfoCard
										type={company?.byMeasurement ? "basic" : "used"}
										title="Licenças utilizadas"
										totalValue={
											company?.byMeasurement
												? licenseData?.onboard + licenseData?.enabled || "--"
												: licenseData?.purchasedProviders ||
												  licenseData?.onboard + licenseData?.enabled ||
												  "--"
										}
										totalUsed={
											licenseData?.onboard + licenseData?.enabled || "--"
										}
									/>
									<InfoCard
										type="status"
										title="Status da assinatura"
										status={licenseData?.status || 0}
									/>
								</InfoCardsContainer>

								{!company.useOwnContracts && (
									<BarsContainer>
										<Text fontSize="18px" fontWeight={700} color="#303030">
											Status dos contratos
										</Text>

										<ProgressBarFullScreen
											label="Assinado"
											quantity={contractsData?.status.Signed}
											total={contractsData?.total}
										/>
										<ProgressBarFullScreen
											label="Pendente Contratante"
											quantity={contractsData?.pendingHirer}
											total={contractsData?.total}
										/>
										<ProgressBarFullScreen
											label="Pendente Prestador"
											quantity={contractsData?.pendingProvider}
											total={contractsData?.total}
										/>
										<ProgressBarFullScreen
											label="Cancelado"
											quantity={contractsData?.status.Cancelled}
											total={contractsData?.total}
										/>
									</BarsContainer>
								)}
							</InfosContainer>
						</CardBody>
					</Card>

					<Card style={{ height: "fit-content" }}>
						<CardBody>
							<InfosContainer>
								<Text fontSize="20px" lineHeight="23px" fontWeight={700}>
									Prestadores
								</Text>
								<Text fontSize="20px" lineHeight="23px" fontWeight={700}>
									CNPJs
								</Text>

								<InfoCardsContainer>
									<InfoCard
										type="basic"
										title="Prestadores Cadastrados"
										totalValue={cnpjData?.registeredProviders}
									/>
									<InfoCard
										type="basic"
										title="Prestadores Ativos"
										totalValue={cnpjData?.activeProviders}
									/>
									<InfoCard
										type="basic"
										title="Prestadores Ativos com CNPJ"
										totalValue={cnpjData?.providersWithCnpj}
									/>
								</InfoCardsContainer>

								<DonutChartsContainer>
									<Col xs={12} md={6} lg={4}>
										<div className="bold-text">
											<h5 className="bold-text">
												Situação de CNPJs (Receita Federal)
											</h5>
										</div>
										<div>
											{cnpjFederalTexts.map((item, index) => (
												<h5 className="bold-text" key={index}>
													{item.subtitle}: {item.absoluteValue}
												</h5>
											))}
										</div>
										<Doughnut data={cnpjFederalStatus} />
									</Col>
									<Col xs={12} md={6} lg={4}>
										<div className="bold-text">
											<h5 className="bold-text">Situação de CNPJs (SuaMEi)</h5>
										</div>
										<div>
											{cnpjDataTexts.map((item, index) => (
												<h5 className="bold-text" key={index}>
													{item.subtitle}: {item.absoluteValue}
												</h5>
											))}
										</div>
										<Doughnut data={cnpjStatusGraphData} />
									</Col>
								</DonutChartsContainer>

								<Text fontSize="20px" lineHeight="23px" fontWeight={700}>
									Impostos e Obrigações
								</Text>

								<BarsContainer>
									<Text fontSize="18px" fontWeight={700} color="#303030">
										Emissões NFs
									</Text>

									<ProgressBarFullScreen
										label="Habilitados"
										quantity={nfStatus?.active}
										total={nfStatus?.total}
										color="#2e8e00"
									/>
									<ProgressBarFullScreen
										label="Habilitando"
										quantity={nfStatus?.activating}
										total={nfStatus?.total}
										color="#dcc600"
									/>
									<ProgressBarFullScreen
										label="Pendente"
										quantity={nfStatus?.pending}
										total={nfStatus?.total}
										color="#ff0000"
									/>
								</BarsContainer>

								<DonutChartsContainer>
									<Col xs={12} md={6} lg={4}>
										<div className="bold-text">
											<h5 className="bold-text">DAS</h5>
										</div>
										<div>
											{dasTexts.map((item, index) => (
												<h5 className="bold-text" key={index}>
													{item.subtitle}: {item.absoluteValue}
												</h5>
											))}
										</div>
										<Doughnut data={dasStatusGraphData} />
									</Col>
									<Col xs={12} md={6} lg={4}>
										<div className="bold-text">
											<h5 className="bold-text">DASN</h5>
										</div>
										<div>
											{dasnTexts.map((item, index) => (
												<h5 className="bold-text" key={index}>
													{item.subtitle}: {item.absoluteValue}
												</h5>
											))}
										</div>
										<Doughnut data={dasnStatusGraphData} />
									</Col>
								</DonutChartsContainer>
							</InfosContainer>
						</CardBody>
					</Card>
				</>
			)}
		</Container>
	);
}
