import React, { useRef } from "react";
import { Button, Spinner } from "reactstrap";
import { FaFileImport } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../containers/Page/Detail/OnBoard/actions";

function OnBoardBatchModal({ company_id }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.transfer);

	const inputFile = useRef(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const handleFileSelected = e => {
		if (!e.target.files[0]) return;

		dispatch(
			// eslint-disable-next-line no-unused-vars
			actions.importOnBoardBatch(e.target.files, company_id, (err, data) => {
				if (err) {
					if (typeof err !== "string") err = JSON.stringify(err);
					alert(err);
				} else {
					alert(
						"Importado com sucesso. Essa ação pode demorar alguns minutos."
					);
					window.location.reload();
				}

				inputFile.current.value = null;
			})
		);
	};

	return (
		<div style={{ marginRight: 6 }}>
			{loading.import ? (
				<Spinner size="sm" />
			) : (
				<Button
					color={"primary"}
					style={{
						color: "#fff"
					}}
					onClick={openSelectFile}
				>
					<FaFileImport className="mr-2" />
					Adicionar Prestadores em lote
				</Button>
			)}
			<input
				ref={inputFile}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileSelected}
			/>
		</div>
	);
}

export default OnBoardBatchModal;
