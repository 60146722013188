import * as api from "services/api";
import { getErrors } from "helpers/error";

export const UPDATE_CONTRACT = "[UPDATE REGULAR CONTRACT] UPDATE_CONTRACT";
export const UPDATE_CONTRACT_ERROR =
	"[UPDATE REGULAR CONTRACT] UPDATE_CONTRACT_ERROR";
export const UPDATE_CONTRACT_SUCCESS =
	"[UPDATE REGULAR CONTRACT] UPDATE_CONTRACT_SUCCESS";

export const UPLOAD_CONTRACT_FILE =
	"[UPDATE REGULAR CONTRACT] UPLOAD_CONTRACT_FILE";
export const UPLOAD_CONTRACT_FILE_ERROR =
	"[UPDATE REGULAR CONTRACT] UPLOAD_CONTRACT_FILE_ERROR";
export const UPLOAD_CONTRACT_FILE_SUCCESS =
	"[UPDATE REGULAR CONTRACT] UPLOAD_CONTRACT_FILE_SUCCESS";

export const GET_HIREDS = "[HIREDS] GET_HIREDS";
export const GET_HIREDS_SUCCESS = "[HIREDS] GET_HIREDS_SUCCESS";
export const GET_HIREDS_FAILED = "[HIREDS] GET_HIREDS_FAILED";

export const SET_VALUE = "[UPDATE REGULAR CONTRACT] SET_VALUE";
export const CLEAR_VALUES = "[UPDATE REGULAR CONTRACT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function updateRegularContract(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_CONTRACT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/contracts/${id} `, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPDATE_CONTRACT_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CONTRACT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CONTRACT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function uploadFile(id, file, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPLOAD_CONTRACT_FILE
		});

		try {
			const { login } = getState();

			if (!id) {
				id = login.user.id;
			}

			let response = await api.sendPostFile(`/contracts/${id}/file`, file, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPLOAD_CONTRACT_FILE_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();
				dispatch({
					type: UPLOAD_CONTRACT_FILE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPLOAD_CONTRACT_FILE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getHiredList(hirer_id, filter, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_HIREDS });
		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/companyProvider/hired-list/${hirer_id}?${filter || "&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_HIREDS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_HIREDS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_HIREDS_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
