import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_VERSION_REPORT = "[VERSION_REPORT DETAIL] GET_VERSION_REPORT";
export const GET_VERSION_REPORT_SUCCESS =
	"[VERSION_REPORT DETAIL] GET_VERSION_REPORT_SUCCESS";
export const GET_VERSION_REPORT_FAILED =
	"[VERSION_REPORT DETAIL] GET_VERSION_REPORT_FAILED";

export const DELETE_VERSION_REPORT =
	"[VERSION_REPORT DETAIL] DELETE_VERSION_REPORT";
export const DELETE_VERSION_REPORT_ERROR =
	"[VERSION_REPORT DETAIL] DELETE_VERSION_REPORT_ERROR";
export const DELETE_VERSION_REPORT_SUCCESS =
	"[VERSION_REPORT DETAIL] DELETE_VERSION_REPORT_SUCCESS";

export const CLEAR_VALUES = "[VERSION_REPORT DETAIL] CLEAR_VALUES";

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getVersionReportItem(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_VERSION_REPORT });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/v2/version-report/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_VERSION_REPORT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_VERSION_REPORT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_VERSION_REPORT_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteVersionReportItem(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_VERSION_REPORT
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/v2/version-report/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_VERSION_REPORT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_VERSION_REPORT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_VERSION_REPORT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
