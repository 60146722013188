import axios from "axios";

const instance = axios.create({
	timeout: 30000
});

const urlList = (urlPick, query) => {
	const urL = new Map();
	let api = "";
	if (process.env.REACT_APP_API) {
		api = process.env.REACT_APP_API;
	} else if (process.env.NODE_ENV === "development") {
		// api = "http://api-hom.suamei.com.br/api";
		// api = "http://api-dev.suamei.com.br/api";
		api = "http://localhost:4121/api";
	} else {
		// api = "";
	}

	urL.set("api", api);

	return `${urL.get(urlPick)}${query || ""}`;
};

const optionsFatory = (token, authType, params) => {
	const options = {
		headers: {
			Authorization: ""
		},
		params: {}
	};
	if (token) {
		options.headers["Authorization"] = "Bearer " + token;
	}

	if (params) {
		options.params = params;
	}

	return options;
};

export function sendPost(urlPick, query, body, token, authType, params) {
	const options = optionsFatory(token, authType, params);
	const url = urlList(urlPick, query);

	return instance.post(url, body, options);
}

export function sendGet(urlPick, query, token, authType, params) {
	const options = optionsFatory(token, authType, params);
	const url = urlList(urlPick, query);

	return instance.get(url, options);
}

export function sendPut(urlPick, query, body, token, authType, params) {
	const options = optionsFatory(token, authType, params);
	const url = urlList(urlPick, query);

	return instance.put(url, body, options);
}
