import { CNPJ, CPF } from "components/NumberFormat";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import React from "react";
import { Card, Col, Container, Row, Badge } from "reactstrap";
import { businessLicenseStatus } from "types/BusinessLicenseStatus";
import { BusinessType, businessTypeTranslate } from "types/BusinessType";
import { PhaseBadge } from "../../containers/Page/Detail/OnBoard/Signature/phaseBadge";
import { StatusBadge } from "./components/StatusBadge";

dayjs.extend(customDateFormat);

const DetailCompany = ({ company, onboard }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{dayjs(company.createdAt).format(
												ls.dateFormatWithoutSeconds
											)}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome empresarial: </strong>
										<br />
										<span>{company.businessName || ""}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome fantasia: </strong>
										<br />
										<span>{company.socialName || ""}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>CNPJ: </strong>
										<br />
										<span>
											{company.cnpj ? (
												<CNPJ value={company.cnpj} displayType="text" />
											) : (
												""
											)}
										</span>
									</p>
								</Col>

								{company.isHired && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Fase:</strong>
											<br />
											<span>{PhaseBadge(onboard?.phase)}</span>
										</p>
									</Col>
								)}

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome do Cliente: </strong>
										<br />
										<span>
											{company.companyGroup?.businessName ||
												company.businessName}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data de abertura: </strong>
										<br />
										<span>{company.startDate || ""}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Capital Social: </strong>
										<br />
										<span>
											{company.shareCapital
												? "R$: " + company.shareCapital
												: ""}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Inscrição estadual: </strong>
										<br />
										<span>{company.stateRegistration || ""}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Inscrição municipal: </strong>
										<br />
										<span>{company.countyRegistration || ""}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Tipo de Empresa: </strong>
										<br />
										<span>
											{company.businessType >= 0
												? businessTypeTranslate[company.businessType]
												: ""}
										</span>
									</p>
								</Col>
								{company.businessType === BusinessType.MEI ? (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Nome do empresário: </strong>
											<br />
											<span>{company.personName || ""}</span>
										</p>
									</Col>
								) : null}
								{company.businessType === BusinessType.MEI ? (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>CPF: </strong>
											<br />
											<span>
												{company.cpf ? (
													<CPF value={company.cpf} displayType="text" />
												) : (
													""
												)}
											</span>
										</p>
									</Col>
								) : null}
								{company.businessType === BusinessType.MEI ? (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>RG: </strong>
											<br />
											<span>{company.rg || ""}</span>
										</p>
									</Col>
								) : null}
								{company.businessType === BusinessType.MEI ? (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Órgão/UF emissor: </strong>
											<br />
											<span>{`${company.rgEmissor ||
												""}/${company.rgEmissorState || ""}`}</span>
										</p>
									</Col>
								) : null}
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data Atualização Receita: </strong>
										<br />
										<span>
											{company.updatedAtReceitaWS
												? dayjs(company.updatedAtReceitaWS).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Atividade Principal: </strong>
										<br />
										<span>{company.activity || ""}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Modo de operação: </strong>
										<br />
										<span>{company.operationMode || ""}</span>
									</p>
								</Col>
								{company.businessType === BusinessType.MEI ? (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>NIRE: </strong>
											<br />
											<span>{company.nire || ""}</span>
										</p>
									</Col>
								) : null}
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Situação: </strong>
										<br />
										<span>{company.situation || ""}</span>
									</p>
								</Col>

								{company.isHired && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Status: </strong>
											<br />
											{company.isActive ? (
												<Badge className="w-50" color="success">
													ATIVO
												</Badge>
											) : (
												<Badge className="w-50" color="warning">
													INATIVO
												</Badge>
											)}
										</p>
									</Col>
								)}

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Tem Dívida Ativa?</strong>
										<br />
										<span>{company.hasActiveDebit ? "Sim" : "Não"}</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>DIT</strong>
										<br />
										<span>{company?.dit ? ls[company.dit] : "Nenhum"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Status da DIT</strong>
										<br />
										<span>
											<StatusBadge status={company?.ditStatus} />
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Seguro de vida</strong>
										<br />
										<span>
											{company?.lifeInsurance
												? ls[company.lifeInsurance]
												: "Nenhum"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Status do Seguro de vida</strong>
										<br />
										<StatusBadge status={company?.lifeInsuranceStatus} />
									</p>
								</Col>
							</Row>

							{company.businessType === BusinessType.MEI ? (
								<Row>
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Data de emissão - CCMEI: </strong>
											<br />
											<span>
												{company.emissionDate
													? dayjs(company.emissionDate, "DD/MM/YYYY").format(
															ls.dateFormatShort
													  )
													: ""}
											</span>
										</p>
									</Col>
									{company.businessType === BusinessType.MEI ? (
										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>Número de identificação - CCMEI: </strong>
												<br />
												<span>{company.identificatorNumber || ""}</span>
											</p>
										</Col>
									) : null}

									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Número do recibo - CCMEI: </strong>
											<br />
											<span>{company.receiptNumber || ""}</span>
										</p>
									</Col>
								</Row>
							) : null}
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data de emissão do alvará: </strong>
										<br />
										<span>
											{company.businessLicenseEmission
												? dayjs(company.businessLicenseEmission).format(
														ls.dateFormatShort
												  )
												: ""}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Status alvará: </strong>
										<br />
										<span>
											{company.businessLicenseStatus !== undefined
												? ls[
														businessLicenseStatus[company.businessLicenseStatus]
												  ]
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data de vencimento do alvará: </strong>
										<br />
										<span>
											{company.businessLicenseDue
												? dayjs(company.businessLicenseDue).format(
														ls.dateFormatShort
												  )
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Arquivo alvará: </strong>
										<br />
										<span>
											{company.businessLicenseFile ? (
												<a
													title={`Alvará-${company.businessName}`}
													href={company.businessLicenseFile}
													target="_blank"
													without
													rel="noreferrer"
												>
													Ver alvará
												</a>
											) : (
												"Arquivo não enviado"
											)}
										</span>
									</p>
								</Col>

								{company.isHired ? (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Por medição: </strong>
											<br />
											<span>{company.byMeasurement ? "Sim" : "Não"}</span>
										</p>
									</Col>
								) : null}
							</Row>

							<Row className="d-none">
								{company.address && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
										<p>
											<strong>ENDEREÇO: </strong>
											<br />
											<span>
												{`${
													company.address.street ? company.address.street : ""
												}, ${
													company.address.number ? company.address.number : ""
												} - ${
													company.address.complement
														? company.address.complement
														: ""
												} - ${
													company.address.neighborhood
														? company.address.neighborhood
														: ""
												} - ${
													company.address.city ? company.address.city : ""
												} - ${
													company.address.state ? company.address.state : ""
												} - ${
													company.address.zipCode ? company.address.zipCode : ""
												}`}
											</span>
										</p>
									</Col>
								)}
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{company.createdAt
												? dayjs(company.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Criado por: </strong>
										<br />
										{company.createdBy ? (
											<a
												href={`/client/${company.createdBy?._id}`}
												target="_blank"
												without
												rel="noreferrer"
											>
												{company.createdBy?.name}
											</a>
										) : (
											"--"
										)}
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data Atualização: </strong>
										<br />
										<span>
											{company.updatedAt
												? dayjs(company.updatedAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Atualizado por: </strong>
										<br />
										{company.updatedBy ? (
											<a
												href={`/client/${company.updatedBy?._id}`}
												target="_blank"
												without
												rel="noreferrer"
											>
												{company.updatedBy?.name}
											</a>
										) : (
											"--"
										)}
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailCompany;
