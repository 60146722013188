import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";

export const CREATE_SUBSCRIPTION = "[SUBSCRIPTION CREATE] CREATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION_SUCCESS =
	"[SUBSCRIPTION CREATE] CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILED =
	"[SUBSCRIPTION CREATE] CREATE_SUBSCRIPTION_FAILED";

export const SET_VALUE = "[SUBSCRIPTION CREATE] SET_VALUE";
export const CLEAR_VALUES = "[SUBSCRIPTION CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createSubscription(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_SUBSCRIPTION });

		try {
			const { login } = getState();

			const {
				user,
				installment,
				value,
				discount,
				installmentInterval,
				description,
				status,
				plan,
				paymentMethod,
				firstPayment,
			} = data;

			const errors = {};

			if (!user) errors.user = ls.required;
			if (!value) errors.value = ls.required;
			if (!installment) errors.installment = ls.required;
			if (!plan) errors.plan = ls.required;
			if (!paymentMethod) errors.paymentMethod = ls.required;
			if (!firstPayment) errors.firstPayment = ls.required;

			dispatch(setValue({ errors }));

			if (Object.keys(errors).length)
				return dispatch({ type: SET_VALUE, payload: { loading: false } });

			const model = {
				user,
				value: `${value}`.indexOf('R') !== 0 ?
					Math.round(+value * 100) :
					Math.round(+value.slice(3).replace(',', '.') * 100),
				discount: `${discount}`.indexOf('R') !== 0 ?
					Math.round(+discount * 100) :
					Math.round(+discount.slice(3).replace(',', '.') * 100),
				installment,
				installmentInterval: installmentInterval || '30',
				description,
				status,
				plan,
				paymentMethod,
				firstPayment,
			};

			var response = await api.sendPost("/Subscription", model, {
				Authorization: "Bearer " + login.token,
			});

			if (response.ok) {
				dispatch({
					type: CREATE_SUBSCRIPTION_SUCCESS,
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_SUBSCRIPTION_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: CREATE_SUBSCRIPTION_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}
