import styled from "styled-components";

export const ProgressBarContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	width: 100vw;
	max-width: 100%;
`;

export const BarContainer = styled.div`
	width: 85%;
`;

export const Bar = styled.div`
	width: 100%;
	height: 10px;
	border: 1px solid #eeefff;
	border-radius: 8px;
	background-color: #e6e6e6;
	position: relative;
`;

export const FilledBar = styled.div`
	width: ${({ percentage }) => `${percentage}%`};
	height: 100%;
	background-color: ${({ color }) => color};
	border-radius: 8px;
	position: absolute;
	top: 0;
	bottom: 0;
`;
