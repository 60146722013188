// import dayjs from "dayjs";

const validate = values => {
	const errors = {};

	// if (!values.due) {
	// 	errors.due = "Data de vencimento precisa ser preenchida";
	// }
	// if (values.due && dayjs(values.due).diff(dayjs(new Date()), "day") < 1) {

	// 	errors.due = "Data de vencimento precisa ser a partir de "
	// 		+ dayjs(new Date()).diff(dayjs(values.due), "day")

	// }

	if (!values.status) {
		errors.status = "Necessário informar status";
	}

	return errors;
};

export default validate;
