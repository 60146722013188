import styled from "styled-components";

const svgMarkup = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>`;
const base64String = Buffer.from(svgMarkup).toString("base64");

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 144px;

	label {
		font-size: 14px;
		color: #000;
		line-height: 23px;
		margin-bottom: 0;
	}

	select {
		padding: 8px 16px;
		border: 1px solid #31475a;
		border-radius: 4px;
		background-color: #fff;

		appearance: none;

		background: url("data:image/svg+xml;base64,${base64String}") no-repeat calc(100% - 16px);

		font-size: 14px;
		line-height: 23px;

		&::placeholder {
			color: #6c757d;
		}
	}
`;
