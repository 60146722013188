import React from "react";
import Input from "components/ReduxForm/Input";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";

const PipefyEditForm = ({ handleSubmit, onCancel }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={4} lg={4} className="form__form-group">
						<span>ID do Pipefy:</span>
						<div className="form__form-group-field">
							<Field name="pipefyId" component={Input} type="text" />
						</div>
					</Col>
				</Row>

				<ButtonToolbar className="form__button-toolbar">
					<Button
						type="button"
						onClick={() => {
							onCancel();
						}}
					>
						Cancelar
					</Button>
					<Button color="primary" type="submit">
						Salvar
					</Button>
				</ButtonToolbar>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "pipefy_edit_form",
	validate
})(PipefyEditForm);

const validate = (values = {}) => {
	const errors = {};

	// if (!values.mei) errors.mei = "Informe o contratante";

	return errors;
};
