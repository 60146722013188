import React, { useEffect } from "react";
import { Badge, Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import { BankStatus, MeiStatus, UserStatus } from "types/userStatus";
import ls from "Localization";
import { dateSort } from "components/Report/helpers";
import dayjs from "dayjs";
import { stringToMask, stringToPascalCase } from "helpers/string";
import states from "assets/states.json";
import phoneNumber from "helpers/phoneNumber";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

let fetchDataDebounced = null;

function Report({ history }) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.clearValues());
	}, []);

	const state = useSelector(s => s.clients);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (fetchDataDebounced) {
			fetchDataDebounced.cancel();
		}

		let sort = sorted[0] || {};

		if (!sorted) sorted = [];
		fetchDataDebounced = _.debounce(() => {
			dispatch(
				actions.getClients(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
					sort.id,
					sort.desc,
					toExport,
					callback
				)
			);
		}, 500);

		fetchDataDebounced();
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						filterable
						title="Usuários"
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						onRowClicked={row => {
							let win = window.open(`/client/${row._id}`, "_blank");
							win.focus();
						}}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: ls.creationDate,
								id: "createdAt",
								accessor: c =>
									dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds),
								sortMethod: dateSort,
								width: 125
							},
							{
								Header: ls.name,
								id: "name",
								accessor: ({ name, lastname }) =>
									`${stringToPascalCase(name)} ${stringToPascalCase(lastname) ||
										""}`,
								className: "bold-text"
							},
							{
								Header: ls.phoneNumber,
								id: "phoneNumber",
								accessor: ({ phoneNumber: phone }) => phoneNumber.format(phone),
								show: true,
								width: 120
							},
							{
								Header: ls.email,
								id: "email",
								accessor: c => c.email,
								show: true,
								width: 200
							},
							{
								Header: ls.birthDate,
								id: "birthDate",
								accessor: c =>
									dayjs.utc(c.birthDate).format(ls.dateFormatShort),
								show: false,
								sortMethod: dateSort,
								width: 120
							},
							{
								Header: ls.cpf,
								id: "cpf",
								accessor: ({ cpf }) =>
									cpf ? stringToMask(cpf, "###.###.###-##") : "--",
								width: 110
							},
							{
								Header: "RG",
								id: "rg",
								accessor: ({ rg }) => rg || "--",
								width: 110
							},
							{
								Header: ls.statusMei,
								id: "status",
								show: true,
								accessor: c => ls[MeiStatus[c.status]],
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(MeiStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[MeiStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 115
							},
							{
								Header: "Status",
								id: "userStatus",
								show: true,
								accessor: ({ userStatus }) => (
									<Badge
										color={userStatus ? "success" : "danger"}
										className="w-100"
									>
										{ls[UserStatus[userStatus]]}
									</Badge>
								),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(UserStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[UserStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 80
							},
							{
								Header: ls.bankStatus,
								id: "bankStatus",
								accessor: c =>
									ls[BankStatus[c.bankStatus !== undefined ? c.bankStatus : 0]],
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(BankStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[BankStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								show: false,
								width: 80
							},
							{
								Header: ls.city,
								id: "city",
								show: false,
								accessor: c => (c.address ? c.address.city : ""),
								width: 130
							},
							{
								Header: "UF",
								id: "state",
								show: false,
								accessor: c => (c.address ? c.address.state : ""),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{states.map(c => (
												<option key={c.label} value={c.label}>
													{c.label}
												</option>
											))}
										</select>
									);
								},
								width: 50
							},
							{
								Header: ls.role,
								id: "role",
								show: false,
								accessor: c =>
									ls[c.roles ? c.roles.find(c => c !== "user") : undefined]
										? ls[c.roles ? c.roles.find(c => c !== "user") : undefined]
										: "",
								width: 140
							},
							{
								Header: ls.street,
								id: "street",
								show: false,
								accessor: c => (c.address ? c.address.street : ""),
								width: 250
							},
							{
								Header: ls.number,
								id: "number",
								show: false,
								accessor: c => (c.address ? c.address.number : ""),
								width: 80
							},
							{
								Header: ls.complement,
								id: "complement",
								show: false,
								accessor: c => (c.address ? c.address.complement : ""),
								width: 180
							},
							{
								Header: ls.neighborhood,
								id: "neighborhood",
								show: false,
								accessor: c => (c.address ? c.address.neighborhood : ""),
								width: 180
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Report;
