import Input from "components/ReduxForm/Input";

import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "../validate";
import TextArea from "components/ReduxForm/TextArea";

const PermissionCreate = ({
	handleSubmit,
	loading,
	submitting,
	isInvalid,
	reset
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={6} lg={4} className="form__form-group">
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Digite o nome da permissão"
							/>
						</div>
					</Col>

					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={TextArea}
								type="text"
								multiple
								rows={3}
								placeholder="Informe uma descrição sobre a permissão"
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading}
				>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "permission_create",
	validate
})(PermissionCreate);
