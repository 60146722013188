import ls from "Localization";

import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
	ObligationPeriods,
	ObligationStatusDASN
} from "types/obligationStatus";
import CreateForm from "../../../../components/CreateObligation";
import { getObligations } from "../../Obligation/actions";
import { clearValues, createObligation } from "./actions";

function CreateObligation({
	match: {
		params: { business }
	},
	history
}) {
	const dispatch = useDispatch();

	const { loading, errors } = useSelector(s => s.obligationCreate);
	const obligation_create = useSelector(s => s.form.obligation_create);

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	const periodStatusData = Object.keys(ObligationPeriods).map(c => ({
		value: c,
		label: ls[ObligationPeriods[c]]
	}));

	const obligationStatusData = Object.keys(ObligationStatusDASN).map(c => ({
		value: c,
		label: ObligationStatusDASN[c]
	}));

	const onSubmit = data => {
		console.log(data);
		dispatch(
			createObligation(
				{
					business,
					due: data.due,
					interval: data.interval,
					name: data.name,
					period: data.period ? data.period.value : 0,
					status: data.status ? data.status.value : 0
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(getObligations(0, 10, `&filters[business]=${business}`));
						history.push(`/companies/${business}`);
					}
				}
			)
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Criar nova obrigação</h5>
							</div>
							<CreateForm
								onSubmit={onSubmit}
								initialValues={{
									interval: 1,
									period: periodStatusData[0],
									status: obligationStatusData[0]
								}}
								loading={loading}
								isInvalid={obligation_create && !!obligation_create.syncErrors}
								errors={errors}
								periodStatusData={periodStatusData}
								obligationStatusData={obligationStatusData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default CreateObligation;
