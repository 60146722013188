import Input from "components/ReduxForm/Input";
import validate from "./validate";
import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";
import { MONEY } from "components/NumberFormat";

const CompanyEdit = ({
	handleSubmit,
	reset,
	loading,
	errors,
	submitting,
	isInvalid,
	change,
	statusData,
	nfseServiceTypesData
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="service"
								component={Input}
								type="text"
								placeholder="Ex.: Descrição do serviço prestado"
								props={{ errors: errors && errors.service }}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Observação</span>
						<div className="form__form-group-field">
							<Field
								name="observations"
								component={Input}
								type="text"
								placeholder="Opcional"
								props={{ errors: errors && errors.observations }}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label">Status</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={statusData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label">Tipos de Serviço</span>
						<div className="form__form-group-field">
							<Field
								name="serviceType"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={nfseServiceTypesData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label">Valor</span>
						<div className="form__form-group-field">
							<Field
								name="totalValue"
								component={MONEY}
								type="text"
								placeholder="Ex.: Valor total do serviço"
								props={{ errors: errors && errors.totalValue }}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={4} xl={4}>
						<h6 className="bold-text">Arquivo PDF</h6>
						<Input
							accept="application/pdf"
							type="file"
							name="file"
							onChange={f => change("file", f.target.files[0])}
						/>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading.create}
				>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "nfse_edit",
	validate
})(CompanyEdit);
