import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import ptBt from "dayjs/locale/pt-br";

import { clearValues, updateBenefit } from "./actions";
import { getBenefit } from "../Details/actions";
import {
	replaceStringCurrencyToNumber,
	toStringCurrency
} from "helpers/string";
import BenefitEdit from "../../../components/EditForm";

import { uploadFile } from "../Create/actions";

dayjs.locale(ptBt);

const Edit = ({
	history,
	match: {
		params: { id }
	}
}) => {
	const { item } = useSelector(state => state.benefitDetails);
	const { loading, errors } = useSelector(state => state.benefitEdit);
	const benefit_edit = useSelector(s => s.form.benefit_edit);
	const dispatch = useDispatch();

	const onSubmit = data => {
		const model = {
			name: data.name,
			description: data.description,
			price: replaceStringCurrencyToNumber("R$", data.price || ""),
			discount: replaceStringCurrencyToNumber("R$", data.discount || "")
		};

		dispatch(
			updateBenefit(id, model, err => {
				if (err) {
					console.log(err);
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					if (data.icon) {
						dispatch(
							uploadFile(item._id, data.icon, err => {
								if (err) {
									if (typeof err === "string") alert(err);
									else alert(JSON.stringify(err));
								} else {
									history.push(`/benefit/${item._id}`);
								}
							})
						);
					} else {
						history.push(`/benefit/${item._id}`);
					}
				}
			})
		);
	};

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getBenefit(id));
	}, [dispatch]);

	if (!item) return null;

	let initialValues = {};

	if (item) {
		initialValues = {
			price: toStringCurrency(item.price / 100),
			discount: toStringCurrency(item.discount / 100),
			name: item.name || null,
			description: item.description || null
		};
	}

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Alterar dados do beneficio</h5>
							</div>
							<BenefitEdit
								errors={errors}
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={benefit_edit && !!benefit_edit.syncErrors}
								billetLink={item.link}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Edit;
