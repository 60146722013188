import React, { useState } from "react";
import { SubmissionError, reset } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import ChangePasswordModal from "./components/Modal";
import { updateProfilePassword, setValue } from "./actions";

import ChangePasswordForm from "./components/ChangePasswordForm";
import "./styles.scss";
import ErrorIcon from "mdi-react/ErrorIcon";

const ChangePassword = () => {
	const dispatch = useDispatch();

	const state = useSelector(s => s.changePassword);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	const onSubmit = data => {
		try {
			setLoading(true);
			return dispatch(
				updateProfilePassword(data, err => {
					if (err) {
						setLoading(false);

						console.log(err.password);

						if (err.password) {
							setShowAlert(true);
							setError(err.password);
						}

						return;
					}

					hideModal();
					setLoading(false);
				})
			);
		} catch (error) {
			setLoading(false);
			throw new SubmissionError(error);
		}
	};

	const hideModal = () => {
		dispatch(setValue({ showModal: false }));
	};

	const onCancel = () => {
		dispatch(reset("change_password_form"));
	};

	const handleCloseAlert = () => {
		setShowAlert(false);
		setError("");
	};

	return (
		<ChangePasswordModal
			title="Alterar Senha"
			loading={false}
			labelButton="Alterar"
			isOpen={state.showModal}
			hide={hideModal}
		>
			{showAlert && (
				<div className="container-error">
					<p>
						<ErrorIcon size={16} /> Old password is invalid
					</p>

					<span onClick={handleCloseAlert}>X</span>
				</div>
			)}

			<ChangePasswordForm
				loading={loading}
				onCancel={onCancel}
				onSubmit={onSubmit}
			/>
		</ChangePasswordModal>
	);
};

export default ChangePassword;
