import { Box, Modal } from "@material-ui/core";
import React from "react";

const style = {
	position: "absolute",
	top: "30%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "white",
	border: "1px solid rgb( 0, 0, 0, 0.2 )",
	borderRadius: "5px",
	boxShadow: 24,
	p: 4
};

export default function BasicModal({ children, open, handleClose }) {
	return (
		<div>
			<Modal open={open} onClose={handleClose}>
				<Box sx={style}>{children}</Box>
			</Modal>
		</div>
	);
}
