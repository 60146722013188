import * as actions from "./actions";

const initialState = {
	item: null,
	errors: {},
	loading: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_VERSION_REPORT: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_VERSION_REPORT_SUCCESS: {
			return {
				...state,
				item: payload ? payload : null,
				loading: { get: false }
			};
		}
		case actions.GET_VERSION_REPORT_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}
		case actions.DELETE_VERSION_REPORT: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_VERSION_REPORT_SUCCESS: {
			return {
				...state,
				loading: { delete: false }
			};
		}
		case actions.DELETE_VERSION_REPORT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
