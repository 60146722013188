import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useState } from "react";
import { AiOutlineReload } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Col, Container, Row, Tooltip } from "reactstrap";
import taxTransfer from "types/taxStatus";
import * as actions from "./actions";

dayjs.extend(customDateFormat);

let getTaxesDebounced = null;

const TaxesInCompany = ({ history, companyId, cnpj }) => {
	const dispatch = useDispatch();

	const { items, pages, count, loading, userSimei } = useSelector(
		state => state.companyTaxList
	);
	const { item: company } = useSelector(state => state.companyDetail);

	const taxColors = ["orange", "green", "info", "red", "info", "blue"];

	const [tooltipReloadDasOpen, setTooltipReloadDasOpen] = useState(false);
	const toggleReloadDas = () => setTooltipReloadDasOpen(!tooltipReloadDasOpen);

	const [tooltipNewOpen, setTooltipNewOpen] = useState(false);
	const toggleNew = () => setTooltipNewOpen(!tooltipNewOpen);

	const [tooltipInstallmentDasOpen, setTooltipInstallmentDasOpen] = useState(
		false
	);
	const toggleInstallmentDas = () =>
		setTooltipInstallmentDasOpen(!tooltipInstallmentDasOpen);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getTaxesDebounced) {
			getTaxesDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getTaxesDebounced = _.debounce(
			() =>
				dispatch(
					actions.getTaxes(
						page * pageSize,
						pageSize,
						`&filters[mei]=${companyId}` +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);
		getTaxesDebounced();
	};

	const handleReloadDas = () => {
		alert(`Os impostos podem demorar alguns minutos para serem processadas.`);

		dispatch(
			actions.reloadTaxes(cnpj, err => {
				if (err) {
					console.log(String(err));
				}
			})
		);
	};

	const handleInstallmentDas = () => {
		alert(
			`O parcelamento dos impostos podem demorar alguns minutos para serem processadas.`
		);

		dispatch(
			actions.getInstallmentDas(
				cnpj,
				userSimei?.codSimei,
				userSimei?.cpf,
				err => {
					if (err) {
						console.log(String(err));
					}
				}
			)
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<ReportTable
						manual
						title={"Impostos"}
						data={items}
						pageSize={12}
						pages={pages}
						loading={loading.getAll}
						onFetchData={handleFetchData}
						filterable
						noExport
						headerRightComponent={
							<div>
								<span className={"mr-2"}>
									Atualizado em:{" "}
									{items.length && items[0].updatedAt
										? dayjs(items[0].updatedAt).format(
												ls.dateFormatWithoutSeconds
										  )
										: ""}
								</span>

								{/* <Button
									className={"mr-2"}
									outline
									onClick={handleReloadDas}
									id="tooltipReloadDas"
								>
									<AiOutlineReload style={{ margin: 0 }} />
								</Button>
								<Tooltip
									placement="top"
									isOpen={tooltipReloadDasOpen}
									target="tooltipReloadDas"
									toggle={toggleReloadDas}
								>
									Acionar o robô para buscar dados do PGMEI
								</Tooltip> */}
							</div>
						}
						defaultSorted={[
							{
								id: "periodDate",
								desc: true
							}
						]}
						// onRowClicked={row => history.push(`/tax/${row._id}`)}
						columns={[
							{
								Header: "Período",
								id: "period",
								accessor: ({ period, quota }) => {
									return `${period}${+quota === 2 ? " - #2" : ""}`;
								},
								width: 130
							},
							{
								Header: "Valor",
								id: "value",
								accessor: c =>
									c.total ? toStringCurrency(c.total / 100.0) : "",
								width: 100,
								filterable: false
							},
							{
								Header: "Vencimento",
								id: "dueDate",
								accessor: c =>
									c.dueDate ? dayjs(c.dueDate).format("DD/MM/YYYY") : "",
								width: 100
							},
							{
								Header: "Cód. barras",
								accessor: "barCode",
								filterable: false
							},
							{
								Header: "Boleto",
								id: "boleto",
								accessor: c =>
									c.link ? (
										<a href={c.link} target="_blank" without rel="noreferrer">
											Abrir boleto
										</a>
									) : null,
								width: 100,
								filterable: false
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => {
									return (
										<Badge
											style={{
												fontSize: 12,
												backgroundColor: taxColors[c.status]
											}}
										>
											{ls[taxTransfer[c.status]]}
										</Badge>
									);
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : ""}
										>
											<option value="">Todos</option>
											{Object.keys(taxTransfer)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[taxTransfer[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 120
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default TaxesInCompany;
