import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "reactstrap";
import {
	ObligationPeriods,
	ObligationStatusDASN
} from "types/obligationStatus";

dayjs.extend(customDateFormat);

const DetailCompany = ({ obligation }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data Criação: </strong>
										<br />
										<span>
											{obligation.createdAt
												? dayjs(obligation.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>{obligation.name ? obligation.name : "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Empresa: </strong>
										<br />
										<span>
											{obligation.business
												? obligation.business.businessName
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Vencimento: </strong>
										<br />
										<span>
											{obligation.due
												? dayjs(obligation.due).format(ls.dateFormatShort)
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Ciclo: </strong>
										<br />
										<span>
											{obligation.period !== undefined
												? ls[ObligationPeriods[obligation.period]]
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Intervalo: </strong>
										<br />
										<span>{obligation.interval}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Status: </strong>
										<br />
										<span>
											{obligation.status !== undefined
												? ls[ObligationStatusDASN[obligation.status]]
												: "--"}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailCompany;
