import { getPermissions } from "containers/Permission/containers/Report/actions";
import React, { useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AccessType } from "types/Access";
import UserPermissionCreate from "../../../components/Create/Permission";
import {
	createUserPermission,
	setValue
} from "../../Details/Permissions/actions";

let findPermissionsDebounced = null;

const AddUserPermission = ({ load }) => {
	const dispatch = useDispatch();

	const {
		params: { id }
	} = useRouteMatch();

	const { items: permissions } = useSelector(s => s.permissions);
	const { showCreateModal, loading, userPermission } = useSelector(
		s => s.userPermission
	);

	const user_permission_create = useSelector(
		s => s.form.user_permission_create
	);

	const showModal = () => {
		dispatch(setValue({ showCreateModal: true }));
	};

	const closeModal = () => {
		dispatch(setValue({ showCreateModal: false }));
		dispatch(setValue({ userPermission: null }));
	};

	const handleFindPermission = name => {
		if (findPermissionsDebounced) {
			findPermissionsDebounced.cancel();
		}

		findPermissionsDebounced = _.debounce(
			() =>
				dispatch(
					getPermissions(
						0,
						10,
						`${name ? `&filters[name]=${name}` : ""}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar as empresas, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findPermissionsDebounced();
	};

	const onChange = values => {
		if (values.permissionSearch) {
			handleFindPermission(values.permissionSearch);
		}
	};

	const onSubmit = data => {
		const model = {
			permission: data.permission.value,
			access: [AccessType.None, AccessType.None, AccessType.None]
		};

		if (data.user) {
			if (data.user.read) model.access[0] += AccessType.Read;
			if (data.user.write) model.access[0] += AccessType.Write;
			if (data.user.delete) model.access[0] += AccessType.Delete;
		}

		if (data.group) {
			if (data.group.read) model.access[1] += AccessType.Read;
			if (data.group.write) model.access[1] += AccessType.Write;
			if (data.group.delete) model.access[1] += AccessType.Delete;
		}

		if (data.all) {
			if (data.all.read) model.access[2] += AccessType.Read;
			if (data.all.write) model.access[2] += AccessType.Write;
			if (data.all.delete) model.access[2] += AccessType.Delete;
		}

		model.user = id;

		dispatch(
			createUserPermission(model, (err, model) => {
				if (err) {
					console.log(err);
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					closeModal();
					load();
				}
			})
		);
	};

	let initialValues = {};

	const setPermissionValues = access => {
		let types = { delete: false, read: false, write: false };

		if (access !== AccessType.None) {
			switch (access) {
				case AccessType.Delete:
					types.delete = true;
					break;
				case AccessType.Write:
					types.write = true;
					break;
				case AccessType.DeleteWrite:
					types.delete = true;
					types.write = true;
					break;
				case AccessType.Read:
					types.read = true;
					break;
				case AccessType.DeleteRead:
					types.delete = true;
					types.read = true;
					break;
				case AccessType.ReadWrite:
					types.read = true;
					types.write = true;
					break;
				case AccessType.ReadWriteDelete:
					types.read = true;
					types.write = true;
					types.delete = true;
					break;
			}
		}

		return types;
	};

	if (userPermission) {
		const { permission, access } = userPermission;

		initialValues = {
			permission: {
				value: permission._id,
				label: permission.name
			},
			user: setPermissionValues(access[0]),
			group: setPermissionValues(access[1]),
			all: setPermissionValues(access[2])
		};
	}

	useEffect(() => {
		handleFindPermission();
	}, []);

	return (
		<div>
			<Button Button className="mr-2" outline onClick={showModal}>
				<MdAdd />
			</Button>
			<Modal
				toggle={closeModal}
				className="theme-light ltr-support login-modal-form"
				isOpen={showCreateModal}
				size="md"
			>
				<ModalHeader>Adicionar permissão</ModalHeader>
				<ModalBody>
					<div className="form__form-group-field">
						<UserPermissionCreate
							onSubmit={onSubmit}
							onChange={onChange}
							loading={loading.create}
							permissions={permissions || []}
							initialValues={initialValues}
							isInvalid={
								user_permission_create && !!user_permission_create.syncErrors
							}
						/>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default AddUserPermission;
