import styled, { css } from "styled-components";

export const Container = styled.div`
	margin-top: 1rem;

	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;

	& * {
		border: none;
	}

	&::-webkit-scrollbar {
		height: 0.5em;
	}

	&::-webkit-scrollbar-track {
		border-radius: 0.5rem;
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 0.5rem;
		background: #6c757d;
	}
`;

export const ContainerHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1.25rem;
	flex-shrink: 0;

	> :last-child {
		width: 4.5rem;
	}
`;

export const TableSc = styled.table`
	border: 0;
	border-collapse: collapse;

	width: 100%;

	font-size: 0.85rem;
	margin-bottom: 0.5rem;

	thead > tr {
		background-color: #e6e6e6;
	}
`;

export const TrSc = styled.tr`
	cursor: ${props =>
		props.cursor == "default" || !props.cursor ? "default" : props.cursor};
	border-radius: 1rem 1rem 1rem 1rem;
	line-height: 0.5rem;

	&:nth-of-type(odd) {
		background-color: #f2f2f2;
	}
	&:nth-of-type(even) {
		background-color: #fcfbfb;
	}
`;

export const ThSc = styled.th`
	padding: 0.8em;

	width: ${props => (props.width ? `${props.width}px` : "100px")};

	font-weight: 400;
	text-align: ${({ align }) => align};
	line-height: 0.8rem;
	white-space: nowrap;

	${({ sticky, width, index }) =>
		sticky &&
		css`
			position: sticky;
			background-color: #e6e6e6;
			min-width: ${width ? `${width}px` : "100px"};
			max-width: ${width ? `${width}px` : "100px"};
			left: ${index * width}px;
		`};

	&:first-of-type {
		border-top-left-radius: 0.625rem;
		padding-right: 0;
	}

	&:last-of-type {
		border-top-right-radius: 0.625rem;
		padding-left: 0;
	}
`;

export const TdSc = styled.td`
	border: 0;
	padding: 0.625rem;

	width: ${props => (props.width ? `${props.width}px` : "100px")};
	min-width: ${props => (props.width ? `${props.width}px` : "100px")};
	max-width: ${props => (props.width ? `${props.width}px` : "100px")};

	text-align: ${({ align }) => align};
	color: #31475a;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	${({ sticky, width, index, rowIndex }) =>
		sticky &&
		css`
			position: sticky;
			background-color: ${rowIndex % 2 ? "#FCFBFB" : "#F2F2F2"};
			min-width: ${width ? `${width}px` : "100px"};
			max-width: ${width ? `${width}px` : "100px"};
			left: ${index * width}px;
		`}

	&:first-of-type {
		padding-right: 0;

		color: #48494b;
	}

	&:last-of-type {
		padding-left: 0;
	}
`;
export const TdFilterSc = styled.td`
	padding: 0.2em 0.8em;

	width: ${({ width }) => (width ? `${width}px` : "100px")};

	&:first-of-type {
		padding-right: 0;
	}

	&:last-of-type {
		padding-left: 0;
	}
`;

export const InputSc = styled.input`
	border-radius: 5px;
	border: 1px solid rgb(161 170 177);

	padding-left: 0.3rem;

	width: 100%;
	height: 35px;

	color: #6c757d;

	background-color: #fcfbfb;

	:focus {
		border-color: ${props => props.color || "#F57F4C"};
		outline: none;

		box-shadow: 0px 0px 6px ${props => props.color || "#F57F4C"};

		color: #6c757d;
	}

	:hover {
		border: 1px solid ${props => props.color || " #F57F4C"};
	}
`;

export const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;

	padding: 1rem;
`;
