import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import EditForm from "../../components/EditForm";
import { clearValues, getVersionReportItem } from "../Details/actions";
import { updateVersionReportItem } from "./actions";

dayjs.extend(customParseFormat);

function Edit({
	match: {
		params: { id }
	},
	history
}) {
	const { item, loading } = useSelector(state => state.versionReportDetail);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getVersionReportItem(id));
	}, [dispatch]);

	if (!item) return "null";

	const onSubmit = data => {
		dispatch(
			updateVersionReportItem(id, data, err => {
				if (err) {
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					history.push(`/version-report/`);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Criar novo relatório de versão</h5>
							</div>
							<EditForm onSubmit={onSubmit} initialValues={item} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
