import React from "react";
import { Button, ButtonToolbar, Col, Container, Spinner } from "reactstrap";
import { Field, reduxForm } from "redux-form";

import Input from "components/ReduxForm/Input";

import validate from "./validate";

function CreateAttendantModal({ handleSubmit, loading, errors, isInvalid }) {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Col className="form__form-group">
					<span className="bold-text">Nome do atendente</span>
					<div className="form__form-group-field">
						<Field
							name="name"
							component={Input}
							type="text"
							placeholder="Insira o nome do atendente"
							props={{ errors: errors && errors.name }}
						/>
					</div>
				</Col>
				<Col className="form__form-group">
					<span className="bold-text">E-mail do atendente</span>
					<div className="form__form-group-field">
						<Field
							name="email"
							component={Input}
							type="email"
							placeholder="Insira o e-mail do atendente"
							props={{ errors: errors && errors.email }}
						/>
					</div>
				</Col>
				<Col className="form__form-group">
					<span className="bold-text">ID do atendente no Digisac</span>
					<div className="form__form-group-field">
						<Field
							name="digisacId"
							component={Input}
							type="text"
							placeholder="Insira o ID do atendente no Digisac"
							props={{ errors: errors && errors.digisacId }}
						/>
					</div>
				</Col>

				<ButtonToolbar className="form__button-toolbar">
					<Button color="primary" type="submit" disabled={isInvalid || loading}>
						{loading ? (
							<Spinner type="grow" size="sm" color="dark" />
						) : (
							"Salvar"
						)}
					</Button>
				</ButtonToolbar>
			</Container>
		</form>
	);
}

export default reduxForm({
	form: "attendant_create",
	validate
})(CreateAttendantModal);
