import * as actions from "./actions";

const initialState = {
	loading: { getAll: false, import: false, find: false },
	errors: {},
	contactCompany: {},
	show: false
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_CONTACT_COMPANY: {
			return {
				...state,
				loading: { find: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CONTACT_COMPANY_SUCCESS: {
			return {
				...state,
				contactCompany: payload || {},
				loading: { find: false },
				show: true
			};
		}
		case actions.GET_CONTACT_COMPANY_FAILED: {
			return {
				...state,
				loading: { find: false },
				errors: payload
			};
		}
		
		case actions.UPDATE_CONTACT_COMPANY: {
			return {
				...state,
				errors: initialState.errors,
				show: false
			};
		}
		case actions.UPDATE_CONTACT_COMPANY_SUCCESS: {
			return {
				...state,
				contactCompany: payload,
				show: true
			};
		}
		case actions.UPDATE_CONTACT_COMPANY_FAILED: {
			return {
				...state,
				error: payload
			};
		}
		
		case actions.CREATE_CONTACT_COMPANY: {
			return {
				...state,
				errors: initialState.errors,
				show: false
			};
		}
		case actions.CREATE_CONTACT_COMPANY_SUCCESS: {
			return {
				...state,
				contactCompany: payload,
				show: true
			};
		}
		case actions.CREATE_CONTACT_COMPANY_FAILED: {
			return {
				...state,
				error: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
