import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useRef } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { companyBenefitStatus } from "types/companyBenefitStatus";
import * as actions from "./actions";

dayjs.extend(customDateFormat);

let getBenefitsDebounced = null;

function benefitsInCompany({ history, companyId }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { items, pages, count, loading } = useSelector(
		state => state.companyBenefitList
	);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBenefitsDebounced) {
			getBenefitsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getBenefitsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getBenefits(
						page * pageSize,
						pageSize,
						`&filters[mei]=${companyId}` +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);
		getBenefitsDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Benefícios"}
						data={items}
						pageSize={5}
						pages={pages}
						loading={loading.getAll}
						filterable
						headerRightComponent={
							<Button
								onClick={() =>
									history.push(`/companies/${companyId}/benefits/new`)
								}
								className="mr-2"
								outline
							>
								<MdAdd />
							</Button>
						}
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						onRowClicked={row =>
							history.push(`/companies/${companyId}/benefits/${row._id}`)
						}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds)
										: "--",
								width: 130
							},
							{
								Header: "Nome",
								id: "name",
								accessor: "benefit.name"
							},
							{
								Header: "Preço",
								id: "price",
								accessor: c => toStringCurrency((c.price || 0) / 100.0),
								width: 100
							},
							{
								Header: "Desconto",
								id: "discount",
								accessor: c => toStringCurrency((c.discount || 0) / 100.0),
								width: 100
							},
							{
								Header: "Status",
								id: "status",
								show: true,
								accessor: c => ls[companyBenefitStatus[c.status]],
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(companyBenefitStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[companyBenefitStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 100
							},
							{
								Header: "Origem",
								id: "from",
								accessor: ({ from }) => from?.businessName || "--",
								width: 200
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default benefitsInCompany;
