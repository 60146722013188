import * as api from "services/api";
import { getErrors } from "helpers/error";

export const ADD_BENEFIT = "[BENEFIT ADD] ADD_BENEFIT";
export const ADD_BENEFIT_ERROR = "[BENEFIT ADD] ADD_BENEFIT_ERROR";
export const ADD_BENEFIT_SUCCESS = "[BENEFIT ADD] ADD_BENEFIT_SUCCESS";

export const ADD_BENEFIT_TO_PROVIDERS =
	"[BENEFIT ADD] ADD_BENEFIT_TO_PROVIDERS";
export const ADD_BENEFIT_TO_PROVIDERS_ERROR =
	"[BENEFIT ADD] ADD_BENEFIT_TO_PROVIDERS_ERROR";
export const ADD_BENEFIT_TO_PROVIDERS_SUCCESS =
	"[BENEFIT ADD] ADD_BENEFIT_SUCCESS";

export const SET_VALUE = "[BENEFIT ADD] SET_VALUE";
export const CLEAR_VALUES = "[BENEFIT ADD] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function addBenefit(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_BENEFIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPost("/companyBenefit", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();
			if (response.ok) {
				dispatch({
					type: ADD_BENEFIT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ADD_BENEFIT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: ADD_BENEFIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function addBenefitToProviders(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_BENEFIT_TO_PROVIDERS
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(
				"/companyBenefit/add-to-providers",
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();
			if (response.ok) {
				dispatch({
					type: ADD_BENEFIT_TO_PROVIDERS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ADD_BENEFIT_TO_PROVIDERS_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: ADD_BENEFIT_TO_PROVIDERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
