import * as api from "../../../../../services/api";
import { getErrors } from "../../../../../helpers/error";

export const GET_USERS_STATUS = "[DASHBOARD] GET_USERS_STATUS";
export const GET_USERS_STATUS_ERROR = "[DASHBOARD] GET_USERS_STATUS_ERROR";
export const GET_USERS_STATUS_SUCCESS = "[DASHBOARD] GET_USERS_STATUS_SUCCESS";

export const GET_COMPANIES_TYPE = "[DASHBOARD] GET_COMPANIES_TYPE";
export const GET_COMPANIES_TYPE_ERROR = "[DASHBOARD] GET_COMPANIES_TYPE_ERROR";
export const GET_COMPANIES_TYPE_SUCCESS =
	"[DASHBOARD] GET_COMPANIES_TYPE_SUCCESS";

export const GET_COMPANIES_HIRED = "[DASHBOARD] GET_COMPANIES_HIRED";
export const GET_COMPANIES_HIRED_ERROR =
	"[DASHBOARD] GET_COMPANIES_HIRED_ERROR";
export const GET_COMPANIES_HIRED_SUCCESS =
	"[DASHBOARD] GET_COMPANIES_HIRED_SUCCESS";

export const GET_COMPANIES_X_USERS = "[DASHBOARD] GET_COMPANIES_X_USERS";
export const GET_COMPANIES_X_USERS_ERROR =
	"[DASHBOARD] GET_COMPANIES_X_USERS_ERROR";
export const GET_COMPANIES_X_USERS_SUCCESS =
	"[DASHBOARD] GET_COMPANIES_X_USERS_SUCCESS";

export const SET_VALUE = "[DASHBOARD] SET_VALUE";
export const CLEAR_VALUES = "[DASHBOARD] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getUsersVersusCompanies(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANIES_X_USERS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/company/chart/users/x/mei`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMPANIES_X_USERS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMPANIES_X_USERS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANIES_X_USERS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getCompaniesHired(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANIES_HIRED
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/company/chart/hired`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMPANIES_HIRED_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMPANIES_HIRED_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANIES_HIRED_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getUsersStatus(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_USERS_STATUS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/user/chart/status`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USERS_STATUS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_USERS_STATUS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USERS_STATUS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getCompaniesType(callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANIES_TYPE
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/company/chart/type`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMPANIES_TYPE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMPANIES_TYPE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANIES_TYPE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
