import { Container, Grid } from "@material-ui/core";
import { StyledTable } from "components/StyledTable";
import React, { useState } from "react";
import { BiMinusCircle, BiPlusCircle, BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as api from "../../../../services/api";
import { ButtonSC, SubTitleSC, TitleSC } from "./styled";
import LoadingComponent from "components/LoadingComponent";

const NewCompanyGroup = () => {
	const history = useHistory();
	const { token } = useSelector(state => state.login);

	const [businessName, setBusinessName] = useState("");
	const [searchCompany, setSearchCompany] = useState("");
	const [searchCompanies, setSearchCompanies] = useState([]);
	const [skipSearchCompany, setSkipSearchCompany] = useState(0);
	const [takeSearchCompany, setTakeSearchCompany] = useState(5);
	const [countSearchCompany, setCountSearchCompany] = useState(0);

	const [data, setData] = useState([]);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(5);
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const columns = [
		{
			field: "businessName",
			headerName: "Nome do Cliente",
			align: "left",
			alignContent: "left",
			width: 600,
			content: value => {
				return (
					<div gap="22px" alignContent="center">
						<span>{value || ""}</span>
					</div>
				);
			}
		},
		{
			field: "situation",
			headerName: "Situação",
			align: "left",
			alignContent: "left",
			width: 50,
			content: value => {
				return (
					<div
						gap="10px"
						alignItems="center"
						style={{
							backgroundColor: value.match(/ativ/i) ? "green" : "red",
							padding: "5px",
							borderRadius: "5px",
							display: "flex",
							justifyContent: "center",
							color: "#fff",
							fontWeight: 700
						}}
					>
						<span>{value}</span>
					</div>
				);
			}
		},
		{
			field: "isActive",
			headerName: (
				<span>
					Empresa
					<br /> Ativa?
				</span>
			),
			align: "left",
			alignContent: "left",
			width: 50,
			content: value => {
				return (
					<div
						gap="10px"
						alignItems="center"
						style={{
							backgroundColor: value ? "green" : "red",
							padding: "5px",
							borderRadius: "5px",
							display: "flex",
							justifyContent: "center",
							color: "#fff",
							fontWeight: 700
						}}
					>
						<span>{value ? "Sim" : "Não"}</span>
					</div>
				);
			}
		}
	];

	function handleChangeSkipSearchCompany(value) {
		setSkipSearchCompany(value);
	}

	function handleChangeTakeSearchCompany({ target }) {
		setTakeSearchCompany(target.value);

		setSkipSearchCompany(0);
	}

	function handleChangeSkip(value) {
		setSkip(value);
	}

	function handleChangeTake({ target }) {
		setTake(target.value);

		setSkip(0);
	}

	const handleFetchCompanies = async () => {
		try {
			setIsLoading(true);

			let filter = "&filters[isHired]=true";

			if (searchCompany) filter += `&filters[businessName]=${searchCompany}`;

			const response = await api.sendGet(
				`/company?skip=${skip *
					take}&take=${take}&sort=businessName&desc=false${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setSearchCompanies(
					result.model.map(item => ({
						_id: item._id,
						businessName: item.businessName,
						isActive: item.isActive,
						situation: item.situation
					}))
				);
				setCountSearchCompany(result.params.count);
			}

			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddCompanyGroup = async () => {
		try {
			if (!businessName) {
				toast.warning("Por favor, informe o nome do cliente");
				return;
			}

			if (data.length < 1) {
				toast.warning("Por favor, adicione pelo menos um cliente");
				return;
			}

			const response = await api.sendPost(
				"/company-group",
				{
					businessName,
					companies: data.map(item => {
						return {
							_id: item._id,
							businessName: item.businessName,
							isActive: item.isActive
						};
					})
				},
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				toast.success("Cliente criado com sucesso");

				history.push("/companies/company-groups");
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddCompany = value => {
		if (data.findIndex(item => item._id === value._id) < 0) {
			setData([...data, value]);

			setCount(data.length);
		} else {
			toast.warning(`${value.businessName} já foi adicionada!`);
		}
	};

	return (
		<Container>
			<Grid container style={{ marginBottom: "16px" }}>
				<Grid item style={{ marginBottom: "16px" }}>
					<TitleSC id="company-group-title">Cadastrar um novo cliente</TitleSC>
				</Grid>

				<Grid
					item
					container
					spacing={2}
					style={{
						background: "#fff",
						borderRadius: "5px",
						padding: "16px"
					}}
				>
					<Grid
						id="company-group-filter-header"
						item
						style={{ borderBottom: "1px solid #ccc", width: "100%" }}
					>
						<SubTitleSC>Cliente</SubTitleSC>
					</Grid>

					<Grid id="company-group-filter-body" item container>
						<Grid
							item
							style={{ display: "flex", flexDirection: "column" }}
							xs={12}
						>
							<span>Inserir um nome para o cliente</span>
							<input
								name="businessName"
								type="text"
								value={businessName}
								onChange={e => setBusinessName(e.target.value)}
								style={{
									padding: "8px 14px",
									borderRadius: "5px",
									border: "1px solid rgb( 0, 0, 0, 0.2 )",
									width: "300px",
									lineHeight: 1.6
								}}
							/>
						</Grid>
					</Grid>

					<Grid id="company-group-filter-body" item container>
						<Grid
							item
							style={{ display: "flex", flexDirection: "column" }}
							xs={12}
						>
							<Grid item container spacing={2}>
								<Grid item style={{ display: "flex", flexDirection: "column" }}>
									<span>Pesquisar empresas para adicionar</span>
									<input
										name="searchCompany"
										type="text"
										value={searchCompany}
										onChange={e => setSearchCompany(e.target.value)}
										style={{
											padding: "8px 14px",
											borderRadius: "5px",
											border: "1px solid rgb( 0, 0, 0, 0.2 )",
											width: "300px",
											lineHeight: 1.6
										}}
									/>
								</Grid>
								<Grid item style={{ display: "flex" }}>
									<ButtonSC
										color="#fff"
										border="1px solid #584c9c"
										onClick={handleFetchCompanies}
										type="button"
										style={{
											padding: "8px 14px",
											lineHeight: 1.6,
											width: "64px",
											height: "38px",
											display: "flex",
											justifyContent: "center"
										}}
									>
										<BiSearch size={24} />
									</ButtonSC>
								</Grid>
							</Grid>

							{isLoading ? (
								<LoadingComponent />
							) : (
								<StyledTable
									data={searchCompanies}
									columns={[
										...columns,
										{
											field: "",
											headerName: "Ações",
											align: "left",
											alignContent: "left",
											width: 80,
											content: value => {
												return (
													<ButtonSC
														background="#fff"
														color="#584c9c"
														border="1px solid #584c9c"
														onClick={() => handleAddCompany(value)}
														type="button"
														style={{
															padding: "8px",
															height: "32px",
															display: "flex",
															justifyContent: "center"
														}}
													>
														<BiPlusCircle size={24} />
													</ButtonSC>
												);
											}
										}
									]}
									handleChangeSkip={handleChangeSkipSearchCompany}
									handleChangeTake={handleChangeTakeSearchCompany}
									skip={skipSearchCompany}
									take={takeSearchCompany}
									count={countSearchCompany}
									rowClick={false}
								/>
							)}
						</Grid>
					</Grid>

					<Grid id="company-group-filter-footer" item container>
						<Grid
							item
							style={{ display: "flex", flexDirection: "column" }}
							xs={12}
						>
							<span>Empresas adicionadas</span>
							<StyledTable
								data={data}
								columns={[
									...columns,
									{
										field: "",
										headerName: "Ações",
										align: "left",
										alignContent: "left",
										width: 80,
										content: value => {
											return (
												<ButtonSC
													background="#fff"
													color="#584c9c"
													border="1px solid #584c9c"
													onClick={() => {
														setData(
															data.filter(item => item._id !== value._id)
														);
														setCount(data.length);
													}}
													type="button"
													style={{
														padding: "8px",
														height: "32px",
														display: "flex",
														justifyContent: "center"
													}}
												>
													<BiMinusCircle size={24} />
												</ButtonSC>
											);
										}
									}
								]}
								handleChangeSkip={handleChangeSkip}
								handleChangeTake={handleChangeTake}
								skip={skip}
								take={take}
								count={count}
								rowClick={false}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				id="company-group-filter-footer"
				item
				container
				spacing={1}
				style={{ display: "flex", justifyContent: "flex-end" }}
			>
				<Grid item>
					<ButtonSC
						background="#fff"
						color="#584c9c"
						border="1px solid #584c9c"
						onClick={() => history.goBack()}
						type="button"
					>
						Cancelar
					</ButtonSC>
				</Grid>

				<Grid item>
					<ButtonSC border="1px solid #584c9c" onClick={handleAddCompanyGroup}>
						Finalizar Cadastro
					</ButtonSC>
				</Grid>
			</Grid>
		</Container>
	);
};

export default NewCompanyGroup;
