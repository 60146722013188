import React from "react";

import { Text } from "components/Text";

import { Bar, BarContainer, FilledBar, ProgressBarContainer } from "./styles";

export function ProgressBarFullScreen({
	label,
	quantity,
	total,
	color = "#7f26f2"
}) {
	return (
		<ProgressBarContainer>
			<BarContainer>
				<Bar>
					<FilledBar
						percentage={
							(quantity * 100) / total > 100 ? 100 : (quantity * 100) / total
						}
						color={color}
					/>
				</Bar>
			</BarContainer>

			<Text as="span" fontSize="14px" color="#676767">
				<Text as="span" fontWeight={700}>
					{quantity}
				</Text>{" "}
				- {label}
			</Text>
		</ProgressBarContainer>
	);
}
