import React from "react";

import { setValue } from "../../containers/Create/actions";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";

import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import Input from "../../../../components/ReduxForm/Input";
import TextArea from "../../../../components/ReduxForm/TextArea";

const CreateForm = ({ reset, handleSubmit, change }) => {
	const dispatch = useDispatch();
	const { loading } = useSelector(s => s.versionReportCreate);

	const { errors } = useSelector(s => s.versionReportCreate);

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={2} className="form__form-group">
						<span className="form__form-group-label">Versão</span>
						<div className="form__form-group-field">
							<Field
								name="version"
								component={Input}
								type="text"
								placeholder="Ex.: 2.0.0"
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={6} className="form__form-group">
						<span className="form__form-group-label">Título</span>
						<div className="form__form-group-field">
							<Field name="title" component={Input} type="text" />
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={6} className="form__form-group">
						<span className="form__form-group-label">Texto do Item</span>
						<div className="form__form-group-field">
							<Field name="text" component={TextArea} type="text" />
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button color={"primary"} type={"submit"} disabled={loading.create}>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>

				<Button
					type="button"
					onClick={() => {
						dispatch(
							setValue({
								users: [],
								errors: {}
							})
						);
						reset();
					}}
				>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "app_version_create",
	validate
})(CreateForm);
