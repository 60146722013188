import React, { useState } from "react";
import LogInForm from "./components/LogInForm";
// import { SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../shared/components/Alert";
import { submitLogin } from "redux/actions/login";
import { SubmissionError } from "redux-form";
import ls from "Localization";
import actions from "redux/actions";

const LogIn = ({ history }) => {
	const dispatch = useDispatch();

	const [error, setError] = useState("");

	const { message } = useSelector(s => s.login);

	const handleDismiss = () => {
		dispatch(actions.login.setValue({ message: "" }));
	};

	const onSubmit = data => {
		return dispatch(
			submitLogin(data, err => {
				if (err) {
					if (!err["user"] && err["info"]) {
						setError(err.info.message);
					}

					if (err["user-pending"]) {
						setError(ls[err["user-pending"]]);
					}

					if (err.reset) {
						alert(ls[err.reset]);
					} else {
						throw new SubmissionError(err);
					}
				} else {
					history.push("/");
				}
			})
		);
	};

	return (
		<div className="account">
			{message && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="success"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p>
							<span className="bold-text">{message}</span>
						</p>
					</Alert>
				</div>
			)}
			{error && (
				<div
					style={{
						position: "absolute",
						right: 16,
						top: 16
					}}
				>
					<Alert
						color="warning"
						className="alert--bordered"
						icon
						onDismiss={handleDismiss}
					>
						<p>
							<span className="bold-text">{error}</span>
						</p>
					</Alert>
				</div>
			)}
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<img
							src="/img/LogoSuaMei_Preto.png"
							style={{ objectFit: "contain" }}
						/>
						<h4 className="account__subhead subhead">
							Acesse e acompanhe seus resultados
						</h4>
					</div>
					<LogInForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	);
};

export default LogIn;
