import React, { useCallback, useRef, useState } from "react";
import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import {
	getUserFullName,
	stringToMask,
	toStringCurrency
} from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	Row
} from "reactstrap";
import { nfseStatus } from "types/nfseStatus";
import { updateCompany } from "../Edit/actions";
import * as actions from "./actions";
import NFSeDataEditForm from "containers/Company/components/NFSeDataEdit/NFSeDataEditForm";
import { getCompanyById } from "../Detail/actions";
import { nfeBusinessStatus } from "types/NFeBusinessStatus";
import EyeIcon from "mdi-react/EyeIcon";
import ImportNfse from "containers/Company/components/ImportNfse";

dayjs.extend(customDateFormat);

let getNfseDebounced = null;

const NfseInCompany = ({ history, companyId }) => {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { items, pages, loading } = useSelector(state => state.companyNfseList);
	const { item } = useSelector(state => state.companyDetail);
	const { loading: loadingEdit, errors } = useSelector(s => s.companyEdit);
	const nfse_data_edit_form = useSelector(s => s.form.nfse_data_edit_form);

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	const [showNfEmissionPassword, setShowNfEmissionPassword] = useState(false);
	const toggleNfEmissionPassword = () =>
		setShowNfEmissionPassword(!showNfEmissionPassword);

	const handleFetchData = useCallback(
		tableState => {
			if (!tableState) return;
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getNfseDebounced) {
				getNfseDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate && creationDate.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate.value.startDate) {
					filtered.push({
						id: "startDate",
						value: creationDate.value.startDate
					});
				}

				if (creationDate.value.endDate)
					filtered.push({
						id: "endDate",
						value: creationDate.value.endDate
					});
			}

			getNfseDebounced = _.debounce(
				() =>
					dispatch(
						actions.getNfses(
							page * pageSize,
							pageSize,
							`&filters[${item?.isHired ? "clientMei" : "mei"}]=${companyId}` +
								filtered.reduce((p, c) => {
									return p + `&filters[${c.id}]=${c.value}`;
								}, ""),
							sorted[0] ? sorted[0].id : undefined,
							sorted[0] ? sorted[0].desc : undefined,
							toExport,
							callback
						)
					),
				500
			);
			getNfseDebounced();
		},
		[companyId]
	);

	const load = () => {
		handleFetchData(table.current ? table.current.state : undefined);
	};

	const onSubmit = async data => {
		dispatch(
			updateCompany(
				companyId,
				{
					nfEmissionLogin: data?.nfEmissionLogin,
					nfEmissionPassword: data?.nfEmissionPassword,
					nfEmissionUrl: data?.nfEmissionUrl
				},
				error => {
					if (error) {
						console.log(error);
					} else {
						toggle();
						alert("Dados alterados com sucesso!");
						dispatch(getCompanyById(companyId));
					}
				}
			)
		);
	};

	return (
		<Container className="dashboard">
			{!item?.isHired && (
				<Row>
					<Col>
						<Card>
							<CardBody>
								<Row className="justify-content-between align-items-center">
									<div className="card__title">
										<h5 className="bold-text">Dados Nota Fiscal</h5>
									</div>
									<div>
										<Button color={"primary"} type={"submit"} onClick={toggle}>
											Editar
										</Button>
									</div>
								</Row>

								<Row>
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>Login: </strong>
											<br />
											<span>{item?.nfEmissionLogin || "--"}</span>
										</p>
									</Col>

									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<strong>Senha: </strong>
										<div className="form__form-group-field">
											<input
												readOnly
												type={showNfEmissionPassword ? "text" : "password"}
												value={item?.nfEmissionPassword || "--"}
											/>
											<button
												className={`form__form-group-button${
													showNfEmissionPassword ? " active" : ""
												}`}
												onClick={toggleNfEmissionPassword}
												type="button"
											>
												<EyeIcon />
											</button>
										</div>
									</Col>

									<Col className="mt-3" xs={9} sm={9} md={4} xl={2}>
										<p>
											<strong>Status: </strong>
											<br />
											<Badge
												color={item?.nfeStatus ? "primary" : "secondary"}
												style={{
													fontSize: 12
												}}
											>
												{ls[nfeBusinessStatus[item?.nfeStatus]]}
											</Badge>
										</p>
									</Col>

									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<p>
											<strong>URL: </strong>
											<br />
											<span>{item?.nfEmissionUrl || "--"}</span>
										</p>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}

			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<ReportTable
								manual
								tableRef={table}
								title={"Nota fiscal de serviço eletrônica"}
								data={items}
								pageSize={5}
								pages={pages}
								loading={loading.getAll}
								onFetchData={handleFetchData}
								filterable
								defaultSorted={[
									{
										id: "createdAt",
										desc: true
									}
								]}
								headerRightComponent={
									<ImportNfse
										id={companyId}
										load={() => load()}
										loading={loading?.import}
									/>
								}
								onRowClicked={row => history.push(`/nfses/${row._id}`)}
								columns={[
									{
										Header: () => (!item?.isHired ? "Tomador" : "Emissor"),
										id: () =>
											!item?.isHired
												? "clientMei.businessName"
												: "mei.businessName",
										filterable: false,
										accessor: ({ clientMei, client, mei }) =>
											!item?.isHired
												? clientMei?.businessName || client?.name
												: mei?.businessName
									},
									{
										Header: () =>
											!item?.isHired ? "CNPJ Tomador" : "CNPJ Emissor",
										id: () => (!item?.isHired ? "clientMei.cnpj" : "mei.cnpj"),
										filterable: false,
										accessor: ({ clientMei, client, mei }) => {
											const c = !item?.isHired
												? clientMei?.cnpj || client?.document
												: mei?.cnpj;

											return c?.length === 11
												? stringToMask(c, "###.###.###-##")
												: stringToMask(c, "##.###.###/####-##");
										}

										// clientMei?.cnpj
										// 	? stringToMask(clientMei?.cnpj, "##.###.###/####-##")
										// 	: mei?.cnpj
										// 	? stringToMask(mei?.cnpj, "##.###.###/####-##")
										// 	: client?.document
										// 	? stringToMask(
										// 			client?.document,
										// 			client?.document?.length === 11
										// 				? "###.###.###-##"
										// 				: "##.###.###/####-##"
										// 	  )
										// 	: "--"
									},
									{
										Header: "Valor Total",
										id: "totalValue",
										filterable: true,
										accessor: c =>
											c.totalValue
												? toStringCurrency(+c.totalValue / 100.0)
												: "--"
									},
									{
										Header: "Observações",
										id: "observations",
										filterable: false,
										accessor: c => (c.observations ? c.observations : "--")
									},

									{
										Header: "Data Criação",
										id: "createdAt",
										accessor: c =>
											c.createdAt
												? dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds)
												: "--"
									},
									{
										Header: "Última atualização",
										id: "updatedAt",
										accessor: c =>
											c.updatedAt
												? dayjs(c.updatedAt).format(ls.dateFormatWithoutSeconds)
												: "--"
									},
									{
										Header: "Emitido por",
										id: "user",
										accessor: ({ user }) => (user ? getUserFullName(user) : ""),
										width: 150
									},
									{
										Header: ls.status,
										id: "status",
										show: true,
										accessor: c => ls[nfseStatus[c.status]],
										Filter: ({ filter, onChange }) => {
											return (
												<select
													onChange={event => onChange(event.target.value)}
													style={{ width: "100%" }}
													value={filter && filter.value ? filter.value : "all"}
												>
													<option value="">Todos</option>
													{Object.keys(nfseStatus)
														.filter(c => !isNaN(+c))
														.map(c => (
															<option key={c} value={c}>
																{ls[nfseStatus[c]]}
															</option>
														))}
												</select>
											);
										},
										width: 140
									}
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle} className={"card__title bold-text"}>
					Nota Fiscal
				</ModalHeader>

				<ModalBody className="theme-light">
					<NFSeDataEditForm
						onSubmit={onSubmit}
						initialValues={item}
						loading={loadingEdit}
						isInvalid={nfse_data_edit_form && !!nfse_data_edit_form.syncErrors}
						errors={errors}
					/>
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default NfseInCompany;
