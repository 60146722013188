/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from "prop-types";

import { Collapse } from 'reactstrap';
import DotsVertical from 'mdi-react/DotsVerticalIcon';

const Actions = ({ buttons }) => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <div className="topbar__collapse">
      <button className="topbar__btn" type="button" onClick={toggle}>
        <DotsVertical />
      </button>
      {collapse && <button className="topbar__back" type="button" onClick={toggle} />}
      <Collapse
        isOpen={collapse}
        className="topbar__menu-wrap"
      >
        <div className="topbar__menu">
          {
            buttons.map(button => (
              <button
                className="topbar__collapse-button ml-4"
                type="button"
                onClick={button.action}
              >
                {button.label}
              </button>
            ))
          }
        </div>
      </Collapse>
    </div>
  );
}

Actions.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    })
  ).isRequired,
};

Actions.defaultProps = {
  buttons: [],
};

export default Actions;
