import React from "react";
import { Route, Switch } from "react-router-dom";
import ContractTemplateDetail from "./containers/Page/Detail/index";
import ContractTemplateList from "./containers/Report/index";
import NewTemplateContract from "./containers/Page/Create";
import NewContract from "./containers/Page/New";
import ContractTemplateEdit from "./containers/Page/Edit";

import ContractsRegularList from "./containers/ReportAll";
import ContractsRegularDetail from "./containers/Page/DetaillAll";
import ContractsRegularEdit from "./containers/Page/EditAll";

import Department from "./containers/ReportDepartment";
import NewLinkedContract from "./containers/Page/NewLinkedContract";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route
					exact
					path="/contracts/template/new"
					component={NewTemplateContract}
				/>
				<Route
					exact
					path="/contracts/template"
					component={ContractTemplateList}
				/>
				<Route
					exact
					path="/contracts/template/:id"
					component={ContractTemplateDetail}
				/>
				<Route
					exact
					path="/contracts/template/:id/edit"
					component={ContractTemplateEdit}
				/>
				<Route exact path="/contracts/department" component={Department} />

				<Route exact path="/contracts" component={ContractsRegularList} />
				<Route exact path="/contracts/new" component={NewContract} />
				<Route
					exact
					path="/contracts/new-linked-contract/:id"
					component={NewLinkedContract}
				/>
				<Route exact path="/contracts/:id" component={ContractsRegularDetail} />
				<Route
					exact
					path="/contracts/:id/edit"
					component={ContractsRegularEdit}
				/>
			</Switch>
		</React.Fragment>
	);
};

export default routes;
