import React from "react";
import { Route, Switch } from "react-router-dom";

import Report from "./containers/Report";
import Create from "./containers/Create";
import Details from "./containers/Details";

const routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/marketing/new" component={Create} />
        <Route exact path="/marketing" component={Report} />
        <Route exact path="/marketing/:id" component={Details} />
      </Switch>
    </React.Fragment>
  );
};

export default routes;
