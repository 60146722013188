import ChangePassword from "containers/Profile/containers/ChangePassword";
import { setValue as setValueChangePassword } from "containers/Profile/containers/ChangePassword/actions";
import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "reactstrap";
import { logoutUser } from "redux/actions/login";
import TopbarMenuLink from "./TopbarMenuLink";

const Ava = `${process.env.PUBLIC_URL}/img/user3.png`;

export default function TopbarProfile() {
	const dispatch = useDispatch();
	const [collapse, setCollapse] = useState(false);

	const { user } = useSelector(c => c.login);

	const toggle = () => {
		setCollapse(e => !e);
	};

	const handleLogOut = () => {
		dispatch(logoutUser());
	};

	const handleShowModal = ev => {
		ev.preventDefault();
		dispatch(setValueChangePassword({ showModal: true }));
		setCollapse(false);
	};

	return (
		<>
			<ChangePassword />

			<div className="topbar__profile">
				<button type="button" className="topbar__avatar" onClick={toggle}>
					<img className="topbar__avatar-img" src={Ava} alt="avatar" />
					<p className="topbar__avatar-name">{user ? `${user.name}` : ""}</p>
					<RiArrowDropDownLine className="topbar__icon" />
				</button>
				{collapse && (
					<button type="button" className="topbar__back" onClick={toggle} />
				)}
				<Collapse isOpen={collapse} className="topbar__menu-wrap">
					<div className="topbar__menu">
						<TopbarMenuLink
							title="Alterar Senha"
							icon="list"
							path="#"
							onClick={handleShowModal}
						/>
						<div className="topbar__menu-divider" />
						<TopbarMenuLink
							title="Sair"
							icon="exit"
							path="/Login"
							onClick={handleLogOut}
						/>
					</div>
				</Collapse>
			</div>
		</>
	);
}
