import * as actions from "./actions";

const initialState = {
	benefit: null,
	companyBenefit: null,
	benefits: [],
	companyBenefits: [],
	benefitFilter: [],
	companyBenefitFilter: [],
	benefitPages: null,
	companyBenefitPages: null,
	benefitCount: 0,
	companyBenefitCount: 0,
	loading: { getAll: false, import: false },
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_COMPANY_BENEFITS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_COMPANY_BENEFITS_SUCCESS: {
			return {
				...state,
				companyBenefits: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_COMPANY_BENEFITS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.ADD_BENEFIT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ADD_BENEFIT_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_BENEFIT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: false
			};
		}

		case actions.ADD_BENEFIT_TO_PROVIDERS: {
			return {
				...state,
				loading: true
			};
		}
		case actions.ADD_BENEFIT_TO_PROVIDERS_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.ADD_BENEFIT_TO_PROVIDERS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: false
			};
		}

		case actions.UPDATE_BENEFIT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.UPDATE_BENEFIT_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.UPDATE_BENEFIT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: false
			};
		}

		case actions.GET_COMPANY_BENEFIT: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_COMPANY_BENEFIT_SUCCESS: {
			return {
				...state,
				companyBenefit: payload,
				loading: { get: false }
			};
		}
		case actions.GET_COMPANY_BENEFIT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}

		case actions.DELETE_COMPANY_BENEFIT: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_COMPANY_BENEFIT_SUCCESS: {
			return {
				...state,
				loading: { delete: false }
			};
		}
		case actions.DELETE_COMPANY_BENEFIT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.DELETE_PROVIDER_BENEFITS: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_PROVIDER_BENEFITS_SUCCESS: {
			return {
				...state,
				loading: { delete: false }
			};
		}
		case actions.DELETE_PROVIDER_BENEFITS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.GET_BENEFIT: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_BENEFIT_SUCCESS: {
			return {
				...state,
				benefits: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_BENEFIT_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
