import React from "react";
import "./style.scss";

export function MultiStatusCards({ cardsData, cardWidth = "100%" }) {
	return cardsData.map((el, index) => {
		return (
			<div key={index} className={"container-status-card"}>
				{el.header && (
					<div style={{ width: cardWidth }}>
						<span>{el.header}</span>
					</div>
				)}

				{el.children.map((child, index) => {
					return (
						<div
							key={index}
							onClick={() =>
								child.onClick(
									`${el.header || ""}${child.title || ""}${child.subtitle ||
										""}`
								)
							}
							className={"body-status-card"}
						>
							<span className="title-status-card">{child.title || ""}</span>

							<span color={"info"} className="count-status-card">
								{child.count || 0}
							</span>
						</div>
					);
				})}
			</div>
		);
	});
}
