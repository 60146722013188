const userHistoryType = {
	Usuário: 0,
	Empresa: 1,
	Das: 2,
	Dasn: 3,
	Contrato: 4,
	Nfse: 5,
	Login: 6,
	Mensagem: 7,
	Erro: 8
};

export default userHistoryType;
