import * as api from "services/api";

export const GET_COMPANY_NOTES = "[COMPANY NOTE] GET_COMPANY_NOTEs";
export const GET_COMPANY_NOTES_ERROR =
	"[COMPANY NOTES] GET_COMPANY_NOTES_ERROR";
export const GET_COMPANY_NOTES_SUCCESS =
	"[COMPANY NOTES] GET_COMPANY_NOTES_SUCCESS";

export const CREATE_COMPANY_NOTE = "[COMPANY NOTE] CREATE_COMPANY_NOTE";
export const CREATE_COMPANY_NOTE_ERROR =
	"[COMPANY NOTE] CREATE_COMPANY_NOTE_ERROR";
export const CREATE_COMPANY_NOTE_SUCCESS =
	"[COMPANY NOTE] CREATE_COMPANY_NOTE_SUCCESS";

export const SET_VALUE = "[COMPANY NOTES] SET_VALUE";
export const CLEAR_VALUES = "[COMPANY NOTES] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getNotesByCompanyId(company_id, limit, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANY_NOTES
		});
		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/v2/note?limit=${limit || 5}&company_id=${company_id}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result) {
				dispatch({
					type: GET_COMPANY_NOTES_SUCCESS,
					payload: result
				});
				callback && callback();
			}
			// else {
			// 	dispatch({
			// 		type: GET_COMPANY_NOTES_ERROR,
			// 		payload: getErrors(result.errors)
			// 	});
			// }
		} catch (error) {
			return dispatch({
				type: GET_COMPANY_NOTES_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function createNote(note, company_id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_COMPANY_NOTE
		});
		try {
			const { login } = getState();

			let response = await api.sendPost(
				`/v2/note/create`,
				{
					company_id,
					note
				},
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			callback & callback(result);
		} catch (error) {
			return dispatch({
				type: GET_COMPANY_NOTES_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
