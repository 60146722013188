import React from "react";
import ReportTable from "components/Report";
import EditBenefitForm from "containers/Benefit/components/EditBenefitForm";
import dayjs from "dayjs";
import { stringToMask } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import { useRef, useState } from "react";
import { FaRegEdit, FaRegFileExcel } from "react-icons/fa";
import { MdOpenInNew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Tooltip,
	UncontrolledDropdown
} from "reactstrap";
import { companyBenefitStatus } from "types/companyBenefitStatus";
import * as actions from "../actions";
import ExportExcel from "helpers/exportExcel";

let getBenefitsDebounced = null;

function CompanyBenefitList() {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.companyBenefitListTable);
	const benefit_edit = useSelector(s => s.form.benefit_edit);

	const [tooltipEdit, setTolltipEdit] = useState(false);
	const toggleToolTipEdit = () => setTolltipEdit(!tooltipEdit);

	const [modalEdit, setModalEdit] = useState(false);
	const toggleModalEdit = () => setModalEdit(!modalEdit);

	const [tooltipExcel, setTooltipExcel] = useState(false);
	const toggleTooltipExcel = () => setTooltipExcel(!tooltipExcel);

	const [tooltipEligible, setTooltipEligible] = useState(false);
	const toggleTooltipEligible = () => setTooltipEligible(!tooltipEligible);

	const [companyProvider, setCompanyProvider] = useState({});

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBenefitsDebounced) {
			getBenefitsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getBenefitsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getCompanyBenefits(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getBenefitsDebounced();
	};

	const createBadge = status => {
		if (status == 0) {
			return (
				<Badge color={"secondary"} className={"w-100"}>
					PENDENTE
				</Badge>
			);
		} else if (status == 1) {
			return (
				<Badge color={"warning"} className={"w-100"}>
					ONBOARD
				</Badge>
			);
		} else if (status == 2) {
			return (
				<Badge color={"success"} className={"w-100"}>
					ATIVO
				</Badge>
			);
		} else if (status == 3) {
			return (
				<Badge color={"danger"} className={"w-100"}>
					INATIVO
				</Badge>
			);
		} else {
			return "PENDENTE";
		}
	};

	const userBadge = id => {
		return (
			<Badge
				style={{
					cursor: "pointer"
				}}
				color={"primary"}
				target={"_blank"}
				onClick={() => {
					let win = window.open(`/client/${id}`, "_blank");
					win.focus();
				}}
				className={"mr-1 mr-2"}
			>
				<MdOpenInNew size={16} />
			</Badge>
		);
	};

	const companyBadge = id => {
		return (
			<Badge
				style={{
					cursor: "pointer"
				}}
				color={"primary"}
				target={"_blank"}
				onClick={() => {
					let win = window.open(`/companies/${id}`, "_blank");
					win.focus();
				}}
				className={"mr-1 mr-2"}
			>
				<MdOpenInNew size={16} />
			</Badge>
		);
	};

	const benefitStatusData = Object.keys(companyBenefitStatus).map(c => ({
		value: c,
		label: ls[companyBenefitStatus[c]]
	}));

	const onSubmit = data => {
		dispatch(
			actions.updateBenefit(
				companyProvider._id,
				{
					insurance: data.insurance.value
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						toggleModalEdit();
						alert("salvo");
						load();
					}
				}
			)
		);
	};

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				"Data de criação": item.createdAt
					? dayjs(item.createdAt).format(ls.dateFormatShort)
					: "",
				PrestadorId:
					item.providerUser && item.providerUser._id
						? `${item.providerUser._id}`
						: "",
				Prestador:
					item.providerUser && item.providerUser.name
						? `${item.providerUser.name}`
						: "",
				Contratante:
					item.business && item.business.businessName
						? `${item.business.businessName}`
						: "",
				CPF:
					item.providerUser && item.providerUser.cpf
						? `${item.providerUser.cpf}`
						: "",
				"Data de Nascimento":
					item.providerUser && item.providerUser.birthDate
						? dayjs(item.providerUser.birthDate).format(ls.dateFormatShort)
						: "",
				Gênero: item.providerUser
					? ["Masculino", "Feminino", "Outros"][item.providerUser.gender]
					: "",
				"Estado Civil": item.providerUser
					? ["Solteiro", "Casado", "Viúvo", "Divorciado"][
							item.providerUser.maritalStatus
					  ]
					: "",
				"Elegível Seguro": item.eligible ? "Sim" : "Não",
				"Status Seguro": ["Pendente", "Habilitado", "Desabilitado"][
					item.insurance
				],
				Status: ["Pendente", "OnBoard", "Ativo", "Inativo"][item.status]
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = eligible => {
		dispatch(
			actions.getBenefitListExcel(
				0,
				state.count,
				eligible ? "&filters[eligible]=true" : null,
				"createdAt",
				true,
				(err, model) => {
					if (err) console.log(err);
					ExportExcel(customData(model), "Lista Segurados");
				}
			)
		);
	};

	const sendOverFtp = () => {
		dispatch(
			actions.sendOverFtp(err => {
				if (err) console.log(err);
				else alert("Envio agendado, você será avisado por e-mail");
			})
		);
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Lista de Segurados"}
						data={state.companyBenefits}
						pages={state.companyBenefitPages}
						count={state.companyBenefitCount}
						noExport={true}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						headerRightComponent={
							<div>
								<UncontrolledDropdown group className={"mr-2"}>
									<Button
										color={"primary"}
										onClick={() => handleExport(true)}
										id="tooltipEligible"
									>
										Baixar elegíveis
									</Button>
									<Tooltip
										placement="top"
										isOpen={tooltipEligible}
										target="tooltipEligible"
										toggle={toggleTooltipEligible}
									>
										Baixar elegíveis
									</Tooltip>
									<DropdownToggle caret color="primary" />
									<DropdownMenu>
										<DropdownItem onClick={sendOverFtp}>
											Enviar por FTP
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<Button
									outline
									onClick={() => handleExport(false)}
									id="tooltipExcel"
								>
									<FaRegFileExcel style={{ margin: 0 }} />
								</Button>
								<Tooltip
									placement="top"
									isOpen={tooltipExcel}
									target="tooltipExcel"
									toggle={toggleTooltipExcel}
								>
									Exportar os dados da tabela
								</Tooltip>
							</div>
						}
						columns={[
							{
								Header: "Prestador",
								id: "providerUser.name",
								accessor: ({ providerUser }) => {
									return (
										<>
											{providerUser?._id ? userBadge(providerUser._id) : ""}
											{providerUser?.name || ""}
										</>
									);
								}
							},
							{
								Header: "Empresa",
								id: "provider.businessName",
								show: false,
								accessor: ({ provider }) => {
									return (
										<>
											{provider?._id ? companyBadge(provider._id) : ""}
											{provider?.businessName || ""}
										</>
									);
								}
							},
							{
								Header: "Contratante",
								id: "business.businessName",
								accessor: ({ business }) => {
									return (
										<>
											{business?._id ? companyBadge(business._id) : ""}
											{business?.businessName || ""}
										</>
									);
								}
							},
							{
								Header: "CPF",
								id: "providerUser.cpf",
								width: 100,
								accessor: ({ providerUser }) =>
									providerUser?.cpf
										? stringToMask(providerUser?.cpf, "###.###.###-###")
										: ""
							},
							{
								Header: "Data de Nascimento",
								id: "providerUser.birthDate",
								width: 100,
								accessor: ({ companyProvider }) =>
									companyProvider?.providerUser?.birthDate
										? dayjs
												.utc(companyProvider?.providerUser?.birthDate)
												.format(ls.dateFormatShort)
										: "Não definida"
							},
							{
								Header: "Gênero",
								id: "providerUser.gender",
								width: 100,
								accessor: ({ providerUser }) => {
									switch (providerUser?.gender) {
										case 0:
											return (
												<Badge color="secondary" className="w-100">
													MASCULINO
												</Badge>
											);
										case 1:
											return (
												<Badge color="secondary" className="w-100">
													FEMININO
												</Badge>
											);
										case 2:
											return (
												<Badge color="secondary" className="w-100">
													OUTROS
												</Badge>
											);

										default:
											return "";
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(["Masculino", "Feminino", "Outros"])
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{["Masculino", "Feminino", "Outros"][c]}
													</option>
												))}
										</select>
									);
								}
							},
							{
								Header: "Estado Civil",
								id: "providerUser.maritalStatus",
								width: 100,
								accessor: ({ providerUser }) => {
									switch (providerUser?.maritalStatus) {
										case 0:
											return (
												<Badge color="secondary" className="w-100">
													SOLTEIRO
												</Badge>
											);
										case 1:
											return (
												<Badge color="secondary" className="w-100">
													CASADO
												</Badge>
											);
										case 2:
											return (
												<Badge color="secondary" className="w-100">
													VIÚVO
												</Badge>
											);
										case 3:
											return (
												<Badge color="secondary" className="w-100">
													DIVORCIADO
												</Badge>
											);

										default:
											return "";
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys([
												"Solteiro",
												"Casado",
												"Viúvo",
												"Divorciado"
											])
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{["Solteiro", "Casado", "Viúvo", "Divorciado"][c]}
													</option>
												))}
										</select>
									);
								}
							},
							{
								Header: "Elegível Seguro",
								id: "eligible",
								width: 100,
								accessor: ({ eligible }) => {
									switch (eligible) {
										case false:
											return (
												<Badge color="secondary" className="w-100">
													NÃO
												</Badge>
											);
										case true:
											return (
												<Badge color="success" className="w-100">
													SIM
												</Badge>
											);
										default:
											return "";
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{[true, false].map(c => (
												<option key={c} value={c}>
													{c ? "Sim" : "Não"}
												</option>
											))}
										</select>
									);
								}
							},
							{
								Header: "Status Seguro",
								id: "insurance",
								width: 100,
								accessor: ({ insurance }) => {
									switch (insurance) {
										case 0:
											return (
												<Badge color="secondary" className="w-100">
													PENDENTE
												</Badge>
											);
										case 1:
											return (
												<Badge color="success" className="w-100">
													HABILITADO
												</Badge>
											);
										case 2:
											return (
												<Badge color="danger" className="w-100">
													DESABILITADO
												</Badge>
											);

										default:
											return "";
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(["Pendente", "Habilitado", "Desabilitado"])
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{["Pendente", "Habilitado", "Desabilitado"][c]}
													</option>
												))}
										</select>
									);
								}
							},
							{
								Header: "Status Prestador",
								id: "status",
								width: 100,
								accessor: ({ status }) => createBadge(status),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(["Pendente", "OnBoard", "Ativo", "Inativo"])
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{["Pendente", "OnBoard", "Ativo", "Inativo"][c]}
													</option>
												))}
										</select>
									);
								}
							},
							{
								Header: "Ações",
								id: "action",
								className: "justify-content-center",
								width: 50,
								filterable: false,
								accessor: row => {
									return (
										<>
											<Badge
												id="edit"
												style={{
													cursor: "pointer"
												}}
												color={"primary"}
												target={"_blank"}
												onClick={() => {
													setCompanyProvider(row);
													toggleModalEdit();
												}}
												className={"mr-1 mr-2"}
											>
												<FaRegEdit size={16} />
											</Badge>
											<Tooltip
												placement="left"
												isOpen={tooltipEdit}
												target="edit"
												toggle={toggleToolTipEdit}
											>
												Editar
											</Tooltip>
										</>
									);
								}
							}
						]}
					/>
				</Col>
			</Row>

			<Modal isOpen={modalEdit} toggle={toggleModalEdit}>
				<ModalHeader
					toggle={toggleModalEdit}
					className={"card__title bold-text"}
				>
					Editar Dados
				</ModalHeader>

				<ModalBody className="theme-light">
					<EditBenefitForm
						onSubmit={onSubmit}
						initialValues={{
							insurance: companyProvider.insurance
						}}
						loading={state.loading}
						isInvalid={benefit_edit && !!benefit_edit.syncErrors}
						errors={state.errors}
						benefitStatusData={benefitStatusData}
					/>
				</ModalBody>
			</Modal>
		</Container>
	);
}

export default CompanyBenefitList;
