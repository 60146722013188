import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";
import validate from "./validate";

const BenefitEdit = ({
	handleSubmit,
	reset,
	loading,
	submitting,
	isInvalid,
	benefitStatusData
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label">Seguro</span>
						<div className="form__form-group-field">
							<Field
								name="insurance"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={benefitStatusData}
							/>
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading.create}
				>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "company_benefit_edit",
	validate
})(BenefitEdit);
