import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ChangePasswordModal = ({ children, title, isOpen, hide }) => {
	return (
		<Modal isOpen={isOpen} toggle={hide}>
			<ModalHeader toggle={hide}>{title}</ModalHeader>
			<ModalBody>{children}</ModalBody>
		</Modal>
	);
};

export default ChangePasswordModal;
