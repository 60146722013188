import * as actions from "../../actions/plan";

const initialState = {
	loading: {},
	items: [],
	errors: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_PLANS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors,
			};
		}
		case actions.GET_PLANS_SUCCESS: {
			return {
				...initialState,
				items: payload || [],
			};
		}
		case actions.GET_PLANS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload,
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload,
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
			};
		default: {
			return state;
		}
	}
};

export default reducer;
