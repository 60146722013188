import dayjs from "dayjs";

import { refreshToken } from "../actions/login";

export function jwt({ dispatch, getState }) {
	return next => action => {
		// only worry about expiring token for async actions
		if (typeof action === "function") {
			const { login } = getState();

			const { expiration, token, freshTokenPromise, user } = login;
			const today = dayjs();
			const diffDays = dayjs(expiration).diff(today);

			if (token && expiration && diffDays < 5000 && user) {
				// make sure we are not already refreshing the token
				if (!freshTokenPromise) {
					return refreshToken(dispatch, getState).then(() => next(action));
				} else {
					return freshTokenPromise.then(() => next(action));
				}
			}
		}
		return next(action);
	};
}
