import * as actions from "./actions";

const initialState = {
	usersByStatus: { enabled: 0, disabled: 0 },
	recordsTimeline: { meis: [], users: [] },
	companiesType: { mei: 0, me: 0 },
	companies: { isHired: 0, instHired: 0 },
	filter: [],
	pages: null,
	count: 0,
	loading: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_USERS_STATUS: {
			return {
				...state,
				loading: { usersByStatus: true }
			};
		}
		case actions.GET_COMPANIES_TYPE: {
			return {
				...state,
				loading: { companiesType: true }
			};
		}
		case actions.GET_COMPANIES_X_USERS: {
			return {
				...state,
				loading: { recordsTimeline: true }
			};
		}
		case actions.GET_COMPANIES_X_USERS_SUCCESS: {
			return {
				...state,
				recordsTimeline: payload ? payload : [],
				loading: { recordsTimeline: false }
			};
		}
		case actions.GET_COMPANIES_X_USERS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { recordsTimeline: false }
			};
		}
		case actions.GET_COMPANIES_HIRED: {
			return {
				...state,
				loading: { companies: true }
			};
		}
		case actions.GET_COMPANIES_HIRED_SUCCESS: {
			return {
				...state,
				companies: payload ? payload : initialState.companies,
				loading: { companies: false }
			};
		}
		case actions.GET_COMPANIES_HIRED_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { companies: false }
			};
		}
		case actions.GET_COMPANIES_TYPE_SUCCESS: {
			return {
				...state,
				companiesType: payload ? payload : [],
				loading: { companiesType: false }
			};
		}
		case actions.GET_COMPANIES_TYPE_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { companiesType: false }
			};
		}
		case actions.GET_USERS_STATUS_SUCCESS: {
			return {
				...state,
				usersByStatus: payload ? payload : [],
				loading: { usersByStatus: false }
			};
		}
		case actions.GET_USERS_STATUS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { usersByStatus: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
