import React, { useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
	addPermissionGroups,
	setValue
} from "../../Details/PermissionGroups/actions";
import PermissionGroupsCreateForm from "../../../components/Create/PermissionGroupsForm";
import _ from "lodash";
import { getGroups } from "../../../../PermissionGroups/containers/Report/actions";

let findGroupsDebounced = null;

const AddPermissionGroups = ({ load }) => {
	const dispatch = useDispatch();

	const {
		params: { id }
	} = useRouteMatch();

	const { items: groups } = useSelector(s => s.groups);
	const { showCreateModal, loading } = useSelector(s => s.userPermissionGroups);
	const permission_groups_create_form = useSelector(
		s => s.form.permission_groups_create_form
	);

	const showModal = () => {
		dispatch(setValue({ showCreateModal: true }));
	};

	const closeModal = () => {
		dispatch(setValue({ showCreateModal: false }));
	};

	const handleFindGroups = name => {
		if (findGroupsDebounced) {
			findGroupsDebounced.cancel();
		}

		findGroupsDebounced = _.debounce(
			() =>
				dispatch(
					getGroups(
						0,
						10,
						`${name ? `&filters[name]=${name}` : ""}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar os grupos de permissões, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findGroupsDebounced();
	};

	const onChange = values => {
		if (values.groupsSearch) {
			handleFindGroups(values.groupsSearch);
		}
	};

	const onSubmit = data => {
		const model = {
			group: data.group.value
		};

		model.user = id;

		dispatch(
			addPermissionGroups(model, (err, model) => {
				if (err) {
					console.log("err", err);
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					closeModal();
					load();
				}
			})
		);
	};

	useEffect(() => {
		handleFindGroups();
	}, []);

	return (
		<div>
			<Button Button className="mr-2" outline onClick={showModal}>
				<MdAdd />
			</Button>
			<Modal
				toggle={closeModal}
				className="theme-light ltr-support login-modal-form"
				isOpen={showCreateModal}
				size="md"
			>
				<ModalHeader>Adicionar grupo de permissão</ModalHeader>
				<ModalBody>
					<div className="form__form-group-field">
						<PermissionGroupsCreateForm
							onSubmit={onSubmit}
							onChange={onChange}
							loading={loading.create}
							groups={groups || []}
							isInvalid={
								permission_groups_create_form &&
								!!permission_groups_create_form.syncErrors
							}
						/>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default AddPermissionGroups;
