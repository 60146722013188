import React from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { DATE } from "components/NumberFormat";

const FiltersForm = ({ handleSubmit, isInvalid, reset }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<span className="bold-text">Data de cadastro</span>
				<Row>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span>Inicial</span>
						<div className="form__form-group-field">
							<Field
								name="createdAtInitDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span>Final</span>
						<div className="form__form-group-field">
							<Field
								name="createdAtEndDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
							/>
						</div>
					</Col>
				</Row>

				<span className="bold-text">Data de Início do Contrato</span>
				<Row>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span>Inicial</span>
						<div className="form__form-group-field">
							<Field
								name="startDateInitDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span>Final</span>
						<div className="form__form-group-field">
							<Field
								name="startDateEndDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
							/>
						</div>
					</Col>
				</Row>

				<span className="bold-text">Data de Fim do Contrato</span>
				<Row>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span>Inicial</span>
						<div className="form__form-group-field">
							<Field
								name="dueInitDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span>Final</span>
						<div className="form__form-group-field">
							<Field
								name="dueEndDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
							/>
						</div>
					</Col>
				</Row>

				<ButtonToolbar className="form__button-toolbar">
					<Button color="primary" type="submit" disabled={isInvalid}>
						Filtrar
					</Button>
					<Button type="button" onClick={reset}>
						Cancelar
					</Button>
				</ButtonToolbar>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "filters_form",
	validate
})(FiltersForm);
