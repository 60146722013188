import { isInRole } from "helpers/auth";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { isLogged } from "../../helpers/auth";
import BankBilletRoutes from "../BankBillet/routes";
import ClientRoutes from "../Client/routes";
import CompanyRoutes from "../Company/routes";
import ContractRoutes from "../Contracts/routes";
import ForgotPassword from "../ForgotPassword";
import Layout from "../Layout/index";
import LogIn from "../LogIn/index";
import MarketingRoutes from "../Marketing/routes";
import NFSeRoutes from "../NFSe/routes";
import Register from "../Register/routes";
import SubscriptionRoutes from "../Subscription/routes";
import TaxRoutes from "../Tax/routes";
import TransferRoutes from "../Transfer/routes";
import MainWrapper from "./MainWrapper";
import BenefitRoutes from "../Benefit/routes";
import DashboardRoutes from "../Dashboard/routes";
import PermissionRoutes from "../Permission/routes";
import ResetPassword from "../ResetPassword";
import VersionReportRoutes from "../VersionReport/routes";
import PermissionGroupsRoutes from "../PermissionGroups/routes";
import DigisacIntegrationRoutes from "../DigisacIntegration/routes";

const AuthRoute = ({ user, needRoles, ...rest }) => {
	if (isInRole(user, needRoles)) return <Route {...rest} />;

	return null;
};

const wrappedRoutes = () => {
	const { user } = useSelector(s => s.login);

	return (
		<div style={{ height: "100%" }}>
			<Layout />
			<div className="container__wrap">
				<AuthRoute
					path="/"
					component={DashboardRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/client"
					component={ClientRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/tax"
					component={TaxRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/contracts"
					component={ContractRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/companies"
					component={CompanyRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/nfses"
					component={NFSeRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/digisac"
					component={DigisacIntegrationRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/bankBillet"
					component={BankBilletRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/marketing"
					component={MarketingRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/subscriptions"
					component={SubscriptionRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/transfer"
					component={TransferRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/benefit"
					component={BenefitRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/permission"
					component={PermissionRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path={"/group/"}
					component={PermissionGroupsRoutes}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					path="/version-report"
					component={VersionReportRoutes}
					user={user}
					needRoles={["admin"]}
				/>
			</div>
		</div>
	);
};

const Router = () => {
	const login = useSelector(s => s.login);

	if (!login) return null;

	var loggedIn = isLogged(login);

	if (!loggedIn) {
		return (
			<MainWrapper>
				<main>
					<Switch>
						<Route exact path="/Login" component={LogIn} />
						<Route exact path="/Register" component={Register} />
						<Route exact path="/ForgotPassword" component={ForgotPassword} />
						<Route exact path="/ResetPassword" component={ResetPassword} />
						<Redirect to="/Login" />
					</Switch>
				</main>
			</MainWrapper>
		);
	}

	return (
		<MainWrapper>
			<main>
				<Switch>
					<Route exact path="/ResetPassword" component={ResetPassword} />
					<Route path="/" component={wrappedRoutes} />
					<Redirect to="/" />
				</Switch>
			</main>
		</MainWrapper>
	);
};

export default Router;
