const validate = values => {
	const errors = {};

	if (!values.name) {
		errors.name = "Informe o nome da permissão.";
	}

	return errors;
};

export default validate;
