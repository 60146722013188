import React, { Component, Fragment } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GlobalContextProvider } from "../../contexts/GlobalContext";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import Router from "./Router";
import storeCreator from "../../redux";
import ScrollToTop from "./ScrollToTop";

import LogRocket from "logrocket";

import setupLogRocketReact from "logrocket-react";

if (process.env.REACT_APP_LOG_ROCKET) {
	setupLogRocketReact(LogRocket);

	LogRocket.init(process.env.REACT_APP_LOG_ROCKET, {
		network: {
			requestSanitizer: request => {
				if (request.body && request.body.indexOf("password") > -1) {
					let body = JSON.parse(request.body);

					if (body.password) body.password = null;

					request.body = JSON.stringify(body);
				}

				// request.headers["Authorization"] = null;

				return request;
			}
		}
	});
}

const { store, persistor } = storeCreator();

class App extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			loaded: false
		};
	}

	componentDidMount() {
		window.addEventListener("load", () => {
			this.setState({ loading: false });
			setTimeout(() => this.setState({ loaded: true }), 500);
		});
	}

	render() {
		const { loaded, loading } = this.state;
		return (
			<Provider store={store}>
				<GlobalContextProvider>
					<PersistGate loading={<div>Loading...</div>} persistor={persistor}>
						<BrowserRouter>
							<ScrollToTop>
								<Fragment>
									{!loaded && (
										<div className={`load${loading ? "" : " loaded"}`}>
											<div className="load__icon-wrap">
												<svg className="load__icon">
													<path
														fill="#504C9A"
														d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
													/>
												</svg>
											</div>
										</div>
									)}
									<div style={{ height: "100%" }}>
										<Router />
									</div>
								</Fragment>
							</ScrollToTop>
						</BrowserRouter>
					</PersistGate>
				</GlobalContextProvider>
			</Provider>
		);
	}
}

export default App;
