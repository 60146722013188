import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import renderSelectField from "shared/components/form/Select";

const PermissionGroupsCreateForm = ({
	handleSubmit,
	loading,
	groups,
	onChange,
	submitting,
	isInvalid,
	reset
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} className="form__form-group">
						<span className="form__form-group-label">Grupos de Permissão</span>
						<div className="form__form-group-field">
							<Field
								name="group"
								component={renderSelectField}
								type="text"
								onChange={onChange}
								placeholder="Pesquise um grupo"
								onInputChange={groupsSearch => onChange({ groupsSearch })}
								options={[
									...groups.map(c => ({
										value: c._id,
										label: c.name
									}))
								]}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading}
				>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "permission_groups_create_form",
	validate
})(PermissionGroupsCreateForm);
