import Input from "components/ReduxForm/Input";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import {
	Button,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
dayjs.extend(customDateFormat);

// eslint-disable-next-line no-unused-vars
function SignatureModal({ toggle, modalOpen, handleSubmit, initialValues }) {
	return (
		<Modal isOpen={modalOpen} toggle={toggle}>
			<form onSubmit={handleSubmit}>
				<ModalHeader>Contratados</ModalHeader>
				<ModalBody>
					<Row className="shadow-sm rounded mb-4 pt-3">
						<Col className="form__form-group">
							<Field
								name="purchasedProviders"
								component={Input}
								type="number"
								// props={{ errors: errors && errors.mei }}
							/>
						</Col>
					</Row>
				</ModalBody>

				<ModalFooter>
					<Button color="primary" type="submit">
						Salvar
					</Button>
					<Button onClick={toggle}>Cancel</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
}

export default reduxForm({
	form: "saler_edit",
	enableReinitialize: true
})(SignatureModal);
