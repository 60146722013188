import NoteCard from "containers/Company/components/NoteCard/NoteCard";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { Button } from "reactstrap/lib";
import { getNotesByCompanyId, createNote } from "./actions";
import dayjs from "dayjs";

const NotesCompany = ({ companyId }) => {
	const dispatch = useDispatch();
	const { notes } = useSelector(state => state.notesDetail);
	useEffect(() => {
		dispatch(getNotesByCompanyId(companyId, 5));
	}, []);

	const [newNote, setNewNote] = useState(false);
	const [note, setNote] = useState("");

	const onSubmit = () => {
		console.log("note => ", note);
		dispatch(
			createNote(note, companyId, () => {
				console.log("teste out");
				dispatch(getNotesByCompanyId(companyId, 10));
				setNote("");
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row className="note-card-container">
				<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
					<h4>
						<strong>Comentários</strong>
						<div
							className="note-create-button"
							on
							onClick={() => setNewNote(!newNote)}
						>
							<BiAddToQueue />
							<span>Adicionar novo Comentário</span>
						</div>
					</h4>
				</Col>
			</Row>
			{newNote && (
				<Row>
					<Col xs={12} sm={12} md={6} xl={4} className="note-text-area-card">
						<textarea
							className="note-text-area"
							value={note}
							onChange={e => {
								setNote(e.target.value);
							}}
						></textarea>
						<Button onClick={onSubmit}>Comentar</Button>
					</Col>
				</Row>
			)}
			{notes.length > 0 &&
				notes?.map((el, i) => (
					<NoteCard
						key={i}
						noteText={el.note}
						postDate={dayjs(el.createdAt).format("DD/MM/YYYY - HH:mm")}
						authorName={el?.createdBy.name}
					/>
				))}
		</Container>
	);
};

export default NotesCompany;
