import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_PERMISSIONS_PER_GROUP =
	"[GROUP_PERMISSIONS DETAIL] GET_PERMISSIONS_PER_GROUP";
export const GET_PERMISSIONS_PER_GROUP_ERROR =
	"[GROUP_PERMISSIONS DETAIL] GET_PERMISSIONS_PER_GROUP_ERROR";
export const GET_PERMISSIONS_PER_GROUP_SUCCESS =
	"[GROUP_PERMISSIONS DETAIL] GET_PERMISSIONS_PER_GROUP_SUCCESS";

export const CREATE_GROUP_PERMISSION =
	"[GROUP_PERMISSION DETAIL] CREATE_GROUP_PERMISSION";
export const CREATE_GROUP_PERMISSION_ERROR =
	"[GROUP_PERMISSION DETAIL] CREATE_GROUP_PERMISSION_ERROR";
export const CREATE_GROUP_PERMISSION_SUCCESS =
	"[GROUP_PERMISSION DETAIL] CREATE_GROUP_PERMISSION_SUCCESS";

export const SET_VALUE = "[GROUP_PERMISSION DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[GROUP_PERMISSION DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getPermissionsPerGroup(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_PERMISSIONS_PER_GROUP
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/group/permission?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PERMISSIONS_PER_GROUP_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_PERMISSIONS_PER_GROUP_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_PERMISSIONS_PER_GROUP_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function createGroupPermissions(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_GROUP_PERMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/group/permission`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_GROUP_PERMISSION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: CREATE_GROUP_PERMISSION_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: CREATE_GROUP_PERMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
