import React from "react";
import { Route, Switch } from "react-router-dom";
import Permission from "./containers/Report";
import Create from "./containers/Page/Create";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/permission" component={Permission} />
				<Route exact path="/permission/new" component={Create} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
