import React from "react";

import dayjs from "dayjs";
import ls from "Localization";
import { toStringCurrency } from "helpers/string";
import subscriptionStatus from "types/subscriptionStatus";
import { InstallmentStatus } from "types/installmentStatus";

import { Card, Row, Col, Container } from "reactstrap";

const DetailsSubscription = ({ subscription }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<p>
								<strong>Data criação: </strong>
								<br />
								<span>
									{
										subscription.createdAt ?
											dayjs(subscription.createdAt).format(ls.dateFormatWithoutSeconds) :
											"--"
									}
								</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<p>
								<strong>{ls.user}: </strong>
								<br />
								<span>
									{
										subscription.user ?
											`${subscription.user.name} ${subscription.user.lastname}` :
											"--"
									}
								</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<p>
								<strong>{ls.plan}: </strong>
								<br />
								<span>
									{
										subscription.plan ?
											subscription.plan.name :
											"--"
									}
								</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<p>
								<strong>{ls.paymentMethod}: </strong>
								<br />
								<span>
									{
										subscription.paymentMethod ?
											subscription.paymentMethod.name :
											"--"
									}
								</span>
							</p>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<p>
								<strong>{ls.value}: </strong>
								<br />
								<span>
									{
										subscription.value != undefined ?
											toStringCurrency(+subscription.value / 100.0) :
											"--"
									}
								</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<p>
								<strong>{ls.installment}: </strong>
								<br />
								<span>
									{
										subscription.installment ?
											subscription.installment :
											"--"
									}
								</span>
							</p>
						</Col>

						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<p>
								<strong>Status: </strong>
								<br />
								<span>
									{
										subscription.status != undefined ?
											ls[subscriptionStatus[subscription.status]] :
											"--"
									}
								</span>
							</p>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12}>
							<p>
								<strong>{ls.description}: </strong>
								<br />
								<span>
									{
										subscription.description ?
											subscription.description :
											"--"
									}
								</span>
							</p>
						</Col>
					</Row>

					<Row>
						<Col className="mt-3" xs={12}>
							<p><strong>{ls.installment}: </strong></p>
							{
								subscription.installments.map((installment, index) => (
									<p>
										<strong>{ls.number}: </strong>
										<span>
											{
												installment.number ?
													installment.number :
													"--"
											}
										</span>

										<strong className="ml-5">Data pagamento: </strong>
										<span>
											{
												installment.paymentDate ?
													dayjs(installment.paymentDate).format("DD/MM/YYYY") :
													"--"
											}
										</span>

										<strong className="ml-5">Status: </strong>
										<span>
											{
												installment.transaction ? (
													installment.transaction.status === 0 ? (
														<a
															href={installment.transaction.url}
															target="_blank"
														>
															Aguardando pagamento
														</a>
													) : ""
												) : ls[InstallmentStatus[installment.status]]
											}
										</span>
									</p>
								))
							}
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailsSubscription;
