import React from "react";

import dayjs from "dayjs";
import ls from "Localization";
import { Card, Col, Container, Row } from "reactstrap";

const DetailsVersionReport = ({ item }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{item.createdAt
												? dayjs(item.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Versão: </strong>
										<br />
										<span>{item.version ? item.version : "--"}</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Título: </strong>
										<br />
										<span>{item.title ? item.title : "--"}</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Texto: </strong>
										<br />
										<span>{item.text ? item.text : "--"}</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailsVersionReport;
