import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CreateForm from "../../components/createForm";
import { UserRoles, UserStatus } from "types/userStatus";
import ls from "Localization";
import { clearValues, createClient } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getCompanies } from "containers/Company/containers/Report/actions";

import _ from "lodash";
import { useState } from "react";

dayjs.extend(customParseFormat);

let getCompaniesDebounced = null;

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading, errors } = useSelector(s => s.clientCreate);
	const client_create = useSelector(s => s.form.client_create);
	const { items: companies } = useSelector(s => s.company);

	const [role, setRole] = useState(0);

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	const onSubmit = data => {
		dispatch(
			createClient(
				{
					cnpj: data.cnpj,
					name: data.name,
					email: data.email.trim(),
					phoneNumber: data.phoneNumber
						.match(/\d/g)
						.join("")
						.trim(),
					role: data.role ? UserRoles[data.role.value] : undefined,
					cpf: String(data.cpf || "")
						.match(/\d/g)
						?.join(""),
					birthDate: dayjs.utc(data.birthDate, "DD/MM/YYYY").toDate(),
					userStatus: (data.status || {}).value,
					mei: (data.mei || {}).value,
					isCompleteRegisterEssential: role == 4
				},
				err => {
					if (err) {
						console.log(err);
						if (err.code) {
							alert(err.code);
						} else if (err.errors?.default) alert(err.errors?.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push("/client");
					}
				}
			)
		);
	};

	const onChangeRole = roleChange => {
		setRole(roleChange);
	};

	const onChangeMei = name => {
		if (getCompaniesDebounced) {
			getCompaniesDebounced.cancel();
		}

		getCompaniesDebounced = _.debounce(() => {
			let filter = "";
			if (role == 4) {
				console.log("contratante");
				filter = `&filters[searchText]=${name}&filters[isHired]=true`;
			} else if (role == 3) {
				console.log("Me");
				filter = `&filters[searchText]=${name}&filters[businessType]=1`;
			} else if (role == 2) {
				console.log("Mei");
				filter = `&filters[searchText]=${name}&filters[hasMei]=true`;
			}
			dispatch(
				getCompanies(0, 10, filter, null, null, false, err => {
					if (err) {
						alert("Não foi possível carregar as empresas");
					}
				})
			);
		}, 500);

		getCompaniesDebounced();
	};

	const userStatusData = Object.keys(UserStatus).map(c => ({
		value: c,
		label: ls[UserStatus[c]]
	}));

	const userRolesData = Object.keys(UserRoles).map(c => ({
		value: c,
		label: ls[UserRoles[c]]
	}));

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar novo usuário</h5>
							</div>
							<CreateForm
								onSubmit={onSubmit}
								userStatusData={userStatusData}
								userRolesData={userRolesData}
								onChangeRole={onChangeRole}
								initialValues={{
									status: userStatusData.find(c => c.value === "2")
								}}
								loading={loading}
								errors={errors}
								isInvalid={client_create && !!client_create.syncErrors}
								companies={companies}
								onChangeMei={onChangeMei}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
