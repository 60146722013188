import ReportTable from "components/Report";
import ExportExcelForm from "containers/Company/components/ExportExcelForm";
import FiltersForm from "containers/Contracts/components/FiltersForm";
import dayjs from "dayjs";
import ExportExcel from "helpers/exportExcel";
import { stringToMask } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { FaRegFileExcel } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	Tooltip
} from "reactstrap";
import {
	contractStatus,
	contractStatusConst,
	contractTemplateTypes,
	contractTemplateTypesConst
} from "types/contractStatus";
import * as api from "../../../../services/api/axios";
import * as actions from "./actions";

let getContractsDebounced = null;

function Contracts({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const [tableRef, setTableRef] = useState();

	const state = useSelector(s => s.contracts);
	const { token } = useSelector(s => s.login);
	const filters_form = useSelector(s => s.form.filters_form);

	const [tooltipNewContract, setTooltipNewContract] = useState(false);
	const toggletooltipNewContract = () =>
		setTooltipNewContract(!tooltipNewContract);

	const [tooltipFilters, settooltipFilters] = useState(false);
	const toggletooltipFilters = () => settooltipFilters(!tooltipFilters);

	const [tooltipExcel, setTooltipExcel] = useState(false);
	const toggleTooltipExcel = () => setTooltipExcel(!tooltipExcel);

	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () => setModalExportExcel(!modalExportExcel);

	const [modalFilters, setModalFilters] = useState(false);
	const toggleFilters = () => setModalFilters(!modalFilters);

	const [filterDates, setFilterDates] = useState("");

	const [progress, setProgress] = useState(0);

	const handleFetchData = tableState => {
		if (!tableState) return;
		setTableRef(tableState);
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getContractsDebounced) {
			getContractsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}
		getContractsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getContractList(
						page * pageSize,
						pageSize,
						filterDates +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getContractsDebounced();
	};

	const handleFilter = data => {
		if (getContractsDebounced) {
			getContractsDebounced.cancel();
		}

		let { page, pageSize, sorted, filtered } = tableRef;

		let filter = "";

		Object.keys(data).forEach(el => {
			filter += `&filters[${el}]=${data[el]}`;
		});

		setFilterDates(filter);

		getContractsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getContractList(
						page * pageSize,
						pageSize,
						filter +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						null,
						() => {
							toggleFilters();
						}
					)
				),
			500
		);

		getContractsDebounced();
	};

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				"Nome Contrato":
					item.template && item.template.name ? item.template.name : "",
				"Tipo Contrato":
					item.template && item.template.type
						? ls[contractTemplateTypes[item.template.type]]
						: "",
				"Status Contrato": ls[contractStatus[item.status]],
				"Assinatura Contratante":
					item.hirerStatus != null
						? item.hirerStatus === 1
							? "Assinado"
							: "Pendente"
						: "",
				"Assinatura Prestador":
					item.hiredStatus != null
						? item.hiredStatus === 1
							? "Assinado"
							: "Pendente"
						: "",
				"Data de inicio": item.startDate
					? dayjs(item.startDate).format("YYYY-MM-DD")
					: "",
				"Data de Fim": item.due ? dayjs(item.due).format("YYYY-MM-DD") : "",
				"Vigência (em meses)": item.lifespan || "",
				"Data Criação": dayjs(item.createdAt).format("YYYY-MM-DD"),
				"Contrato Principal": item.mainContract?.template?.name
					? item.mainContract.template.name
					: "",
				"Sequência do Contrato": item.sequence ? item.sequence : "",
				"Empresa Contratante":
					item.mei && item.mei.businessName ? `${item.mei.businessName}` : "",
				"Empresa Prestadora":
					item.hired && item.hired.businessName
						? `${item.hired.businessName}`
						: "",
				"Status Prestador": item.companyProvider
					? ["Pendente", "OnBoard", "Ativo", "Inativo"][
							item.companyProvider?.status
					  ]
					: "",
				"Nome do Prestador": item.companyProvider?.providerUser?.name || "",
				"Telefone do Prestador":
					item.companyProvider?.providerUser?.phoneNumber || "",
				"Email do Prestador": item.companyProvider?.providerUser?.email || "",
				"Link Arquivo": item.file || ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = type => {
		dispatch(async () => {
			const dataTmp = [];
			const takeTmp = 100;

			for (let skipTmp = 0; skipTmp < state.count; skipTmp += takeTmp) {
				const { data } = await api.sendGet(
					"api",
					`/contracts/all-contracts?skip=${skipTmp}&take=${takeTmp}`,
					token,
					"bearer",
					null
				);

				if (data.model) {
					dataTmp.push(...data.model);
					setProgress(Math.round((skipTmp / state.count) * 100));
				} else {
					console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
					break;
				}
			}

			ExportExcel(customData(dataTmp), "Lista_Contratos", type);
			toggleModalExportExcel();
		});
	};

	const onSubmitExportExcel = data => {
		if (state.count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (data.exportExcel) {
			handleExport("xlsx");
		}

		if (data.exportCsv) {
			handleExport("csv");
		}
	};

	const createBadge = status => {
		if (status == 0) {
			return (
				<Badge color={"secondary"} className={"w-100"}>
					PENDENTE
				</Badge>
			);
		} else if (status == 1) {
			return (
				<Badge color={"warning"} className={"w-100"}>
					ONBOARD
				</Badge>
			);
		} else if (status == 2) {
			return (
				<Badge color={"success"} className={"w-100"}>
					ATIVO
				</Badge>
			);
		} else if (status == 3) {
			return (
				<Badge color={"danger"} className={"w-100"}>
					INATIVO
				</Badge>
			);
		} else {
			return "PENDENTE";
		}
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Lista Contratos"}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						count={state.count}
						filterable
						noExport={true}
						onFetchData={handleFetchData}
						onRowClicked={row => {
							let win = window.open(`/contracts/${row._id}`, "_blank");
							win.focus();
						}}
						headerRightComponent={
							<div>
								<Button
									className={"mr-2"}
									color={"primary"}
									onClick={() => history.push(`/contracts/new`)}
									id="tooltipNewContract"
								>
									Novo Contrato Principal
								</Button>
								<Tooltip
									placement="top"
									isOpen={tooltipNewContract}
									target="tooltipNewContract"
									toggle={toggletooltipNewContract}
								>
									Criar um novo contrato
								</Tooltip>
								<Button
									className={"mr-2"}
									outline
									onClick={toggleFilters}
									id="tooltipFilters"
								>
									<FiFilter />
								</Button>
								<Tooltip
									placement="top"
									isOpen={tooltipFilters}
									target="tooltipFilters"
									toggle={toggletooltipFilters}
								>
									Filtros
								</Tooltip>
								<Button
									outline
									onClick={toggleModalExportExcel}
									id="tooltipExcel"
								>
									<FaRegFileExcel style={{ margin: 0 }} />
								</Button>
								<Tooltip
									placement="top"
									isOpen={tooltipExcel}
									target="tooltipExcel"
									toggle={toggleTooltipExcel}
								>
									Exportar os dados da tabela
								</Tooltip>
							</div>
						}
						columns={[
							{
								Header: "Id",
								id: "_id",
								accessor: c => c._id,
								width: 85,
								show: false
							},
							{
								Header: (
									<span>
										Data <br />
										Cadastro
									</span>
								),
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatShort)
										: "",
								width: 75
							},
							{
								Header: "Última atualização",
								id: "updatedAt",
								accessor: c =>
									c.updatedAt
										? dayjs(c.updatedAt).format(ls.dateFormatWithoutSeconds)
										: "",
								show: false
							},
							{
								Header: "Id Template",
								id: "template._id",
								filterable: true,
								accessor: ({ template }) => template?._id || "Não definido",
								show: false
							},
							{
								Header: "Id Contratante",
								id: "mei._id",
								filterable: true,
								accessor: ({ mei }) => mei?._id || "Não definido",
								show: false
							},
							{
								Header: "Contratante",
								id: "mei.businessName",
								filterable: true,
								accessor: ({ mei }) =>
									mei?.businessName
										? `${mei?.businessName}${
												mei?.cnpj
													? " - " +
													  stringToMask(mei?.cnpj, "##.###.###/####-##")
													: ""
										  }`
										: "Não definido"
							},
							{
								Header: "Id Prestador",
								id: "hired._id",
								filterable: true,
								accessor: ({ hired }) => hired._id || "Não definido",
								show: false
							},
							{
								Header: "Prestador",
								id: "hired.businessName",
								filterable: true,
								accessor: ({ hired }) => hired.businessName || "Não definido"
							},
							{
								Header: "ID Externo",
								id: "template.externalId",
								filterable: true,
								accessor: ({ template }) =>
									template.externalId || "Não definido",
								width: 180,
								show: false
							},
							{
								Header: "Nome Contrato",
								id: "template.name",
								filterable: true,
								accessor: ({ template }) => template?.name || "Não definido",
								width: 180
							},
							{
								Header: "Categoria",
								id: "template.type",
								show: true,
								accessor: ({ template }) => {
									switch (template?.type) {
										case contractTemplateTypesConst.Others:
											return (
												<Badge
													style={{ backgroundColor: "red" }}
													className="w-100"
												>
													OUTROS
												</Badge>
											);
										case contractTemplateTypesConst.Nda:
											return (
												<Badge
													style={{ backgroundColor: "brown" }}
													className="w-100"
												>
													NDA
												</Badge>
											);
										case contractTemplateTypesConst.Confidentiality:
											return (
												<Badge
													style={{ backgroundColor: "orange" }}
													className="w-100"
												>
													CONFIDENCIALIDADE
												</Badge>
											);
										case contractTemplateTypesConst.Lending:
											return (
												<Badge
													style={{ backgroundColor: "blue" }}
													className="w-100"
												>
													COMODATO
												</Badge>
											);
										case contractTemplateTypesConst.ServicesProvision:
											return (
												<Badge
													style={{ backgroundColor: "darkgreen" }}
													className="w-100"
												>
													PRESTAÇÃO DE SERVIÇOS
												</Badge>
											);
										case contractTemplateTypesConst.ContractAmendment:
											return (
												<Badge
													style={{ backgroundColor: "forestgreen" }}
													className="w-100"
												>
													ADITIVO CONTRATUAL
												</Badge>
											);
										case contractTemplateTypesConst.Dissolution:
											return (
												<Badge
													style={{ backgroundColor: "darkred" }}
													className="w-100"
												>
													DISTRATO
												</Badge>
											);

										default:
											return "";
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(contractTemplateTypes)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[contractTemplateTypes[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 90
							},
							{
								Header: "Contrato Principal",
								id: "mainContract.template.name",
								accessor: ({ mainContract }) =>
									mainContract?.template?.name || "",
								width: 180
							},
							{
								Header: "Principal?",
								id: "isMainContract",
								show: false,
								accessor: ({ isMainContract }) =>
									isMainContract ? <Badge color={"success"}>O</Badge> : "",
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{["Não", "Sim"].map(c => (
												<option key={c} value={c}>
													{[c]}
												</option>
											))}
										</select>
									);
								},
								width: 85
							},
							{
								Header: (
									<span>
										Sequência do
										<br />
										Contrato
									</span>
								),
								id: "sequence",
								accessor: ({ sequence }) => `${sequence || ""}`,
								className: "justify-content-start",
								width: 65
							},
							{
								Header: (
									<span>
										Data de
										<br />
										inicio
									</span>
								),
								id: "startDate",
								accessor: c =>
									c.startDate
										? dayjs(c.startDate).format(ls.dateFormatShort)
										: "",
								className: "justify-content-center",
								width: 75
							},
							{
								Header: (
									<span>
										Data de
										<br />
										Fim
									</span>
								),
								id: "due",
								accessor: c =>
									c.due ? dayjs(c.due).format(ls.dateFormatShort) : "",
								className: "justify-content-center",
								width: 75
							},
							{
								Header: (
									<span>
										Vigência
										<br />
										(em meses)
									</span>
								),
								id: "lifespan",
								accessor: c => c.lifespan || "",
								className: "justify-content-center",
								width: 90
							},
							{
								Header: (
									<span>
										Status
										<br />
										Contrato
									</span>
								),
								id: "status",
								show: true,
								accessor: ({ status, mei }) => {
									if (mei.useOwnContracts) {
										return (
											<Badge color="info" className="w-100">
												NÃO OPTANTE
											</Badge>
										);
									}

									switch (status) {
										case contractStatusConst.Signed:
											return (
												<Badge color="success" className="w-100">
													ASSINADO
												</Badge>
											);
										case contractStatusConst.Pending:
											return (
												<Badge color="secondary" className="w-100">
													PENDENTE
												</Badge>
											);
										case contractStatusConst.Due:
											return (
												<Badge color="danger" className="w-100">
													VENCIDO
												</Badge>
											);
										case contractStatusConst.Cancelled:
											return (
												<Badge
													className="w-100"
													style={{ backgroundColor: "orange" }}
												>
													CANCELADO
												</Badge>
											);
										case contractStatusConst.Rescind:
											return (
												<Badge
													className="w-100"
													style={{ backgroundColor: "brown" }}
												>
													RESCINDIDO
												</Badge>
											);
										case contractStatusConst.Excluded:
											return (
												<Badge
													className="w-100"
													style={{ backgroundColor: "red" }}
												>
													EXCLUÍDO
												</Badge>
											);
										case contractStatusConst.AwaitingSignature:
											return (
												<Badge
													className="w-100"
													style={{ backgroundColor: "yellow", color: "black" }}
												>
													AGUARDANDO ASSINATURA
												</Badge>
											);

										default:
											return (
												<Badge color="secondary" className="w-100">
													Pendente
												</Badge>
											);
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(contractStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[contractStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 85
							},
							{
								Header: "Assinatura Prestador",
								id: "hiredStatus",
								filterable: true,
								accessor: ({ hiredStatus }) => hiredStatus || "Não definido",
								show: false
							},
							{
								Header: "Token Prestador",
								id: "hiredToken",
								filterable: true,
								accessor: ({ hiredToken }) => hiredToken || "Não definido",
								show: false
							},
							{
								Header: "Assinatura Contratante",
								id: "hirerStatus",
								filterable: true,
								accessor: ({ hirerStatus }) => hirerStatus || "Não definido",
								show: false
							},
							{
								Header: "Token Contratante",
								id: "hirerToken",
								filterable: true,
								accessor: ({ hirerToken }) => hirerToken || "Não definido",
								show: false
							},
							{
								Header: "Data de Pagamento",
								id: "paymentDate",
								filterable: true,
								accessor: c =>
									c.due
										? dayjs(c.paymentDate).format(ls.dateFormatShort)
										: "Não definida",
								show: false
							},
							{
								Header: "Intervalo de Pagamento",
								id: "paymentInterval",
								filterable: true,
								accessor: ({ paymentInterval }) =>
									paymentInterval || "Não definido",
								show: false
							},
							{
								Header: "Período de Pagamento",
								id: "paymentPeriod",
								filterable: true,
								accessor: ({ paymentPeriod }) =>
									paymentPeriod || "Não definido",
								show: false
							},
							{
								Header: "Valor de Pagamento",
								id: "paymentValue",
								filterable: true,
								accessor: ({ paymentValue }) => paymentValue || "Não definido",
								show: false
							},
							{
								Header: () => (
									<>
										Status
										<br />
										Prestador
									</>
								),
								id: "companyProvider.status",
								width: 85,
								accessor: ({ companyProvider }) =>
									companyProvider && createBadge(companyProvider.status),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(["Pendente", "OnBoard", "Ativo", "Inativo"])
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{["Pendente", "OnBoard", "Ativo", "Inativo"][c]}
													</option>
												))}
										</select>
									);
								}
							},
							{
								Header: "Departamento",
								id: "department.name",
								show: true,
								accessor: ({ department }) => department?.name || "",
								filterable: true,
								width: 130
							}
						]}
					/>
				</Col>
			</Row>

			<Modal isOpen={modalFilters} toggle={toggleFilters}>
				<ModalHeader toggle={toggleFilters}>Filtros</ModalHeader>
				<ModalBody>
					<FiltersForm
						onSubmit={handleFilter}
						isInvalid={filters_form && !!filters_form.syncErrors}
					/>
				</ModalBody>
			</Modal>

			<Modal isOpen={modalExportExcel} toggle={toggleModalExportExcel}>
				<ModalHeader
					toggle={toggleModalExportExcel}
					className={"card__title bold-text"}
				>
					Exportar Relatório
				</ModalHeader>

				<ModalBody className="theme-light">
					<ExportExcelForm onSubmit={onSubmitExportExcel} progress={progress} />
				</ModalBody>
			</Modal>
		</Container>
	);
}

export default Contracts;
