import React, { useEffect } from "react";
import ReportTable from "components/Report";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Tooltip
} from "reactstrap";
import * as actions from "./actions";
import { MdEdit } from "react-icons/md";
import DepartmentFormEdit from "containers/Contracts/components/DepartmentForm/departmentFormEdit";
import DepartmentFormNew from "containers/Contracts/components/DepartmentForm/departmentFormNew";
import { BiTrash } from "react-icons/bi";

let getDepartmentDebounced = null;

function Department({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { items: departmentList, pages, loading, count } = useSelector(
		s => s.departmentList
	);

	const [currentDepartment, setCurrentDepartment] = useState(null);

	const [tooltipNewDepartment, setTooltipNewDepartment] = useState(false);
	const toggletooltipNewDepartment = () =>
		setTooltipNewDepartment(!tooltipNewDepartment);

	const [modalNew, setModalNew] = useState(false);
	const toggleNew = () => setModalNew(!modalNew);

	const [modalEdit, setModalEdit] = useState(false);
	const toggleEdit = () => setModalEdit(!modalEdit);

	const [modalDelete, setModalDelete] = useState(false);
	const toggleDelete = () => setModalDelete(!modalDelete);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getDepartmentDebounced) {
			getDepartmentDebounced.cancel();
		}

		if (!sorted) sorted = [];

		getDepartmentDebounced = _.debounce(
			() =>
				dispatch(
					actions.getDepartmentList(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => {
							return p + `&filters[${c.id}]=${c.value}`;
						}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getDepartmentDebounced();
	};

	const onSubmitNew = data => {
		dispatch(
			actions.createDepartment(
				{
					name: data.name
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					}
					toggleNew();
					load();
				}
			)
		);
	};

	const onSubmitEdit = data => {
		dispatch(
			actions.updateDepartment(
				currentDepartment._id,
				{
					name: data.name
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					}
					toggleEdit();
					load();
				}
			)
		);
	};

	const onSubmitDelete = () => {
		dispatch(
			actions.deleteDepartment(currentDepartment._id, err => {
				if (err) {
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				}
				toggleDelete();
				load();
			})
		);
	};

	const handleShowEdit = value => {
		setCurrentDepartment(value);
		toggleEdit();
	};

	const handleShowDelete = value => {
		setCurrentDepartment(value);
		toggleDelete();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	useEffect(() => {
		dispatch(actions.clearValues());
		load();
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Lista Departamentos"}
						data={departmentList}
						pages={pages}
						loading={loading.getAll}
						filterable
						count={count}
						onFetchData={handleFetchData}
						noExport={true}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						onRowClicked={row => history.push(`/contracts/template/${row._id}`)}
						headerRightComponent={
							<div>
								<Button
									className={"mr-2"}
									color={"primary"}
									onClick={toggleNew}
									id="tooltipNewDepartment"
								>
									Novo Departamento
								</Button>
								<Tooltip
									placement="top"
									isOpen={tooltipNewDepartment}
									target="tooltipNewDepartment"
									toggle={toggletooltipNewDepartment}
								>
									Criar um novo departamento
								</Tooltip>
							</div>
						}
						columns={[
							{
								Header: "Data Cadastro",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatShort)
										: "",
								width: 150
							},
							{
								Header: "Nome",
								id: "name",
								filterable: true,
								accessor: c => (c.name ? c.name : "Não definido")
							},
							{
								Header: "Ações",
								id: "actions",
								filterable: false,
								Cell: props => (
									<ButtonToolbar>
										<ButtonGroup className="btn-group--icons">
											<Button
												outline
												onClick={() => handleShowEdit(props.original)}
											>
												<MdEdit />
											</Button>
											<Button
												outline
												onClick={() => handleShowDelete(props.original)}
											>
												<BiTrash />
											</Button>
										</ButtonGroup>
									</ButtonToolbar>
								),
								width: 114
							}
						]}
					/>
				</Col>
			</Row>

			<Modal isOpen={modalNew} toggle={toggleNew}>
				<ModalHeader toggle={toggleNew}>Adicionar Departamento</ModalHeader>
				<ModalBody>
					<DepartmentFormNew onSubmit={onSubmitNew} loading={loading} />
				</ModalBody>
			</Modal>

			<Modal isOpen={modalEdit} toggle={toggleEdit}>
				<ModalHeader toggle={toggleEdit}>Editar Departamento</ModalHeader>
				<ModalBody>
					<DepartmentFormEdit
						onSubmit={onSubmitEdit}
						loading={loading}
						initialValues={currentDepartment}
					/>
				</ModalBody>
			</Modal>

			<Modal isOpen={modalDelete} toggle={toggleDelete}>
				<ModalHeader toggle={toggleDelete}>
					Deseja excluir este departamento?
				</ModalHeader>
				<ModalBody>
					<span>{currentDepartment?.name}</span>
				</ModalBody>
				<ModalFooter>
					<ButtonToolbar className="form__button-toolbar">
						<Button color="primary" onClick={onSubmitDelete}>
							Excluir
						</Button>
						<Button type="button" onClick={toggleDelete}>
							Cancelar
						</Button>
					</ButtonToolbar>
				</ModalFooter>
			</Modal>
		</Container>
	);
}

export default Department;
