import Input from "components/ReduxForm/Input";
import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import renderSelectField from "shared/components/form/Select";
const CreateForm = ({
	handleSubmit,
	reset,
	loading,
	errors,
	onChange,
	typeData,
	departmentData,
	companies
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Nome*</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: Modelo Contrato 1"
								props={{ errors: errors && errors.name }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Empresa</span>
						<div className="form__form-group-field">
							<Field
								name="mei"
								component={renderSelectField}
								type="text"
								onChange={onChange}
								placeholder="Busque uma empresa"
								onInputChange={meiSearch => onChange({ meiSearch })}
								options={[
									...companies.map(c => ({
										value: c._id,
										label: c.businessName
									}))
								]}
								props={{ errors: errors && errors.user }}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Tipo</span>
						<div className="form__form-group-field">
							<Field
								name="type"
								component={renderSelectField}
								type="text"
								placeholder="Selecione um tipo"
								options={typeData}
								props={{ errors: errors && errors.user }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Departamento</span>
						<div className="form__form-group-field">
							<Field
								name="department"
								component={renderSelectField}
								type="text"
								placeholder="Selecione um departamento"
								options={departmentData}
								// props={{ errors: errors && errors.user }}
							/>
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading.create}>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "contract_template_create",
	validate
})(CreateForm);
