import React, { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Col,
	Container,
	Row,
	UncontrolledTooltip
} from "reactstrap";

import SignatureModal from "./SignatureModal/signatureModal";
import FinancialStatusModal from "./SignatureModal/financialStatusModal";

import { MultiStatusCards } from "components/MultiStatusCards";

import * as api from "services/api";

import * as actions from "../actions";

import { FinancialStatusBadge } from "./financialStatusBadge";
import LoadingComponent from "components/LoadingComponent";
import dayjs from "dayjs";
// import { PhaseBadge } from "./phaseBadge";

const SignatureDetail = () => {
	const dispatch = useDispatch();

	const { item, dashboard } = useSelector(c => c.onBoardDetail);
	const { item: company } = useSelector(s => s.companyDetail);
	const { token } = useSelector(s => s.login);

	const [openSignaturesModal, setOpenSignaturesModal] = useState(false);
	const [openFinancialStatusModal, setOpenFinancialStatusModal] = useState(
		false
	);
	const [
		initialFinancialStatusValue,
		setInitialFinancialStatusValue
	] = useState(null);
	const [financialStatusToUpdate, setFinancialStatusToUpdate] = useState(0);
	const [onboardData, setOnboardData] = useState(item);
	const [isLoadingPipefyData, setIsLoadingPipefyData] = useState(false);

	const savePurchasedProviders = data => {
		dispatch(
			actions.updatePurchasedProviders(
				{
					_id: item._id,
					purchasedProviders: data.purchasedProviders
				},
				(err, res) => {
					if (err) {
						console.log("error", err);
						return;
					}

					if (res) {
						setOpenSignaturesModal(false);
						dispatch(actions.getOnboardData(company._id, e => console.log(e)));
					}
				}
			)
		);
	};

	async function getInitialFinancialStatus() {
		try {
			const response = await api.sendGet(`/v2/onboard/license/${company._id}`, {
				Authorization: "Bearer " + token
			});

			const result = await response.json();

			if (result.isValid) {
				setInitialFinancialStatusValue(result.model.status);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function handleFinancialStatusSave(event) {
		event.preventDefault();

		try {
			await api.sendPut(
				`/v2/onboard/change-license-status/${company._id}`,
				{
					status: financialStatusToUpdate
				},
				{
					Authorization: "Bearer " + token
				}
			);
		} catch (err) {
			console.log(err);
		} finally {
			window.location.reload();
		}
	}

	async function handleFetchPipefyData() {
		try {
			setIsLoadingPipefyData(true);

			const response = await api.sendPatch(
				`/pipefy/pipefy-hirer-information?companyId=${company._id}&pipefyId=${company.pipefyId}`,
				{},
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setOnboardData(result.model);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingPipefyData(false);
		}
	}

	useEffect(() => {
		dispatch(actions.getOnboardData(company._id, e => console.log(e)));
	}, []);

	return (
		<Container className="dashboard">
			<Row
				style={{
					height: "auto",
					marginBottom: "1rem"
				}}
			>
				<Col
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						gap: "0.5rem"
					}}
				>
					<MultiStatusCards
						cardsData={[
							{
								children: [
									{
										title: (
											<div>
												Contratados
												<span
													className="onboard-edit-button"
													onClick={() => setOpenSignaturesModal(true)}
												>
													<AiOutlineEdit />
												</span>
											</div>
										),
										color: "info",
										count: item?.purchasedProviders || 0,
										onClick: () => {}
									}
								]
							},
							{
								children: [
									{
										title: "Ativos",
										color: "success",
										count:
											(dashboard?.enabled || 0) +
											(dashboard?.onboard || 0) +
											(dashboard?.pending || 0),
										onClick: () => {}
									}
								]
							},

							{
								children: [
									{
										title: "Ativados",
										color: "danger",
										count: dashboard?.disabled || 0,
										onClick: () => {}
									}
								]
							}
						]}
					/>
				</Col>
			</Row>
			<div>
				<Row className="mt-3">
					<Col className="d-flex align-items-center">
						<h4 className="mr-auto">
							<strong>Dados de gestão</strong>
						</h4>

						<h5>
							Atualizado em:{" "}
							{onboardData?.pipefyLastUpdatedDate
								? dayjs(onboardData.pipefyLastUpdatedDate).format(
										"DD/MM/YYYY HH:mm"
								  )
								: "--"}
						</h5>

						{company?.pipefyId ? (
							<Button
								color="primary"
								className="ml-2 my-0"
								style={{ lineHeight: "14px", padding: "7px 8px" }}
								onClick={handleFetchPipefyData}
							>
								Atualizar
							</Button>
						) : (
							<div id="update-pipefy-data">
								<Button
									color="primary"
									className="ml-2 my-0"
									style={{ lineHeight: "14px", padding: "7px 8px" }}
									onClick={handleFetchPipefyData}
									disabled
								>
									Atualizar
								</Button>
								<UncontrolledTooltip target={"update-pipefy-data"}>
									Não há vínculo entre este contratante e o Pipefy
								</UncontrolledTooltip>
							</div>
						)}
					</Col>
				</Row>
				{isLoadingPipefyData ? (
					<LoadingComponent />
				) : (
					<Row style={{ flexWrap: "wrap" }}>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<div>
								<strong className="onboard-header-edit">Vendedor:</strong>
								<br />
								<span>{onboardData?.salerName || "Não informado"}</span>
							</div>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<div>
								<strong className="onboard-header-edit">
									Gerente de Contas:
								</strong>
								<br />
								<span>
									{onboardData?.accountManagerName || "Não informado"}
								</span>
							</div>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<div>
								<strong className="onboard-header-edit">Fase:</strong>
								<br />
								<div
									style={{
										fontSize: "1rem",
										display: "flex",
										flexDirection: "column"
									}}
								>
									<Badge
										style={{ width: "fit-content", textTransform: "uppercase" }}
										color="secondary"
									>
										{onboardData?.phase
											? onboardData.phase === 1
												? "Onboard"
												: "Ongoing"
											: "Não informado"}
									</Badge>
								</div>
							</div>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<div>
								<strong className="onboard-header-edit">Etapa:</strong>
								<br />
								<div
									style={{
										fontSize: "1rem",
										display: "flex",
										flexDirection: "column"
									}}
								>
									<Badge
										style={{ width: "fit-content", textTransform: "uppercase" }}
										color="secondary"
									>
										{onboardData?.step || "Não informado"}
									</Badge>
								</div>
							</div>
						</Col>
						<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
							<div>
								<strong className="onboard-header-edit">Touch:</strong>
								<br />
								<span>{onboardData?.touch || "Não informado"}</span>
							</div>
						</Col>
					</Row>
				)}
			</div>

			<div className="topbar__menu-divider" />

			<Row>
				<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
					<div>
						<strong className="onboard-header-edit">
							Status Financeiro:
							<div
								className="onboard-edit-button"
								onClick={() => setOpenFinancialStatusModal(true)}
							>
								<AiOutlineEdit />
							</div>
						</strong>
						<br />
						<div
							style={{
								fontSize: "1rem",
								display: "flex",
								flexDirection: "column"
							}}
						>
							{!(initialFinancialStatusValue === null)
								? FinancialStatusBadge(initialFinancialStatusValue)
								: null}
						</div>
					</div>
					<FinancialStatusModal
						toggle={() => setOpenFinancialStatusModal(false)}
						modalOpen={openFinancialStatusModal}
						defaultValue={initialFinancialStatusValue}
						handleSubmit={handleFinancialStatusSave}
						handleChange={setFinancialStatusToUpdate}
					/>
				</Col>
			</Row>

			<SignatureModal
				toggle={() => setOpenSignaturesModal(false)}
				modalOpen={openSignaturesModal}
				onSubmit={savePurchasedProviders}
				initialValues={{ purchasedProviders: item?.purchasedProviders || 0 }}
			/>
		</Container>
	);
};

export default SignatureDetail;
