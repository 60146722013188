import { getErrors } from "helpers/error";
import * as api from "services/api";

export const GET_NFSE = "[NFSE] GET_NFSE";
export const GET_NFSE_SUCCESS = "[NFSE] GET_NFSE_SUCCESS";
export const GET_NFSE_FAILED = "[NFSE] GET_NFSE_FAILED";

export const IMPORT_NFSE = "[NFSE] IMPORT_NFSE";
export const IMPORT_NFSE_SUCCESS = "[NFSE] IMPORT_NFSE_SUCCESS";
export const IMPORT_NFSE_FAILED = "[NFSE] IMPORT_NFSE_FAILED";

export const SET_VALUE = "[NFSE] SET_VALUE";
export const CLEAR_VALUES = "[NFSE] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getNfses(skip, take, filter, sort, desc, toExport, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_NFSE });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/nfse?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_NFSE_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_NFSE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_NFSE_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function importNfse(companyId, data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_NFSE });

		try {
			const { login } = getState();

			var response = await api.sendPost(`/nfse/import/${companyId}`, data, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_NFSE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_NFSE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_NFSE_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}