import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import validate from "./validate";

import Input from "../../../components/ReduxForm/Input";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import EyeIcon from "mdi-react/EyeIcon";

class ResetPasswordForm extends PureComponent {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired
	};

	constructor() {
		super();

		this.state = {
			showPassword: false,
			showConfirmPassword: false
		};
	}

	showPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({ showPassword: !prevState.showPassword }));
	};

	showConfirmPassword = e => {
		e.preventDefault();
		this.setState(prevState => ({
			showConfirmPassword: !prevState.showConfirmPassword
		}));
	};

	render() {
		const { handleSubmit } = this.props;
		const { showPassword, showConfirmPassword } = this.state;

		return (
			<form className="form" onSubmit={handleSubmit}>
				<div className="form__form-group">
					<span className="form__form-group-label">Nova Senha</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field
							name="password"
							component={Input}
							type={showPassword ? "text" : "password"}
							placeholder="Nova Senha"
						/>

						<button
							className={`form__form-group-button${
								showPassword ? " active" : ""
							}`}
							onClick={e => this.showPassword(e)}
							type="button"
						>
							<EyeIcon />
						</button>
					</div>
				</div>

				<div className="form__form-group">
					<span className="form__form-group-label">Confirmar Senha</span>
					<div className="form__form-group-field">
						<div className="form__form-group-icon">
							<KeyVariantIcon />
						</div>
						<Field
							name="confirmPassword"
							component={Input}
							type={showConfirmPassword ? "text" : "password"}
							placeholder="Confirmar Senha"
						/>

						<button
							className={`form__form-group-button${
								showConfirmPassword ? " active" : ""
							}`}
							onClick={e => this.showConfirmPassword(e)}
							type="button"
						>
							<EyeIcon />
						</button>
					</div>
				</div>

				<button
					className="btn btn-primary account__btn account__btn--small"
					type="submit"
				>
					Alterar senha
				</button>
			</form>
		);
	}
}

export default reduxForm({
	form: "log_in_form",
	validate
})(ResetPasswordForm);
