import styled from "styled-components";

export const PageWrapper = styled.main`
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding-inline: 20px;
`;

export const PageTitle = styled.h1`
	font-size: 20px;
	font-weight: bold;
	line-height: 23px;
	color: #000 !important;

	span {
		font-weight: 400;
	}
`;

export const FilterSection = styled.div`
	background-color: #fff;
	border: 1px solid #eeefff;
	border-radius: 8px;
	width: 100%;
`;

export const AnimatedAdditionalFilterContainer = styled.div`
	width: 100%;
	overflow: hidden;
	position: relative;
	max-height: ${({ isOpen }) => (isOpen ? "1000px" : "0")};
	opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
	visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
	transition: max-height 0.5s ease-out 0s, opacity 0.5s ease-out 0s,
		visibility 0.5s ease-out 0s;
`;

export const FilterHeaderText = styled.h2`
	font-size: 16px;
	font-weight: bold;
	color: #000 !important;
`;

export const FilterTitle = styled(FilterHeaderText)`
	padding: 12px 16px;
	border-bottom: 1px solid #eeefff;
`;

export const InputsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-inline: 16px;
	margin: 16px 0 12px 0;
`;

export const MainFiltersContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

export const AdditionalFiltersContainer = styled(MainFiltersContainer)`
	margin-top: 8px;
	flex-wrap: wrap;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 38px;
`;

export const ChangeFiltersExhibitionButton = styled.button`
	background: transparent;
	font-size: 16px;
	line-height: 23px;
	font-weight: bold;
	color: #31475a;
	cursor: pointer;
	padding: 8px 2px;
	text-decoration: underline;
	border: none;

	transition: filter 0.2s;

	&:hover {
		filter: brightness(0.9);
	}
`;

export const RightSideButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	button:first-child {
		background: transparent;
		border: 1px solid #504c9a;
		padding: 8px 48px;
		color: #504c9a;
		font-size: 16px;
		line-height: 23px;
		font-weight: bold;
		border-radius: 8px;
	}

	button:last-child {
		background: #504c9a;
		border: 1px solid #504c9a;
		padding: 8px 48px;
		color: #fff;
		font-size: 16px;
		line-height: 23px;
		font-weight: bold;
		border-radius: 8px;
	}
`;

export const ButtonAlignerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

export const ExportExcelButton = styled.button`
	border: 1px solid #504c9a;
	background: #504c9a;
	border-radius: 8px;
	padding: 8px 16px;
`;

export const StatusBadge = styled.div`
	background-color: ${props => props.variant};
	border-radius: 2px;
	padding: 2px 8px;
	width: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	span {
		color: #fff;
		font-size: 8px;
		font-weight: bold;
		text-align: center;
	}
`;

export const TableCellButton = styled.button`
	padding: 0;
	border: none;
	background: transparent;
	color: #31475a;
	cursor: pointer;
`;
