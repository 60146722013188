import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { SubmissionError } from "redux-form";
import { submitResetPassword, validateToken } from "redux/actions/login";
import ResetPasswordForm from "./components/ResetPasswordForm";

const ResetPassword = ({ history }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const token = location.search.replace("?token=", "");

	React.useEffect(() => {
		dispatch(
			validateToken(token, err => {
				if (err) history.push("/");
			})
		);
	}, []);

	const onSubmit = data => {
		dispatch(
			submitResetPassword(data, token, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					history.push("/");
				}
			})
		);
	};

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<h3 className="account__title">
							<span className="account__logo">
								SUA<span className="account__logo-accent">MEI</span>
							</span>
						</h3>
						<h4 className="account__subhead subhead">Alteração de senha</h4>
					</div>
					<ResetPasswordForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
