import React from "react";

const NoteCard = ({ authorName, postDate, noteText }) => {
	return (
		<div className="note-container">
			<span className="note-author-name">{authorName || "Desconhecido"}</span>
			<span className="note-text-card">{noteText}</span>
			<span className="note-date-card">{postDate}</span>
		</div>
	);
};

export default NoteCard;
