import Input from "components/ReduxForm/Input";
import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";
import validate from "./validateRegular";
import { DATE, MONEY } from "components/NumberFormat";
import { stringToMask } from "helpers/string";
import renderCheckBoxField from "shared/components/form/CheckBox";

import { TooltipComp } from "components/Tooltip";
import { useSelector } from "react-redux";
import { validateContractCode } from "helpers/validators";

const ContractEdit = ({
	handleSubmit,
	reset,
	loading,
	errors,
	submitting,
	isInvalid,
	change,
	initialValues,

	typeStatus,
	signerStatus,
	periodTypeData,

	meis,
	hireds,

	onSelectMei,
	onSearchMei,
	onSelectHired,
	onSearchHired,

	typeData,
	departmentData
}) => {
	const { values } = useSelector(state => state.form.contract_regular_edit);

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row className="justify-content-between align-items-center mb-4">
					<div className="card__title  flex-column">
						<h5 className="bold-text">Alterar dados do contrato</h5>
					</div>

					<ButtonToolbar className="form__button-toolbar">
						<Button
							color="primary"
							type="submit"
							disabled={submitting || isInvalid || loading.create}
						>
							{loading.create ? (
								<Spinner type="grow" size="sm" color="dark" />
							) : (
								"Salvar"
							)}
						</Button>
						<Button type="button" onClick={reset}>
							Limpar
						</Button>
					</ButtonToolbar>
				</Row>

				<Row className="mb-4">
					<Col xs={12} sm={12} md={6} lg={6} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Contratante*
						</span>
						<div className="form__form-group-field">
							<Field
								name="mei"
								component={renderSelectField}
								type="text"
								placeholder="Busque uma empresa"
								onChange={mei => onSelectMei(mei)}
								onInputChange={meiSearch => onSearchMei(meiSearch)}
								options={[
									...meis.map(c => ({
										value: c._id,
										label: `${c.businessName}${
											c?.cnpj
												? " - CNPJ: " +
												  stringToMask(c?.cnpj, "##.###.###/####-##")
												: ""
										} - ID: ${c._id}`
									}))
								]}
								// props={{ errors: errors && errors.mei }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={6} lg={6} className="form__form-group">
						<span className="form__form-group-label bold-text">Prestador*</span>
						<div className="form__form-group-field">
							<Field
								name="hired"
								component={renderSelectField}
								type="text"
								placeholder="Busque um prestador"
								onChange={hired => onSelectHired(hired)}
								onInputChange={hiredSearch => onSearchHired(hiredSearch)}
								options={
									hireds?.length
										? [
												...hireds.map(c => ({
													value: c._id,
													label: `${c.businessName}${
														c?.cnpj
															? " - CNPJ: " +
															  stringToMask(c?.cnpj, "##.###.###/####-##")
															: ""
													} - ID: ${c._id}`
												}))
										  ]
										: [{ value: "", label: "Busque um prestador" }]
								}
								// props={{ errors: errors && errors.hired }}
							/>
						</div>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col xs={12} sm={12} md={4} lg={6} className="form__form-group">
						<span className="form__form-group-label bold-text">Nome*</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: Modelo Contrato 1"
								props={{ errors: errors && errors.name }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label bold-text">Tipo*</span>
						<div className="form__form-group-field">
							<Field
								name="type"
								component={renderSelectField}
								type="text"
								placeholder="Selecione um tipo"
								options={typeData}
								props={{ errors: errors && errors.type }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Departamento*
						</span>
						<div className="form__form-group-field">
							<Field
								name="department"
								component={renderSelectField}
								type="text"
								placeholder="Selecione um departamento"
								options={departmentData}
								props={{ errors: errors && errors.user }}
							/>
						</div>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Data de Início*
						</span>
						<div className="form__form-group-field">
							<Field
								name="startDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
								props={{ errors: errors.startDate }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="bold-text">Contrato Autorrenovável</span>
						<div className="form__form-group-field">
							<Field
								name="selfRenewable"
								component={renderCheckBoxField}
								defaultChecked={initialValues.selfRenewable}
							/>
						</div>
					</Col>

					{values.selfRenewable ? (
						<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
							<span className="bold-text">Vigência (em meses)*</span>
							<div className="form__form-group-field">
								<Field
									name="lifespan"
									component={Input}
									type="number"
									placeholder="insira a duração da vigência (em meses)"
									props={{ errors: errors && errors.lifespan }}
								/>
							</div>
						</Col>
					) : (
						<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
							<span className="bold-text">Data de fim do contrato*</span>
							<div className="form__form-group-field">
								<Field
									name="due"
									component={DATE}
									type="text"
									placeholder="Ex.: 01/01/1970"
									props={{ errors: errors && errors.due }}
								/>
							</div>
						</Col>
					)}
				</Row>

				<Row className="mb-4">
					<Col xs={12} sm={12} md={9} lg={9} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Código Contrato{" "}
							<TooltipComp
								id="externalId"
								className="form__form-group-label bold-text"
								message={"Código do ZapSign refente ao contrato"}
							/>
						</span>
						<div className="form__form-group-field">
							<Field
								name="externalId"
								component={Input}
								type="text"
								placeholder="Ex.: 00000000-0000-0000-0000-000000000000"
								validate={validateContractCode}
								props={{ errors: errors && errors.externalId }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={3} lg={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Status Contrato{" "}
							<TooltipComp
								id="status"
								className="form__form-group-label bold-text"
								message={'O Status padrão do contrato será "Pendente"'}
							/>
						</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={typeStatus}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={9} lg={9} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Código Assinatura Contratante{" "}
							<TooltipComp
								id="hirerToken"
								className="form__form-group-label bold-text"
								message={
									"Código do ZapSign refente à assinatura do Contratante"
								}
							/>
						</span>
						<div className="form__form-group-field">
							<Field
								name="hirerToken"
								component={Input}
								type="text"
								placeholder="Ex.: 00000000-0000-0000-0000-000000000000"
								props={{ errors: errors && errors.hirerToken }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={3} lg={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Status Assinatura Contratante
						</span>
						<div className="form__form-group-field">
							<Field
								name="hirerStatus"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={signerStatus}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={9} lg={9} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Código Assinatura Prestador{" "}
							<TooltipComp
								id="hiredToken"
								className="form__form-group-label bold-text"
								message={"Código do ZapSign refente à assinatura do Prestador"}
							/>
						</span>
						<div className="form__form-group-field">
							<Field
								name="hiredToken"
								component={Input}
								type="text"
								placeholder="Ex.: 00000000-0000-0000-0000-000000000000"
								props={{ errors: errors && errors.hiredToken }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={3} lg={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Status Assinatura Prestador
						</span>
						<div className="form__form-group-field">
							<Field
								name="hiredStatus"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={signerStatus}
							/>
						</div>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">Comentário</span>
						<div className="form__form-group-field">
							<Field name="note" component={Input} type="text" />
						</div>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col className="form__form-group">
						<h6 className="bold-text">Arquivo PDF</h6>
						<Input
							accept="application/pdf"
							type="file"
							name="file"
							onChange={f => change("file", f.target.files[0])}
							style={{ width: 500 }}
						/>
						{initialValues.filename ? (
							<a
								color="primary"
								href={initialValues.filename}
								target={"_blank"}
								rel="noreferrer"
							>
								{initialValues.filename}
							</a>
						) : (
							""
						)}
					</Col>
				</Row>

				<Row className="mb-4">
					<Col xs={12} sm={6} md={6} lg={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Valor do contrato
						</span>
						<div className="form__form-group-field">
							<Field
								name="paymentValue"
								component={MONEY}
								type="text"
								placeholder="Ex.: R$ 19,90"
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={4} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Primeiro pagamento
						</span>
						<div className="form__form-group-field">
							<Field
								name="paymentDate"
								component={DATE}
								type="text"
								placeholder="EX.: 01/01/1970"
								// props={{ errors: errors.due }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Ciclo de pagamento
						</span>
						<div className="form__form-group-field">
							<Field
								name="paymentPeriod"
								component={renderSelectField}
								type="text"
								placeholder="Selecione um período para pagamento"
								options={periodTypeData}
								// props={{ errors: errors && errors.user }}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "contract_regular_edit",
	validate,
	enableReinitialize: true
})(ContractEdit);
