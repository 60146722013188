import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import {
	Button,
	Container,
	FormGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";
dayjs.extend(customDateFormat);

// eslint-disable-next-line no-unused-vars
function FinancialStatusModal({
	toggle,
	modalOpen,
	defaultValue,
	handleSubmit,
	handleChange
}) {
	return (
		<Modal isOpen={modalOpen} toggle={toggle}>
			<form onSubmit={event => handleSubmit(event)}>
				<ModalHeader>Status</ModalHeader>
				<ModalBody>
					<Container>
						<FormGroup>
							<Field
								defaultValue={defaultValue}
								name="accountManager"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={[
									{
										label: "Em dia",
										value: 0
									},
									{
										label: "Atrasado",
										value: 1
									}
								]}
								onChange={({ value }) => {
									handleChange(value);
								}}
							/>
						</FormGroup>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" type="submit">
						Salvar
					</Button>
					<Button onClick={toggle}>Cancel</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
}

export default reduxForm({
	form: "onboard_status_edit"
})(FinancialStatusModal);
