import React from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { GiPayMoney } from "react-icons/gi";
import { BsCardChecklist } from "react-icons/bs";

import { useSelector } from "react-redux";
import { BiSliderAlt } from "react-icons/all";

function SidebarContent({ onClick }) {
	const hideSidebar = () => {
		onClick();
	};

	const { user } = useSelector(s => s.login);

	return (
		<div className="sidebar__content">
			<SidebarLink
				title="Dashboard"
				route="/"
				onClick={hideSidebar}
				icon="home"
			/>
			{/* <ul className="sidebar__block">
				<SidebarCategory
					title="Impostos"
					user={user}
					roles={["admin"]}
					icon={<GiPayMoney />}
				>
					<SidebarLink
						title="Cadastrar"
						route="/tax/new"
						onClick={hideSidebar}
					/>
					<SidebarLink title="Relatório" route="/tax" onClick={hideSidebar} />
				</SidebarCategory>
			</ul> */}

			<ul className="sidebar__block">
				<SidebarCategory
					title="Empresas"
					icon="store"
					user={user}
					roles={["admin"]}
				>
					<SidebarLink
						title="Cadastrar"
						route="/companies/new"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Prestadores"
						route="/companies/dashboard"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Contratantes"
						route="/companies"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Todas Empresas"
						route="/companies/report-all"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Clientes"
						route="/companies/company-groups"
						onClick={hideSidebar}
					/>
				</SidebarCategory>
			</ul>

			<ul className="sidebar__block">
				<SidebarLink
					icon="file-empty"
					title="NFS-e"
					route="/nfses"
					onClick={hideSidebar}
				/>
			</ul>

			<ul className="sidebar__block">
				<SidebarLink
					icon="file-empty"
					title="Integração Digisac"
					route="/digisac"
					onClick={hideSidebar}
				/>
			</ul>

			<ul className="sidebar__block">
				<SidebarCategory
					title="Contratos"
					icon="book"
					user={user}
					roles={["admin"]}
				>
					<SidebarLink
						title="Lista Departamentos"
						route="/contracts/department"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Lista Modelos"
						route="/contracts/template"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Lista Contratos"
						route="/contracts"
						onClick={hideSidebar}
					/>
				</SidebarCategory>
			</ul>

			<ul className="sidebar__block">
				<SidebarCategory
					title="Usuários"
					icon="users"
					user={user}
					roles={["admin"]}
				>
					<SidebarLink
						title="Cadastrar"
						route="/client/new"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Relatório"
						route="/client"
						onClick={hideSidebar}
					/>
				</SidebarCategory>
			</ul>

			<ul className="sidebar__block">
				<SidebarCategory
					title="Benefícios"
					user={user}
					roles={["admin"]}
					icon={<BsCardChecklist />}
				>
					<SidebarLink
						title="Cadastrar"
						route="/benefit/new"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Relatório"
						route="/benefit"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Lista de Segurados"
						route="/benefit/insured-list"
						onClick={hideSidebar}
					/>
				</SidebarCategory>
			</ul>

			<ul className="sidebar__block">
				<SidebarCategory
					title="Controle de Acesso"
					user={user}
					roles={["admin"]}
					icon={<BiSliderAlt />}
				>
					<SidebarCategory title="Permissões" user={user} roles={["admin"]}>
						<SidebarLink
							title="Cadastrar"
							route="/permission/new"
							onClick={hideSidebar}
						/>
						<SidebarLink
							title="Relatório"
							route="/permission"
							onClick={hideSidebar}
						/>
					</SidebarCategory>
					<SidebarCategory title="Grupo" user={user} roles={["admin"]}>
						<SidebarLink
							title="Cadastrar"
							route="/group/new"
							onClick={hideSidebar}
						/>
						<SidebarLink
							title="Relatório"
							route="/group"
							onClick={hideSidebar}
						/>
					</SidebarCategory>
				</SidebarCategory>
			</ul>

			{/* <SidebarLink
        title="Boletos"
        route="/bankBillet"
        onClick={hideSidebar}
        icon={<GiMoneyStack />}
      />*/}
			{/* <ul className="sidebar__block">
				<SidebarCategory
					title="Marketing"
					icon="bullhorn"
					user={user}
					roles={["admin"]}
				>
					<SidebarLink
						title="Cadastrar"
						route="/marketing/new"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Relatório"
						route="/marketing"
						onClick={hideSidebar}
					/>
				</SidebarCategory>
			</ul> */}
			{/* <ul className="sidebar__block">
        <SidebarCategory
          title="Assinaturas"
          user={user}
          roles={["admin"]}
          icon={<MdAttachMoney />}
        >
          <SidebarLink
            title="Cadastrar"
            route="/subscriptions/new"
            onClick={hideSidebar}
          />
          <SidebarLink
            title="Relatório"
            route="/subscriptions"
            onClick={hideSidebar}
          />
        </SidebarCategory>
      </ul> */}
			{/* <SidebarLink
        title="Transferências"
        route="/transfer"
        onClick={hideSidebar}
        icon={<BiTransfer />}
      /> */}
			{/* <ul className="sidebar__block">
				<SidebarCategory
					title="Relatório de Versão"
					icon="pushpin"
					user={user}
					roles={["admin"]}
				>
					<SidebarLink
						title="Cadastrar"
						route="/version-report/new"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="Relatório"
						route="/version-report"
						onClick={hideSidebar}
					/>
				</SidebarCategory>
			</ul> */}
		</div>
	);
}

SidebarContent.propTypes = {
	changeToDark: PropTypes.func.isRequired,
	changeToLight: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired
};

export default SidebarContent;
