import React from "react";

import { SelectContainer } from "./styles";

export function SelectInput({ label, value, onChange, options, ...props }) {
	return (
		<SelectContainer>
			<label htmlFor={label}>{label}</label>
			<select id={label} value={value} onChange={onChange} {...props}>
				{options.map(option => (
					<option key={`${option.value} ${option.label}`} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</SelectContainer>
	);
}
