import React, { createRef, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledTooltip
} from "reactstrap";

import ReportTable from "components/Report";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import * as api from "services/api";

import companyHistoryType from "types/companyHistory";
import { FaRegFileExcel } from "react-icons/fa";
import ExportExcelForm from "../ExportExcelForm";
import ExportExcel from "helpers/exportExcel";

dayjs.extend(customParseFormat);

const CompanyHistoryList = ({ companyId }) => {
	const { token } = useSelector(s => s.login);

	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () => setModalExportExcel(!modalExportExcel);

	const [progress, setProgress] = useState(0);

	const table = useRef(null);

	const [companyHistoryList, setCompanyHistoryList] = useState([]);
	const [pages, setPages] = useState(0);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const handleFetchData = async tableState => {
		if (!tableState) return;
		createRef(tableState);

		let { page, pageSize } = tableState;

		setLoading(true);

		try {
			const response = await api.sendGet(
				`/companyHistory/${companyId}?skip=${page * pageSize}&take=${pageSize}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setCompanyHistoryList(result?.model);
				setCount(result.params?.count);
				setPages(Math.ceil(result.params?.count / pageSize));
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				"Data Cadastro": item.createdAt
					? dayjs(item.createdAt).format("DD/MM/YYYY")
					: "",
				"Criado por": item.createdByName || "",
				Observação: item.note || "",
				Tipo: Object.keys(companyHistoryType)[item.type] || ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = async type => {
		const dataTmp = [];
		const takeTmp = 500;

		for (let skipTmp = 0; skipTmp < count; skipTmp += takeTmp) {
			const response = await api.sendGet(
				`/companyHistory/${companyId}?skip=${skipTmp}&take=${takeTmp}&sort=createdAt&desc=true`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.model) {
				dataTmp.push(...result.model);
				setProgress(Math.round((skipTmp / count) * 100));
			} else {
				console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
				break;
			}
		}

		ExportExcel(customData(dataTmp), "Historico_Contratantes", type);
		toggleModalExportExcel();
	};

	const onSubmitExportExcel = data => {
		if (count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (data.exportExcel) {
			handleExport("xlsx");
		}

		if (data.exportCsv) {
			handleExport("csv");
		}
	};

	return (
		<Card>
			<CardBody>
				<ReportTable
					manual
					tableRef={table}
					title={"Histórico do Contratante"}
					data={companyHistoryList}
					pages={pages}
					count={count}
					defaultPageSize={5}
					loading={loading}
					noExport={true}
					filterable={false}
					sortable={true}
					onFetchData={handleFetchData}
					defaultSorted={[
						{
							id: "createdAt",
							desc: false
						}
					]}
					headerRightComponent={
						<div>
							<Button
								className={"mr-2"}
								outline
								onClick={toggleModalExportExcel}
								id="tooltipExcel"
							>
								<FaRegFileExcel style={{ margin: 0 }} />
							</Button>
							<UncontrolledTooltip target="tooltipExcel">
								Exportar os dados da tabela
							</UncontrolledTooltip>
						</div>
					}
					columns={[
						{
							Header: (
								<span>
									Data <br />
									Cadastro
								</span>
							),
							id: "createdAt",
							accessor: c =>
								c.createdAt
									? dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds)
									: "",
							width: 120,
							sortable: false
						},
						{
							Header: "Criado por",
							id: "createdByName",
							accessor: "createdByName",
							width: 130,
							sortable: false
						},
						{
							Header: "Observação",
							id: "Note",
							accessor: ({ note }) => note || "Não definido",
							sortable: false
						},
						{
							Header: "Tipo",
							id: "type",
							show: true,
							accessor: c => Object.keys(companyHistoryType)[c.type],
							Filter: ({ filter, onChange }) => {
								return (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter && filter.value ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(companyHistoryType).map((c, i) => (
											<option key={c} value={i}>
												{c}
											</option>
										))}
									</select>
								);
							},
							width: 100,
							sortable: false
						}
					]}
				/>
			</CardBody>
			<Modal isOpen={modalExportExcel} toggle={toggleModalExportExcel}>
				<ModalHeader
					toggle={toggleModalExportExcel}
					className={"card__title bold-text"}
				>
					Exportar Relatório
				</ModalHeader>

				<ModalBody className="theme-light">
					<ExportExcelForm onSubmit={onSubmitExportExcel} progress={progress} />
				</ModalBody>
			</Modal>
		</Card>
	);
};

export default CompanyHistoryList;
