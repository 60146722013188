import * as api from "../../../../../../services/api/axios";
import * as apiV1 from "../../../../../../services/api";
import { getErrors } from "helpers/error";

export const GET_ONBOARD = "[onboard] GET_ONBOARD";
export const GET_ONBOARD_SUCCESS = "[onboard] GET_ONBOARD_SUCCESS";
export const GET_ONBOARD_FAILED = "[onboard] GET_ONBOARD_FAILED";

export const UPDATE_ONBOARD = "[onboard] UPDATE_ONBOARD";
export const UPDATE_ONBOARD_SUCCESS = "[onboard] UPDATE_ONBOARD_SUCCESS";
export const UPDATE_ONBOARD_FAILED = "[onboard] UPDATE_ONBOARD_FAILED";

export const IMPORT_ONBOARD_BATCH = "[onboard] IMPORT_ONBOARD_BATCH";
export const IMPORT_ONBOARD_BATCH_SUCCESS =
	"[onboard] IMPORT_ONBOARD_BATCH_SUCCESS";
export const IMPORT_ONBOARD_BATCH_FAILED =
	"[onboard] IMPORT_ONBOARD_BATCH_FAILED";

export const SET_VALUE = "[onboard] SET_VALUE";
export const CLEAR_VALUES = "[onboard] CLEAR_VALUES";

export const GET_COMPANY_PROVIDERS_STATUS =
	"[onboard] GET_COMPANY_PROVIDERS_STATUS";
export const GET_COMPANY_PROVIDERS_STATUS_SUCCESS =
	"[onboard] GET_COMPANY_PROVIDERS_STATUS_SUCCESS";
export const GET_COMPANY_PROVIDERS_STATUS_ERROR =
	"[onboard] GET_COMPANY_PROVIDERS_STATUS_ERROR";

export const GET_COMPANY_PROVIDERS_PHASES =
	"[onboard] GET_COMPANY_PROVIDERS_PHASES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getOnboard(
	company_id,
	skip,
	take,
	filter,
	sort,
	desc,
	callback = null
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ONBOARD });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				"api",
				`/v2/onboard/find-one/${company_id}?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				login.token,
				"bearer",
				null
			);

			if (response.data.isValid) {
				dispatch({
					type: GET_ONBOARD_SUCCESS,
					payload: response.data.model
				});

				if (response.data.params) {
					dispatch(
						setValue({
							pages: Math.ceil(response.data.params.count / take),
							...response.data.params
						})
					);
				}

				callback && callback(null, response.data.model);
			} else {
				const errors = getErrors(response.data.errors);

				dispatch({
					type: GET_ONBOARD_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ONBOARD_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getOnboardData(companyId, callback = null) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ONBOARD });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				"api",
				`/v2/onboard/admin-users/${companyId}`,
				login.token,
				"bearer",
				null
			);

			if (response.data.isValid) {
				dispatch({
					type: GET_ONBOARD_SUCCESS,
					payload: { onboard: response.data.model.onboardInfo }
				});

				callback && callback(null, response.data.model);
			} else {
				const errors = getErrors(response.data.errors);

				dispatch({
					type: GET_ONBOARD_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ONBOARD_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateOnBoard(payload) {
	return async (dispatch, getState) => {
		// dispatch({ type: UPDATE_ONBOARD });

		const { login } = getState();

		await api.sendPost(
			"api",
			`/v2/onboard/update-one/${payload._id}`,
			payload,
			login.token,
			"bearer",
			null
		);

		return;
	};
}

export function updateProvider(payload) {
	return async (dispatch, getState) => {
		// dispatch({ type: UPDATE_ONBOARD });

		const { login } = getState();

		await api.sendPut(
			"api",
			`/companyProvider/${payload._id}`,
			payload,
			login.token,
			"bearer",
			null
		);

		return;
	};
}

export function hiredOnBoard(payload, callback) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: UPDATE_ONBOARD });
			const { login } = getState();

			const response = await apiV1.sendPost(`/v2/onboard/contratado`, payload, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ONBOARD_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ONBOARD_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_ONBOARD_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function submitOnboard(payload) {
	return async (dispatch, getState) => {
		const { login } = getState();

		await api.sendPost(
			"api",
			`/v2/onboard/start-onboard`,
			payload,
			login.token,
			"bearer",
			null
		);

		return;
	};
}

export function submitInactivateMany(payload, callback) {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: UPDATE_ONBOARD });
			const { login } = getState();

			var response = await apiV1.sendPut(
				`/companyProvider/toggle-status`,
				payload,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ONBOARD_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ONBOARD_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_ONBOARD_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function submitOnboardHirer(payload) {
	return async (dispatch, getState) => {
		const { login } = getState();

		await api.sendPost(
			"api",
			`/v2/onboard/start-onboard-hirer`,
			payload,
			login.token,
			"bearer",
			null
		);

		return;
	};
}

export function createUpdateOnboard(cnpj) {
	return async (dispatch, getState) => {
		const { login } = getState();

		await api.sendPost(
			"api",
			`/v2/onboard/create-onboard`,
			{ cnpj },
			login.token,
			"bearer",
			null
		);

		return;
	};
}

export function importOnBoardBatch(files, company_id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_ONBOARD_BATCH });

		try {
			const { login } = getState();

			var response = await apiV1.sendPostFiles(
				`/v2/onboard/contratado/batch/${company_id}`,
				files,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_ONBOARD_BATCH_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_ONBOARD_BATCH_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_ONBOARD_BATCH_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getCompanyProvidersStatus(id) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANY_PROVIDERS_STATUS
		});
		try {
			const { token } = getState().login;

			let response = await apiV1.sendGet(`/companyProvider/get-status/${id}`, {
				Authorization: `Bearer ${token}`
			});
			let result = await response.json();
			if (result.isValid) {
				dispatch({
					type: GET_COMPANY_PROVIDERS_STATUS_SUCCESS,
					payload: result.model
				});

				// callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_COMPANY_PROVIDERS_STATUS_ERROR,
					payload: errors
				});

				// callback && callback(errors);
			}
		} catch (error) {
			// dispatch({
			// 	type: GET_COMPANY_PROVIDERS_STATUS_ERROR,
			// 	payload: {
			// 		error: "Connection error"
			// });
		}
	};
}

export function updateAccountManager(data, callback) {
	return async (dispatch, getState) => {
		const { login } = getState();
		dispatch({
			type: GET_COMPANY_PROVIDERS_STATUS
		});

		try {
			const response = await apiV1.sendPost(
				`/v2/onboard/update-account-manager`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				console.log(result);
				callback && callback();
			}
		} catch (error) {
			console.log(error);
		}
	};
}

export function updateSaler(data, callback) {
	return async (dispatch, getState) => {
		const { login } = getState();
		dispatch({
			type: GET_COMPANY_PROVIDERS_STATUS
		});

		try {
			const response = await apiV1.sendPost(`/v2/onboard/update-saler`, data, {
				Authorization: "Bearer " + login.token
			});

			const result = await response.json();

			if (result.isValid) {
				callback && callback();
			}
		} catch (error) {
			console.log(error);
		}
	};
}

export function updateOnboardStatus(data, callback) {
	return async (dispatch, getState) => {
		const { login } = getState();
		dispatch({
			type: GET_COMPANY_PROVIDERS_STATUS
		});

		try {
			const response = await apiV1.sendPost(
				`/v2/onboard/update-onboard-status`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				callback && callback();
			}
		} catch (error) {
			console.log(error);
		}
	};
}

export function updatePurchasedProviders(data, callback) {
	return async (dispatch, getState) => {
		const { login } = getState();
		dispatch({
			type: GET_COMPANY_PROVIDERS_STATUS
		});

		try {
			const response = await apiV1.sendPost(
				`/v2/onboard/update-purchased-providers`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				callback && callback(null, true);
			} else {
				const errors = getErrors(result.errors);

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);
		}
	};
}

export function updateOnboardPhases(data, callback) {
	return async (dispatch, getState) => {
		const { login } = getState();
		dispatch({
			type: GET_COMPANY_PROVIDERS_PHASES
		});

		try {
			const response = await apiV1.sendPatch(
				`/v2/onboard/update-onboard-phase`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				callback && callback();
			}
		} catch (error) {
			console.log(error);
		}
	};
}
