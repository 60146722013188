import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_COMPANY_BENEFIT =
	"[COMPANY BENEFIT DETAIL] GET_COMPANY_BENEFIT";
export const GET_COMPANY_BENEFIT_ERROR =
	"[COMPANY BENEFIT DETAIL] GET_COMPANY_BENEFIT_ERROR";
export const GET_COMPANY_BENEFIT_SUCCESS =
	"[COMPANY BENEFIT DETAIL] GET_COMPANY_BENEFIT_SUCCESS";

export const DELETE_COMPANY_BENEFIT =
	"[COMPANY_BENEFIT DELETE] DELETE_COMPANY_BENEFIT";
export const DELETE_COMPANY_BENEFIT_ERROR =
	"[COMPANY_BENEFIT DELETE] DELETE_COMPANY_BENEFIT_ERROR";
export const DELETE_COMPANY_BENEFIT_SUCCESS =
	"[COMPANY_BENEFIT DELETE] DELETE_COMPANY_BENEFIT_SUCCESS";

export const DELETE_PROVIDER_BENEFITS =
	"[PROVIDER_BENEFITS DELETE] DELETE_PROVIDER_BENEFITS";
export const DELETE_PROVIDER_BENEFITS_ERROR =
	"[PROVIDER_BENEFITS DELETE] DELETE_PROVIDER_BENEFITS_ERROR";
export const DELETE_PROVIDER_BENEFITS_SUCCESS =
	"[PROVIDER_BENEFITS DELETE] DELETE_PROVIDER_BENEFITS_SUCCESS";

export const SET_VALUE = "[COMPANY BENEFIT DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[COMPANY BENEFIT DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getBenefitById(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANY_BENEFIT
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/companyBenefit/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMPANY_BENEFIT_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMPANY_BENEFIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANY_BENEFIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteCompanyBenefit(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_COMPANY_BENEFIT
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/companyBenefit/${id} `, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: DELETE_COMPANY_BENEFIT_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_COMPANY_BENEFIT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: DELETE_COMPANY_BENEFIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteProviderBenefits(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_PROVIDER_BENEFITS
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(
				`/companyBenefit/provider-benefits/${id} `,
				{
					Authorization: "Bearer " + login.token
				}
			);

			if (response.ok) {
				dispatch({
					type: DELETE_PROVIDER_BENEFITS_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_PROVIDER_BENEFITS_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: DELETE_PROVIDER_BENEFITS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
