import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

import { useGlobalContext } from "contexts/GlobalContext";
import GlobalModal from "components/Modal";
import DetailsTax from "../../../components/DetailsTax";
import { getTax, deleteTax } from "./actions";

function Details({
	history,
	match: {
		params: { id }
	}
}) {
	const { showModal, hideModal } = useGlobalContext();
	const { item, loading } = useSelector(state => state.taxDetails);
	const dispatch = useDispatch();

	const handleDelete = () => {
		dispatch(
			deleteTax(id, err => {
				if (err) {
					alert(String(err));
					hideModal();
				} else {
					history.push("/tax");
					hideModal();
				}
			})
		);
	};

	useEffect(() => {
		dispatch(getTax(id));
	}, [dispatch]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do imposto</h5>
								</div>
								<div>
									<Button color="danger" onClick={showModal}>
										Excluir
									</Button>
									<Link to={`/tax/${id}/edit`}>
										<Button color="primary">Editar</Button>
									</Link>
								</div>
							</Row>
							<DetailsTax tax={{ ...item }} />
						</CardBody>
					</Card>
				</Col>
			</Row>
			<GlobalModal
				title="Deletar imposto"
				onOk={handleDelete}
				loading={loading.delete}
			>
				<p>
					Tem certeza que deseja excluir este imposto? Essa ação é irreversível
				</p>
			</GlobalModal>
		</Container>
	);
}

export default Details;
