import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_TRANSFERS = "[TRANSFER] GET_TRANSFERS";
export const GET_TRANSFERS_SUCCESS = "[TRANSFER] GET_TRANSFERS_SUCCESS";
export const GET_TRANSFERS_FAILED = "[TRANSFER] GET_TRANSFERS_FAILED";

export const IMPORT_TRANSFERS = "[TRANSFER] IMPORT_TRANSFERS";
export const IMPORT_TRANSFERS_SUCCESS = "[TRANSFER] IMPORT_TRANSFERS_SUCCESS";
export const IMPORT_TRANSFERS_FAILED = "[TRANSFER] IMPORT_TRANSFERS_FAILED";

export const SET_VALUE = "[TRANSFER EDIT] SET_VALUE";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function importTransfers(
	data,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_TRANSFERS });

		try {
			const { login } = getState();

			var response = await api.sendPost(`/Transfer/Import`, data, {
				Authorization: "Bearer " + login.token,
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_TRANSFERS_SUCCESS,
					payload: result.model,
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_TRANSFERS_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_TRANSFERS_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}

export function getTransfers(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_TRANSFERS });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/Transfer?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: "Bearer " + login.token,
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_TRANSFERS_SUCCESS,
						payload: result.model,
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params,
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_TRANSFERS_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_TRANSFERS_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}
