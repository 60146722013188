import React, { useEffect, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getGroupById } from "../Report/actions";
import ls from "../../../../Localization";
import { Access } from "../../../../types/Access";
import Report from "../../../../components/Report";
import _ from "lodash";
import { getPermissionsPerGroup } from "../PermissionsReport/actions";

let getPermissionsDebounced = null;

function Details({
	match: {
		params: { id }
	},
	history
}) {
	const dispatch = useDispatch();

	const { item } = useSelector(s => s.groups);
	const state = useSelector(s => s.permissionGroups);

	const table = useRef(null);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getPermissionsDebounced) {
			getPermissionsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate && creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate && creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getPermissionsDebounced = _.debounce(
			() =>
				dispatch(
					getPermissionsPerGroup(
						page * pageSize,
						10,
						filtered.reduce(
							(p, c) => p + `&filters[${c.id}]=${c.value}`,
							`&filters[group]=${id}`
						),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getPermissionsDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	useEffect(() => {
		dispatch(getGroupById(id));
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do grupo de permissão</h5>
								</div>

								<div>
									<Link to={`/group/${id}/edit`}>
										<Button color="primary">Editar</Button>
									</Link>
								</div>
							</Row>

							<Card>
								<Container>
									<Row className="mt-3">
										<Col xs={3}>
											<p>
												<strong>Nome:</strong>
												<br />
												<span>{item.name}</span>
											</p>
										</Col>

										<Col>
											<p>
												<strong>Descrição:</strong>
												<br />
												<span>{item.description}</span>
											</p>
										</Col>
									</Row>
								</Container>
							</Card>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Report
								manual
								tableRef={table}
								title={"Permissões"}
								data={state.items}
								pages={state.pages}
								loading={state.loading.getAll}
								onFetchData={handleFetchData}
								defaultSorted={[
									{
										id: "createdAt",
										desc: true
									}
								]}
								pageSize={
									state.items.length > 10
										? 10
										: state.items.length <= 0
										? 1
										: state.items.length + 1
								}
								columns={[
									{
										Header: "Nome",
										id: "name",
										width: 250,
										accessor: "permission.name"
									},
									{
										Header: "Usuário",
										id: "user",
										accessor: c => ls[Access[c.access[0]]]
									},
									{
										Header: "Empresa",
										id: "group",
										accessor: c => ls[Access[c.access[1]]]
									},
									{
										Header: "Geral",
										id: "all",
										accessor: c => ls[Access[c.access[2]]]
									}
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Details;
