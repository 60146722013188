import * as actions from "./actions";

const initialState = {
	notes: [],
	loading: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_COMPANY_NOTES: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_COMPANY_NOTES_SUCCESS: {
			return {
				...state,
				notes: payload,
				loading: { get: false }
			};
		}
		case actions.GET_COMPANY_NOTES_ERROR: {
			return {
				...state,
				notes: payload,
				loading: { get: false }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
