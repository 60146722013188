import React, { useRef, useState } from "react";
import {
	Badge,
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
	UncontrolledTooltip
} from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import ls from "Localization";
import { stringToMask } from "helpers/string";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import { companySituationConst } from "types/companySituation";
import { FaRegFileExcel } from "react-icons/fa";
import * as api from "../../../../services/api/axios";
import ExportExcelForm from "containers/Company/components/ExportExcelForm";
import ExportExcel from "helpers/exportExcel";
import { PhaseBadge } from "../Page/Detail/OnBoard/Signature/phaseBadge";
import { onboardPhase } from "types/onboardPhase";
import insuranceStatus from "types/insuranceStatus";

dayjs.extend(customDateFormat);

let getCompaniesDebounced = null;

function Company() {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.company);
	const { token } = useSelector(s => s.login);

	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () => setModalExportExcel(!modalExportExcel);

	const [progress, setProgress] = useState(0);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getCompaniesDebounced) {
			getCompaniesDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getCompaniesDebounced = _.debounce(
			() =>
				dispatch(
					actions.getAllHirers(
						page * pageSize,
						pageSize,
						`&filters[isHired]=true${filtered.reduce(
							(p, c) => p + `&filters[${c.id}]=${c.value}`,
							""
						)}`,
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getCompaniesDebounced();
	};

	const statusCnpj = status => {
		if (status == "ATIVO") {
			return (
				<Badge className="w-100" color="primary">
					{status}
				</Badge>
			);
		} else if (status == "BAIXADA") {
			return (
				<Badge className="w-100" color="warning">
					{status}
				</Badge>
			);
		} else if (status == "INAPTA") {
			return (
				<Badge className="w-100" color="danger">
					{status}
				</Badge>
			);
		} else {
			return (
				<Badge className="w-100" color="secondary">
					{"PENDENTE"}
				</Badge>
			);
		}
	};

	const statusActive = status => {
		if (status) {
			return (
				<Badge className="w-100" color="success">
					ATIVO
				</Badge>
			);
		} else if (!status) {
			return (
				<Badge className="w-100" color="warning">
					INATIVO
				</Badge>
			);
		} else {
			return (
				<Badge className="w-100" color="secondary">
					{"PENDENTE"}
				</Badge>
			);
		}
	};

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				Cliente: item.companyGroup
					? item.companyGroup.businessName
					: item.businessName,
				"Quantidade de Empresa do Cliente": item.companyGroup
					? item.companyGroup.countCompanies
					: 1,
				"Razão Social": item.businessName,
				"Nome Fantasia": item.socialName,
				CNPJ: item.cnpj,
				Situação: item.situation,
				Fase:
					item.onboard && item.onboard.phase === 1
						? "Onboard"
						: item.onboard && item.onboard.phase === 2
						? "Ongoing"
						: "Não Informado",
				"Contratante Ativo": item.isActive ? "SIM" : "NAO",
				"Prestadores Contratados":
					item.onboard && item.onboard.purchasedProviders
						? item.onboard.purchasedProviders
						: 0,
				"Pipefy ID": item.pipefyId || "",
				Vendedor:
					item.onboard && item.onboard.salerName ? item.onboard.salerName : "",
				"Gerente de Contas":
					item.onboard && item.onboard.accountManagerName
						? item.onboard.accountManagerName
						: "",
				"Atendente Onboard":
					item.onboardAttendant && item.onboardAttendant.name
						? item.onboardAttendant.name
						: "",
				"Atendente Ongoing":
					item.ongoingdAttendant && item.ongoingdAttendant.name
						? item.ongoingdAttendant.name
						: "",
				Etapa: item.onboard && item.onboard.step ? item.onboard.step : "",
				Touch: item.onboard && item.onboard.touch ? item.onboard.touch : "",
				"Data de Contratação": item.createdAt
					? dayjs(item.createdAt).format("DD/MM/YYYY")
					: "",
				"Data de Início": item.startDate || "",
				"Capital Social": item.shareCapital,
				"Registro Estadual": item.stateRegistration,
				"Registro Municipal": item.countyRegistration,
				DIT: item?.dit ? (item.dit === "Both" ? "Ambos" : item.dit) : "",
				"DIT Status": item?.ditStatus
					? ls[insuranceStatus[item.ditStatus]]
					: "",
				"Seguro de Vida": item?.lifeInsurance
					? item.lifeInsurance === "Both"
						? "Ambos"
						: item.lifeInsurance
					: "",
				"Status Seguro de Vida": item?.lifeInsuranceStatus
					? ls[insuranceStatus[item.lifeInsuranceStatus]]
					: "",
				Operação: item.operationMode,
				Atividade: item.activity,
				"Atividades Secundárias": item.secondaryActivities
					? item.secondaryActivities?.map((acc, e) => acc + e + "; ")
					: ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = type => {
		dispatch(async () => {
			const dataTmp = [];
			const takeTmp = 500;

			for (let skipTmp = 0; skipTmp < state.count; skipTmp += takeTmp) {
				const { data } = await api.sendGet(
					"api",
					`/company?skip=${skipTmp}&take=${takeTmp}&sort=createdAt&desc=true&filters[isHired]=true`,
					token,
					"bearer",
					null
				);

				if (data.model) {
					dataTmp.push(...data.model);
					setProgress(Math.round((skipTmp / state.count) * 100));
				} else {
					console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
					break;
				}
			}

			ExportExcel(customData(dataTmp), "Lista_Contratantes", type);
			toggleModalExportExcel();
		});
	};

	const onSubmitExportExcel = data => {
		if (state.count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (data.exportExcel) {
			handleExport("xlsx");
		}

		if (data.exportCsv) {
			handleExport("csv");
		}
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Relatório de Contratantes"}
						count={state.count}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						noExport={true}
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						onRowClicked={row => {
							let win = window.open(`/companies/${row._id}`, "_blank");
							win.focus();
						}}
						headerRightComponent={
							<div>
								<Button
									className={"mr-2"}
									outline
									onClick={toggleModalExportExcel}
									id="tooltipExcel"
								>
									<FaRegFileExcel style={{ margin: 0 }} />
								</Button>
								<UncontrolledTooltip target="tooltipExcel">
									Exportar os dados da tabela
								</UncontrolledTooltip>
							</div>
						}
						columns={[
							{
								Header: "Razão Social",
								id: "businessName",
								accessor: "businessName",
								className: "bold-text"
							},
							{
								Header: "Nome Fantasia",
								id: "socialName",
								accessor: "socialName"
							},
							{
								Header: "CNPJ",
								id: "cnpj",
								accessor: ({ cnpj }) =>
									cnpj ? stringToMask(cnpj, "##.###.###/####-##") : "--",
								width: 130
							},
							{
								Header: (
									<span>
										Situação
										<br />
										Cadastral
									</span>
								),
								id: "situation",
								accessor: c => statusCnpj(c.situation),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(companySituationConst)
												.slice(0, 3)
												.map(c => (
													<option key={c} value={c}>
														{c}
													</option>
												))}
										</select>
									);
								},
								width: 85
							},
							{
								Header: "Fase",
								id: "onboard.phase",
								accessor: ({ onboard }) => PhaseBadge(onboard?.phase),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(onboardPhase).map(c => (
												<option key={c} value={c}>
													{onboardPhase[c]}
												</option>
											))}
										</select>
									);
								},
								width: 85
							},
							{
								Header: "Data de Início",
								id: "startDate",
								accessor: "startDate",
								show: false
							},
							{
								Header: "Capital Social",
								id: "shareCapital",
								accessor: "shareCapital",
								show: false
							},
							{
								Header: "Registo Estadual",
								id: "stateRegistration",
								accessor: "stateRegistration",
								show: false
							},
							{
								Header: "Registo Municipal",
								id: "countyRegistration",
								accessor: "countyRegistration",
								show: false
							},
							{
								Header: "Operação",
								id: "operationMode",
								accessor: "operationMode",
								show: false
							},
							{
								Header: "Atividade",
								id: "activity",
								accessor: "activity",
								show: false
							},
							{
								Header: "Atividade secundárias",
								id: "secondaryActivities",
								accessor: ({ secondaryActivities }) =>
									secondaryActivities?.map((acc, e) => acc + e + "; "),
								show: false
							},
							{
								Header: (
									<span>
										Última
										<br />
										Alteração
									</span>
								),
								id: "updatedAt",
								accessor: ({ updatedAt }) =>
									updatedAt
										? dayjs(updatedAt).format(ls.dateFormatShort)
										: "--",
								width: 85
							},
							{
								Header: "Status",
								id: "isActive",
								accessor: c => statusActive(c.isActive),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{["Ativo", "Inativo"].map(c => (
												<option key={c} value={c}>
													{c}
												</option>
											))}
										</select>
									);
								},
								width: 85
							},
							{
								Header: (
									<span>
										Prestadores
										<br />
										Contratados
									</span>
								),
								id: "onboard.purchasedProviders",
								filterable: false,
								accessor: ({ onboard }) => onboard?.purchasedProviders || "",
								width: 85
							}
						]}
					/>
				</Col>
			</Row>

			<Modal isOpen={modalExportExcel} toggle={toggleModalExportExcel}>
				<ModalHeader
					toggle={toggleModalExportExcel}
					className={"card__title bold-text"}
				>
					Exportar Relatório
				</ModalHeader>

				<ModalBody className="theme-light">
					<ExportExcelForm onSubmit={onSubmitExportExcel} progress={progress} />
				</ModalBody>
			</Modal>
		</Container>
	);
}

export default Company;
