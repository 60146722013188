import styled from "styled-components";

export const InfosContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 28px;
`;

export const InfoCardsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
	height: 130px;
`;

export const BarsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	background-color: #fcfbfb;
	border: 1px solid #eeefff;
	border-radius: 8px;
	padding: 18px 20px;
`;

export const DonutChartsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 32px;
`;
