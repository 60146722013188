import * as actions from "./actions";

const initialState = {
	items: [],
	errors: {},
	loading: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.CREATE_VERSION_REPORT: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_VERSION_REPORT_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items]
			};
		}
		case actions.CREATE_VERSION_REPORT_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
