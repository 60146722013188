const validate = values => {
	const errors = {};

	if (!values.exportExcel && !values.exportCsv) {
		errors.exportExcel = "Selecione uma forma de exportação";
	}

	if (
		!values.dueDateInitDate &&
		!values.dueDateEndDate &&
		!values.receptionDateInitDate &&
		!values.receptionDateEndDate
	) {
		errors.dueDateInitDate = "Selecione uma data";
	}

	return errors;
};

export default validate;
