import React, { useEffect } from 'react';

import subscriptionStatus from 'types/subscriptionStatus';
import ls from 'Localization';
import { getClients } from 'containers/Client/containers/Report/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from 'redux/actions/plan';
import { getPaymentMethods } from 'redux/actions/paymentMethod';
import { createSubscription } from './actions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { clearValues } from 'containers/Client/containers/Report/actions';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import CreateForm from '../../components/CreateForm';

dayjs.extend(customParseFormat);
let debounceFindUser = null;

function Create({ history }) {
  const dispatch = useDispatch();
  const { items: users } = useSelector((s) => s.clients);
  const { items: plans } = useSelector((s) => s.plan);
  const { items: paymentMethods } = useSelector((s) => s.paymentMethod);

  useEffect(() => {
    dispatch(clearValues());
    dispatch(getPlans());
    dispatch(getPaymentMethods());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(
			createSubscription({
        ...data,
        installmentInterval: data.installmentInterval ? data.installmentInterval.value : undefined,
        paymentMethod: data.paymentMethod ? data.paymentMethod.value : undefined,
        plan: data.plan ? data.plan.value : undefined,
        user: data.user ? data.user.value : undefined,
        status: data.status ? data.status.value : undefined,
        firstPayment: dayjs(data.firstPayment, 'DD/MM/YYYY'),
      }, (err) => {
				if (err) {
					console.log(err);
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					history.push("/subscriptions");
				}
			})
		);
  };

  const handleChangeUser = (name) => {
    if (name.length >= 3) {
      if (debounceFindUser) debounceFindUser.cancel();

      debounceFindUser = _.debounce(() => {
        dispatch(
          getClients(
            0,
            10,
            `&filters[searchText]=${name}&filters[roles]=mei`,
            undefined,
            false,
            false,
            (err) => {
              if (err) {
                alert("Não foi possível carregar os usuários");
              }
            }
          )
        );
      }, 1000);

      debounceFindUser();
    }
  };

  const subscriptionStatusData = Object.keys(subscriptionStatus).map((c) => ({
    value: c,
    label: ls[subscriptionStatus[c]],
  }));

  return (
    <Container className="dashboard">
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Criar assinatura</h5>
              </div>
              <CreateForm
                onSubmit={onSubmit}
                subscriptionStatusData={subscriptionStatusData}
                onChangeUser={handleChangeUser}
                users={users}
                plans={plans}
                paymentMethods={paymentMethods}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Create;