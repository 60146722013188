import moment from "moment";

const validate = (values = {}) => {
	const errors = {};

	if (!values.name) errors.name = "Informe o nome";

	if (!values.email) errors.email = "Informe o e-mail";

	if (!values.phoneNumber) errors.phoneNumber = "Informe o número de telefone";

	if (!values.role) errors.role = "Selecione uma função";

	if (!values.status) errors.status = "Selecione um status";

	if (
		values.cpf &&
		(values.cpf.replace(/\D/g, "").length <= 10 ||
			values.cpf.replace(/\D/g, "").length >= 12)
	) {
		errors.cpf = "Cpf inválido";
	}

	if (
		values.birthDate &&
		moment().diff(moment(values.birthDate, "DD/MM/YYYY"), "years") < 18
	) {
		errors.birthDate = "Data menor que 18 anos";
	}

	if (values.birthDate && !/\d{2}\/\d{2}\/\d{4}/.test(values.birthDate)) {
		errors.birthDate = "Data de nascimento inválida";
	}

	return errors;
};

export default validate;
