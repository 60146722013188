import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { CPF, DATE, CNPJ, Phone } from "components/NumberFormat";
import Input from "components/ReduxForm/Input";

import renderSelectField from "shared/components/form/Select";

import validate from "./validate";

const CreateForm = ({
	handleSubmit,
	onChangeMei,
	onChangeRole,
	companies,
	reset,
	userStatusData,
	userRolesData,
	loading,
	errors,
	isInvalid,
	...props
}) => {
	// eslint-disable-next-line no-unused-vars
	const [role, setRole] = useState(0);
	const [create, setCreate] = useState(0);

	const handleCreateChange = create => {
		if (create.value == 1) {
			onChangeMei("");
		}
		setCreate(create.value);
	};

	const handleRoleChange = role => {
		onChangeRole(role.value);
		setRole(role.value);
	};

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Nome Completo*
						</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: Fulano"
								props={{ errors: errors && errors.name }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label bold-text">Telefone*</span>
						<div className="form__form-group-field">
							<Field
								name="phoneNumber"
								component={Phone}
								type="text"
								placeholder="Ex.: (00) 00000-0000"
								props={{ errors: errors && errors.phoneNumber }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Status do cliente*
						</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={userStatusData}
								props={{ errors: errors && errors.status }}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label bold-text">E-mail*</span>
						<div className="form__form-group-field">
							<Field
								name="email"
								component={Input}
								type="text"
								placeholder="Ex.: email@email.com"
								props={{ errors: errors && errors.email }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={2} xl={2} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Função/Cargo*
						</span>
						<div className="form__form-group-field">
							<Field
								name="role"
								placeholder="Selecione"
								component={renderSelectField}
								onChange={handleRoleChange}
								type="text"
								options={userRolesData}
								props={{ errors: errors && errors.role }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={2} xl={2} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Criar Nova Empresa ?
						</span>
						<div className="form__form-group-field">
							<Field
								name="create"
								placeholder="Selecione"
								component={renderSelectField}
								onChange={handleCreateChange}
								type="text"
								options={[
									{ label: "Sim", value: "0" },
									{ label: "Não", value: "1" }
								]}
								props={{ errors: errors }}
							/>
						</div>
					</Col>

					{+create === 0 && (
						<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
							<span className="form__form-group-label bold-text">{"CNPJ"}</span>
							<div className="form__form-group-field">
								<Field
									name="cnpj"
									component={CNPJ}
									type="text"
									placeholder="Ex.: 12.123.123/0001-12"
									props={{ errors: errors && errors.cpf }}
								/>
							</div>
						</Col>
					)}

					{+create === 1 && (
						<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
							<span className="form__form-group-label bold-text">Empresa</span>
							<div className="form__form-group-field">
								<Field
									onInputChange={onChangeMei}
									options={[
										{ value: "", label: "Pesquise uma empresa" },
										...companies.map(c => ({
											value: c._id,
											label: `${c.businessName}`
										}))
									]}
									name="mei"
									placeholder="Pesquise uma empresa"
									component={renderSelectField}
									type="text"
								/>
							</div>
						</Col>
					)}
				</Row>

				<Row>
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Data de Nascimento
						</span>
						<div className="form__form-group-field">
							<Field
								name="birthDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
								props={{ errors: errors && errors.birthDate }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label bold-text">CPF</span>
						<div className="form__form-group-field">
							<Field
								name="cpf"
								component={CPF}
								type="text"
								placeholder="Ex.: 123.456.789-00"
								props={{ errors: errors && errors.cpf }}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={loading.create || isInvalid}
				>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "client_create",
	validate
})(CreateForm);
