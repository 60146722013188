/* eslint-disable no-unused-vars */
import React from "react";
import validate from "./validate";

import { reduxForm, Field } from "redux-form";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import renderCheckBoxField from "shared/components/form/CheckBox";
import renderDatePickerField from "shared/components/form/DatePicker";

const ExportExcelDasForm = ({ handleSubmit, isInvalid }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">
							<Field
								label={"Exportar Excel"}
								name="exportExcel"
								component={renderCheckBoxField}
							/>
						</span>
					</Col>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">
							<Field
								label={"Exportar CSV"}
								name="exportCsv"
								component={renderCheckBoxField}
							/>
						</span>
					</Col>
				</Row>

				<span className="form__form-group-label bold-text">
					Filtrar por data de vencimento da DAS
				</span>
				<Row>
					<Col className="form__form-group">
						<span>Data inicial</span>
						<div className="form__form-group-field">
							<Field
								name="dueDateInitDate"
								component={renderDatePickerField}
								type="text"
								datePickerProps={{
									placeholderText: "Ex.: 01/1970",
									showMonthYearPicker: true,
									dateFormat: "MM/yyyy"
								}}
							/>
						</div>
					</Col>

					<Col className="form__form-group">
						<span>Data final</span>
						<div className="form__form-group-field">
							<Field
								name="dueDateEndDate"
								component={renderDatePickerField}
								type="text"
								datePickerProps={{
									placeholderText: "Ex.: 01/1970",
									showMonthYearPicker: true,
									dateFormat: "MM/yyyy"
								}}
							/>
						</div>
					</Col>
				</Row>

				<span className="form__form-group-label bold-text">
					Filtrar por data de vencimento do BOLETO
				</span>
				<Row>
					<Col className="form__form-group">
						<span>Data inicial</span>
						<div className="form__form-group-field">
							<Field
								name="receptionDateInitDate"
								component={renderDatePickerField}
								type="text"
								datePickerProps={{
									placeholderText: "Ex.: 01/1970",
									showMonthYearPicker: true,
									dateFormat: "MM/yyyy"
								}}
							/>
						</div>
					</Col>

					<Col className="form__form-group">
						<span>Data final</span>
						<div className="form__form-group-field">
							<Field
								name="receptionDateEndDate"
								component={renderDatePickerField}
								type="text"
								datePickerProps={{
									placeholderText: "Ex.: 01/1970",
									showMonthYearPicker: true,
									dateFormat: "MM/yyyy"
								}}
							/>
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar mt-5">
				<Button color="primary" type="submit" disabled={isInvalid}>
					Enviar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "export_excel_das_form",
	validate
})(ExportExcelDasForm);
