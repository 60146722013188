export const TransferStatus = {
  0: "Pending",
  1: "Done",
  2: "Failed",
  3: "Canceled"
};

export const TransferType = {
  0: "BankAccount",
  1: "P2P"
};
