import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CREATE_BENEFIT = "[BENEFIT CREATE] CREATE_BENEFIT";
export const CREATE_BENEFIT_ERROR = "[BENEFIT CREATE] CREATE_BENEFIT_ERROR";
export const CREATE_BENEFIT_SUCCESS = "[BENEFIT CREATE] CREATE_BENEFIT_SUCCESS";

export const UPLOAD_FILE = "[BENEFIT CREATE] UPLOAD_FILE";
export const UPLOAD_FILE_ERROR = "[BENEFIT CREATE] UPLOAD_FILE_ERROR";
export const UPLOAD_FILE_SUCCESS = "[BENEFIT CREATE] UPLOAD_FILE_SUCCESS";

export const SET_VALUE = "[BENEFIT CREATE] SET_VALUE";
export const CLEAR_VALUES = "[BENEFIT CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createBenefit(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_BENEFIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/benefit`, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				let result = await response.json();
				dispatch({
					type: CREATE_BENEFIT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				let result = await response.json();
				const errors = getErrors(result);

				dispatch({
					type: CREATE_BENEFIT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);
			callback && callback(error);

			return dispatch({
				type: CREATE_BENEFIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function uploadFile(id, file, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPLOAD_FILE
		});

		try {
			const { login } = getState();

			if (!id) {
				id = login.user.id;
			}

			let response = await api.sendPostFile(`/benefit/${id}/file`, file, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPLOAD_FILE_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();
				dispatch({
					type: UPLOAD_FILE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPLOAD_FILE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
