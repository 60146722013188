import React, { useState } from "react";

import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { clearValues } from "../../containers/Create/actions";
import validate from "./validate";

import {
	Button,
	ButtonToolbar,
	Spinner,
	Container,
	Row,
	Col
} from "reactstrap";
import { DATE } from "components/NumberFormat";
import Input from "components/ReduxForm/Input";
import renderSelectField from "shared/components/form/Select";
import { MONEY } from "components/NumberFormat";

const CreateForm = ({
	reset,
	handleSubmit,
	subscriptionStatusData,
	onChangeUser,
	users,
	plans,
	paymentMethods,
	change
}) => {
	const dispatch = useDispatch();
	const { loading, errors } = useSelector(s => s.subscriptionCreate);

	const [installment, setInstallment] = useState(1);

	const handlePlanChange = newPlan => {
		const plan = plans.find(c => c._id === newPlan.value);

		change("value", plan.value / 100.0);
		change("discount", plan.discount / 100.0);
		change("installment", plan.installment);
		setInstallment(plan.installment);
		change("installmentInterval", plan.installmentInterval);
	};

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Usuário</span>
						<div className="form__form-group-field">
							<Field
								onInputChange={onChangeUser}
								options={[
									...users.map(c => ({
										value: c._id,
										label: `${c.name} ${c.lastname}`
									}))
								]}
								name="user"
								placeholder="Buscar"
								component={renderSelectField}
								type="text"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Plano</span>
						<div className="form__form-group-field">
							<Field
								name="plan"
								placeholder="Selecione"
								component={renderSelectField}
								onChange={handlePlanChange}
								type="text"
								options={[
									...plans.map(c => ({
										value: c._id,
										label: c.name
									}))
								]}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Valor</span>
						<div className="form__form-group-field">
							<Field
								name="value"
								component={MONEY}
								type="text"
								placeholder="EX.: R$ 120,00"
								props={{ errors: errors.value }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Desconto</span>
						<div className="form__form-group-field">
							<Field
								name="discount"
								component={MONEY}
								type="text"
								placeholder="EX.: R$ 120,00"
								props={{ errors: errors.discount }}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Parcelas</span>
						<div className="form__form-group-field">
							<Field
								name="installment"
								component={Input}
								onChange={event => setInstallment(event.target.value)}
								type="number"
								min={1}
								max={12}
								props={{ errors: errors.installment }}
							/>
						</div>
					</Col>

					{+installment > 1 && (
						<Col xs={12} sm={6} md={6} xl={3} className="form__form-group">
							<span className="form__form-group-label">
								Intervalo de cobrança
							</span>
							<div className="form__form-group-field">
								<Field
									name="installmentInterval"
									placeholder="Selecione"
									component={renderSelectField}
									type="text"
									options={[
										{
											value: "15",
											label: "15 dias"
										},
										{
											value: "30",
											label: "30 dias"
										}
									]}
								/>
							</div>
						</Col>
					)}

					<Col xs={12} sm={12} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Primeiro pagamento</span>
						<div className="form__form-group-field">
							<Field
								name="firstPayment"
								component={DATE}
								type="text"
								placeholder="EX.: 10/10/2020"
								props={{ errors: errors.firstPayment }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Método de pagamento</span>
						<div className="form__form-group-field">
							<Field
								name="paymentMethod"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={[
									...paymentMethods.map(c => ({
										value: c._id,
										label: c.name
									}))
								]}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Status</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={subscriptionStatusData}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={12} xl={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={Input}
								type="text"
								props={{ errors: errors.description }}
							/>
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
				<Button
					type="button"
					onClick={() => {
						dispatch(clearValues());
						setInstallment(1);
						reset();
					}}
				>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "subscription_create",
	validate
})(CreateForm);
