import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SVGComemorativo from "containers/Company/components/SVGComemorativo/indes";

class Topbar extends PureComponent {
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
		changeSidebarVisibility: PropTypes.func.isRequired
	};

	render() {
		const {
			changeMobileSidebarVisibility,
			changeSidebarVisibility
		} = this.props;

		return (
			<div className="topbar">
				<div className="topbar__wrapper">
					<div className="topbar__left d-flex">
						<TopbarSidebarButton
							changeMobileSidebarVisibility={changeMobileSidebarVisibility}
							changeSidebarVisibility={changeSidebarVisibility}
						/>
						<Link className="topbar__logo" to="/">
							<img
								src="/img/LogoSuaMei_Branco.png"
								style={{ objectFit: "contain" }}
							/>
						</Link>

						<SVGComemorativo />
					</div>

					<div className="topbar__right">
						<TopbarProfile />
					</div>
				</div>

				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
		);
	}
}

export default Topbar;
