import React from "react";
import { Route, Switch } from "react-router-dom";

import Report from "./containers/Report";
import Create from "./containers/Page/Create";
import Details from "./containers/Page/Details";
import Edit from "./containers/Page/Edit";

import CompanyBenefitList from "./containers/CompanyBenefits/CompanyBenefitList";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/benefit" component={Report} />
				<Route exact path="/benefit/new" component={Create} />
				<Route
					exact
					path="/benefit/insured-list"
					component={CompanyBenefitList}
				/>
				<Route exact path="/benefit/:id" component={Details} />
				<Route exact path="/benefit/:id/edit" component={Edit} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
