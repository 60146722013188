import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import dayjs from "dayjs";
import ptBt from "dayjs/locale/pt-br";

import { replaceStringCurrencyToNumber } from "helpers/string";
import BenefitCreate from "../../../components/CreateForm";
import { clearValues, createBenefit, uploadFile } from "./actions";

dayjs.locale(ptBt);

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading } = useSelector(s => s.benefitCreate);
	const benefit_create = useSelector(s => s.form.benefit_create);

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	const onSubmit = data => {
		dispatch(
			createBenefit(
				{
					name: data.name,
					description: data.description,
					price: replaceStringCurrencyToNumber("R$", data.price || ""),
					discount: replaceStringCurrencyToNumber("R$", data.discount || "")
				},
				(err, item) => {
					if (err) {
						console.log(err);
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						if (data.icon) {
							dispatch(
								uploadFile(item._id, data.icon, err => {
									if (err) {
										if (typeof err === "string") alert(err);
										else alert(JSON.stringify(err));
									} else {
										history.push("/benefit");
									}
								})
							);
						} else {
							history.push("/benefit");
						}
					}
				}
			)
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<div className="card__title">
							<h4 className="bold-text">Cadastrar novo beneficio</h4>
						</div>
						<CardBody>
							<BenefitCreate
								onSubmit={onSubmit}
								loading={loading.create}
								isInvalid={benefit_create && !!benefit_create.syncErrors}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
