export const ObligationPeriods = {
	0: "Once",
	1: "Daily",
	2: "Monthly",
	3: "Quarterly",
	4: "Yearly"
};

export const ObligationStatus = {
	0: "Pending",
	1: "Done",
	2: "Due",
	3: "Canceled",
	4: "PENDENTE"
};

export const ObligationStatusDASN = {
	0: "Declarar",
	1: "Retificar",
	2: "Atrasado",
	3: "Cancelado",
	4: "Não optante"
};

export const ObligationStatusConst = {
	Pending: 0,
	Done: 1,
	Due: 2,
	Canceled: 3,
	PENDENTE: 4
};
