import React from "react";
import { Card, Row, Col, Container, Button } from "reactstrap";
import dayjs from "dayjs";
import ls from "Localization";

import { toStringCurrency } from "helpers/string";

const DetailsTax = ({ tax }) => {
	const renderValue = value => (value ? value : "Não definido");

	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{tax.createdAt
												? dayjs(tax.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6}>
									<p>
										<strong>Empresa: </strong>
										<br />
										<span>
											{tax.mei
												? tax.mei.businessName
												: tax.user
												? `${tax.user.name} ${tax.user.lastname}`
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data de vencimento: </strong>
										<br />
										<span>
											{tax.dueDate
												? dayjs(tax.dueDate).format(ls.dateFormatWithoutSeconds)
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data da última atualização: </strong>
										<br />
										<span>
											{tax.updatedAt
												? dayjs(tax.updatedAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Valor:</strong>
										<br />
										<span>
											{renderValue(toStringCurrency((tax.value || 0) / 100))}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Multa:</strong>
										<br />
										<span>
											{renderValue(toStringCurrency((tax.fine || 0) / 100))}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Juros: </strong>
										<br />
										<span>
											{renderValue(toStringCurrency((tax.interest || 0) / 100))}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Total: </strong>
										<br />
										<span>
											{renderValue(toStringCurrency((tax.total || 0) / 100))}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nº do documento:</strong>
										<br />
										<span>{renderValue(tax.documentNumber)}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Código de barras do boleto:</strong>
										<br />
										<span>{renderValue(tax.barCode)}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data de vencimento do boleto: </strong>
										<br />
										<span>
											{tax.bankBilletDueDate
												? dayjs(tax.bankBilletDueDate).format(
														ls.dateFormatWithoutSeconds
												  )
												: "Não definido"}
										</span>
									</p>
								</Col>
								{tax.link && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
										<Button
											href={tax.link}
											target="_blank"
											without
											rel="noreferrer"
											// size="sm"
											color="primary"
										>
											Visualizar boleto
										</Button>
									</Col>
								)}
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailsTax;
