import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";

import { Badge, Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { MdOpenInNew } from "react-icons/md";
import { AiOutlineReload } from "react-icons/ai";
import ls from "Localization";
import onboardHirer from "types/onboardHirer";
import * as api from "services/api";

dayjs.extend(customDateFormat);

const AdminCompany = ({ history, companyCnpj, companyId }) => {
	const dispatch = useDispatch();

	const { token } = useSelector(c => c.login);

	const [adminUsers, setAdminUsers] = useState([]);
	const [onboard, setOnboard] = useState({});
	const [loading, setLoading] = useState(false);

	const createUpdateOnboard = () => {
		dispatch(actions.createUpdateOnboard(companyCnpj));
		history.go(0);
	};

	const userBadge = id => {
		return (
			<Badge
				style={{
					cursor: "pointer"
				}}
				color={"primary"}
				target={"_blank"}
				onClick={() => {
					let win = window.open(`/client/${id}`, "_blank");
					win.focus();
				}}
				className={"w-100"}
			>
				Usuário <MdOpenInNew size={16} />
			</Badge>
		);
	};

	async function getAdminUsers() {
		setLoading(true);
		try {
			const response = await api.sendGet(
				`/v2/onboard/admin-users/${companyId}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setAdminUsers(result?.model?.adminUsers);
				setOnboard(result?.model?.onboardInfo[0]);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}

	useEffect(() => {
		getAdminUsers();
	}, []);

	return (
		<Container className="dashboard">
			<Row className="">
				<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
					<h4>
						<strong>Usuários Administradores</strong>
					</h4>
				</Col>

				<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
					<Badge
						style={{
							cursor: "pointer"
						}}
						color={"primary"}
						onClick={createUpdateOnboard}
					>
						<AiOutlineReload /> Reload
					</Badge>
				</Col>
			</Row>
			<Row>
				<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
					<p>
						<strong>Inicio do Processo: </strong>
						<br />
						<span>
							{dayjs(onboard?.createdAt).format(ls.dateFormatWithoutSeconds)}
						</span>
					</p>
				</Col>

				<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
					<p>
						<strong>Status: </strong>
						<br />
						<span>
							<Badge color={"primary"}>{onboardHirer[onboard?.status]}</Badge>
						</span>
					</p>
				</Col>
			</Row>
			<Row>
				<Col className="mt-3" xs={12} sm={12} md={8} xl={6}>
					<ReportTable
						manual
						noExport={true}
						title={"Usuários Admin"}
						data={adminUsers}
						loading={loading}
						pageSize={adminUsers?.length}
						count={adminUsers?.length}
						showPagination={false}
						defaultSorted={[
							{
								id: "status",
								desc: false
							}
						]}
						columns={[
							{
								Header: "Name",
								filterable: false,
								id: "name",
								accessor: c => (c?.name ? c?.name : "--")
							},
							{
								Header: "Email",
								filterable: false,
								id: "email",
								accessor: c => (c?.email ? c?.email : "--")
							},
							{
								Header: "Ação",
								filterable: false,
								id: "_id",
								accessor: c => (c?._id ? userBadge(c?._id) : "--"),
								width: 80
							},
							{
								Header: "Status",
								id: "userStatus",
								accessor: ({ userStatus }) => {
									if (userStatus == 1) {
										return (
											<Badge color={"success"} className={"w-100"}>
												Ativo
											</Badge>
										);
									} else if (userStatus == 0) {
										return (
											<Badge color={"danger"} className={"w-100"}>
												Inativo
											</Badge>
										);
									} else {
										return "PENDENTE";
									}
								},
								width: 80
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default AdminCompany;
