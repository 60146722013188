import React from "react";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { SubmissionError } from "redux-form";
import { useDispatch } from "react-redux";
import { submitRecoverPassword } from "redux/actions/login";

const ForgotPassword = ({ history }) => {
	const dispatch = useDispatch();

	const onSubmit = data => {
		dispatch(
			submitRecoverPassword(data.email, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					history.push("/Login");
				}
			})
		);
	};

	return (
		<div className="account">
			<div className="account__wrapper">
				<div className="account__card">
					<div className="account__head">
						<h3 className="account__title">
							<span className="account__logo">
								SUA<span className="account__logo-accent">MEI</span>
							</span>
						</h3>
						<h4 className="account__subhead subhead">
							Informe seu e-mail para recuperar a senha
						</h4>
					</div>
					<ForgotPasswordForm onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
