import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CREATE_COMPANY = "[COMPANY CREATE] CREATE_COMPANY";
export const CREATE_COMPANY_ERROR = "[COMPANY CREATE] CREATE_COMPANY_ERROR";
export const CREATE_COMPANY_SUCCESS = "[COMPANY CREATE] CREATE_COMPANY_SUCCESS";

export const UPLOAD_BUSINESS_LICENSE_FILE =
	"[CREATE_COMPANY] UPLOAD_BUSINESS_LICENSE_FILE";
export const UPLOAD_BUSINESS_LICENSE_FILE_ERROR =
	"[CREATE_COMPANY] UPLOAD_BUSINESS_LICENSE_FILE_ERROR";
export const UPLOAD_BUSINESS_LICENSE_FILE_SUCCESS =
	"[CREATE_COMPANY] UPLOAD_BUSINESS_LICENSE_FILE_SUCCESS";

export const SET_VALUE = "[COMPANY CREATE] SET_VALUE";
export const CLEAR_VALUES = "[COMPANY CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createCompany(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_COMPANY
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/company`, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				let result = await response.json();
				dispatch({
					type: CREATE_COMPANY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				let result = await response.json();
				const errors = getErrors(result);

				dispatch({
					type: CREATE_COMPANY_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);
			callback && callback(error);

			return dispatch({
				type: CREATE_COMPANY_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function uploadBusinessLicenseFile(id, file, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPLOAD_BUSINESS_LICENSE_FILE
		});

		try {
			const { login } = getState();

			if (!id) {
				id = login.user.id;
			}

			let response = await api.sendPostFile(
				`/company/license/${id}/file`,
				file,
				{
					Authorization: "Bearer " + login.token
				}
			);

			if (response.ok) {
				dispatch({
					type: UPLOAD_BUSINESS_LICENSE_FILE_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();
				dispatch({
					type: UPLOAD_BUSINESS_LICENSE_FILE_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPLOAD_BUSINESS_LICENSE_FILE_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
