const validate = values => {
	const errors = {};

	console.log(values);
	if (!values.email) {
		errors.email = "Informe seu e-mail";
	} else if (
		!new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$", "i").test(
			values.email
		)
	) {
		errors.email = "Informe um e-mail válido";
	}

	return errors;
};

export default validate;
