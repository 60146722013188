import React, { useEffect } from "react";
import ls from "Localization";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import dayjs from "dayjs";
import ptBt from "dayjs/locale/pt-br";

import { dasStatus } from "types/dasStatus";
import { replaceStringCurrencyToNumber } from "helpers/string";
import TaxCreate from "../../../components/CreateForm/TaxCreate";
import { clearValues, createTax, uploadTaxBillet } from "./actions";
import { getCompanies } from "containers/Company/containers/Report/actions";

import _ from "lodash";

dayjs.locale(ptBt);

let getCompaniesDebounced = null;

function Create({ history }) {
	const dispatch = useDispatch();
	const { loading } = useSelector(s => s.taxCreate);
	const companyState = useSelector(s => s.company);
	const tax_create = useSelector(s => s.form.tax_create);

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	const companiesData = (companyState.items || []).map(c => ({
		value: c._id,
		label: c.businessName
	}));

	const dasStatusData = Object.keys(dasStatus).map(c => ({
		value: c,
		label: ls[dasStatus[c]]
	}));

	const onSubmit = data => {
		dispatch(
			createTax(
				{
					value: replaceStringCurrencyToNumber("R$", data.value || ""),
					fine: replaceStringCurrencyToNumber("R$", data.fine || ""),
					interest: replaceStringCurrencyToNumber("R$", data.interest || ""),
					dueDate: data.dueDate,
					bankBilletDueDate: data.bankBilletDueDate,
					documentNumber: data.documentNumber,
					barCode: data.barCode,
					status: data.status.value,
					periodDate: data.periodDate,
					total:
						replaceStringCurrencyToNumber("R$", data.value || "") +
						replaceStringCurrencyToNumber("R$", data.fine || "") +
						replaceStringCurrencyToNumber("R$", data.interest || ""),
					period: dayjs(data.periodDate).format("MMMM/YYYY"),
					mei: data.company.value
				},
				(err, model) => {
					if (err) {
						console.log(err);
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						if (data.billetFile) {
							dispatch(
								uploadTaxBillet(model._id, data.billetFile, err => {
									if (err) {
										if (typeof err === "string") {
											alert(err);
										} else {
											alert(JSON.stringify(err));
										}
									} else {
										history.push("/tax");
									}
								})
							);
						} else {
							history.push("/tax");
						}
					}
				}
			)
		);
	};

	const onCompanyChange = name => {
		if (name.length >= 3) {
			if (getCompaniesDebounced) {
				getCompaniesDebounced.cancel();
			}

			getCompaniesDebounced = _.debounce(() => {
				dispatch(
					getCompanies(
						0,
						10,
						`&filters[searchText]=${name}&filters[hasMei]=true`,
						null,
						null,
						false,
						err => {
							if (err) {
								alert("Não foi possível carregar as empresas");
							}
						}
					)
				);
			}, 500);

			getCompaniesDebounced();
		}
	};

	const initialValues = {
		status: dasStatusData[0]
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<div className="card__title">
							<h4 className="bold-text">Cadastrar novo imposto</h4>
						</div>
						<CardBody>
							<TaxCreate
								initialValues={initialValues}
								onSubmit={onSubmit}
								loading={loading.create}
								onCompanyChange={onCompanyChange}
								companiesData={companiesData}
								isInvalid={tax_create && !!tax_create.syncErrors}
								dasStatusData={dasStatusData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
