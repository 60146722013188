import { stringToMask } from "helpers/string";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";

const AddCompanies = ({ DisplayIcon, providers, onChange, handleSubmit }) => {
	const { item: user } = useSelector(state => state.clientDetail);
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const createCompanies = () => {
		toggle();
		handleSubmit();
	};

	return (
		<div>
			<Button Button className="mr-2" outline onClick={toggle}>
				{DisplayIcon}
			</Button>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Adicionar empresa</ModalHeader>
				<ModalBody>
					<div className="form__form-group-field">
						<Field
							name="provider"
							component={renderSelectField}
							type="text"
							onChange={onChange}
							placeholder="Pesquise uma empresa"
							onInputChange={providerSearch => onChange({ providerSearch })}
							options={[
								...providers.map(c => ({
									value: c._id,
									label: `${c?.businessName} - CNPJ: ${stringToMask(
										c?.cnpj,
										"##.###.###/####-##"
									)}`
								}))
							]}
						/>
					</div>

					{user.meis?.length > 0 && (
						<h5>
							<b>
								Já existem uma empresa vinculada a este usuário. <br />
								Recomendamos que você remova esta empresa e adicione uma nova.
								<br />
								Você tem certeza?
							</b>
						</h5>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={createCompanies}>
						Adicionar
					</Button>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default reduxForm({
	form: "create_company_provier"
})(AddCompanies);
