import Input from "components/ReduxForm/Input";

import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import renderSelectField from "shared/components/form/Select";
import { MONEY } from "components/NumberFormat";
import renderDatePickerField from "shared/components/form/DatePicker";

const TaxCreate = ({
	handleSubmit,
	loading,
	submitting,
	isInvalid,
	reset,
	dasStatusData,
	companiesData,
	onCompanyChange,
	change
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<div className="card__title border-bottom">
					<h5 className="bold-text">Identificação</h5>
				</div>
				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Empresa*</span>
						<div className="form__form-group-field">
							<Field
								onInputChange={onCompanyChange}
								name="company"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={companiesData}
							/>
						</div>
					</Col>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Período*</span>
						<div className="form__form-group-field">
							<Field
								name="periodDate"
								component={renderDatePickerField}
								type="text"
								datePickerProps={{
									showMonthYearPicker: true,
									placeholderText: "Ex.: Janeiro/2020",
									dateFormat: "MMMM/yyyy"
								}}
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Valor*</span>
						<div className="form__form-group-field">
							<Field
								name="value"
								component={MONEY}
								type="text"
								placeholder="Ex.: R$ 1.500,00"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Multa</span>
						<div className="form__form-group-field">
							<Field
								name="fine"
								component={MONEY}
								type="text"
								placeholder="Ex.: R$ 1.000,00"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Juros</span>
						<div className="form__form-group-field">
							<Field
								name="interest"
								component={MONEY}
								type="text"
								placeholder="Ex.: R$ 1.000,00"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Data de vencimento</span>
						<div className="form__form-group-field">
							<Field
								name="dueDate"
								component={renderDatePickerField}
								type="text"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Data de vencimento do boleto
						</span>
						<div className="form__form-group-field">
							<Field
								name="bankBilletDueDate"
								component={renderDatePickerField}
								type="text"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Número do documento</span>
						<div className="form__form-group-field">
							<Field
								name="documentNumber"
								component={Input}
								type="text"
								placeholder="Ex.:123"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Código de barras</span>
						<div className="form__form-group-field">
							<Field
								name="barCode"
								component={Input}
								type="text"
								placeholder="Ex.:123"
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Status do imposto</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={dasStatusData}
							/>
						</div>
					</Col>
					<Col xs={12} md={4} lg={3}>
						<h6 className="bold-text">Arquivo PDF do boleto</h6>
						<Input
							accept="application/pdf"
							type="file"
							name="billetFile"
							onChange={f => change("billetFile", f.target.files[0])}
						/>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading}
				>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "tax_create",
	validate
})(TaxCreate);
