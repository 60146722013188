import React from "react";

import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { useSelector } from "react-redux";
import validate from "./validate";

import {
	Button,
	ButtonToolbar,
	Spinner,
	Container,
	Row,
	Col
} from "reactstrap";
import Input from "components/ReduxForm/Input";
import renderSelectField from "shared/components/form/Select";
import { MONEY } from "components/NumberFormat";

const EditForm = ({
	handleSubmit,
	subscriptionStatusData,
	onChangeUser,
	users
}) => {
	const { loading, errors } = useSelector(s => s.subscriptionEdit);

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={6} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Usuário</span>
						<div className="form__form-group-field">
							<Field
								onInputChange={onChangeUser}
								options={[
									...users.map(c => ({
										value: c._id,
										label: `${c.name} ${c.lastname}`
									}))
								]}
								name="user"
								placeholder="Buscar"
								component={renderSelectField}
								type="text"
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Valor</span>
						<div className="form__form-group-field">
							<Field
								name="value"
								component={MONEY}
								type="text"
								placeholder="EX.: R$ 120,00"
								props={{ errors: errors.value }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={6} xl={3} className="form__form-group">
						<span className="form__form-group-label">Status</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={subscriptionStatusData}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={12} xl={12} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={Input}
								type="text"
								props={{ errors: errors.description }}
							/>
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading}>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "subscription_edit",
	validate
})(EditForm);
