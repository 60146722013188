import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_CONTRACT = "[CONTRACT] GET_CONTRACT";
export const GET_CONTRACT_SUCCESS = "[CONTRACT] GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILED = "[CONTRACT] GET_CONTRACT_FAILED";

export const SET_VALUE = "[CONTRACT] SET_VALUE";
export const CLEAR_VALUES = "[CONTRACT] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getContracts(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback,
	companyId
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONTRACT });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/v2/contracts?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}&company=${companyId}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_CONTRACT_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CONTRACT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CONTRACT_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
