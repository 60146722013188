const validate = values => {
	const errors = {};

	if (!values.benefit) {
		errors.benefit = "Selecione o beneficio";
	}

	return errors;
};

export default validate;
