const validate = (values = {}) => {
	const errors = {};

	if (!values.mei) errors.mei = "Informe o contratante";
	if (!values.hired) errors.hired = "Informe o prestador";

	if (!values.name) errors.name = "Informe o nome do contrato";
	if (!values.type) errors.type = "Informe o tipo do contrato";
	if (!values.department)
		errors.department = "Informe o departamento do contrato";

	if (!values.startDate) errors.startDate = "Informe a data de inicio";

	if (values.selfRenewable) {
		if (!values.lifespan)
			errors.lifespan = "Informe a duração da vigência (em meses)";
	} else {
		if (!values.due) errors.due = "Informe data de fim do contrato";
	}

	return errors;
};

export default validate;
