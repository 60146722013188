import * as actions from "./actions";

const initialState = {
	loading: {},
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.CREATE_CLIENT: {
			return {
				...state,
				loading: { create: true }
			};
		}
		case actions.CREATE_CLIENT_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				errors: {}
			};
		}
		case actions.CREATE_CLIENT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { create: false }
			};
		}
		case actions.UPLOAD_CLIENT_AVATAR: {
			return {
				...state,
				loading: { avatar: true }
			};
		}
		case actions.UPLOAD_CLIENT_AVATAR_SUCCESS: {
			return {
				...state,
				loading: { avatar: false }
			};
		}
		case actions.UPLOAD_CLIENT_AVATAR_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { avatar: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
