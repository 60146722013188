import React, { useRef } from "react";
import ls from "Localization";
import dayjs from "dayjs";
import { dateSort } from "components/Report/helpers";
import { toStringCurrency } from "helpers/string";
import Report from "components/Report";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { getSubscriptions } from "containers/Subscription/containers/Report/actions";
import subscriptionStatus from "types/subscriptionStatus";

let getSubscriptionsDebounded = null;

function Subscriptions() {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		params: { id },
	} = useRouteMatch();

	const table = useRef(null);

	const handleFetchData = (tableState, instance) => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getSubscriptionsDebounded) {
			getSubscriptionsDebounded.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find((c) => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter((c) => c.id !== "creationDate");

			if (creationDate && creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate,
				});
			}

			if (creationDate && creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate,
				});
		}

		getSubscriptionsDebounded = _.debounce(
			() =>
				dispatch(
					getSubscriptions(
						page * pageSize,
						pageSize,
						filtered.reduce(
							(p, c) => p + `&filters[${c.id}]=${c.value}`,
							`&filters[user]=${id}`
						),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getSubscriptionsDebounded();
	};

	const state = useSelector((s) => s.subscription);

	return (
		<Report
			manual
			tableRef={table}
			title={ls.subscriptions}
			data={state.items}
			pages={state.pages}
			loading={state.loading.getAll}
			onFetchData={handleFetchData}
			pageSize={
				state.items.length > 10
					? 10
					: state.items.length <= 0
					? 1
					: state.items.length
			}
			filterable
			defaultSorted={[
				{
					id: "createdAt",
					desc: true,
				},
			]}
			onRowClicked={(data) => {}
				// history.push(`/Dashboard/Subscriptions/${data._id}`)
			}
			columns={[
					{
						Header: ls.creationDate,
						id: "createdAt",
						accessor: (c) =>
							dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds),
						show: false,
						sortMethod: dateSort,
						width: 150,
					},
					{
						Header: ls.plan,
						id: "plan",
						show: true,
						accessor: (c) => c.plan ? c.plan.name : undefined,
						width: 150,
					},
					{
						Header: ls.paymentMethod,
						id: "paymentMethod",
						show: true,
						accessor: (c) => c.paymentMethod ? c.paymentMethod.name : undefined,
						width: 180,
					},
					{
						Header: ls.value,
						id: "value",
						show: true,
						filterable: false,
						accessor: (c) => toStringCurrency(+c.value / 100),
						width: 150,
					},
					{
						Header: ls.discount,
						id: "discount",
						show: true,
						filterable: false,
						accessor: (c) => toStringCurrency(+c.discount || 0 / 100),
						width: 150,
					},
					{
						Header: ls.installment,
						id: "installment",
						show: true,
						filterable: false,
						accessor: (c) => c.installment,
						width: 150,
					},
					{
						Header: ls.description,
						accessor: "description",
						show: true,
					},
					{
						Header: 'Status',
						id: "status",
						show: true,
						accessor: (c) => ls[subscriptionStatus[c.status]],
						width: 140,
					},
				]}
		/>
	);
}

export default Subscriptions;
