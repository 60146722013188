import dayjs from "dayjs";
import { stringToMask } from "helpers/string";
import ls from "Localization";
import React from "react";
import { MdOpenInNew } from "react-icons/md";
import { Badge, Col, Row } from "reactstrap";
import {
	MeiStatus,
	UserGender,
	UserMaritalStats,
	UserStatus
} from "types/userStatus";

const ProviderDetails = ({ companyProvider }) => {
	const userBadge = providerUser => {
		return (
			<>
				<Badge
					style={{
						cursor: "pointer"
					}}
					color={"primary"}
					target={"_blank"}
					onClick={() => {
						let win = window.open(`/client/${providerUser._id}`, "_blank");
						win.focus();
					}}
					className={"mr-1"}
				>
					<MdOpenInNew size={16} />
				</Badge>
				{`${providerUser?.name} ${providerUser?.lastname || ""}`}
			</>
		);
	};

	return (
		<>
			<h5 className="bold-text card__title">Detalhes do Usuário</h5>

			<Row>
				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Nome: </strong>
						<br />
						<span>{userBadge(companyProvider.providerUser)}</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>CPF: </strong>
						<br />
						<span>
							{companyProvider.providerUser?.cpf
								? stringToMask(
										companyProvider.providerUser?.cpf,
										"###.###.###-##"
								  )
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>{ls.birthDate}: </strong>
						<br />
						<span>
							{companyProvider.providerUser?.birthDate
								? dayjs(companyProvider.providerUser?.birthDate).format(
										ls.dateFormatShort
								  )
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Email: </strong>
						<br />
						<span>
							{companyProvider.providerUser?.email
								? companyProvider.providerUser?.email
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Genêro</strong>
						<br />
						<span>
							{UserGender[companyProvider.providerUser?.gender]
								? ls[UserGender[companyProvider.providerUser?.gender]]
								: ""}
						</span>
					</p>
				</Col>
				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Estado Civil</strong>
						<br />
						<span>
							{UserMaritalStats[companyProvider.providerUser?.maritalStatus]
								? ls[
										UserMaritalStats[
											companyProvider.providerUser?.maritalStatus
										]
								  ]
								: ""}
						</span>
					</p>
				</Col>
				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>{ls.userStatus}: </strong>
						<br />
						<span>
							{companyProvider.providerUser?.userStatus !== undefined
								? ls[UserStatus[companyProvider.providerUser?.userStatus]]
								: ""}
						</span>
					</p>
				</Col>
				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>{ls.meiStatus}: </strong>
						<br />
						<span>
							{companyProvider.providerUser?.status !== undefined
								? ls[MeiStatus[companyProvider.providerUser?.status]]
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Data criação: </strong>
						<br />
						<span>
							{companyProvider.providerUser?.createdAt
								? dayjs(companyProvider.providerUser?.createdAt).format(
										ls.dateFormatWithoutSeconds
								  )
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Criado por: </strong>
						<br />
						{companyProvider.providerUser?.createdBy ? (
							<a
								href={`/client/${companyProvider.providerUser?.createdBy?._id}`}
								target="_blank"
								without
								rel="noreferrer"
							>
								{companyProvider.providerUser?.createdBy?.name}
							</a>
						) : (
							""
						)}
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Data Atualização: </strong>
						<br />
						<span>
							{companyProvider.providerUser?.updatedAt
								? dayjs(companyProvider.providerUser?.updatedAt).format(
										ls.dateFormatWithoutSeconds
								  )
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Atualizado por: </strong>
						<br />
						{companyProvider.providerUser?.updatedBy ? (
							<a
								href={`/client/${companyProvider.providerUser?.updatedBy?._id}`}
								target="_blank"
								without
								rel="noreferrer"
							>
								{companyProvider.providerUser?.updatedBy?.name}
							</a>
						) : (
							""
						)}
					</p>
				</Col>
			</Row>
		</>
	);
};

export default ProviderDetails;
