import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CREATE_CONTRACT = "[CREATE_CONTRACT] CREATE_CONTRACT";
export const CREATE_CONTRACT_ERROR = "[CREATE_CONTRACT] CREATE_CONTRACT_ERROR";
export const CREATE_CONTRACT_SUCCESS =
	"[CREATE_CONTRACT] CREATE_CONTRACT_SUCCESS";

export const SET_VALUE = "[CREATE_CONTRACT] SET_VALUE";
export const CLEAR_VALUES = "[CREATE_CONTRACT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createContract(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_CONTRACT
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/contracts/admin`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CONTRACT_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result);

				dispatch({
					type: CREATE_CONTRACT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: CREATE_CONTRACT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
