import React, { createRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	Button,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledTooltip
} from "reactstrap";
import { FaRegFileExcel } from "react-icons/fa";
import dayjs from "dayjs";

import * as api from "services/api";

import ls from "Localization";

import Report from "components/Report";

import ExportExcelForm from "containers/Company/components/ExportExcelForm";

import ExportExcel from "helpers/exportExcel";

import { onboardPhase } from "types/onboardPhase";

const PipefyHistoryTable = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [tableData, setTableData] = useState();
	const [count, setCount] = useState(0);
	const [pages, setPages] = useState(0);
	const [modalExportExcel, setModalExportExcel] = useState(false);
	const [progress, setProgress] = useState(0);
	const [tableRef] = useState();

	const { token } = useSelector(s => s.login);
	const { item: company } = useSelector(s => s.companyDetail);

	const toggleModalExportExcel = () =>
		setModalExportExcel(prevState => !prevState);

	const customData = data => {
		let rows = [];
		for (let item of data) {
			rows.push({
				"Data de Solicitação": item.createdAt
					? dayjs(item.createdAt).format("YYYY-MM-DD")
					: "",
				"Última Atualização": item.updatedAt
					? dayjs(item.updatedAt).format("YYYY-MM-DD")
					: "",
				Fase: item.phase ? onboardPhase[item.phase] : "",
				Etapa: item.step ? item.step : "",
				Touch: item.touch ? item.touch : "",
				Vendedor: item.salerName ? item.salerName : "",
				"Gerente de Contas": item.accountManagerName
					? item.accountManagerName
					: ""
			});
		}
		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = async type => {
		const dataTmp = [];
		const takeTmp = 500;

		for (let skipTmp = 0; skipTmp < count; skipTmp += takeTmp) {
			var response = await api.sendGet(
				`/pipefyHistory?skip=${skipTmp}&take=${takeTmp}&sort=createdAt&desc=true&companyId=${company._id}`,
				{
					Authorization: "Bearer " + token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dataTmp.push(...result.model);
				setProgress(Math.round((skipTmp / count) * 100));
			} else {
				console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
				break;
			}
		}

		ExportExcel(customData(dataTmp), "Histórico_Pipefy", type);
		toggleModalExportExcel();
	};

	const onSubmitExportExcel = data => {
		if (count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (data.exportExcel) {
			handleExport("xlsx");
		}

		if (data.exportCsv) {
			handleExport("csv");
		}
	};

	const getPipefyHistory = async tableState => {
		try {
			if (!tableState) return;

			createRef(tableState);

			let { page, pageSize } = tableState;

			setIsLoading(true);
			setCount(0);

			const response = await api.sendGet(
				`/pipefyHistory?skip=${pageSize * page}&take=${pageSize}&companyId=${
					company._id
				}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setTableData(result.model);
				setCount(result.params.count);
				setPages(Math.ceil(result.count / pageSize));
			} else {
				setTableData([]);
			}
		} catch (error) {
			console.log(error);
			setTableData([]);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Container>
			<Report
				manual
				tableRef={tableRef}
				title={"Histórico de dados do Pipefy"}
				data={tableData}
				count={count}
				pages={pages}
				defaultPageSize={5}
				loading={isLoading}
				filterable={false}
				sortable={false}
				noExport={true}
				onFetchData={getPipefyHistory}
				headerRightComponent={
					<div>
						<Button
							className={"mr-2"}
							outline
							onClick={toggleModalExportExcel}
							id="tooltipExcel"
						>
							<FaRegFileExcel style={{ margin: 0 }} />
						</Button>
						<UncontrolledTooltip target="tooltipExcel">
							Exportar os dados da tabela
						</UncontrolledTooltip>
					</div>
				}
				columns={[
					{
						Header: "Data criação",
						id: "createdAt",
						accessor: c =>
							c.createdAt
								? dayjs(c.createdAt).format(ls.dateFormatShort)
								: "--",
						width: 85
					},
					{
						Header: "Fase",
						id: "phase",
						accessor: c => (c.phase ? onboardPhase[c.phase] : "--"),
						width: 100
					},
					{
						Header: "Etapa",
						id: "step",
						accessor: c => c.step || "--",
						width: 150
					},
					{
						Header: "Touch",
						id: "touch",
						accessor: c => c.touch || "--",
						width: 100
					},
					{
						Header: "Vendedor",
						id: "salerName",
						accessor: c => c.salerName || "--",
						width: 160
					},
					{
						Header: "Gerente de Contas",
						id: "accountManagerName",
						accessor: c => c.accountManagerName || "--",
						width: 160
					}
				]}
			/>

			<Modal isOpen={modalExportExcel} toggle={toggleModalExportExcel}>
				<ModalHeader
					toggle={toggleModalExportExcel}
					className={"card__title bold-text"}
				>
					Exportar Relatório
				</ModalHeader>
				<ModalBody className="theme-light">
					<ExportExcelForm onSubmit={onSubmitExportExcel} progress={progress} />
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default PipefyHistoryTable;
