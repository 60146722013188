import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	Row
} from "reactstrap";
import * as actions from "./actions";
import ContactCompanyEdit from "../../../components/ContacCompanyEdit/ContactCompanyEdit";
import { stringToMask } from "helpers/string";
import { AiOutlineReload } from "react-icons/ai";
import phoneNumber from "helpers/phoneNumber";
import { toast } from "react-toastify";

dayjs.extend(customDateFormat);

let contactCompanyDebounced = null;

function ContactInCompany({ companyId }) {
	const dispatch = useDispatch();

	const { contactCompany, show } = useSelector(c => c.contactCompanyDetail);
	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const getContacCompany = () => {
		if (contactCompanyDebounced) contactCompanyDebounced.cancel();

		// eslint-disable-next-line no-undef
		contactCompanyDebounced = _.debounce(() => {
			dispatch(actions.getContactCompanys(companyId));
		});

		contactCompanyDebounced();
	};

	const onSubmit = async data => {
		toggle();
		dispatch(
			actions.updateContactCompany(
				{
					...contactCompany,
					...data
				},
				error => {
					if (error) {
						toast.error(
							"Houve um erro inesperado ao atualizar o contato financeiro!"
						);

						console.log(error);
						return;
					}

					toast.success("Contato Financeiro atualizado com sucesso!");
					getContacCompany();
				}
			)
		);
	};

	const handleEmptyContact = () => {
		dispatch(actions.createContactCompanyByMei({ mei: companyId }));
	};

	const initialValues = {
		email: contactCompany.email ? contactCompany.email : null,
		phone: contactCompany.phone ? contactCompany.phone : null,
		contractSignerEmail: contactCompany.contractSignerEmail
			? contactCompany.contractSignerEmail
			: null
	};

	useEffect(() => {
		dispatch(actions.clearValues());
		getContacCompany();
	}, []);

	const datailForm = () => {
		return (
			<Card>
				<Container>
					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<Row className="justify-content-between align-items-center">
										<div className="card__title">
											<h5 className="bold-text">Contato Financeiro</h5>
										</div>

										<div style={{ display: "flex", flexDirection: "row" }}>
											{Object.keys(contactCompany) < 1 && (
												<div className="pr-2">
													<Button
														color={"secondary"}
														type={"submit"}
														onClick={handleEmptyContact}
													>
														<AiOutlineReload
															style={{ margin: 0, padding: 0 }}
														/>
													</Button>
												</div>
											)}

											<div>
												<Button
													color={"primary"}
													type={"submit"}
													onClick={toggle}
												>
													Editar
												</Button>
											</div>
										</div>
									</Row>
									<Row>
										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>Razão Social: </strong>
												<br />
												<span>
													{contactCompany?.mei
														? contactCompany?.mei?.businessName
														: "--"}
												</span>
											</p>
										</Col>

										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>CNPJ: </strong>
												<br />
												<span>
													{contactCompany?.mei
														? stringToMask(
																contactCompany?.mei?.cnpj,
																"##.###.###/####-##"
														  )
														: "--"}
												</span>
											</p>
										</Col>
										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>Email Financeiro: </strong>
												<br />
												<span>
													{contactCompany.email ? contactCompany.email : "--"}
												</span>
											</p>
										</Col>

										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>Email Assinatura de Contratos: </strong>
												<br />
												<span>
													{contactCompany.contractSignerEmail
														? contactCompany.contractSignerEmail
														: "--"}
												</span>
											</p>
										</Col>

										<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
											<p>
												<strong>Telefone Financeiro: </strong>
												<br />
												<span>
													{contactCompany.phone
														? phoneNumber.format(contactCompany.phone)
														: "--"}
												</span>
											</p>
										</Col>
									</Row>
									<Row>
										<Col className="mt-3" xs={12} sm={12} md={6} xl={6}>
											<p>
												<strong>Endereço: </strong>
												<br />
												<span>
													{contactCompany?.address
														? `${
																contactCompany?.address?.street
																	? contactCompany?.address?.street
																	: ""
														  }, ${
																contactCompany?.address?.number
																	? contactCompany?.address?.number
																	: ""
														  } - ${
																contactCompany?.address?.complement
																	? contactCompany?.address?.complement
																	: ""
														  } - ${
																contactCompany?.address?.neighborhood
																	? contactCompany?.address?.neighborhood
																	: ""
														  } - ${
																contactCompany?.address?.city
																	? contactCompany?.address?.city
																	: ""
														  } - ${
																contactCompany?.address?.state
																	? contactCompany?.address?.state
																	: ""
														  } - ${
																contactCompany?.address?.zipCode
																	? contactCompany?.address?.zipCode
																	: ""
														  }`
														: "--"}
												</span>
											</p>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</Card>
		);
	};

	return (
		<div>
			{show ? datailForm() : ""}
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle} className={"card__title bold-text"}>
					Contato Financeiro
				</ModalHeader>
				<ModalBody className="theme-light">
					<ContactCompanyEdit
						onSubmit={onSubmit}
						initialValues={initialValues}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default ContactInCompany;
