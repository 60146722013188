import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
	clearValues,
	deleteVersionReportItem,
	getVersionReportItem
} from "./actions";
import DetailsVersionReport from "../../components/DetailsVersionReport";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../../contexts/GlobalContext";
import GlobalModal from "../../../../components/Modal";

function Details({
	match: {
		params: { id }
	},
	history
}) {
	const { item, loading } = useSelector(state => state.versionReportDetail);
	const { showModal, hideModal } = useGlobalContext();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getVersionReportItem(id));
	}, [dispatch]);

	if (!item) return null;

	const handleDelete = () => {
		dispatch(
			deleteVersionReportItem(id, err => {
				{
					console.log("err", err);
				}
				if (err) {
					alert(String(err));
				} else {
					history.push("/version-report");
				}
				hideModal();
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do Item de Versão</h5>
								</div>

								<div>
									<Button color="danger" onClick={showModal}>
										Excluir
									</Button>

									<Link to={`/version-report/${id}/edit`}>
										<Button color="primary">Editar</Button>
									</Link>
								</div>
							</Row>

							<DetailsVersionReport item={item} />
						</CardBody>
					</Card>
				</Col>
			</Row>

			<GlobalModal title="Deletar" onOk={handleDelete} loading={loading.delete}>
				<p>
					Tem certeza que deseja excluir este imposto?
					<br />
					<strong>Essa ação é irreversível</strong>
				</p>
			</GlobalModal>
		</Container>
	);
}

export default Details;
