import React from "react";

import { InputContainer } from "./styles";

export function TextInput({ label, value, onChange, ...props }) {
	return (
		<InputContainer>
			<label htmlFor={label}>{label}</label>
			<input
				type="text"
				id={label}
				value={value}
				onChange={onChange}
				{...props}
			/>
		</InputContainer>
	);
}
