export const Access = {
	"0": "None",
	"1": "Delete",
	"2": "Write",
	"3": "DeleteWrite",
	"4": "Read",
	"5": "DeleteRead",
	"6": "ReadWrite",
	"7": "ReadWriteDelete"
};

export const AccessType = {
	None: 0,
	Delete: 1,
	Write: 2,
	DeleteWrite: 3,
	Read: 4,
	DeleteRead: 5,
	ReadWrite: 6,
	ReadWriteDelete: 7
};
