import GlobalModal from "components/Modal";
import ContractList from "containers/Contracts/components/ContractList";
import { useGlobalContext } from "contexts/GlobalContext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	Card,
	CardBody,
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row
} from "reactstrap";
import Button from "reactstrap/lib/Button";
import DetailsForm from "../../../components/DetailForm/detailRegularContractForm";
import { getContractRegularById, deleteContract } from "./actions";
import classNames from "classnames";
import ContractHistoryList from "containers/Contracts/components/ContractHistoryList";

function Details({
	match: {
		params: { id }
	},
	history
}) {
	const { showModal, hideModal } = useGlobalContext();
	const { item, loading } = useSelector(state => state.contractsDetail);
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState("Lista Contratos Vinculados");
	const toggleTabs = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const handleDelete = () => {
		dispatch(
			deleteContract(id, err => {
				console.log(err);
				if (err) {
					alert(String(err));
				} else {
					history.push("/contracts");
				}
				hideModal();
			})
		);
	};

	useEffect(() => {
		dispatch(getContractRegularById(id));
	}, [dispatch]);

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes Contrato</h5>
								</div>
								<div>
									<Button color="danger" onClick={showModal}>
										Excluir
									</Button>
									<Link to={`/contracts/${id}/edit`}>
										<Button color="primary">Editar</Button>
									</Link>
									<Link to={`/contracts/new-linked-contract/${id}`}>
										<Button color="primary ml-3">
											Novo Contrato Vinculado
										</Button>
									</Link>
								</div>
							</Row>
							<DetailsForm
								contract={{
									...item
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Nav tabs className="ml-1" style={{ cursor: "pointer" }}>
					<NavItem>
						<NavLink
							className={classNames({
								active: activeTab === "Lista Contratos Vinculados"
							})}
							onClick={() => {
								toggleTabs("Lista Contratos Vinculados");
							}}
						>
							Lista Contratos Vinculados
						</NavLink>
					</NavItem>

					<NavItem>
						<NavLink
							className={classNames({
								active: activeTab === "contractHistory"
							})}
							onClick={() => {
								toggleTabs("contractHistory");
							}}
						>
							Histórico
						</NavLink>
					</NavItem>
				</Nav>
			</Row>

			{activeTab === "Lista Contratos Vinculados" && (
				<Row>
					<Col>
						<ContractList
							title={"Lista Contratos Vinculados"}
							contractId={item._id}
							mainContractId={item.mainContract?._id}
							isMainContract={item.isMainContract}
						/>
					</Col>
				</Row>
			)}

			{activeTab == "contractHistory" && (
				<Row>
					<Col>
						<ContractHistoryList contractId={item._id} />
					</Col>
				</Row>
			)}

			<GlobalModal
				title="Deletar contrato"
				onOk={handleDelete}
				loading={loading.delete}
			>
				<p>
					Tem certeza que deseja excluir este contrato? Essa ação é irreversível
				</p>
			</GlobalModal>
		</Container>
	);
}

export default Details;
