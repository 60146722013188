import * as actions from "./actions";

const initialState = {
	items: [],
	loading: {},
	errors: {},
	showCreateModal: false
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_USER_GROUP_PERMISSIONS: {
			return {
				...state,
				loading: { getAll: true }
			};
		}
		case actions.GET_USER_GROUP_PERMISSIONS_SUCCESS: {
			return {
				...state,
				items: payload,
				loading: { getAll: false }
			};
		}
		case actions.GET_USER_GROUP_PERMISSIONS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { getAll: false }
			};
		}
		case actions.ADD_GROUPS: {
			return {
				...state,
				loading: { create: true }
			};
		}
		case actions.ADD_GROUPS_SUCCESS: {
			return {
				...state,
				loading: { create: false }
			};
		}
		case actions.ADD_GROUPS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { create: false }
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
