export const companySituationStr = {
	1: "ATIVO",
	2: "BAIXADA",
	3: "INAPTA",
	4: "EM ANDAMENTO",
	5: "PENDENTE"
};

export const companySituationConst = {
	ATIVO: 1,
	BAIXADA: 2,
	INAPTA: 3,
	"Em Andamento": 4,
	Pendente: 5
};
