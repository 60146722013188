import styled from "styled-components";

export const StatusLabelContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	padding: 4px 8px;
	background-color: ${({ backgroundColor }) => backgroundColor};
`;
