import * as actions from "../../actions/login";
import dayjs from "dayjs";

const initialState = {
	recoverEmail: "",
	username: "",
	password: "",
	message: "",
	loading: { login: false },
	user: null,
	token: null,
	expiration: null,
	freshTokenPromise: null,
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.PASSWORD_RECOVERY: {
			return {
				...state,
				loading: { ...state.loading, recovery: true },
				errors: initialState.errors
			};
		}
		case actions.PASSWORD_RECOVERY_SUCCESS: {
			return {
				...state,
				loading: { ...state.loading, recovery: false },
				errors: initialState.errors
			};
		}
		case actions.RESET_PASSWORD_SUCCESS: {
			return {
				...state,
				loading: { ...state.loading, recovery: false },
				errors: initialState.errors
			};
		}
		case actions.VALIDATE_TOKEN_ERROR: {
			return {
				...state,
				loading: { ...state.loading, recovery: false },
				errors: initialState.errors
			};
		}
		case actions.VALIDATE_TOKEN_SUCCESS: {
			return {
				...state,
				errors: payload
			};
		}
		case actions.RESET_PASSWORD_FAILED: {
			return {
				...state,
				loading: { ...state.loading, recovery: false },
				errors: payload
			};
		}
		case actions.PASSWORD_RECOVERY_FAILED: {
			return {
				...state,
				loading: { ...state.loading, recovery: false },
				errors: payload
			};
		}
		case actions.LOGIN: {
			return {
				...state,
				loading: { ...state.loading, login: true },
				errors: initialState.errors
			};
		}
		case actions.LOGIN_SUCCESS: {
			return {
				...state,
				message: "",
				username: "",
				password: "",
				loading: { ...state.loading, login: false },
				token: payload.token,
				expiration: dayjs(payload.expiration),
				errors: initialState.errors
			};
		}
		case actions.LOGIN_ERROR: {
			return {
				...state,
				loading: { ...state.loading, login: false },
				user: initialState.user,
				token: initialState.token,
				errors: payload
			};
		}
		case actions.GET_USER_DATA: {
			return {
				...state,
				loading: { ...state.loading, user: true },
				errors: initialState.errors
			};
		}
		case actions.SET_USER_DATA: {
			return {
				...state,
				loading: { ...state.loading, user: false },
				user: payload,
				errors: initialState.errors
			};
		}
		case actions.GET_USER_DATA_ERROR: {
			return {
				...state,
				loading: { ...state.loading, user: false },
				errors: payload
			};
		}
		case actions.REFRESH_TOKEN: {
			return {
				...state,
				loading: { ...state.loading, refresh: false },
				refreshTokenPromise: payload
			};
		}
		case actions.REFRESH_TOKEN_ERROR: {
			return {
				...state,
				loading: { ...state.loading, refresh: false },
				user: initialState.user,
				token: initialState.token,
				refreshTokenPromise: null,
				error: payload
			};
		}
		case actions.REFRESH_TOKEN_SUCCESS: {
			return {
				...state,
				loading: { ...state.loading, refresh: false },
				refreshTokenPromise: null
			};
		}
		case actions.USER_LOGGED_OUT: {
			return {
				...state,
				...initialState
			};
		}
		case actions.SET_ERROR: {
			return {
				...state,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
				message: state.message,
				username: state.username
			};
		default: {
			return state;
		}
	}
};

export default reducer;
