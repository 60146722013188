import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	gap: 0.25rem;

	button {
		background: none;
	}
`;

export const NumButton = styled.button`
	border: none;
	border-radius: 0.375rem;

	width: 2rem;
	height: 1.5rem;

	color: #6c757d;
	font-size: 1rem;

	cursor: pointer;

	:hover:enabled {
		transition: 0.2s;

		background: #dfdfdf;
	}

	:disabled {
		border-width: 1px;
		border-color: #504c9a;

		background: #504c9a;

		color: #ffffff;

		cursor: default;
	}
`;

export const ArrowButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	border: none;

	width: 1.5rem;
	height: 1.5rem;

	color: #6c757d;
	cursor: pointer;

	:disabled {
		cursor: default;
		color: red;
		color: #6c757d;
	}

	:hover:enabled {
		transition: 0.2s;
		background: #dfdfdf;
	}
`;
