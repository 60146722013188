import dayjs from "dayjs";
import ls from "Localization";

export const dateSort = (a, b, desc) => {
	let aD = dayjs(a, { format: ls.dateFormatWithoutSeconds });
	let bD = dayjs(b, { format: ls.dateFormatWithoutSeconds });

	if (aD.isSame(bD)) return 0;
	if (aD.isAfter(bD)) return desc ? 1 : -1;
	if (aD.isBefore(bD)) return desc ? -1 : 1;
};
