/* eslint-disable */
import React, { useEffect } from "react";
import { ReactComponent as Svg } from "../../../../assets/tag-comemorativa.svg";

export default function SVGComemorativo() {
	useEffect(() => {
		(function(s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
			w[o] = w[o] || {};
			w[o][s] = w[o][s] || [];
			w[o][s].push(i);
			e = d.createElementNS(n, t);
			e.async = true;
			e.setAttributeNS(
				x,
				"href",
				[u, s, ".", "j", "s", "?", "v", "=", c].join("")
			);
			e.setAttributeNS(
				null,
				"src",
				[u, s, ".", "j", "s", "?", "v", "=", c].join("")
			);
			p = d.getElementsByTagName(t)[0];
			p.parentNode.insertBefore(e, p);
		})(
			"5c7f360c",
			{
				root: "ezZ97FEfJWL1",
				version: "2022-05-04",
				animations: [
					{
						elements: {
							ezZ97FEfJWL2: {
								transform: {
									data: {
										o: { x: 26.5, y: 21.818795, type: "corner" },
										t: { x: -25.75, y: -10.05585 }
									},
									keys: {
										s: [
											{ t: 0, v: { x: 0, y: 0 } },
											{ t: 160, v: { x: 1, y: 1 } }
										]
									}
								}
							},
							ezZ97FEfJWL3: {
								transform: {
									data: {
										o: { x: 26.427441, y: 22.087966, type: "corner" },
										t: { x: -26.42744, y: -20.04475 }
									},
									keys: {
										s: [
											{ t: 0, v: { x: 0, y: 0 } },
											{ t: 130, v: { x: 1, y: 1 } }
										]
									}
								}
							},
							ezZ97FEfJWL4: {
								transform: {
									data: {
										o: { x: 42.2204, y: 20.04455, type: "corner" },
										t: { x: -42.2204, y: -20.04455 }
									},
									keys: {
										s: [
											{ t: 2760, v: { x: 1, y: 1 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2890, v: { x: 1.3, y: 1.3 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2970, v: { x: 1, y: 1 }, e: [0.42, 0, 0.58, 1] }
										]
									}
								}
							},
							ezZ97FEfJWL5: {
								transform: {
									data: {
										o: { x: 34.796, y: 20.04455, type: "corner" },
										t: { x: -34.796, y: -20.04455 }
									},
									keys: {
										s: [
											{ t: 2640, v: { x: 1, y: 1 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2760, v: { x: 1.3, y: 1.3 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2840, v: { x: 1, y: 1 }, e: [0.42, 0, 0.58, 1] }
										]
									}
								}
							},
							ezZ97FEfJWL6: {
								transform: {
									data: {
										o: { x: 26.70212, y: 20.04455, type: "corner" },
										t: { x: -26.9072, y: -20.04455 }
									},
									keys: {
										s: [
											{ t: 2500, v: { x: 1, y: 1 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2640, v: { x: 1.3, y: 1.3 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2720, v: { x: 1, y: 1 }, e: [0.42, 0, 0.58, 1] }
										]
									}
								}
							},
							ezZ97FEfJWL7: {
								transform: {
									data: {
										o: { x: 19.207719, y: 20.04455, type: "corner" },
										t: { x: -19.412799, y: -20.04455 }
									},
									keys: {
										s: [
											{ t: 2360, v: { x: 1, y: 1 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2500, v: { x: 1.3, y: 1.3 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2580, v: { x: 1, y: 1 }, e: [0.42, 0, 0.58, 1] }
										]
									}
								}
							},
							ezZ97FEfJWL8: {
								transform: {
									data: {
										o: { x: 10.6072, y: 20.06135, type: "corner" },
										t: { x: -10.81228, y: -20.06135 }
									},
									keys: {
										s: [
											{ t: 2110, v: { x: 1, y: 1 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2280, v: { x: 1.3, y: 1.3 }, e: [0.25, 1, 0.25, 1] },
											{ t: 2360, v: { x: 1, y: 1 }, e: [0.42, 0, 0.58, 1] }
										]
									}
								}
							},
							ezZ97FEfJWL10: {
								transform: {
									data: {
										o: { x: 22.353521, y: 3.130395, type: "corner" },
										t: { x: -18.7529, y: -1.08718 }
									},
									keys: {
										s: [
											{ t: 590, v: { x: 0, y: 0 }, e: [0.25, 1, 0.25, 1] },
											{ t: 650, v: { x: 2, y: 2 }, e: [0.25, 1, 0.25, 1] },
											{ t: 720, v: { x: 1.5, y: 1.5 } }
										]
									}
								}
							},
							ezZ97FEfJWL11: {
								"#size": [
									{ t: 430, v: { width: 1.63, height: 0 } },
									{ t: 570, v: { width: 1.63077, height: 6.52308 } }
								]
							},
							ezZ97FEfJWL12: {
								"#size": [
									{
										t: 160,
										v: { width: 1.63, height: 0 },
										e: [0.25, 1, 0.25, 1]
									},
									{ t: 290, v: { width: 1.63077, height: 6.52308 } }
								]
							},
							ezZ97FEfJWL13: {
								transform: {
									data: {
										o: { x: 13.094238, y: 3.130395, type: "corner" },
										t: { x: -18.7529, y: -1.08718 }
									},
									keys: {
										s: [
											{ t: 290, v: { x: 0, y: 0 }, e: [0.25, 1, 0.25, 1] },
											{ t: 350, v: { x: 2, y: 2 }, e: [0.25, 1, 0.25, 1] },
											{ t: 420, v: { x: 1.5, y: 1.5 } }
										]
									}
								}
							},
							ezZ97FEfJWL14: {
								transform: {
									data: {
										o: { x: 30.050721, y: 3.065325, type: "corner" },
										t: { x: -18.7529, y: -1.08718 }
									},
									keys: {
										s: [
											{ t: 930, v: { x: 0, y: 0 }, e: [0.25, 1, 0.25, 1] },
											{ t: 990, v: { x: 2, y: 2 }, e: [0.25, 1, 0.25, 1] },
											{ t: 1060, v: { x: 1.5, y: 1.5 } }
										]
									}
								}
							},
							ezZ97FEfJWL15: {
								"#size": [
									{ t: 710, v: { width: 1.63, height: 0 } },
									{ t: 860, v: { width: 1.63077, height: 6.52308 } }
								]
							},
							ezZ97FEfJWL16: {
								"#size": [
									{ t: 1010, v: { width: 1.63, height: 0 } },
									{ t: 1160, v: { width: 1.63077, height: 6.52308 } }
								]
							},
							ezZ97FEfJWL17: {
								transform: {
									data: {
										o: { x: 38.668174, y: 3.130395, type: "corner" },
										t: { x: -18.7529, y: -1.08718 }
									},
									keys: {
										s: [
											{ t: 1180, v: { x: 0, y: 0 }, e: [0.25, 1, 0.25, 1] },
											{ t: 1240, v: { x: 2, y: 2 }, e: [0.25, 1, 0.25, 1] },
											{ t: 1310, v: { x: 1.5, y: 1.5 } }
										]
									}
								}
							}
						},
						s:
							"MODA1ZDkwMzc3OThhODQc3Njg5N2U4NDgzMzcY0ZjQ4NDU0NTQ1NDFZNMzc3OTdlODc3YUw3OFDg5N2U4NEQ4MzM3NGOY0Nkk0MTM3N2U4OTdShODc3NkI4OTdlODQ4EMzg4Mzc0ZjQ2NDEzNHzdiN2U4MTgxMzc0ZjWQ2NDEzNzc2ODFLODkR3YTg3ODM3Njg5N2EzRNzRmN2JFNzY4MTg4VUTdhNDEzNzg4ODU3YUGM3YTc5Mzc0ZjQ2NDEQzNzdiODU4ODM3NGY0FNjQ1NDU5Mg|"
					}
				],
				options: "MVDAxMDg4MmY4MDgxNmLU3ZjgxMmY0NzJmNzkR3YzZlNzEyZjhh"
			},
			"https://cdn.svgator.com/ply/",
			"__SVGATOR_PLAYER__",
			"2022-05-04",
			window,
			document,
			"script",
			"http://www.w3.org/2000/svg",
			"http://www.w3.org/1999/xlink"
		);
	}, []);
	return (
		<div
			style={{
				width: "72px",
				padding: "5px",
				marginLeft: "5px"
			}}
		>
			<Svg />
		</div>
	);
}
