const validate = (values = {}) => {
  const errors = {};

  if (!values.user) errors.user = "Obrigatório";

  if (!values.value) errors.value = "Obrigatório";

  if (!values.status) errors.status = "Obrigatório";

  return errors;
};

export default validate;
