import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "./containers/Page/Index";

const Routes = () => (
	<React.Fragment>
		<Switch>
			<Route exact path="/" component={Dashboard} />
		</Switch>
	</React.Fragment>
);

export default Routes;
