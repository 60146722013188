export default {
	0: "completeRegistration",
	1: "pending",
	2: "activated",
	3: "disabled"
};

export const UserRoles = {
	2: "mei",
	3: "me",
	4: "hirer"
};

export const BankStatus = {
	0: "NoAccount",
	1: "Pending",
	2: "Enabled",
	3: "Active",
	4: "Deleted"
};

export const UserStatus = {
	0: "disabled",
	1: "enabled"
};

export const MeiStatus = {
	0: "pending",
	1: "noMei",
	2: "createMei",
	3: "done",
	4: "phoneNotValidated",
	5: "responsibleForAnotherBusinessError",
	6: "noGovBrAccount",
	7: "errorOnFinished",
	8: "meiCreated",
	9: "errorOnGetMei",
	10: "invalidBirthDate",
	11: "invalidVoterNumber",
	12: "errorOnCreateMei",
	13: "invalidDirpfNumber",
	14: "invalidState",
	15: "invalidActivity"
};

export const MeiStatusConst = {
	pending: 0,
	noMei: 1,
	createMei: 2,
	done: 3,
	phoneNotValidated: 4,
	responsibleForAnotherBusinessError: 5,
	noGovBrAccount: 6,
	errorOnFinished: 7,
	meiCreated: 8,
	errorOnGetMei: 9,
	invalidBirthDate: 10,
	invalidVoterNumber: 11,
	errorOnCreateMei: 12,
	invalidDirpfNumber: 13,
	invalidState: 14,
	invalidActivity: 15
}

export const UserGender = {
	0: "male",
	1: "female",
	2: "other"
};

export const UserMaritalStats = {
	0: "single",
	1: "married",
	2: "widower",
	3: "divorced"
};
