import React from "react";
import { Pagination } from "./pagination";
import {
	Container,
	ContainerHeader,
	InputSc,
	PaginationContainer,
	SelectContainer,
	TableSc,
	TdFilterSc,
	TdSc,
	ThSc,
	TrSc
} from "./styles";
import { FormControl, MenuItem, Select } from "@material-ui/core";

export const StyledTable = ({
	columns,
	data = [],
	filter = false,
	onChangeFilter = (id, value) => {
		console.log(id, value, "Adicione uma função a propriedade onChangeFilter");
	},
	take = 10,
	skip = 0,
	count = 0,
	handleChangeTake,
	handleChangeSkip,
	onRowClick = null,
	headerContent = null,
	showHeader,
	rowClick = true,
	title = ""
}) => {
	const gridCol = columns;

	const fieldFormat = (field, row) => {
		try {
			const fieldList = field.split(".");

			for (let i = 0; i < fieldList.length; i++) {
				row = row[fieldList[i]];
			}
			return row;
		} catch (error) {
			return "";
		}
	};

	const onFilter = (e, id) => {
		if (e.key == "Enter") {
			onChangeFilter(id, e.target.value);
		}
	};

	const onSelectFilter = (e, id) => {
		onChangeFilter(id, e.target.value);
	};

	const maxPages = Math.ceil(count / take);

	return (
		<div>
			{showHeader && (
				<ContainerHeader>
					<span>
						{title ? <b>{title} </b> : ""}Total -{" "}
						{skip * take + take > count ? count : skip * take + take} / {count}
					</span>
					<div style={{ display: "flex", gap: "10px" }}>
						{headerContent}

						<SelectContainer>
							<p>Registros por página</p>
							<FormControl size="small">
								<Select
									onChange={handleChangeTake}
									defaultValue={take}
									variant="outlined"
								>
									{[5, 10, 15, 20, 25].map(value => (
										<MenuItem key={value} value={value}>
											{value}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</SelectContainer>
					</div>
				</ContainerHeader>
			)}

			<Container>
				<TableSc>
					<thead>
						<tr>
							{gridCol.map((props, i) => {
								const { headerName, width, align, sticky } = props;
								return (
									<ThSc
										key={i}
										width={width}
										align={align ? align : "left"}
										index={i}
										sticky={sticky}
									>
										{headerName}
									</ThSc>
								);
							})}
						</tr>
					</thead>

					<tbody>
						{filter && (
							<tr>
								{gridCol.map(
									(
										{ width, field, filterField, filterOptions, filterType },
										i
									) => (
										<TdFilterSc key={i} width={width}>
											{//TODO: Implementar filtro de data
											filterField && (
												<>
													{filterType === "text" ? (
														<InputSc
															id={filterField || String(field)}
															onKeyDown={e => onFilter(e, filterField || field)}
															type="text"
														/>
													) : (
														<Select
															onChange={e =>
																onSelectFilter(e, filterField || field)
															}
															defaultValue={"Todos"}
														>
															<MenuItem key={"Todos"} value={"Todos"}>
																Todos
															</MenuItem>
															{filterOptions?.map(({ value, label }, index) => (
																<MenuItem key={index} value={value}>
																	{label}
																</MenuItem>
															))}
														</Select>
													)}
												</>
											)}
										</TdFilterSc>
									)
								)}
							</tr>
						)}
						{data.length != 0 ? (
							data?.map((row, index) => {
								return (
									<TrSc
										key={index}
										onClick={() => (rowClick ? onRowClick?.(row) : null)}
										cursor={rowClick ? "pointer" : "default"}
									>
										{gridCol.map(
											({ content, field, width, align, sticky }, i) => {
												let value = "";

												if (String(field).includes(".")) {
													if (content) {
														value = content(fieldFormat(field, row));
													} else {
														value = fieldFormat(field, row);
													}
												} else if (content) {
													if (!field) value = content(row);
													else value = content(row[field]);
												} else {
													value = row[field];
												}

												return (
													<TdSc
														key={i}
														align={align ? align : "left"}
														width={width}
														rowIndex={index}
														index={i}
														sticky={sticky}
													>
														{value}
													</TdSc>
												);
											}
										)}
									</TrSc>
								);
							})
						) : (
							<TrSc>
								<td align="center" colSpan={gridCol.length}>
									Sem Resultados
								</td>
							</TrSc>
						)}
					</tbody>
				</TableSc>
			</Container>

			<PaginationContainer>
				<Pagination
					pageCount={maxPages}
					canNextPage={skip < maxPages - 1}
					canPreviousPage={skip !== 0}
					handleChangePage={handleChangeSkip}
					page={skip}
				/>
			</PaginationContainer>
		</div>
	);
};
