const strings = {
	"--": "--",
	"Attention!": "Atenção!",
	"This benefit has already been added": "Este benefício já foi adicionado",
	"User registration is pending":
		"Seu cadastro está pendente de aprovação, iremos processar seu cadastro em breve.",
	"you need to reset your password":
		"Senha expirada, clique em recuperar senha",
	Active: "Ativo",
	AwaitingSignature: "Aguardando Assinatura",
	BankAccount: "Conta bancária",
	Both: "Ambos",
	Canceled: "Cancelado",
	Cancelled: "Cancelado",
	Centauro: "Centauro",
	Company: "Pessoa Jurídica",
	Confidentiality: "Confidencialidade",
	ContractAmendment: "Aditivo Contratual",
	DEMAIS: "DEMAIS",
	divorced: "Divorciado(a)",
	Daily: "Diário",
	Delete: "Excluir",
	DeleteRead: "Ler, excluir",
	DeleteWrite: "Criar, excluir",
	Deleted: "Excluída",
	Disabled: "Desativado",
	Dissolution: "Distrato",
	Doing: "Executando",
	Done: "Concluído",
	Due: "Atrasado",
	Enabled: "Habilitado",
	Enabling: "Habilitando",
	enabling: "Habilitando",
	Excluded: "Excluído",
	Failed: "Falhado",
	Fired: "Desligado",
	Gazin: "Gazin",
	Generated: "Gerado",
	HIRER: "Contratante",
	hirerSigned: "Contratante Assinado",
	hiredSigned: "Prestador Assinado",
	InProgress: "Em progresso",
	Inactive: "Inativo/Suspenso",
	inactive: "Inativo",
	Individual: "Pessoa Física",
	Lending: "Comodato",
	ME: "ME",
	MEI: "MEI",
	Monthly: "Mensal",
	Nda: "NDA",
	NoAccount: "Sem conta",
	None: "Nenhum",
	NonOpting: "Não Optante",
	Once: "Uma vez",
	other: "Outros",
	Others: "Outros",
	OverDue: "Vencido",
	P2P: "Entre contas",
	pending: "Pendente",
	Pending: "Pendente",
	Quarterly: "Trimestral",
	Queued: "Agendado",
	Read: "Ler",
	ReadWrite: "Criar, ler",
	ReadWriteDelete: "Criar, ler, excluir",
	Rescind: "Rescindido",
	ServicesProvision: "Prestação de serviço",
	Signed: "Assinado",
	Write: "Criar",
	Yearly: "Anual",
	actions: "Ações",
	activated: "Ativado",
	activating: "Habilitando",
	active: "Ativo",
	admin: "Administrador",
	appName: "SuaMEi",
	bankStatus: "Status Conta Digital",
	birthDate: "Data de nascimento",
	canceled: "Cancelado",
	cancelled: "Cancelado",
	charges: "Cobranças",
	checking: "Verificando",
	city: "Cidade",
	client: "Cliente",
	company: "Pessoa Jurídica",
	complement: "Complemento",
	completeRegistration: "Completar cadastro",
	confirmed: "Confirmado",
	cpf: "CPF",
	createMei: "Criar MEI",
	creationDate: "Data criação",
	daily: "Diário",
	dateFormatShort: "DD/MM/YYYY",
	dateFormatWithoutSeconds: "DD/MM/YYYY - HH:mm",
	demais: "DEMAIS",
	description: "Descrição",
	disabled: "Desativado",
	discount: "Desconto",
	doing: "Executando",
	done: "Finalizado",
	due: "Atrasado",
	email: "E-mail",
	enabled: "Ativo",
	errorOnCreateMei: "Erro ao criar MEI",
	errorOnFinished: "Erro ao criar mei",
	errorOnGetMei: "Erro ao buscar mei",
	failed: "Falha",
	female: "Feminino",
	fired: "Desligado",
	generated: "Gerado",
	hirer: "Contratante",
	inInstallment: "Em Parcelamento",
	individual: "Pessoa Física",
	installment: "Parcelas",
	invalidActivity: "Atividade inválida",
	invalidBirthDate: "Data nasc. inválida",
	invalidDirpfNumber: "Nº DIRPF inválido",
	invalidState: "UF inválido",
	invalidVoterNumber: "Título inválido",
	male: "Masculino",
	married: "Casado(a)",
	me: "ME",
	mei: "MEI",
	meiCreated: "Mei Criado",
	meiStatus: "Status do MEI",
	monthly: "Mensal",
	name: "Nome",
	neighborhood: "Bairro",
	newPasswordSentForEmail:
		"Instruções para criar uma nova senha enviadas para o e-mail informado.",
	noGovBrAccount: "Sem conta GOV.BR",
	noMei: "Sem MEI",
	noMessage: "Sem mensagem",
	noToken: "Sem token",
	none: "Nenhum",
	notDefined: "Não definido",
	notOpting: "Não optante",
	notPaid: "Não pago",
	number: "Número",
	once: "Único",
	openExternalBrowser: "Abrir link no navegador",
	openInternalBrowser: "Abrir link no APP",
	overDue: "Vencido",
	paid: "Pago",
	passwordChanged:
		"Senha alterara com sucesso, acesse o sistema com sua nova senha",
	paymentDate: "Data pagamento",
	paymentMethod: "Método de pagamento",
	phoneNotValidated: "Telefone não validado",
	phoneNumber: "Telefone",
	plan: "Plano",
	quarterly: "Trimestral",
	queued: "Agendado",
	read: "Lido",
	received: "Recebido",
	required: "Obrigatório",
	rescind: "Rescindido",
	responsibleForAnotherBusinessError: "Já tem CNPJ",
	role: "Função/Cargo",
	single: "Solteiro",
	sending: "Enviando",
	sent: "Enviado",
	settled: "Quitado",
	startAt: "Começa em",
	state: "Estado",
	statusContractTemplate: "Status Contrato",
	statusMei: "Status MEI",
	street: "Endereço",
	subscriptions: "Assinaturas",
	support: "Suporte",
	template: "Modelo",
	transfers: "Transferências",
	type: "Tipo",
	typeContract: "Tipo de Contrato",
	waitApproval: "Aguardando Aprovação",
	rejected: "Rejeitado",
	unavailable: "Não disponível",
	used: "Usado",
	user: "Usuário",
	userStatus: "Status do usuário",
	value: "Valor",
	yearly: "Anual",
	widower: "Viúvo(a)",
	close: "Fechar",
	"Not found": "Não encontrado",
	cnpj: "CNPJ",
	service: "Serviço",
	serviceType: "Tipo do serviço",
	observations: "Observações",
	total: "Total",
	Error: "Erro",
	toExpire: "A vencer",
	liquidated: "Liquidado",
	debtor: "Devedor"
};

export default strings;
