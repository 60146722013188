import * as actions from "./actions";

const initialState = {
	hireds: [],
	loading: false,
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.UPLOAD_CONTRACT_FILE: {
			return {
				...state,
				loading: true
			};
		}
		case actions.UPLOAD_CONTRACT_FILE_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.UPLOAD_CONTRACT_FILE_ERROR: {
			return {
				...state,
				errors: payload,
				loading: false
			};
		}

		case actions.UPDATE_CONTRACT: {
			return {
				...state,
				loading: true
			};
		}
		case actions.UPDATE_CONTRACT_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case actions.UPDATE_CONTRACT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: false
			};
		}

		case actions.GET_HIREDS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_HIREDS_SUCCESS: {
			return {
				...state,
				hireds: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_HIREDS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
