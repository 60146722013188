import * as actions from "./actions";

const initialState = {
	items: [],
	item: null,
	name: "",
	users: [],
	startAt: null,
	economicMode: true,
	emailSubject: "",
	emailMessage: "",
	smsMessage: "",
	whatsAppMessage: "",
	whatsAppRestrict: true,
	pushAction: 0,
	pushValue: "",
	pushTitle: "",
	pushMessage: "",
	errors: {},
	loading: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_MARKETING: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors,
			};
		}
		case actions.GET_MARKETING_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.name = payload.name ? payload.name : initialState.name;
				newState.users =
					payload.users && payload.users.map((c) => ({ userId: c.userId })) ?
						payload.users.map((c) => ({ userId: c.userId })) :
						initialState.users;
				newState.startAt = payload.startAt ? payload.startAt : initialState.startAt;
				newState.economicMode =
					payload.economicMode ? payload.economicMode : initialState.economicMode;
				newState.emailSubject =
					payload.emailSubject ? payload.emailSubject : initialState.emailSubject;
				newState.emailMessage =
					payload.emailMessage ? payload.emailMessage : initialState.emailMessage;
				newState.smsMessage = payload.smsMessage ? payload.smsMessage : initialState.smsMessage;
				newState.whatsAppMessage =
					payload.whatsAppMessage ? payload.whatsAppMessage : initialState.whatsAppMessage;
				newState.whatsAppRestrict =
					payload.whatsAppRestrict ? payload.whatsAppRestrict : initialState.whatsAppRestrict;
				newState.pushMessage = payload.pushMessage ? payload.pushMessage : initialState.pushMessage;
				newState.pushTitle = payload.pushTitle ? payload.pushTitle : initialState.pushTitle;
				newState.pushAction = payload.pushAction ? payload.pushAction : initialState.pushAction;
				newState.pushValue = payload.pushValue ? payload.pushValue : initialState.pushValue;
			} else {
				newState.item = null;
				newState.name = initialState.name;
				newState.users = initialState.users;
				newState.startAt = initialState.startAt;
				newState.economicMode = initialState.economicMode;
				newState.emailSubject = initialState.emailSubject;
				newState.emailMessage = initialState.emailMessage;
				newState.smsMessage = initialState.smsMessage;
				newState.whatsAppMessage = initialState.whatsAppMessage;
				newState.whatsAppRestrict = initialState.whatsAppRestrict;
				newState.pushMessage = initialState.pushMessage;
				newState.pushTitle = initialState.pushTitle;
				newState.pushAction = initialState.pushAction;
				newState.pushValue = initialState.pushValue;
			}

			return {
				...newState,
				loading: { get: false },
			};
		}
		case actions.GET_MARKETING_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload,
			};
		}
		case actions.UPDATE_MARKETING: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors,
			};
		}
		case actions.UPDATE_MARKETING_SUCCESS: {
			let items = state.items.filter((c) => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				name: initialState.name,
				users: initialState.users,
				startAt: initialState.startAt,
				economicMode: initialState.economicMode,
				emailSubject: initialState.emailSubject,
				emailMessage: initialState.emailMessage,
				smsMessage: initialState.smsMessage,
				whatsAppMessage: initialState.whatsAppMessage,
				whatsAppRestrict: initialState.whatsAppRestrict,
				pushTitle: initialState.pushTitle,
				pushMessage: initialState.pushMessage,
				pushAction: initialState.pushAction,
				pushValue: initialState.pushValue,
			};
		}
		case actions.UPDATE_MARKETING_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload,
			};
		}
		case actions.CLEAR_VALUES: {
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				name: initialState.name,
				users: initialState.users,
				startAt: initialState.startAt,
				economicMode: initialState.economicMode,
				emailSubject: initialState.emailSubject,
				emailMessage: initialState.emailMessage,
				smsMessage: initialState.smsMessage,
				whatsAppMessage: initialState.whatsAppMessage,
				whatsAppRestrict: initialState.whatsAppRestrict,
				pushTitle: initialState.pushTitle,
				pushMessage: initialState.pushMessage,
				pushAction: initialState.pushAction,
				pushValue: initialState.pushValue,
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
