import React, { useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import UsersStatusChart from "../../../components/Charts/UsersStatus";
import UsersVersusMeiChart from "../../../components/Charts/UsersVersusMei";
import CompaniesType from "../../../components/Charts/CompaniesType";
import HiredsChart from "../../../components/Charts/Hireds";
import {
	getUsersStatus,
	getCompaniesType,
	getCompaniesHired,
	getUsersVersusCompanies,
	clearValues
} from "./actions";

const Index = () => {
	const dispatch = useDispatch();
	const {
		loading,
		usersByStatus,
		companiesType,
		companies,
		recordsTimeline
	} = useSelector(s => s.dashboard);

	useEffect(() => {
		dispatch(clearValues());
		loadCharts();
	}, []);

	const loadCharts = useCallback(() => {
		dispatch(getUsersStatus());
		dispatch(getCompaniesType());
		dispatch(getCompaniesHired());
		dispatch(getUsersVersusCompanies());
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Col xs={12}>
				<Row>
					<UsersVersusMeiChart
						contentData={recordsTimeline}
						loading={loading.recordsTimeline}
					/>
					<UsersStatusChart
						loading={loading.usersByStatus}
						data={usersByStatus}
					/>
					<CompaniesType loading={loading.companiesType} data={companiesType} />
					<HiredsChart loading={loading.companies} data={companies} />
				</Row>
			</Col>
		</Container>
	);
};

export default Index;
