const validate = values => {
	const errors = {};

	if (!values.status && String(values.status) !== "0") {
		errors.status = "Selecione o status";
	}

	if (!values.totalValue) {
		errors.totalValue = "Informe o valor";
	}

	if (!values.service) {
		errors.service = "Informe o serviço";
	}

	console.log(errors);
	return errors;
};

export default validate;
