const validate = values => {
	const errors = {};

	if (!values.name) errors.name = "Informe seu nome";
	if (!values.lastName) errors.lastName = "Informe seu sobrenome";

	if (!values.email) {
		errors.email = "Informe seu e-mail";
	} else if (
		!new RegExp("^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$", "i").test(
			values.email
		)
	) {
		errors.email = "Informe um e-mail válido";
	}

	if (!values.password) errors.password = "Informe sua senha";
	else if (values.password.length < 6)
		errors.password = "Informe pelo menos 6 caracteres";
	else if (values.confirmPassword && values.password !== values.confirmPassword)
		errors.password = "Senhas não conferem";

	return errors;
};

export default validate;
