import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_COMPANY = "[COMPANY DETAIL] GET_COMPANY";
export const GET_COMPANY_ERROR = "[COMPANY DETAIL] GET_COMPANY_ERROR";
export const GET_COMPANY_SUCCESS = "[COMPANY DETAIL] GET_COMPANY_SUCCESS";

export const GET_USERS_BY_COMPANY = "[COMPANY DETAIL] GET_USERS_BY_COMPANY";
export const GET_USERS_BY_COMPANY_ERROR =
	"[COMPANY DETAIL] GET_USERS_BY_COMPANY_ERROR";
export const GET_USERS_BY_COMPANY_SUCCESS =
	"[COMPANY DETAIL] GET_USERS_BY_COMPANY_SUCCESS";

export const SET_VALUE = "[COMPANY DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[COMPANY DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCompanyById(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANY
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/company/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMPANY_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_COMPANY_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANY_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateCompanyByReceita(cnpj) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_COMPANY
		});
		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/company/update/receita/${cnpj}`,
				null,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();
			if (result.isValid) {
				dispatch({
					type: GET_COMPANY_SUCCESS,
					payload: result.model
				});
				return result.model;
			} else {
				dispatch({
					type: GET_COMPANY_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANY_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getUsersByCompany(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_USERS_BY_COMPANY
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/company/users-by-company/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USERS_BY_COMPANY_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_USERS_BY_COMPANY_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USERS_BY_COMPANY_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function saveAccountManage() {
	return async (dispatch, getState) => {};
}
