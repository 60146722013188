import React, { useRef } from "react";
import { Button, Spinner } from "reactstrap";
import { FaFileImport } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../containers/Report/actions";

function ImportTaxes({ load }) {
  const dispatch = useDispatch();

  const { loading } = useSelector(s => s.transfer);

  const inputFile = useRef(null);

  const openSelectFile = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleFileSelected = e => {
    if (!e.target.files[0]) return;

    dispatch(
      actions.importTaxes(e.target.files, (err, data) => {
        if (err) {
          if (typeof err !== "string") err = JSON.stringify(err);
          alert(err);
        } else {
          load();
          alert("Importado com sucesso");
        }

        inputFile.current.value = null;
      })
    );
  };

  return (
    <div style={{ marginRight: 6 }}>
      {loading.import ? (
        <Spinner size="sm" />
      ) : (
        <Button outline onClick={openSelectFile}>
          <FaFileImport />
        </Button>
      )}
      <input
        ref={inputFile}
        style={{ display: "none" }}
        type="file"
        accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={handleFileSelected}
      />
    </div>
  );
}

export default ImportTaxes;
