import React, { useEffect, useState } from "react";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

import { ArrowButton, Container, NumButton } from "./styled";

export const Pagination = ({
	canPreviousPage,
	canNextPage,
	page,
	pageCount,
	handleChangePage
}) => {
	const [pagesArr, setPagesArr] = useState([0]);
	const [isIncreasingPages, setIsIncreasingPages] = useState(false);

	useEffect(() => {
		setPagesArr(pageSetter());
	}, [page, pageCount]);

	const pageSetter = () => {
		let pages = [];
		switch (true) {
			case !canPreviousPage && canNextPage: {
				for (let i = page; i < page + 4 && i < pageCount; i++) {
					pages.push(i + page);
				}
				break;
			}

			case canPreviousPage && !canNextPage: {
				for (let i = 0; i < 4 && pageCount - i > 0; i++) {
					pages.unshift(page - i);
				}
				break;
			}
			case !canPreviousPage && !canNextPage: {
				pages = [0];
				break;
			}

			case page === 1: {
				for (let i = 0; i < 4 && i < pageCount; i++) {
					pages.push(i);
				}
				break;
			}

			case page === pageCount - 2: {
				for (let i = 0; i < 4 && pageCount - i > 0; i++) {
					pages.unshift(pageCount - 1 - i);
				}
				break;
			}

			case isIncreasingPages: {
				pages = [page - 2, page - 1, page, page + 1];
				break;
			}
			case !isIncreasingPages: {
				pages = [page - 1, page, page + 1, page + 2];
				break;
			}

			default: {
				pages = [page - 1, page, page + 1];
				break;
			}
		}

		return pages;
	};

	return (
		<Container>
			<ArrowButton
				onClick={() => handleChangePage(0)}
				disabled={!canPreviousPage}
			>
				<BiChevronsLeft size="1rem" />
			</ArrowButton>
			{pagesArr.map((el, i) => (
				<NumButton
					key={i}
					disabled={el === page}
					onClick={() => {
						if (el > page && !isIncreasingPages) {
							setIsIncreasingPages(true);
						}
						if (el < page && isIncreasingPages) {
							setIsIncreasingPages(false);
						}
						handleChangePage(el);
					}}
				>
					{el + 1}
				</NumButton>
			))}
			<ArrowButton
				onClick={() => handleChangePage(pageCount - 1)}
				disabled={!canNextPage}
			>
				<BiChevronsRight size="1rem" />
			</ArrowButton>
		</Container>
	);
};
