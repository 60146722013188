export const businessTypeTranslate = {
	0: "MEI",
	1: "ME",
	2: "DEMAIS",
	3: "Pendente"
};

export const BusinessType = {
	MEI: 0,
	ME: 1,
	DEMAIS: 2,
	Pending: 3
};
