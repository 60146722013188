import React, { useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import ls from "Localization";
import { toStringCurrency } from "helpers/string";

let getBenefitsDebounced = null;

function Benefit({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.benefit);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBenefitsDebounced) {
			getBenefitsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getBenefitsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getBenefits(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getBenefitsDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Benefícios"}
						data={state.items}
						pages={state.pages}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						onRowClicked={row => history.push(`/benefit/${row._id}`)}
						columns={[
							{
								Header: ls.name,
								id: "name",
								accessor: c => (c.name ? c.name : "Não definido"),
								width: 250
							},
							{
								Header: "Descrição",
								id: "description",
								accessor: "description"
							},
							{
								Header: "Preço",
								id: "price",
								accessor: c => toStringCurrency((c.price || 0) / 100.0),
								width: 100
							},
							{
								Header: "Desconto",
								id: "discount",
								accessor: c => toStringCurrency((c.discount || 0) / 100.0),
								width: 100
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Benefit;
