import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CreateForm from "../../../components/CreateForm/createForm";
import { clearValues, createContract } from "./actions";
import { getCompanies } from "../../../../Company/containers/Report/actions";
import _ from "lodash";
import ls from "Localization";
import { contractTemplateTypes } from "types/contractStatus";
import { getDepartmentList } from "../../ReportDepartment/actions";

let findCompanyDebounced = null;
let getDepartmentDebounced = null;

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading, errors } = useSelector(s => s.contractCreate);
	const { items: companies } = useSelector(s => s.company);
	const { items: departmentList } = useSelector(s => s.departmentList);

	const onSubmit = data => {
		dispatch(
			createContract(
				{
					name: data.name,
					displayName: data.displayName,
					externalId: data.externalId,
					mei: data.mei ? data.mei.value : null,
					type: data.type ? data.type.value : 0,
					department: data.department ? data.department.value : null
				},
				(err, model) => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push(`/contracts/template/${model._id}`);
					}
				}
			)
		);
	};

	const handleFindCompany = company => {
		if (findCompanyDebounced) {
			findCompanyDebounced.cancel();
		}

		findCompanyDebounced = _.debounce(
			() =>
				dispatch(
					getCompanies(
						0,
						10,
						`${company ? `&filters[searchText]=${company}` : ""}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar os usuários, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findCompanyDebounced();
	};

	const onChange = values => {
		if (values.meiSearch) {
			handleFindCompany(values.meiSearch);
		}
	};

	const typeData = Object.keys(contractTemplateTypes).map(c => ({
		value: c,
		label: ls[contractTemplateTypes[c]]
	}));

	const departmentData = departmentList.map(c => ({
		value: c._id,
		label: c.name
	}));

	const initialValues = { type: typeData[0] };

	const handleFetchDepartmentData = name => {
		if (getDepartmentDebounced) {
			getDepartmentDebounced.cancel();
		}

		let filterStr = "";

		if (name) filterStr += `&filters[name]=${name}`;

		getDepartmentDebounced = _.debounce(
			() =>
				dispatch(
					getDepartmentList(0, 10, filterStr, "createdAt", true, false, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: JSON.stringify(err)
							);
					})
				),
			500
		);

		getDepartmentDebounced();
	};

	useEffect(() => {
		dispatch(clearValues());
		handleFetchDepartmentData();
	}, []);

	useEffect(() => {
		handleFindCompany();
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Cadastrar novo modelo de contrato</h5>
							</div>
							<CreateForm
								onSubmit={onSubmit}
								loading={loading}
								errors={errors}
								initialValues={initialValues}
								onChange={onChange}
								companies={companies}
								typeData={typeData}
								departmentData={departmentData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
