import React from "react";
import { Route, Switch } from "react-router-dom";

import PermissionGroupsReport from "./containers/Report";
import PermissionGroupsDetails from "./containers/Details";
import PermissionGroupsCreate from "./containers/Create";
import PermissionGroupsEdit from "./containers/Edit";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/group" component={PermissionGroupsReport} />
				<Route exact path="/group/new" component={PermissionGroupsCreate} />
				<Route
					exact
					path="/group/:id/details"
					component={PermissionGroupsDetails}
				/>
				<Route exact path="/group/:id/edit" component={PermissionGroupsEdit} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
