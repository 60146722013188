import * as actions from "./actions";

const initialState = {
	items: [],
	item: null,
	name: "",
	users: [],
	startAt: null,
	economicMode: true,
	emailSubject: "",
	emailMessage: "",
	smsMessage: "",
	whatsAppMessage: "",
	whatsAppRestrict: true,
	pushAction: 0,
	pushValue: "",
	pushTitle: "",
	pushMessage: "",
	errors: {},
	loading: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_MARKETINGS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors,
			};
		}
		case actions.GET_MARKETINGS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false },
			};
		}
		case actions.GET_MARKETINGS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload,
			};
		}
		case actions.CLEAR_VALUES: {
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				name: initialState.name,
				users: initialState.users,
				startAt: initialState.startAt,
				economicMode: initialState.economicMode,
				emailSubject: initialState.emailSubject,
				emailMessage: initialState.emailMessage,
				smsMessage: initialState.smsMessage,
				whatsAppMessage: initialState.whatsAppMessage,
				whatsAppRestrict: initialState.whatsAppRestrict,
				pushTitle: initialState.pushTitle,
				pushMessage: initialState.pushMessage,
				pushAction: initialState.pushAction,
				pushValue: initialState.pushValue,
			};
		}
		case actions.SET_VALUE: {
			return {
				...state,
				...payload,
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
