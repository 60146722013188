import ReportTable from "components/Report";
import ExportExcelForm from "containers/Company/components/ExportExcelForm";
import dayjs from "dayjs";
import ExportExcel from "helpers/exportExcel";
import {
	getUserFullName,
	stringToMask,
	toStringCurrency
} from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FaRegFileExcel } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	Row
} from "reactstrap";
import { UncontrolledTooltip } from "reactstrap/lib";
import { nfseStatus, nfseStatusConst } from "types/nfseStatus";
import * as actions from "./actions";
import * as api from "services/api";

let getCompaniesDebounced = null;

function NFSe() {
	const dispatch = useDispatch();
	const table = useRef(true);

	const state = useSelector(s => s.nfse);
	const { token } = useSelector(s => s.login);

	const initialNfseCountRef = useRef(0);

	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () =>
		setModalExportExcel(prevState => !prevState);

	const [progress, setProgress] = useState(0);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getCompaniesDebounced) {
			getCompaniesDebounced.cancel();
		}

		if (!sorted) sorted = [];

		getCompaniesDebounced = _.debounce(
			() =>
				dispatch(
					actions.getNFSeList(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => {
							return p + `&filters[${c.id}]=${c.value}`;
						}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getCompaniesDebounced();
	};

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				"Data de Solicitação": item.createdAt
					? dayjs(item.createdAt).format("YYYY-MM-DD")
					: "",
				"Última Atualização": item.updatedAt
					? dayjs(item.updatedAt).format("YYYY-MM-DD")
					: "",
				"Status ":
					item.status || item.status == 0 ? ls[nfseStatus[item.status]] : "",
				"Empresa prestadora":
					item.mei && item.mei.businessName ? `${item.mei.businessName}` : "",
				"Nome prestador":
					item?.companyProvider && item.companyProvider.providerUser
						? `${item.companyProvider.providerUser.name}`
						: "",
				CNPJ: item?.mei?.cnpj ? `${stringToMask(item.mei.cnpj, "cnpj")}` : "",
				Cliente:
					item.clientMei && item.clientMei.businessName
						? `${item.clientMei.businessName}`
						: "",
				"CNPJ Cliente":
					item.clientMei && item.clientMei.cnpj
						? `${stringToMask(item.clientMei.cnpj, "cnpj")}`
						: "",
				"Email Cliente":
					item.contactCompany && item.contactCompany.email
						? `${item.contactCompany.email}`
						: "",
				"Valor Total": item.totalValue
					? `${toStringCurrency(+item.totalValue / 100.0)}`
					: "",
				"Login Emissão NF":
					item.mei && item.mei.nfEmissionLogin
						? `${item.mei.nfEmissionLogin}`
						: "",
				"Password Emissão NF":
					item.mei && item.mei.nfEmissionPassword
						? `${item.mei.nfEmissionPassword}`
						: "",
				"Url Emissão NF":
					item.mei && item.mei.nfEmissionUrl ? `${item.mei.nfEmissionUrl}` : "",
				"Motivo do erro":
					item?.statusReason && item.status === 9 ? item.statusReason : "",
				"Emitido por": item.user && item.user.name ? `${item.user.name}` : "",
				"Link Arquivo": item.file || ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = async type => {
		const dataTmp = [];
		const takeTmp = 500;

		for (
			let skipTmp = 0;
			skipTmp < initialNfseCountRef.current;
			skipTmp += takeTmp
		) {
			var response = await api.sendGet(
				`/nfse?skip=${skipTmp}&take=${takeTmp}&sort=createdAt&desc=true&filters=null`,
				{
					Authorization: "Bearer " + token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dataTmp.push(...result.model);
				setProgress(Math.round((skipTmp / initialNfseCountRef.current) * 100));
			} else {
				console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
				break;
			}
		}

		ExportExcel(customData(dataTmp), "Notas_Fiscais", type);
		setProgress(0);
		toggleModalExportExcel();
	};

	const onSubmitExportExcel = data => {
		if (initialNfseCountRef.current > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (data.exportExcel) {
			handleExport("xlsx");
		}

		if (data.exportCsv) {
			handleExport("csv");
		}
	};

	useEffect(() => {
		if (state.count > initialNfseCountRef.current) {
			initialNfseCountRef.current = state.count;
		}
	}, [state.count]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Nota Fiscal de Serviço Eletronica"}
						count={state.count}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						noExport={true}
						onFetchData={handleFetchData}
						onRowClicked={row => window.open(`/nfses/${row._id}`)}
						headerRightComponent={
							<div>
								<Button
									className={"mr-2"}
									outline
									onClick={toggleModalExportExcel}
									id="tooltipExcel"
								>
									<FaRegFileExcel style={{ margin: 0 }} />
								</Button>
								<UncontrolledTooltip target="tooltipExcel">
									Exportar os dados da tabela
								</UncontrolledTooltip>
							</div>
						}
						columns={[
							{
								Header: "Data Solicitação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatShort)
										: "--",
								width: 85
							},
							{
								Header: "Última Atualização",
								id: "updatedAt",
								accessor: c =>
									c.updatedAt
										? dayjs(c.updatedAt).format(ls.dateFormatShort)
										: "--",
								width: 140,
								show: false
							},
							{
								Header: "Status",
								id: "status",
								show: true,
								accessor: ({ status }) => {
									switch (status) {
										case nfseStatusConst.Generated:
											return (
												<Badge color="success" className="w-100">
													GERADO
												</Badge>
											);
										case nfseStatusConst.Pending:
											return (
												<Badge color="secondary" className="w-100">
													PENDENTE
												</Badge>
											);
										case nfseStatusConst.Canceled:
											return (
												<Badge color="danger" className="w-100">
													CANCELADO
												</Badge>
											);
										case nfseStatusConst.rejected:
											return (
												<Badge
													className="w-100"
													style={{ backgroundColor: "orange" }}
												>
													REJEITADO
												</Badge>
											);
										case nfseStatusConst.waitApproval:
											return (
												<Badge
													className="w-100"
													style={{ backgroundColor: "brown" }}
												>
													AGUARDANDO APROVAÇÃO
												</Badge>
											);
										case nfseStatusConst.Error:
											return (
												<Badge color="danger" className="w-100">
													ERRO
												</Badge>
											);

										default:
											return "";
									}
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(nfseStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[nfseStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 90
							},
							{
								Header: "Empresa",
								id: "mei.businessName",
								filterable: true,
								accessor: ({ mei }) =>
									mei ? mei?.businessName : mei?.socialName
							},
							{
								Header: "Cliente",
								id: "clientMei.businessName",
								filterable: true,
								accessor: ({ clientMei, client }) =>
									clientMei?.businessName ||
									client?.mei?.cnpj ||
									client?.name ||
									client?.document ||
									""
							},
							{
								Header: "CNPJ Cliente",
								id: "clientMei.cnpj",
								accessor: ({ clientMei, client }) =>
									clientMei || client
										? stringToMask(
												clientMei?.cnpj ||
													client?.mei?.cnpj ||
													client?.document,
												"##.###.###/####-##"
										  )
										: "",
								width: 150,
								show: false
							},
							{
								Header: "Email Cliente",
								id: "contactCompany.email",
								accessor: ({ contactCompany }) => contactCompany?.email || "",
								show: false
							},
							{
								Header: "Emitido por",
								id: "user.name",
								accessor: ({ user }) => (user ? getUserFullName(user) : ""),
								width: 250
							},
							{
								Header: "Valor Total",
								id: "totalValue",
								filterable: true,
								accessor: c =>
									c.totalValue ? toStringCurrency(+c.totalValue / 100.0) : "--",
								width: 100,
								show: false
							},
							{
								Header: "Login Emissão NF",
								id: "mei.nfEmissionLogin",
								accessor: ({ mei }) => mei?.nfEmissionLogin || "--",
								show: false
							},
							{
								Header: "Password Emissão NF",
								id: "mei.nfEmissionPassword",
								accessor: ({ mei }) => mei?.nfEmissionPassword || "--",
								show: false
							},
							{
								Header: "Url Emissão NF",
								id: "mei.nfEmissionUrl",
								accessor: ({ mei }) => mei?.nfEmissionUrl || "--",
								show: false
							},
							{
								Header: "Link Arquivo NF",
								id: "file",
								accessor: ({ file }) => file || "--",
								show: false
							}
						]}
					/>
				</Col>
			</Row>

			<Modal isOpen={modalExportExcel} toggle={toggleModalExportExcel}>
				<ModalHeader
					toggle={toggleModalExportExcel}
					className={"card__title bold-text"}
				>
					Exportar Relatório
				</ModalHeader>

				<ModalBody className="theme-light">
					<ExportExcelForm onSubmit={onSubmitExportExcel} progress={progress} />
				</ModalBody>
			</Modal>
		</Container>
	);
}

export default NFSe;
