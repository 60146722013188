import dayjs from "dayjs";
import { stringToMask, toStringCurrency } from "helpers/string";
import ls from "Localization";
import React from "react";
import { Badge, Card, Col, Container, Row } from "reactstrap";
import {
	contractStatusConst,
	contractTemplateTypes,
	contractTemplateTypesConst,
	signerStatus
} from "types/contractStatus";

const DetailContract = ({ contract }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3">
									<h4>
										<strong>Nome do Contrato: </strong>
									</h4>

									<h4>{contract?.template?.name}</h4>
									<Row>
										{contract.mainContract && (
											<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
												<strong>Contrato Principal: </strong>
												<br />
												<span>{contract.mainContract?.template?.name}</span>
											</Col>
										)}
										{contract.externalId && (
											<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
												<p>
													<strong>Código Contrato: </strong>
													<br />
													{contract.externalId}
												</p>
											</Col>
										)}
									</Row>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<strong>Sequência do Contrato: </strong>
									<br />
									<span>
										{contract.isMainContract && "Principal - "}
										{contract.sequence}
									</span>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<strong>Tipo de Contrato: </strong>
									<br />
									<div style={{ width: "8rem" }}>
										{ContractTemplateType(contract?.template?.type)}
									</div>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<strong>Status: </strong>
									<br />
									<div style={{ width: "6rem" }}>
										{ContractStatusBadge(
											contract.status,
											contract.mei?.useOwnContracts
										)}
									</div>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Prestador de Serviço</strong>
									</h4>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Prestador: </strong>
										<br />
										<span>
											{contract.hired && contract.hired.businessName
												? `${contract.hired.businessName}`
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Cnpj: </strong>
										<br />
										<span>
											{contract.hired && contract.hired.cnpj
												? `${stringToMask(
														contract.hired.cnpj,
														"##.###.###/####-##"
												  )}`
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Assinatura: </strong>
										<br />
										{statusPart(contract.hiredStatus)}
									</p>
								</Col>

								{contract.hiredToken && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
										<p>
											<strong>Código Assinatura Prestador: </strong>
											<br />
											{contract.hiredToken}
										</p>
									</Col>
								)}
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Tomador do Serviço:</strong>
									</h4>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Razão Social: </strong>
										<br />
										<span>
											{contract.mei && contract.mei.businessName
												? `${contract.mei.businessName}`
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Cnpj: </strong>
										<br />
										<span>
											{contract.mei && contract.mei.cnpj
												? `${stringToMask(
														contract.mei.cnpj,
														"##.###.###/####-##"
												  )}`
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Assinatura: </strong>
										<br />
										{statusPart(contract.hirerStatus)}
									</p>
								</Col>

								{contract.hirerToken && (
									<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
										<p>
											<strong>Código Assinatura Contratante: </strong>
											<br />
											{contract.hirerToken}
										</p>
									</Col>
								)}
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Informações do Contrato:</strong>
									</h4>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Date de inicio: </strong>
										<br />
										<span>
											{contract.startDate
												? dayjs(contract.startDate).format(ls.dateFormatShort)
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Data de Fim: </strong>
										<br />
										<span>
											{contract.due
												? dayjs(contract.due).format(ls.dateFormatShort)
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Vigência (em meses): </strong>
										<br />
										<span>{contract.lifespan || ""}</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Arquivo do contrato: </strong>
										<br />
										<span>
											{contract.file ? (
												<Badge
													color="primary"
													href={contract.file}
													target={"_blank"}
												>
													Clique Aqui!!
												</Badge>
											) : (
												""
											)}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Valor do contrato: </strong>
										<br />
										<span>
											{toStringCurrency((contract.paymentValue || 0) / 100.0)}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Primeiro pagamento: </strong>
										<br />
										<span>
											{contract.paymentDate
												? dayjs(contract.paymentDate).format(ls.dateFormatShort)
												: ""}
										</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Comentário</strong>
									</h4>
									<br />
									<span>{contract.note}</span>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{contract.createdAt
												? dayjs(contract.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Criado por: </strong>
										<br />
										{contract.createdBy ? (
											<a
												href={`/client/${contract.createdBy?._id}`}
												target="_blank"
												without
												rel="noreferrer"
											>
												{contract.createdBy?.name}
											</a>
										) : (
											""
										)}
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Departamento: </strong>
										<br />
										<span>
											{contract.department ? (
												<Badge color="primary">
													{contract.department?.name}
												</Badge>
											) : (
												""
											)}
										</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Data Atualização: </strong>
										<br />
										<span>
											{contract.updatedAt
												? dayjs(contract.updatedAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Atualizado por: </strong>
										<br />
										{contract.updatedBy ? (
											<a
												href={`/client/${contract.updatedBy?._id}`}
												target="_blank"
												without
												rel="noreferrer"
											>
												{contract.updatedBy?.name}
											</a>
										) : (
											""
										)}
									</p>
								</Col>
							</Row>
						</Col>

						{contract?.file ? (
							<Col
								className="mt-3"
								xs={12}
								sm={12}
								md={6}
								xl={6}
								style={{ height: 600 }}
							>
								<object
									data={contract?.file}
									width="100%"
									height="100%"
								></object>
							</Col>
						) : null}
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailContract;

export const statusPart = status => {
	if (status == 1) {
		return <Badge color="primary">{ls[signerStatus[status]]}</Badge>;
	} else if (status == 0) {
		return <Badge color="secondary">{ls[signerStatus[status]]}</Badge>;
	}
};

export const ContractTemplateType = type => {
	switch (type) {
		case contractTemplateTypesConst.Others:
			return (
				<Badge color="secondary" className="w-100">
					{ls[contractTemplateTypes[contractTemplateTypesConst.Others]]}
				</Badge>
			);
		case contractTemplateTypesConst.Nda:
			return (
				<Badge color="secondary" className="w-100">
					{ls[contractTemplateTypes[contractTemplateTypesConst.Nda]]}
				</Badge>
			);
		case contractTemplateTypesConst.Confidentiality:
			return (
				<Badge color="secondary" className="w-100">
					{
						ls[
							contractTemplateTypes[contractTemplateTypesConst.Confidentiality]
						]
					}
				</Badge>
			);
		case contractTemplateTypesConst.Lending:
			return (
				<Badge color="secondary" className="w-100">
					{ls[contractTemplateTypes[contractTemplateTypesConst.Lending]]}
				</Badge>
			);
		case contractTemplateTypesConst.ServicesProvision:
			return (
				<Badge color="secondary" className="w-100">
					{
						ls[
							contractTemplateTypes[
								contractTemplateTypesConst.ServicesProvision
							]
						]
					}
				</Badge>
			);
		case contractTemplateTypesConst.ContractAmendment:
			return (
				<Badge color="secondary" className="w-100">
					{
						ls[
							contractTemplateTypes[
								contractTemplateTypesConst.ContractAmendment
							]
						]
					}
				</Badge>
			);
		case contractTemplateTypesConst.Dissolution:
			return (
				<Badge color="secondary" className="w-100">
					{ls[contractTemplateTypes[contractTemplateTypesConst.Dissolution]]}
				</Badge>
			);

		default:
			return "";
	}
};

export const ContractStatusBadge = (status, useOwnContracts) => {
	if (useOwnContracts) {
		return (
			<Badge color="info" className="w-100">
				NÃO OPTANTE
			</Badge>
		);
	}

	switch (status) {
		case contractStatusConst.Signed:
			return (
				<Badge color="success" className="w-100">
					ASSINADO
				</Badge>
			);
		case contractStatusConst.Pending:
			return (
				<Badge color="secondary" className="w-100">
					PENDENTE
				</Badge>
			);
		case contractStatusConst.Due:
			return (
				<Badge color="danger" className="w-100">
					VENCIDO
				</Badge>
			);
		case contractStatusConst.Cancelled:
			return (
				<Badge className="w-100" style={{ backgroundColor: "orange" }}>
					CANCELADO
				</Badge>
			);
		case contractStatusConst.Rescind:
			return (
				<Badge className="w-100" style={{ backgroundColor: "brown" }}>
					RESCINDIDO
				</Badge>
			);
		case contractStatusConst.Excluded:
			return (
				<Badge className="w-100" style={{ backgroundColor: "red" }}>
					EXCLUÍDO
				</Badge>
			);
		case contractStatusConst.AwaitingSignature:
			return (
				<Badge
					className="w-100"
					style={{ backgroundColor: "yellow", color: "black" }}
				>
					AGUARDANDO
					<br />
					ASSINATURA
				</Badge>
			);

		default:
			return (
				<Badge color="secondary" className="w-100">
					Pendente
				</Badge>
			);
	}
};
