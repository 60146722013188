import React, { useState } from "react";
import Input from "components/ReduxForm/Input";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import EyeIcon from "mdi-react/EyeIcon";

const NFSeDataEditForm = ({
	handleSubmit,
	reset,
	errors,
	submitting,
	loading,
	isInvalid
}) => {
	const [showNfEmissionPassword, setShowNfEmissionPassword] = useState(false);
	const toggleNfEmissionPassword = () =>
		setShowNfEmissionPassword(!showNfEmissionPassword);

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label">Login</span>
						<div className="form__form-group-field">
							<Field
								name="nfEmissionLogin"
								placeholder="suamei"
								component={Input}
								type="text"
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label">Senha</span>
						<div className="form__form-group-field">
							<Field
								name="nfEmissionPassword"
								component={Input}
								type={showNfEmissionPassword ? "text" : "password"}
								placeholder="Ex.: Senha"
								props={{ errors: errors && errors.showNfEmissionPassword }}
								autoComplete={"new-password"}
							/>
							<button
								className={`form__form-group-button${
									showNfEmissionPassword ? " active" : ""
								}`}
								onClick={toggleNfEmissionPassword}
								type="button"
							>
								<EyeIcon />
							</button>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label">URL</span>
						<div className="form__form-group-field">
							<Field
								name="nfEmissionUrl"
								placeholder="suamei.com.br"
								component={Input}
								type="text"
							/>
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading.create}
				>
					Salvar
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "nfse_data_edit_form"
})(NFSeDataEditForm);
