import React from "react";
import { Card, Row, Col, Container, Badge } from "reactstrap";
import dayjs from "dayjs";
import ls from "Localization";
import {
	MeiStatus,
	UserStatus,
	UserGender,
	UserMaritalStats
} from "types/userStatus";
import { stringToMask } from "helpers/string";
import phoneNumber from "helpers/phoneNumber";

const DetailsClient = ({ client }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>{`${client.name || "--"} ${client.lastname ||
											""}`}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>RG: </strong>
										<br />
										<span>{client.rg || "--"}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Órgão Emissor: </strong>
										<br />
										<span>{client.rgEmissor || "--"}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Estado do Órgão Emissor: </strong>
										<br />
										<span>{client.rgEmissorState || "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>CPF: </strong>
										<br />
										<span>
											{client.cpf
												? stringToMask(client.cpf, "###.###.###-##")
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Título de Eleitor: </strong>
										<br />
										<span>{client.titulo || "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>{ls.birthDate}: </strong>
										<br />
										<span>
											{client.birthDate
												? dayjs.utc(client.birthDate).format(ls.dateFormatShort)
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Telefone: </strong>
										<br />
										<span>{phoneNumber.format(client.phoneNumber) || "-"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>{ls.role}: </strong>
										<br />
										<span>{client.role ? ls[client.role] : "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Email: </strong>
										<br />
										<span>{client.email ? client.email : "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Senha Gov.br </strong>
										<br />
										<span>
											{client.govPassword ? client.govPassword : "--"}
										</span>
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Genêro</strong>
										<br />
										<span>
											{UserGender[client.gender]
												? ls[UserGender[client.gender]]
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Estado Civil</strong>
										<br />
										<span>
											{UserMaritalStats[client.maritalStatus]
												? ls[UserMaritalStats[client.maritalStatus]]
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>{ls.userStatus}: </strong>
										<br />
										<span>
											{client.userStatus !== undefined ? (
												<Badge
													color={client.userStatus ? "success" : "danger"}
													style={{
														fontSize: "12px",
														textTransform: "uppercase",
														padding: "2px 8px"
													}}
												>
													{ls[UserStatus[client.userStatus]]}
												</Badge>
											) : (
												"--"
											)}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>{ls.meiStatus}: </strong>
										<br />
										<span>
											{client.status !== undefined
												? ls[MeiStatus[client.status]]
												: "--"}
										</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{client.createdAt
												? dayjs(client.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Criado por: </strong>
										<br />
										{client.createdBy ? (
											<a
												href={`/client/${client.createdBy?._id}`}
												target="_blank"
												without
												rel="noreferrer"
											>
												{client.createdBy?.name}
											</a>
										) : (
											"--"
										)}
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data Atualização: </strong>
										<br />
										<span>
											{client.updatedAt
												? dayjs(client.updatedAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Atualizado por: </strong>
										<br />
										{client.updatedBy ? (
											<a
												href={`/client/${client.updatedBy?._id}`}
												target="_blank"
												without
												rel="noreferrer"
											>
												{client.updatedBy?.name}
											</a>
										) : (
											"--"
										)}
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailsClient;
