import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Card,
	CardBody,
	Modal,
	ModalBody,
	ModalHeader,
	Tooltip
} from "reactstrap";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";
import ls from "Localization";
import {
	contractStatus,
	contractStatusConst,
	contractTemplateTypes,
	contractTemplateTypesConst
} from "types/contractStatus";
import ReportTable from "components/Report";
import { FiFilter } from "react-icons/fi";
import FiltersForm from "containers/Contracts/components/FiltersForm";
import { stringToMask } from "helpers/string";
import {
	getContractList,
	clearValues
} from "containers/Contracts/containers/ReportAll/actions";

dayjs.extend(customParseFormat);

let getContractsDebounced = null;

const ContractList = ({
	hiredId,
	contractId,
	mainContractId,
	isMainContract,
	title
}) => {
	const dispatch = useDispatch();

	const table = useRef(null);
	const [tableRef, setTableRef] = useState();

	const { items: contractList, pages, count } = useSelector(s => s.contracts);

	const filters_form = useSelector(s => s.form.filters_form);

	const [filterDates, setFilterDates] = useState("");

	const [modalFilters, setModalFilters] = useState(false);
	const toggleFilters = () => setModalFilters(!modalFilters);

	const [tooltipFilters, settooltipFilters] = useState(false);
	const toggletooltipFilters = () => settooltipFilters(!tooltipFilters);

	const handleFetchData = tableState => {
		if (!tableState) return;
		setTableRef(tableState);

		if (hiredId || contractId || mainContractId) {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getContractsDebounced) {
				getContractsDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let filterStr = "";
			filterStr += hiredId ? `&filters[hired]=${hiredId}` : "";

			if (isMainContract)
				filterStr += contractId
					? `&filters[contractSequence]=${contractId}`
					: "";
			else
				filterStr += mainContractId
					? `&filters[contractSequence]=${mainContractId}`
					: "";

			if (!filterDates && !filterStr) return;

			getContractsDebounced = _.debounce(
				() =>
					dispatch(
						getContractList(
							page * pageSize,
							pageSize,
							filterDates +
								filterStr +
								filtered.reduce((p, c) => {
									return p + `&filters[${c.id}]=${c.value}`;
								}, ""),
							sorted[0] ? sorted[0].id : undefined,
							sorted[0] ? sorted[0].desc : undefined,
							toExport,
							callback
						)
					),
				500
			);

			getContractsDebounced();
		}
	};

	const handleFilter = data => {
		if (getContractsDebounced) {
			getContractsDebounced.cancel();
		}

		let { page, pageSize, sorted, filtered } = tableRef;

		let filter = `&filters[hired]=${hiredId}`;

		Object.keys(data).forEach(el => {
			filter += `&filters[${el}]=${data[el]}`;
		});

		setFilterDates(filter);

		getContractsDebounced = _.debounce(
			() =>
				dispatch(
					getContractList(
						page * pageSize,
						pageSize,
						filter +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						null,
						() => {
							toggleFilters();
						}
					)
				),
			500
		);

		getContractsDebounced();
	};

	useEffect(() => {
		handleFetchData(table.current ? table.current.state : undefined);
	}, [hiredId, contractId, mainContractId]);

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	return (
		<Card>
			<CardBody>
				<ReportTable
					manual
					tableRef={table}
					title={title}
					data={contractList}
					pages={pages}
					count={count}
					filterable
					noExport={true}
					onFetchData={handleFetchData}
					onRowClicked={row => {
						let win = window.open(`/contracts/${row._id}`, "_blank");
						win.focus();
					}}
					headerRightComponent={
						<div>
							<Button
								className={"mr-2"}
								outline
								onClick={toggleFilters}
								id="tooltipFilters"
							>
								<FiFilter />
							</Button>
							<Tooltip
								placement="top"
								isOpen={tooltipFilters}
								target="tooltipFilters"
								toggle={toggletooltipFilters}
							>
								Filtros
							</Tooltip>
						</div>
					}
					defaultSorted={[
						{
							id: "sequence",
							desc: false
						}
					]}
					columns={[
						{
							Header: "Id",
							id: "_id",
							accessor: c => c._id,
							width: 85,
							show: false
						},
						{
							Header: (
								<span>
									Data <br />
									Cadastro
								</span>
							),
							id: "createdAt",
							accessor: c =>
								c.createdAt
									? dayjs(c.createdAt).format(ls.dateFormatShort)
									: "",
							width: 75
						},
						{
							Header: "Última atualização",
							id: "updatedAt",
							accessor: c =>
								c.updatedAt
									? dayjs(c.updatedAt).format(ls.dateFormatWithoutSeconds)
									: "",
							show: false
						},
						{
							Header: "Id Template",
							id: "template._id",
							filterable: true,
							accessor: ({ template }) => template?._id || "Não definido",
							show: false
						},
						{
							Header: "Id Contratante",
							id: "mei._id",
							filterable: true,
							accessor: ({ mei }) => mei?._id || "Não definido",
							show: false
						},
						{
							Header: "Contratante",
							id: "mei.businessName",
							filterable: true,
							accessor: ({ mei }) =>
								mei?.businessName
									? `${mei?.businessName}${
											mei?.cnpj
												? " - " + stringToMask(mei?.cnpj, "##.###.###/####-##")
												: ""
									  }`
									: "Não definido",
							show: false
						},
						{
							Header: "Id Prestador",
							id: "hired._id",
							filterable: true,
							accessor: ({ hired }) => hired._id || "Não definido",
							show: false
						},
						{
							Header: "Prestador",
							id: "hired.businessName",
							filterable: true,
							accessor: ({ hired }) => hired.businessName || "Não definido"
						},
						{
							Header: "ID Externo",
							id: "template.externalId",
							filterable: true,
							accessor: ({ template }) => template.externalId || "Não definido",
							width: 180,
							show: false
						},
						{
							Header: "Nome Contrato",
							id: "template.name",
							filterable: true,
							accessor: ({ template }) => template?.name || "Não definido"
						},
						{
							Header: "Categoria",
							id: "template.type",
							show: true,
							accessor: ({ template }) => {
								switch (template?.type) {
									case contractTemplateTypesConst.Others:
										return (
											<Badge
												style={{ backgroundColor: "red" }}
												className="w-100"
											>
												OUTROS
											</Badge>
										);
									case contractTemplateTypesConst.Nda:
										return (
											<Badge
												style={{ backgroundColor: "brown" }}
												className="w-100"
											>
												NDA
											</Badge>
										);
									case contractTemplateTypesConst.Confidentiality:
										return (
											<Badge
												style={{ backgroundColor: "orange" }}
												className="w-100"
											>
												CONFIDENCIALIDADE
											</Badge>
										);
									case contractTemplateTypesConst.Lending:
										return (
											<Badge
												style={{ backgroundColor: "blue" }}
												className="w-100"
											>
												EMPRÉSTIMO
											</Badge>
										);
									case contractTemplateTypesConst.ServicesProvision:
										return (
											<Badge
												style={{ backgroundColor: "darkgreen" }}
												className="w-100"
											>
												PRESTAÇÃO DE SERVIÇOS
											</Badge>
										);
									case contractTemplateTypesConst.ContractAmendment:
										return (
											<Badge
												style={{ backgroundColor: "forestgreen" }}
												className="w-100"
											>
												ADITIVO CONTRATUAL
											</Badge>
										);
									case contractTemplateTypesConst.Dissolution:
										return (
											<Badge
												style={{ backgroundColor: "darkred" }}
												className="w-100"
											>
												DISTRATO
											</Badge>
										);

									default:
										return "";
								}
							},
							Filter: ({ filter, onChange }) => {
								return (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter && filter.value ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(contractTemplateTypes)
											.filter(c => !isNaN(+c))
											.map(c => (
												<option key={c} value={c}>
													{ls[contractTemplateTypes[c]]}
												</option>
											))}
									</select>
								);
							},
							width: 90
						},
						{
							Header: "Contrato Principal",
							id: "mainContract.template.name",
							accessor: ({ mainContract }) =>
								mainContract?.template?.name || "",
							width: 180
						},
						{
							Header: (
								<span>
									Sequência do
									<br />
									Contrato
								</span>
							),
							id: "sequence",
							accessor: ({ sequence }) => `${sequence || ""}`,
							className: "justify-content-start",
							width: 65
						},
						{
							Header: (
								<span>
									Data de
									<br />
									inicio
								</span>
							),
							id: "startDate",
							accessor: c =>
								c.startDate
									? dayjs(c.startDate).format(ls.dateFormatShort)
									: "",
							className: "justify-content-center",
							width: 75
						},
						{
							Header: (
								<span>
									Data de
									<br />
									Fim
								</span>
							),
							id: "due",
							accessor: c =>
								c.due ? dayjs(c.due).format(ls.dateFormatShort) : "",
							className: "justify-content-center",
							width: 75
						},
						{
							Header: (
								<span>
									Vigência
									<br />
									(em meses)
								</span>
							),
							id: "lifespan",
							accessor: c => c.lifespan || "",
							className: "justify-content-center",
							width: 90
						},
						{
							Header: (
								<span>
									Status
									<br />
									Contrato
								</span>
							),
							id: "status",
							show: true,
							accessor: ({ status, mei }) => {
								if (mei.useOwnContracts) {
									return (
										<Badge color="info" className="w-100">
											NÃO OPTANTE
										</Badge>
									);
								}

								switch (status) {
									case contractStatusConst.Signed:
										return (
											<Badge color="success" className="w-100">
												ASSINADO
											</Badge>
										);
									case contractStatusConst.Pending:
										return (
											<Badge color="secondary" className="w-100">
												PENDENTE
											</Badge>
										);
									case contractStatusConst.Due:
										return (
											<Badge color="danger" className="w-100">
												VENCIDO
											</Badge>
										);
									case contractStatusConst.Cancelled:
										return (
											<Badge
												className="w-100"
												style={{ backgroundColor: "orange" }}
											>
												CANCELADO
											</Badge>
										);
									case contractStatusConst.Rescind:
										return (
											<Badge
												className="w-100"
												style={{ backgroundColor: "brown" }}
											>
												RESCINDIDO
											</Badge>
										);
									case contractStatusConst.Excluded:
										return (
											<Badge
												className="w-100"
												style={{ backgroundColor: "red" }}
											>
												EXCLUÍDO
											</Badge>
										);
									case contractStatusConst.AwaitingSignature:
										return (
											<Badge
												className="w-100"
												style={{
													backgroundColor: "yellow",
													color: "black"
												}}
											>
												AGUARDANDO ASSINATURA
											</Badge>
										);

									default:
										return (
											<Badge color="secondary" className="w-100">
												Pendente
											</Badge>
										);
								}
							},
							Filter: ({ filter, onChange }) => {
								return (
									<select
										onChange={event => onChange(event.target.value)}
										style={{ width: "100%" }}
										value={filter && filter.value ? filter.value : "all"}
									>
										<option value="">Todos</option>
										{Object.keys(contractStatus)
											.filter(c => !isNaN(+c))
											.map(c => (
												<option key={c} value={c}>
													{ls[contractStatus[c]]}
												</option>
											))}
									</select>
								);
							},
							width: 85
						},
						{
							Header: "Assinatura Prestador",
							id: "hiredStatus",
							filterable: true,
							accessor: ({ hiredStatus }) => hiredStatus || "Não definido",
							show: false
						},
						{
							Header: "Token Prestador",
							id: "hiredToken",
							filterable: true,
							accessor: ({ hiredToken }) => hiredToken || "Não definido",
							show: false
						},
						{
							Header: "Assinatura Contratante",
							id: "hirerStatus",
							filterable: true,
							accessor: ({ hirerStatus }) => hirerStatus || "Não definido",
							show: false
						},
						{
							Header: "Token Contratante",
							id: "hirerToken",
							filterable: true,
							accessor: ({ hirerToken }) => hirerToken || "Não definido",
							show: false
						},
						{
							Header: "Data de Pagamento",
							id: "paymentDate",
							filterable: true,
							accessor: c =>
								c.due
									? dayjs(c.paymentDate).format(ls.dateFormatShort)
									: "Não definida",
							show: false
						},
						{
							Header: "Intervalo de Pagamento",
							id: "paymentInterval",
							filterable: true,
							accessor: ({ paymentInterval }) =>
								paymentInterval || "Não definido",
							show: false
						},
						{
							Header: "Período de Pagamento",
							id: "paymentPeriod",
							filterable: true,
							accessor: ({ paymentPeriod }) => paymentPeriod || "Não definido",
							show: false
						},
						{
							Header: "Valor de Pagamento",
							id: "paymentValue",
							filterable: true,
							accessor: ({ paymentValue }) => paymentValue || "Não definido",
							show: false
						}
					]}
				/>
			</CardBody>

			<Modal isOpen={modalFilters} toggle={toggleFilters}>
				<ModalHeader toggle={toggleFilters}>Filtros</ModalHeader>
				<ModalBody>
					<FiltersForm
						onSubmit={handleFilter}
						isInvalid={filters_form && !!filters_form.syncErrors}
					/>
				</ModalBody>
			</Modal>
		</Card>
	);
};

export default ContractList;
