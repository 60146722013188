/* eslint-disable no-unused-vars */
import React from "react";
import Input from "components/ReduxForm/Input";
import validate from "./validate";
import { Phone } from "components/NumberFormat";

import { reduxForm, Field } from "redux-form";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import renderCheckBoxField from "shared/components/form/CheckBox";

const AccessForm = ({ handleSubmit, initialValues }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">Nome</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: Fulano de Tal"
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">Email</span>
						<div className="form__form-group-field">
							<Field
								name="email"
								component={Input}
								type="text"
								placeholder="Ex.: email@email.com"
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">Telefone</span>
						<div className="form__form-group-field">
							<Field
								name="phoneNumber"
								component={Phone}
								type="text"
								placeholder="Ex.: (00) 00000-0000"
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">
							Cnpj (Opcional)
						</span>
						<div className="form__form-group-field">
							<Field
								name="cnpj"
								component={Input}
								type="text"
								placeholder="Ex.: 99.999.999/0009-99"
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className="form__form-group">
						<div className="form__form-group-field">
							<Field name="useOwnContracts" component={renderCheckBoxField} />
							<span className="form__form-group-label bold-text">
								Usar contratos próprios da empresa?
							</span>
						</div>
					</Col>
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar mt-5">
				<Button color="primary" type="submit">
					Salvar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "access_form",
	validate
})(AccessForm);
