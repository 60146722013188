import React from "react";
import { Route, Switch } from "react-router-dom";
import NFSeDetail from "./containers/Page/Detail/index";
import EditNFSe from "./containers/Page/Edit/index";
import Report from "./containers/Report";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/nfses" component={Report} />
				<Route exact path="/nfses/:id" component={NFSeDetail} />
				<Route exact path="/nfses/:id/edit" component={EditNFSe} />
			</Switch>
		</React.Fragment>
	);
};

export default routes;
