import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";

export const CREATE_MARKETING = "[MARKETING CREATE] CREATE_MARKETING";
export const CREATE_MARKETING_SUCCESS =
	"[MARKETING CREATE] CREATE_MARKETING_SUCCESS";
export const CREATE_MARKETING_FAILED =
	"[MARKETING CREATE] CREATE_MARKETING_FAILED";

export const SET_VALUE = "[MARKETING CREATE] SET_VALUE";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function createMarketing(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_MARKETING });

		try {
			const { login, marketingCreate } = getState();

			const {
				users,
				economicMode,
				emailSubject,
				emailMessage,
				smsMessage,
				whatsAppMessage,
				whatsAppRestrict
			} = marketingCreate;

			const {
				name,
				startAt,
				pushAction,
				pushMessage,
				pushTitle,
				pushValue
			} = data;

			const model = {
				name,
				users: users.map(c => ({
					...c,
					user: c.user ? c.user._id : undefined
				})),
				startAt,
				economicMode,
				emailSubject,
				emailMessage,
				smsMessage,
				whatsAppMessage,
				whatsAppFile: "",
				whatsAppRestrict,
				pushTitle,
				pushMessage,
				pushAction,
				pushValue
			};

			const response = await api.sendPost("/marketing", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_MARKETING_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_MARKETING_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_MARKETING_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
