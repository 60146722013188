import Report from "components/Report";
import _ from "lodash";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import AddPermissionGroups from "../../Create/PermissionGroups";
import { getGroupsPerUser } from "./actions";

let getPermissionGroupsDebounced = null;

function PermissionGroups() {
	const dispatch = useDispatch();
	let history = useHistory();
	const {
		params: { id }
	} = useRouteMatch();

	const state = useSelector(s => s.userPermissionGroups);

	const table = useRef(null);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getPermissionGroupsDebounced) {
			getPermissionGroupsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate && creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate && creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getPermissionGroupsDebounced = _.debounce(
			() =>
				dispatch(
					getGroupsPerUser(
						page * pageSize,
						pageSize,
						filtered.reduce(
							(p, c) => p + `&filters[${c.id}]=${c.value}`,
							`&filters[user]=${id}`
						),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getPermissionGroupsDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	return (
		<Report
			manual
			tableRef={table}
			title={"Grupo de Permissões"}
			headerRightComponent={<AddPermissionGroups load={load} />}
			data={state.items}
			pages={state.pages}
			loading={state.loading.getAll}
			onFetchData={handleFetchData}
			onRowClicked={row => history.push(`/group/${row._id}/details`)}
			defaultSorted={[
				{
					id: "createdAt",
					desc: true
				}
			]}
			pageSize={
				state.items.length > 10
					? 10
					: state.items.length <= 0
					? 1
					: state.items.length + 1
			}
			columns={[
				{
					Header: "Nome",
					id: "name",
					accessor: "name",
					width: 200
				},
				{
					Header: "Descrição",
					id: "description",
					accessor: "description"
				}
			]}
		/>
	);
}

export default PermissionGroups;
