import ReportTable from "components/Report";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import * as actions from "./actions";
import { clearValues } from "./actions";

let getDataDebounced = null;

function PermissionGroupsReport({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.groups);

	const handleFetchData = tableState => {
		if (!tableState) {
			return;
		}
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getDataDebounced) {
			getDataDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}
		getDataDebounced = _.debounce(
			() =>
				dispatch(
					actions.getGroups(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => {
							return p + `&filters[${c.id}]=${c.value}`;
						}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getDataDebounced();
	};

	const load = () => {
		dispatch(clearValues());
		handleFetchData(table.current ? table.current.state : undefined);
	};

	useEffect(() => {
		load();
	}, []);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Grupo de Permissões"}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						onRowClicked={row => history.push(`/group/${row._id}/details`)}
						columns={[
							{
								Header: "Nome",
								id: "name",
								accessor: "name",
								width: 300
							},
							{
								Header: "Descrição",
								id: "description",
								accessor: "description"
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default PermissionGroupsReport;
