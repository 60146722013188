import * as api from "services/api/index";
import { getErrors } from "helpers/error";

export const GET_BENEFIT = "[BENEFIT DETAIL] GET_BENEFIT";
export const GET_BENEFIT_ERROR = "[BENEFIT DETAIL] GET_BENEFIT_ERROR";
export const GET_BENEFIT_SUCCESS = "[BENEFIT DETAIL] GET_BENEFIT_SUCCESS";

export const DELETE_BENEFIT = "[BENEFIT DETAIL] DELETE_BENEFIT";
export const DELETE_BENEFIT_ERROR = "[BENEFIT DETAIL] DELETE_BENEFIT_ERROR";
export const DELETE_BENEFIT_SUCCESS = "[BENEFIT DETAIL] DELETE_BENEFIT_SUCCESS";

export const SET_VALUE = "[BENEFIT DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[BENEFIT DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getBenefit(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_BENEFIT
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(`/benefit/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BENEFIT_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_BENEFIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BENEFIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteBenefit(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_BENEFIT
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/benefit/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_BENEFIT_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				dispatch({
					type: DELETE_BENEFIT_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: DELETE_BENEFIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
