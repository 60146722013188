const validate = (values = {}) => {
	const errors = {};

	if (!values.name) errors.name = "Informe o modelo do contrato";

	if (!values.startDate) errors.startDate = "Informe a data de inicio";

	if (values.selfRenewable) {
		if (!values.lifespan)
			errors.lifespan = "Informe a duração da vigência (em meses)";
	} else {
		if (!values.due) errors.due = "Informe data de fim do contrato";
	}

	return errors;
};

export default validate;
