import React from "react";
import Input from "components/ReduxForm/Input";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";

const DepartmentFormEdit = ({
	handleSubmit,
	reset,
	loading,
	errors,
	submitting,
	isInvalid
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col className="form__form-group">
						<span className="bold-text">Departamento</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Insira o nome do departamento"
								props={{ errors: errors && errors.name }}
							/>
						</div>
					</Col>
				</Row>

				<ButtonToolbar className="form__button-toolbar">
					<Button
						color="primary"
						type="submit"
						disabled={submitting || isInvalid || loading.new}
					>
						{loading.new ? (
							<Spinner type="grow" size="sm" color="dark" />
						) : (
							"Salvar"
						)}
					</Button>
					<Button type="button" onClick={reset}>
						Limpar
					</Button>
				</ButtonToolbar>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "department_create",
	validate
})(DepartmentFormEdit);
