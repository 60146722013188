import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";
import {
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Spinner
} from "reactstrap";
import {
	AiOutlineCheckCircle,
	AiFillDelete,
	AiFillBook
} from "react-icons/all";
import { MdAdd } from "react-icons/md";

import * as api from "services/api";

import ReportTable from "components/Report";

import ls from "Localization";

import AddCompaniesModal from "../../containers/Create/Companies";
import { getClient } from "../../containers/Details/actions";
import { getCompanies } from "../../../Company/containers/Report/actions";
import { linkUserCompany } from "../../containers/Edit/actions";

let findCompanyDebounced = null;

dayjs.extend(customDateFormat);

function CompaniesListView({ history, user }) {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isDeletingCompany, setIsDeletingCompany] = useState(false);
	const [selectedCompanyToDelete, setSelectedCompanyToDelete] = useState("");

	const dispatch = useDispatch();
	const { pages, loading } = useSelector(state => state.clientDetail);
	const { token } = useSelector(s => s.login);

	const { items: companies } = useSelector(s => s.company);

	const handleFindCompany = user => {
		if (findCompanyDebounced) {
			findCompanyDebounced.cancel();
		}

		findCompanyDebounced = _.debounce(
			() =>
				dispatch(
					getCompanies(
						0,
						10,
						`${user ? `&filters[searchText]=${user}` : ""}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar as empresas, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findCompanyDebounced();
	};

	const onSubmit = data => {
		if (data.provider && data.provider.value) {
			if (user.meis.find(c => c?._id === data.provider.value)) {
				alert("Essa empresa já foi adicionada!");
				return;
			}

			user.meis.push({ _id: data.provider.value });

			dispatch(
				linkUserCompany(user._id, data.provider.value, err => {
					if (err) {
						console.log(err);
					} else {
						dispatch(getClient(user._id));
						alert("Empresa vinculada com sucesso");
					}
				})
			);
		}
	};

	const onChange = values => {
		if (values.providerSearch) {
			handleFindCompany(values.providerSearch);
		}
	};

	function handleSetModalVisible(companyId) {
		setIsModalVisible(prevState => !prevState);
		setSelectedCompanyToDelete(companyId);
		console.log({ user_id: user._id });
		console.log({ companyId });
	}

	async function handleRemoveCompany() {
		setIsDeletingCompany(true);
		console.log({ user_id: user._id });
		console.log({ selectedCompanyToDelete });

		try {
			const response = await api.sendPut(
				"/user/unlink/company",
				{
					user_id: user._id,
					company_id: selectedCompanyToDelete
				},
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				alert("Empresa removida com sucesso");
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsDeletingCompany(false);
			handleSetModalVisible(null);
		}
	}

	const getButton = company_id => {
		const size = 25;
		return (
			<Row>
				<Col md={2}></Col>
				<Col md={4}>
					<Button
						onClick={() => history.push(`/companies/${company_id}`)}
						color={"success"}
						style={{ width: size, height: size, padding: 0, margin: 5 }}
					>
						<AiFillBook style={{ width: 15, height: 15, margin: 0 }} />
					</Button>

					<Button
						onClick={() => handleSetModalVisible(company_id)}
						color={"danger"}
						style={{ width: size, height: size, padding: 0, margin: 5 }}
					>
						<AiFillDelete style={{ width: 15, height: 15, margin: 0 }} />
					</Button>
				</Col>
			</Row>
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title={"Empresas"}
						data={
							user.meis?.length > 0 && user.meis[0] != null ? user.meis : []
						}
						pages={pages}
						pageSize={5}
						loading={loading.getAll}
						headerRightComponent={
							<AddCompaniesModal
								onChange={onChange}
								providers={companies}
								onSubmit={onSubmit}
								userId={user._id}
								DisplayIcon={<MdAdd />}
								initialValues={{ provider: null }}
							/>
						}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds)
										: "--",
								width: 150
							},
							{
								Header: "Nome",
								id: "name",
								accessor: "businessName"
							},
							{
								Header: "CNPJ",
								id: "cnpj",
								accessor: "cnpj",
								width: 160
							},
							{
								Header: () => (
									<span>
										<AiOutlineCheckCircle /> Ações
									</span>
								),
								id: "_id",
								accessor: ({ _id }) => getButton(_id),
								width: 120
							}
						]}
					/>

					<Modal isOpen={isModalVisible} toggle={handleSetModalVisible}>
						<ModalHeader toggle={handleSetModalVisible}>
							Remover empresa vinculada
						</ModalHeader>

						<ModalBody>
							<p>
								Você tem certeza que deseja remover essa empresa da lista de
								empresas vinculadas?
							</p>
						</ModalBody>

						<ModalFooter>
							<Button
								color="danger"
								onClick={handleRemoveCompany}
								disabled={isDeletingCompany}
							>
								{isDeletingCompany ? (
									<Spinner type="grow" size="sm" color="light" />
								) : (
									"Remover"
								)}
							</Button>
							<Button color="secondary" onClick={handleSetModalVisible}>
								Cancelar
							</Button>
						</ModalFooter>
					</Modal>
				</Col>
			</Row>
		</Container>
	);
}

export default CompaniesListView;
