const validate = values => {
	const errors = {};

	if (!values.businessLicenseStatus) {
		errors.businessLicenseStatus = "Selecione o status do alvará";
	}

	if (values.isHired === true && !values.purchasedProviders) {
		errors.purchasedProviders = "Preencha o campo Quantidade Assinaturas";
	}

	return errors;
};

export default validate;
