export const nfeBusinessStatus = {
	0: "pending",
	1: "activating",
	2: "active",
	3: "NonOpting"
};

export const nfeBusinessStatusConst = {
	pending: 0,
	activating: 1,
	active: 2,
	NonOpting: 3
};
