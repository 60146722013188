function format(phoneNumber) {
	if (!phoneNumber) {
		return "";
	}

	let number = String(phoneNumber).replace(/\D/g, "");

	if (number.startsWith("55") && number.length > 11) {
		number = number.replace("55", "");
	}

	return number
		.replace(/^(\d{2})(\d)/g, "($1) $2")
		.replace(/(\d)(\d{4})$/, "$1-$2");
}

const phoneNumber = {
	format
};

export default phoneNumber;
