import * as api from "services/api";
import * as apiAxios from "services/api/axios";
import { getErrors } from "helpers/error";

export const GET_COMPANIES = "[COMPANIES] GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "[COMPANIES] GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILED = "[COMPANIES] GET_COMPANIES_FAILED";

export const PROCESS_COMPANIES = "[COMPANIES] PROCESS_COMPANIES";
export const PROCESS_COMPANIES_SUCCESS =
	"[COMPANIES] PROCESS_COMPANIES_SUCCESS";
export const PROCESS_COMPANIES_FAILED = "[COMPANIES] PROCESS_COMPANIES_FAILED";

export const SET_VALUE = "[COMPANY] SET_VALUE";
export const CLEAR_VALUES = "[COMPANY] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCompanies(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_COMPANIES });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/companyProvider/contracted-company-report?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_COMPANIES_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_COMPANIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_COMPANIES_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function reprocessCompanies(data, companies, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: PROCESS_COMPANIES });

		try {
			const { login } = getState();

			var response = await api.sendPost(
				`/companyProvider/process-company-providers`,
				{ hireds: companies, columns: data },
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: PROCESS_COMPANIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: PROCESS_COMPANIES_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getContractListExcel(skip, take, filter, sort, desc, callback) {
	return async (dispatch, getState) => {
		try {
			const { login } = getState();

			var response = await apiAxios.sendGet(
				"api",
				`/v2/relatorio/data-insurance?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}&CompanyProvider_deleted=false`,
				login.token,
				"bearer",
				null
			);

			if (response.data.length) {
				callback && callback(null, response.data);
			} else {
				callback && callback("errors");
			}
		} catch (error) {
			console.log("error -> ", error);
		}
	};
}
