import Input from "components/ReduxForm/Input";
import React from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";

const DigisacEditForm = ({
	handleSubmit,
	isInvalid,
	reset,
	onCancel,
	connectionTypesData,
	digisacAttendants
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={4} lg={4} className="form__form-group">
						<span>Tipo de Conexão:</span>
						<div className="form__form-group-field">
							<Field
								name="connectionType"
								component={renderSelectField}
								type="text"
								placeholder="Selecione"
								options={connectionTypesData}
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={4} className="form__form-group">
						<span>Atendente de Onboard:</span>
						<div className="form__form-group-field">
							<Field
								name="onboardAttendant"
								component={renderSelectField}
								type="text"
								placeholder="Selecione um atendente para Onboard"
								options={digisacAttendants}
							/>
						</div>
					</Col>
					<Col xs={12} sm={12} md={4} lg={4} className="form__form-group">
						<span>Atendente de Ongoing:</span>
						<div className="form__form-group-field">
							<Field
								name="ongoingAttendant"
								component={renderSelectField}
								type="text"
								placeholder="Selecione um atendente para Ongoing"
								options={digisacAttendants}
							/>
						</div>
					</Col>
				</Row>

				<ButtonToolbar className="form__button-toolbar">
					<Button
						type="button"
						onClick={() => {
							onCancel();
							reset();
						}}
					>
						Cancelar
					</Button>
					<Button color="primary" type="submit" disabled={isInvalid}>
						Salvar
					</Button>
				</ButtonToolbar>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "digisac_edit_form",
	validate
})(DigisacEditForm);

const validate = (values = {}) => {
	const errors = {};

	// if (!values.mei) errors.mei = "Informe o contratante";

	return errors;
};
