import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_PAYMENT_METHODS = "[PAYMENT_METHOD] GET_ALL";
export const GET_PAYMENT_METHODS_SUCCESS = "[PAYMENT_METHOD] GET_ALL_SUCCESS";
export const GET_PAYMENT_METHODS_FAILED = "[PAYMENT_METHOD] GET_ALL_FAILED";

export function getPaymentMethods(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PAYMENT_METHODS });

		try {
			const { login } = getState();

			var response = await api.sendGet("/PaymentMethod", {
				Authorization: "Bearer " + login.token,
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PAYMENT_METHODS_SUCCESS,
					payload: result.model,
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PAYMENT_METHODS_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_PAYMENT_METHODS_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}
