export const businessLicenseStatus = {
	0: "pending",
	1: "checking",
	2: "none",
	3: "active",
	4: "overDue",
	5: "notOpting"
};

export const businessLicenseStatusConst = {
	"pending": 0,
	"checking": 1,
	"none": 2,
	"active": 3,
	"overDue": 4,
	"notOpting": 5
};