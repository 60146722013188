import * as api from "services/api";
import { getErrors } from "helpers/error";

export const UPDATE_BENEFIT = "[BENEFIT UPDATE] UPDATE_BENEFIT";
export const UPDATE_BENEFIT_ERROR = "[BENEFIT UPDATE] UPDATE_BENEFIT_ERROR";
export const UPDATE_BENEFIT_SUCCESS = "[BENEFIT UPDATE] UPDATE_BENEFIT_SUCCESS";

export const SET_VALUE = "[BENEFIT UPDATE] SET_VALUE";
export const CLEAR_VALUES = "[BENEFIT UPDATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function updateBenefit(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_BENEFIT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/companyBenefit/${id} `, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPDATE_BENEFIT_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_BENEFIT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_BENEFIT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
