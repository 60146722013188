import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGroup } from "./actions";
import { clearValues, getGroupById } from "../Report/actions";
import { Card, CardBody, Container } from "reactstrap";
import PermissionsReport from "../PermissionsReport";
import GroupEditForm from "../../components/GroupEditForm";

function Edit({
	history,
	match: {
		params: { id }
	}
}) {
	const dispatch = useDispatch();

	const { item } = useSelector(s => s.groups);
	const { loading, errors } = useSelector(s => s.groupEdit);

	const group_edit = useSelector(s => s.form.group_edit);

	const load = () => {
		dispatch(clearValues());
		dispatch(getGroupById(id));
	};

	useEffect(() => {
		load();
	}, [dispatch]);

	if (!item) return "null";

	const onSubmit = data => {
		dispatch(
			updateGroup(
				item._id,
				{
					...item,
					name: data.name,
					description: data.description
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					}
				}
			)
		);
	};

	const initialValues = {
		name: item.name,
		description: item.description
	};

	return (
		<Container>
			<Card>
				<CardBody>
					<GroupEditForm
						onSubmit={onSubmit}
						initialValues={initialValues}
						loading={loading}
						isInvalid={group_edit && !!group_edit.syncErrors}
						errors={errors}
					/>
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<PermissionsReport />
				</CardBody>
			</Card>
		</Container>
	);
}

export default Edit;
