import React from "react";

import DigisacIntegration from "./digisac";
import PipefyIntegration from "./pipefy";

const Integrations = () => {
	return (
		<>
			<DigisacIntegration />
			<PipefyIntegration />
		</>
	);
};
export default Integrations;
