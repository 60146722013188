import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "reactstrap";

dayjs.extend(customDateFormat);

const DetailCompanyProvider = ({ companyProvider, deleteCompanyProvider }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={4} lg={3}>
									<p>
										<strong>Data Criação: </strong>
										<br />
										<span>
											{companyProvider.createdAt
												? dayjs(companyProvider.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={8} lg={6}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>
											{companyProvider.provider
												? companyProvider.provider.businessName
												: "--"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} lg={3}>
									<p>
										<strong>CNPJ: </strong>
										<br />
										<span>
											{companyProvider.provider
												? companyProvider.provider.cnpj
												: "--"}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailCompanyProvider;
