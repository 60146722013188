/* eslint-disable no-unused-vars */
import {
	Button,
	Tooltip,
	Modal,
	ModalBody,
	ModalHeader,
	Spinner,
	ButtonToolbar
} from "reactstrap";
import { FaFileImport } from "react-icons/fa";
import { getValue, removeDiacritics, toStringCurrency } from "helpers/string";
import { useDispatch } from "react-redux";
import { useState } from "react";
import LogRocket from "logrocket";
import ls from "Localization";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactTable from "react-table";
import ReportTable from "components/Report";
import XLSX from "xlsx";
import { importNfse } from "containers/Company/containers/Page/NFS-e/actions";

const getFooterProps = () => ({
	style: { color: "#000", fontWeight: "bold" }
});

const errorColumns = [
	{
		Header: ls.name,
		accessor: "name"
	},
	{
		Header: ls.cnpj,
		accessor: "document"
	},
	{
		Header: ls.service,
		accessor: "service"
	},
	{
		Header: ls.serviceType,
		accessor: "serviceType"
	},
	{
		Header: ls.observations,
		accessor: "observations"
	},
	{
		Header: ls.total,
		accessor: "totalValue"
	},
	{
		Header: "Descrição do erro",
		accessor: "erro"
	}
];

export default function ImportNfse({ id, load, loading }) {
	const dispatch = useDispatch({});

	const [returnErrors, setReturnErrors] = useState([]);
	const [items, setItems] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	const table = useRef(null);
	const inputRef = useRef(null);

	useEffect(() => {
		setData(
			items.map(c => ({
				name: c.nome,
				document: c.cnpj,
				service: c.servico,
				serviceType: c.tipo,
				observations: c.observacoes,
				totalValue: c.valor
			}))
		);
	}, [items]);

	const handleClose = () => setOpen(false);

	const renderEditable = useCallback(
		cellInfo => (
			<div
				style={{ backgroundColor: "#fafafa" }}
				contentEditable
				suppressContentEditableWarning
				onBlur={e => {
					data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
					setData([...data]);
				}}
				dangerouslySetInnerHTML={{
					__html: data[cellInfo.index][cellInfo.column.id]
				}}
			/>
		),
		[data]
	);

	const columns = useMemo(() => {
		const total = data
			.map(c => c.valor)
			.reduce((a, b) => a + Number(b || 0), 0);

		const columns = [
			{
				Header: ls.name,
				accessor: "name",
				Footer: `${data.length} usuários`,
				getFooterProps,
				Cell: renderEditable
			},
			{
				Header: ls.cnpj,
				accessor: "document",
				Cell: renderEditable
			},
			{
				Header: ls.service,
				accessor: "service",
				Cell: renderEditable
			},
			{
				Header: ls.serviceType,
				accessor: "serviceType",
				Cell: renderEditable
			},
			{
				Header: ls.observations,
				accessor: "observations",
				Cell: renderEditable
			},
			{
				Header: ls.total,
				accessor: "totalValue",
				Footer: toStringCurrency(total),
				getFooterProps,
				Cell: renderEditable
			}
		];

		return columns;
	}, [data, renderEditable]);

	const handleFileChange = e => {
		try {
			const file = e.target.files[0];
			e.preventDefault();
			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			reader.onload = e => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
				/* Get first worksheet */
				const wsName = wb.SheetNames[0];
				const sheet = wb.Sheets[wsName];
				/* Update state */

				const ref = XLSX.utils.decode_range(sheet["!ref"]);

				const headers = [];

				for (let C = ref.s.c; C <= ref.e.c; ++C) {
					const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
					if (cell && cell.t === "s") {
						cell.v = removeDiacritics(cell.v.trim().toLowerCase());
						if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());

						headers.push(removeDiacritics(cell.v.trim().toLowerCase()));
					}
				}

				LogRocket.identify("ImportNFSe-Create", {
					businessId: id
				});

				LogRocket.debug("payload", data);

				const data = XLSX.utils
					.sheet_to_json(sheet, {})
					.filter(row => Object.values(row).some(v => v !== ""));

				setItems(data);

				setOpen(true);
			};

			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);

			e.target.value = null;
		} catch (error) {
			toastError(`${error}`);
		}
	};

	const onSubmit = useCallback(
		e => {
			e.preventDefault();

			const body = data.map(d => ({
				...d,
				serviceType: d.serviceType?.split(" - ")[0],
				document: d.document.match(/\d/g).join(""),
				totalValue: Math.trunc(getValue(d.totalValue * 100))
			}));

			dispatch(
				importNfse(id, body, err => {
					if (err) {
						console.log(err);
						const errors = [];
						for (const cnpj in err) {
							const item = body.find(c => c.document === cnpj) || {};

							errors.push({
								...item,
								totalValue: item.totalValue / 100,
								erro: ls[err[cnpj]] || err[cnpj]
							});
						}

						setReturnErrors(errors);
					} else {
						handleClose();
					}
					load();
				})
			);
		},
		[data]
	);

	if (returnErrors.length > 0) {
		return (
			<Modal
				toggle={() => setReturnErrors([])}
				className="theme-light ltr-support login-modal-form"
				isOpen
				size="xl"
			>
				<ModalHeader>
					As seguintes NFS-e não foram importados por causa dos seguintes erros
				</ModalHeader>
				<ModalBody>
					<ReactTable
						data={returnErrors}
						ref={table}
						columns={errorColumns}
						defaultPageSize={10}
						className="-striped -highlight"
					/>
					<ButtonToolbar className="form__button-toolbar">
						<Button
							color="primary"
							onClick={() => {
								setReturnErrors([]);
								handleClose();
							}}
						>
							{ls.close}
						</Button>
					</ButtonToolbar>
				</ModalBody>
			</Modal>
		);
	}

	return (
		<>
			<div style={{ alignContent: "center" }}>
				<a
					href="https://docs.google.com/spreadsheets/d/17qQj3NlQHk_wOfKoSeC150EVTNlrucLr4-Ku47KXZkE/export?format=xlsx"
					target="_blank"
					rel="noopener noreferrer"
					style={{ marginRight: 6 }}
				>
					Baixar modelo de importação
				</a>
				<Button
					id="import-nfse"
					outline
					onClick={() => inputRef.current.click()}
				>
					<FaFileImport />
				</Button>
				<Tooltip
					placement="top"
					isOpen={tooltipOpen}
					target="import-nfse"
					toggle={toggle}
				>
					Importar NFS-e
				</Tooltip>
			</div>
			<Modal
				toggle={() => handleClose()}
				className="theme-light ltr-support login-modal-form"
				isOpen={open}
				size="xl"
			>
				<ModalBody>
					<form noValidate onSubmit={onSubmit}>
						<ReportTable
							title="Importar NFS-e"
							columns={columns}
							data={data}
							defaultPageSize={10}
							className="-striped -highlight"
						/>

						<ButtonToolbar className="form__button-toolbar">
							<Button
								type="button"
								onClick={() => handleClose()}
								disabled={loading}
							>
								Cancelar
							</Button>
							<Button color="primary" type="submit" disabled={loading}>
								{loading ? (
									<Spinner type="grow" size="sm" color="dark" />
								) : (
									"Importar"
								)}
							</Button>
						</ButtonToolbar>
					</form>
				</ModalBody>
			</Modal>
			<input
				ref={inputRef}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileChange}
			/>
		</>
	);
}
