export const stateOptions = [
	{
		code: "1",
		name: "Acre",
		sigla: "AC"
	},
	{
		code: "2",
		name: "Alagoas",
		sigla: "AL"
	},
	{
		code: "3",
		name: "Amapá",
		sigla: "AP"
	},
	{
		code: "4",
		name: "Amazonas",
		sigla: "AM"
	},
	{
		code: "5",
		name: "Bahia",
		sigla: "BA"
	},
	{
		code: "6",
		name: "Ceará",
		sigla: "CE"
	},
	{
		code: "7",
		name: "Distrito Federal",
		sigla: "DF"
	},
	{
		code: "8",
		name: "Espírito Santo",
		sigla: "ES"
	},
	{
		code: "9",
		name: "Goiás",
		sigla: "GO"
	},
	{
		code: "10",
		name: "Maranhão",
		sigla: "MA"
	},
	{
		code: "11",
		name: "Mato Grosso",
		sigla: "MT"
	},
	{
		code: "12",
		name: "Mato Grosso do Sul",
		sigla: "MS"
	},
	{
		code: "13",
		name: "Minas Gerais",
		sigla: "MG"
	},
	{
		code: "14",
		name: "Pará",
		sigla: "PA"
	},
	{
		code: "15",
		name: "Paraíba",
		sigla: "PB"
	},
	{
		code: "16",
		name: "Paraná",
		sigla: "PR"
	},
	{
		code: "17",
		name: "Pernambuco",
		sigla: "PE"
	},
	{
		code: "18",
		name: "Piauí",
		sigla: "PI"
	},
	{
		code: "19",
		name: "Rio de Janeiro",
		sigla: "RJ"
	},
	{
		code: "20",
		name: "Rio Grande do Norte",
		sigla: "RN"
	},
	{
		code: "21",
		name: "Rio Grande do Sul",
		sigla: "RS"
	},
	{
		code: "22",
		name: "Rondônia",
		sigla: "RO"
	},
	{
		code: "23",
		name: "Roraima",
		sigla: "RR"
	},
	{
		code: "24",
		name: "Santa Catarina",
		sigla: "SC"
	},
	{
		code: "25",
		name: "São Paulo",
		sigla: "SP"
	},
	{
		code: "26",
		name: "Sergipe",
		sigla: "SE"
	},
	{
		code: "27",
		name: "Tocantins",
		sigla: "TO"
	}
];
