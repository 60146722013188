import React from "react";

import { Text } from "components/Text";

import { StatusLabelContainer } from "./styles";

export function StatusLabel({ text, backgroundColor, icon }) {
	return (
		<StatusLabelContainer backgroundColor={backgroundColor}>
			{icon}
			<Text fontSize="16px" color="#ffffff" lineHeight="23px">
				{text}
			</Text>
		</StatusLabelContainer>
	);
}
