import GlobalModal from "components/Modal";
import { useGlobalContext } from "contexts/GlobalContext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import DetailCompanyForm from "../../../components/DetailForm/Companies";
import { updateClient } from "../../Edit/actions";

function DetailCompanies({
	match: {
		params: { id, business }
	},
	history
}) {
	const { showModal, hideModal } = useGlobalContext();
	const [company, setCompany] = useState({});
	const { item: client, loading } = useSelector(state => state.clientDetail);

	const dispatch = useDispatch();

	useEffect(() => {
		setCompany(
			client.meis.filter(el => {
				return el._id === business;
			})[0]
		);
	}, [dispatch]);

	if (!client && !company) return null;

	const handleDelete = () => {
		let model = client;
		model.meis = client.meis.filter(el => {
			return el._id !== business;
		});

		dispatch(
			updateClient(id, model, err => {
				if (err) {
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					history.push(`/client/${id}`);
				}

				hideModal();
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhe da empresa2</h5>
								</div>
								<div>
									<Button size="sm" color="danger" onClick={showModal}>
										Remover
									</Button>
								</div>
							</Row>
							<DetailCompanyForm company={company} />
						</CardBody>
					</Card>
				</Col>
			</Row>

			<GlobalModal
				title="Remover empresa"
				onOk={handleDelete}
				loading={loading.delete}
			>
				<p>
					Tem certeza que deseja remover esta empresa? Essa ação é irreversível
				</p>
			</GlobalModal>
		</Container>
	);
}

export default DetailCompanies;
