import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card, CardBody } from "reactstrap";

import ReportTable from "components/Report";
import { clearValues } from "containers/Contracts/containers/ReportAll/actions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as api from "services/api";
import ls from "Localization";

dayjs.extend(customParseFormat);

const ContractHistoryList = ({ contractId }) => {
	const dispatch = useDispatch();

	const { token } = useSelector(s => s.login);

	const table = useRef(null);

	const [contractHistoryList, setContractHistoryList] = useState([]);
	const [pages, setPages] = useState(0);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const handleFetchData = async tableState => {
		if (!tableState) return;
		createRef(tableState);

		let { page, pageSize } = tableState;

		setLoading(true);

		try {
			const response = await api.sendGet(
				`/contractHistory/${contractId}?skip=${page *
					pageSize}&take=${pageSize}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setContractHistoryList(result?.model);
				setCount(result.params?.count);
				setPages(Math.ceil(result.params?.count / pageSize));
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		dispatch(clearValues());
	}, []);

	return (
		<Card>
			<CardBody>
				<ReportTable
					manual
					tableRef={table}
					title={"Histórico do Contrato"}
					data={contractHistoryList}
					pages={pages}
					count={count}
					defaultPageSize={5}
					loading={loading}
					noExport={true}
					filterable={false}
					sortable={true}
					onFetchData={handleFetchData}
					defaultSorted={[
						{
							id: "createdAt",
							desc: false
						}
					]}
					columns={[
						{
							Header: (
								<span>
									Data <br />
									Cadastro
								</span>
							),
							id: "createdAt",
							accessor: c =>
								c.createdAt
									? dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds)
									: "",
							width: 120
						},
						{
							Header: "Observação",
							id: "Note",
							accessor: ({ note }) => note || "Não definido"
						},
						{
							Header: "Tipo",
							id: "type",
							show: true,
							width: 75,
							accessor: ({ type }) => {
								switch (type) {
									case 0:
										return (
											<Badge
												style={{ backgroundColor: "red" }}
												className="w-100"
											>
												Erro
											</Badge>
										);
									case 1:
										return (
											<Badge
												style={{ backgroundColor: "red" }}
												className="w-100"
											>
												Erro
											</Badge>
										);
									case 2:
										return (
											<Badge color="success" className="w-100">
												OK
											</Badge>
										);

									default:
										return "";
								}
							}
						}
					]}
				/>
			</CardBody>
		</Card>
	);
};

export default ContractHistoryList;
