import { Container, Grid } from "@material-ui/core";
import LoadingComponent from "components/LoadingComponent";
import { StyledTable } from "components/StyledTable";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as api from "../../../../services/api";
import { ButtonSC, SubTitleSC } from "./styled";
import dayjs from "dayjs";
import ls from "Localization";

const DetailsCompanyGroup = ({
	match: {
		params: { id }
	}
}) => {
	const history = useHistory();
	const { token } = useSelector(state => state.login);

	const [companyGroups, setCompanyGroups] = useState([]);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(5);
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const columns = [
		{
			field: "businessName",
			headerName: "Nome do Cliente",
			align: "left",
			alignContent: "left",
			width: 600,
			content: value => {
				return (
					<div gap="22px" alignContent="center">
						<span>{value || ""}</span>
					</div>
				);
			}
		},
		{
			field: "situation",
			headerName: "Situação",
			align: "left",
			alignContent: "left",
			width: 50,
			content: value => {
				return (
					<div
						gap="10px"
						alignItems="center"
						style={{
							backgroundColor: value.match(/ativ/i) ? "green" : "red",
							padding: "5px",
							borderRadius: "5px",
							display: "flex",
							justifyContent: "center",
							color: "#fff",
							fontWeight: 700
						}}
					>
						<span>{value}</span>
					</div>
				);
			}
		},
		{
			field: "isActive",
			headerName: (
				<span>
					Empresa
					<br /> Ativa?
				</span>
			),
			align: "left",
			alignContent: "left",
			width: 50,
			content: value => {
				return (
					<div
						gap="10px"
						alignItems="center"
						style={{
							backgroundColor: value ? "green" : "red",
							padding: "5px",
							borderRadius: "5px",
							display: "flex",
							justifyContent: "center",
							color: "#fff",
							fontWeight: 700
						}}
					>
						<span>{value ? "Sim" : "Não"}</span>
					</div>
				);
			}
		}
	];

	function handleChangeSkip(value) {
		setSkip(value);
	}

	function handleChangeTake({ target }) {
		setTake(target.value);

		setSkip(0);
	}

	const handleFetchData = async () => {
		try {
			setIsLoading(true);

			const response = await api.sendGet(`/company-group/${id}`, {
				Authorization: "Bearer " + token
			});

			const result = await response.json();

			if (result.isValid) {
				setCompanyGroups(result.model);
				setCount(result.params.count);
			}

			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		handleFetchData();
	}, []);

	return (
		<Container>
			<Grid container style={{ marginBottom: "16px" }}>
				<Grid
					item
					container
					spacing={2}
					style={{
						background: "#fff",
						borderRadius: "5px",
						padding: "16px"
					}}
				>
					<Grid item style={{ borderBottom: "1px solid #ccc", width: "100%" }}>
						<SubTitleSC>{companyGroups.businessName}</SubTitleSC>
					</Grid>

					<Grid
						item
						container
						spacing={1}
						direction="column"
						style={{ borderBottom: "1px solid #ccc", width: "100%" }}
					>
						<Grid item>
							<SubTitleSC>Criado por </SubTitleSC>
							{companyGroups.createdBy ? (
								<a
									href={`/client/${companyGroups.createdBy?._id}`}
									target="_blank"
									without
									rel="noreferrer"
								>
									{companyGroups.createdBy?.name}
								</a>
							) : (
								"--"
							)}
							<SubTitleSC> em </SubTitleSC>
							<span>
								{dayjs(companyGroups.createdAt).format(
									ls.dateFormatWithoutSeconds
								)}
							</span>
						</Grid>

						<Grid item>
							<SubTitleSC>Atualizado por </SubTitleSC>
							{companyGroups.updatedBy ? (
								<a
									href={`/client/${companyGroups.updatedBy?._id}`}
									target="_blank"
									without
									rel="noreferrer"
								>
									{companyGroups.updatedBy?.name}
								</a>
							) : (
								"--"
							)}
							<SubTitleSC> em </SubTitleSC>
							<span>
								{dayjs(companyGroups.updatedAt).format(
									ls.dateFormatWithoutSeconds
								)}
							</span>
						</Grid>
					</Grid>

					{isLoading ? (
						<LoadingComponent />
					) : (
						<Grid item style={{ width: "100%" }}>
							<StyledTable
								data={companyGroups.companies}
								columns={columns}
								handleChangeSkip={handleChangeSkip}
								handleChangeTake={handleChangeTake}
								skip={skip}
								take={take}
								count={count}
								showHeader={true}
								onRowClick={row => history.push(`/companies/${row._id}`)}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid
				item
				container
				spacing={1}
				style={{ display: "flex", justifyContent: "flex-end" }}
			>
				<Grid item>
					<ButtonSC
						background="#fff"
						color="#584c9c"
						border="1px solid #584c9c"
						onClick={() => history.goBack()}
						type="button"
					>
						Voltar
					</ButtonSC>
				</Grid>
			</Grid>
		</Container>
	);
};

export default DetailsCompanyGroup;
