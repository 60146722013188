import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import renderSelectField from "shared/components/form/Select";
import Input from "components/ReduxForm/Input";

import states from "assets/states.json";
import { CEP, CPF, CPFCNPJ, DATE, Phone } from "components/NumberFormat";

import validate from "./validate";
import EyeIcon from "mdi-react/EyeIcon";
import { stringToMask } from "helpers/string";

const EditForm = ({
	handleSubmit,
	meiStatus,
	onChangeMei,
	companies,
	userStatusData,
	userRolesData,
	userGenders,
	userMaritalStats,
	stateSelectOptions,
	loading,
	errors,
	addressId,
	isInvalid,
	...props
}) => {
	const [zipCodeLoading, setZipCodeLoading] = useState(false);
	const [role, setRole] = useState(
		props.initialValues.role ? props.initialValues.role.value : 0
	);
	const [showGovPassword, setShowGovPassword] = useState(false);
	const toggleGovPassword = () => setShowGovPassword(!showGovPassword);

	const [showPasswordSimei, setShowPasswordSimei] = useState(false);
	const togglePasswordSimei = () => setShowPasswordSimei(!showPasswordSimei);

	const handleZipCodeChange = zipCode => {
		if (zipCode && zipCode.length === 8) {
			setZipCodeLoading(true);
			fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json) {
						props.change("street", json.logradouro);
						props.change("complement", json.complemento);
						props.change("neighborhood", json.bairro);
						props.change("city", json.localidade);
						props.change("state", states.find(c => c.value === json.uf).label);
					}
					setZipCodeLoading(false);
				})
				.catch(err => {
					console.log(err);
					setZipCodeLoading(false);
				});
		}
	};

	const handleRoleChange = role => {
		setRole(role.value);
	};

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<h5 className="bold-text mb-2">Dados de Cadastro</h5>
				<Row className="shadow-sm rounded mb-4 pt-3">
					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label bold-text">Nome*</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Ex.: Fulano"
								props={{ errors: errors && errors.name }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} xl={4} className="form__form-group">
						<span className="form__form-group-label">Sobrenome</span>
						<div className="form__form-group-field">
							<Field
								name="lastName"
								component={Input}
								type="text"
								placeholder="Ex.: de Tal"
								props={{ errors: errors && errors.lastname }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Status do cliente*
						</span>
						<div className="form__form-group-field">
							<Field
								name="status"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={userStatusData}
								props={{ errors: errors && errors.status }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">E-mail*</span>
						<div className="form__form-group-field">
							<Field
								name="email"
								component={Input}
								type="text"
								placeholder="Ex.: joaodasilva@email.com"
								props={{ errors: errors && errors.email }}
							/>
						</div>
					</Col>
				</Row>

				<h5 className="bold-text mb-2">Dados de Empresa</h5>
				<Row className="shadow-sm rounded  mb-4 pt-3">
					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Função/Cargo*
						</span>
						<div className="form__form-group-field">
							<Field
								name="role"
								placeholder="Selecione"
								component={renderSelectField}
								onChange={handleRoleChange}
								type="text"
								options={userRolesData}
							/>
						</div>
					</Col>

					{+role === 2 && (
						<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
							<span className="form__form-group-label">Empresa</span>
							<div className="form__form-group-field">
								<Field
									onInputChange={onChangeMei}
									options={[
										{ value: "", label: "Pesquise uma empresa" },
										...companies.map(c => ({
											value: c._id,
											label: `${c?.businessName} - CNPJ: ${stringToMask(
												c?.cnpj,
												"##.###.###/####-##"
											)}`
										}))
									]}
									name="mei"
									placeholder="Pesquise uma empresa"
									component={renderSelectField}
									type="text"
								/>
							</div>
						</Col>
					)}

					{+role === 2 && (
						<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
							<span className="form__form-group-label">Status MEI</span>
							<div className="form__form-group-field">
								<Field
									name="statusMei"
									placeholder="Selecione"
									component={renderSelectField}
									type="text"
									options={meiStatus}
								/>
							</div>
						</Col>
					)}
				</Row>

				<h5 className="bold-text mb-2">Dados Pessoais</h5>
				<Row className="shadow-sm rounded  mb-4 pt-3">
					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label">Data de Nascimento</span>
						<div className="form__form-group-field">
							<Field
								name="birthDate"
								component={DATE}
								type="text"
								placeholder="Ex.: 01/01/1970"
								props={{ errors: errors && errors.birthDate }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">Telefone*</span>
						<div className="form__form-group-field">
							<Field
								name="phoneNumber"
								component={Phone}
								type="text"
								placeholder="Ex.: (00) 00000-0000"
								props={{ errors: errors && errors.phoneNumber }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label">
							{+role !== 2 ? "CPF ou CNPJ" : "CPF"}
						</span>
						<div className="form__form-group-field">
							<Field
								name="cpf"
								component={+role !== 2 ? CPFCNPJ : CPF}
								type="text"
								placeholder="Ex.: 123.456.789-00"
								props={{ errors: errors && errors.cpf }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label">Senha Gov.BR</span>
						<div className="form__form-group-field">
							<Field
								name="govPassword"
								component={Input}
								type={showGovPassword ? "text" : "password"}
								placeholder="Ex.: Senha"
								props={{ errors: errors && errors.govPassword }}
							/>
							<button
								className={`form__form-group-button${
									showGovPassword ? " active" : ""
								}`}
								onClick={toggleGovPassword}
								type="button"
							>
								<EyeIcon />
							</button>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label">
							Código de Acesso Simei
						</span>
						<div className="form__form-group-field">
							<Field
								name="codSimei"
								component={Input}
								type={showPasswordSimei ? "text" : "password"}
								placeholder="Ex.: Senha"
								props={{ errors: errors && errors.codSimei }}
							/>
							<button
								className={`form__form-group-button${
									showPasswordSimei ? " active" : ""
								}`}
								onClick={togglePasswordSimei}
								type="button"
							>
								<EyeIcon />
							</button>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">Gênero</span>
						<div className="form__form-group-field">
							<Field
								name="gender"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={userGenders}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Estado Civil
						</span>
						<div className="form__form-group-field">
							<Field
								name="maritalStatus"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={userMaritalStats}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">RG</span>
						<div className="form__form-group-field">
							<Field
								name="rg"
								placeholder="Selecione"
								component={Input}
								type="text"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Órgão Emissor
						</span>
						<div className="form__form-group-field">
							<Field
								name="rgEmissor"
								placeholder="Ex.: SSP"
								component={Input}
								type="text"
								maxLength="10"
								onInput={e => (e.target.value = e.target.value.toUpperCase())}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Estado do Órgão Emissor
						</span>
						<div className="form__form-group-field">
							<Field
								name="rgEmissorState"
								component={renderSelectField}
								type="text"
								placeholder="Selecione"
								options={stateSelectOptions}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} xl={3} className="form__form-group">
						<span className="form__form-group-label bold-text">
							Título de Eleitor
						</span>
						<div className="form__form-group-field">
							<Field
								name="titulo"
								placeholder="Selecione"
								component={Input}
								type="text"
							/>
						</div>
					</Col>
				</Row>

				{addressId && <h5 className="bold-text mb-2">Endereço</h5>}

				{addressId && (
					<Row className="shadow-sm rounded  mb-4 pt-3">
						<Col xs={12} sm={12} md={3} className="form__form-group">
							<span className="form__form-group-label">CEP</span>
							<div className="form__form-group-field">
								<Field
									name="zipCode"
									component={CEP}
									type="text"
									onChange={handleZipCodeChange}
									props={{ errors: errors && errors.zipCode }}
								/>
							</div>
						</Col>
						<Col xs={12} sm={12} md={6} className="form__form-group">
							<span className="form__form-group-label">Endereço</span>
							<div className="form__form-group-field">
								<Field
									name="street"
									component={Input}
									type="text"
									placeholder="Ex.: Rua Governador Celso Ramos"
									props={{ errors: errors && errors.street }}
								/>
							</div>
						</Col>
						<Col xs={12} sm={12} md={3} className="form__form-group">
							<span className="form__form-group-label">Número</span>
							<div className="form__form-group-field">
								<Field
									name="number"
									component={Input}
									type="text"
									placeholder="Ex.: Rua Governador Celso Ramos"
									props={{ errors: errors && errors.number }}
								/>
							</div>
						</Col>
						<Col xs={8} sm={6} md={3} lg={3}>
							<div className="form__form-group">
								<span className="form__form-group-label">Complemento</span>
								<div className="form__form-group-field">
									<Field
										name="complement"
										placeholder="Ex.: Sala 1"
										component={Input}
										props={{ errors: errors && errors.complement }}
									/>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} md={3} lg={3} className="form__form-group">
							<span className="form__form-group-label">Bairro</span>
							<div className="form__form-group-field">
								<Field
									name="neighborhood"
									component={Input}
									type="text"
									placeholder="Ex.: Centro"
									props={{ errors: errors && errors.neighborhood }}
								/>
							</div>
						</Col>

						<Col xs={12} sm={6} md={3} lg={3} className="form__form-group">
							<span className="form__form-group-label">Cidade</span>
							<div className="form__form-group-field">
								<Field
									name="city"
									component={Input}
									type="text"
									placeholder="Ex.: Florianópolis"
									props={{ errors: errors && errors.city }}
								/>
							</div>
						</Col>

						<Col xs={6} sm={6} md={2} lg={1} className="form__form-group">
							<span className="form__form-group-label">Estado</span>
							<div className="form__form-group-field">
								<Field
									name="state"
									component={Input}
									type="text"
									placeholder="Ex.: RJ"
									props={{ errors: errors && errors.state }}
								/>
							</div>
						</Col>
					</Row>
				)}
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button color="primary" type="submit" disabled={loading || isInvalid}>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	enableReinitialize: true,
	form: "client_edit",
	validate
})(EditForm);
