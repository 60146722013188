import React, { useRef, useEffect } from "react";

import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { getMarketings, clearValues } from "./actions";
import _ from "lodash";
import ls from "Localization";
import { stringSort } from "helpers/string";
import dayjs from "dayjs";
import { MarketingStatus } from "types/Marketing";

import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";

let getMarketingsDebounded = null;

function Marketing({ history, location }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const query = queryString.parse(location.search);

	const { items, pages, loading } = useSelector(s => s.marketing);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch]);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getMarketingsDebounded) {
			getMarketingsDebounded.cancel();
		}

		if (!sorted) sorted = [];

		getMarketingsDebounded = _.debounce(
			() =>
				dispatch(
					getMarketings(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getMarketingsDebounded();
	};

	useEffect(() => {
		if (table.current) {
			let { page, pageSize, sorted, filtered } = table.current.state;

			if (getMarketingsDebounded) {
				getMarketingsDebounded.cancel();
			}

			if (!sorted) sorted = [];

			getMarketingsDebounded = _.debounce(
				() =>
					dispatch(
						getMarketings(
							page * pageSize,
							pageSize,
							filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
							sorted[0] ? sorted[0].id : undefined,
							sorted[0] ? sorted[0].desc : undefined
						)
					),
				500
			);

			getMarketingsDebounded();
		}
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Marketing"
						data={items}
						pages={pages}
						filterable
						defaultFiltered={[
							{ id: "status", value: query && query.status ? query.status : "" }
						]}
						loading={loading.getAll}
						onFetchData={handleFetchData}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id])
								.toLowerCase()
								.indexOf(filter.value.toLowerCase()) > -1
						}
						onRowClicked={row => history.push(`/marketing/` + row._id)}
						columns={[
							{
								Header: ls.creationDate,
								id: "creationDate",
								accessor: c =>
									dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
								sortMethod: (a, b, desc) => {
									let aD = dayjs(a, ls.dateFormatWithoutSeconds);
									let bD = dayjs(b, ls.dateFormatWithoutSeconds);

									return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
								}
							},
							{
								Header: ls.name,
								accessor: "name",
								sortMethod: stringSort
							},
							{
								Header: ls.startAt,
								id: "startAt",
								accessor: c =>
									c.startAt
										? dayjs(c.startAt).format(ls.dateFormatWithoutSeconds)
										: "",
								sortMethod: (a, b, desc) => {
									let aD = dayjs(a, ls.dateFormatWithoutSeconds);
									let bD = dayjs(b, ls.dateFormatWithoutSeconds);

									return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
								}
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[MarketingStatus[c.status]],
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : ""}
										>
											<option value="">Todos</option>
											{Object.keys(MarketingStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[MarketingStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 120
							},
							{
								Header: ls.actions,
								id: "actions",
								accessor: c => (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flex: 1
										}}
									>
										<Link to={`/marketing/${c._id}/Edit`}>
											<MdEdit />
										</Link>
									</div>
								),
								filterable: false
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Marketing;
