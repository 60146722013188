import React from "react";
import { Card, Row, Col, Container } from "reactstrap";
import dayjs from "dayjs";
import ls from "Localization";

import { toStringCurrency } from "helpers/string";

const DetailsBenefit = ({ item }) => {
	const renderValue = value => (value ? value : "Não definido");

	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={3} lg={2}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{item.createdAt
												? dayjs(item.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: "Não definido"}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={8} lg={6}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>{item.name}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={6} md={6} lg={2}>
									<p>
										<strong>Preço:</strong>
										<br />
										<span>
											{renderValue(toStringCurrency((item.price || 0) / 100))}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={6} md={6} lg={2}>
									<p>
										<strong>Desconto:</strong>
										<br />
										<span>
											{renderValue(
												toStringCurrency((item.discount || 0) / 100)
											)}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12}>
									<p>
										<strong>Descrição:</strong>
										<br />
										<span>{renderValue(item.description)}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12}>
									<p>
										<strong>Ícone:</strong>
										<br />
										<span>
											{item.icon ? (
												<a href={item.icon} target="_blank" rel="noreferrer">
													Ver ícone
												</a>
											) : (
												"Não definido"
											)}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailsBenefit;
