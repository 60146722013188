import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_DEPARTMENT_LIST = "[DEPARTMENT] GET_DEPARTMENT_LIST";
export const GET_DEPARTMENT_LIST_SUCCESS =
	"[DEPARTMENT] GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_FAILED =
	"[DEPARTMENT] GET_DEPARTMENT_LIST_FAILED";

export const CREATE_DEPARTMENT = "[CREATE_DEPARTMENT] CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT_ERROR =
	"[CREATE_DEPARTMENT] CREATE_DEPARTMENT_ERROR";
export const CREATE_DEPARTMENT_SUCCESS =
	"[CREATE_DEPARTMENT] CREATE_DEPARTMENT_SUCCESS";

export const UPDATE_DEPARTMENT = "[UPDATE_DEPARTMENT] UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_ERROR =
	"[UPDATE_DEPARTMENT] UPDATE_DEPARTMENT_ERROR";
export const UPDATE_DEPARTMENT_SUCCESS =
	"[UPDATE_DEPARTMENT] UPDATE_DEPARTMENT_SUCCESS";

export const DELETE_DEPARTMENT = "[DELETE_DEPARTMENT] DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_ERROR =
	"[DELETE_DEPARTMENT] DELETE_DEPARTMENT_ERROR";
export const DELETE_DEPARTMENT_SUCCESS =
	"[DELETE_DEPARTMENT] DELETE_DEPARTMENT_SUCCESS";

export const SET_VALUE = "[DEPARTMENT] SET_VALUE";
export const CLEAR_VALUES = "[DEPARTMENT] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getDepartmentList(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_DEPARTMENT_LIST });

		try {
			const { login } = getState();

			var response = await api.sendGet(
				`/department?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_DEPARTMENT_LIST_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_DEPARTMENT_LIST_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_DEPARTMENT_LIST_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function createDepartment(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_DEPARTMENT
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/department/`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (response.ok) {
				dispatch({
					type: CREATE_DEPARTMENT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result);

				dispatch({
					type: CREATE_DEPARTMENT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: CREATE_DEPARTMENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateDepartment(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_DEPARTMENT
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/department/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (response.ok) {
				dispatch({
					type: UPDATE_DEPARTMENT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result);

				dispatch({
					type: UPDATE_DEPARTMENT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: UPDATE_DEPARTMENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteDepartment(id, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DELETE_DEPARTMENT
		});

		try {
			const { login } = getState();

			let response = await api.sendDelete(`/department/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (response.ok) {
				dispatch({
					type: DELETE_DEPARTMENT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result);

				dispatch({
					type: DELETE_DEPARTMENT_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: DELETE_DEPARTMENT_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
