import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	Badge,
	Button,
	Col,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row
} from "reactstrap";
import { contractStatus } from "types/contractStatus";
import * as actions from "./actions";
import { clearValues } from "./actions";
import classnames from "classnames";

dayjs.extend(customDateFormat);

let getContractsDebounced = null;

const ContractsInCompany = ({ history, companyId }) => {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { items, pages, loading } = useSelector(
		state => state.companyContractList
	);

	const [activeTab, setActiveTab] = useState("1");
	const toggleTabs = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const contractColors = [
		"gray",
		"green",
		"orange",
		"red",
		"red",
		"",
		"",
		"red",
		"lightblue",
		"orange"
	];

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getContractsDebounced) {
			getContractsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getContractsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getContracts(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => {
							return p + `&filters[${c.id}]=${c.value}`;
						}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback,
						companyId
					)
				),
			500
		);
		getContractsDebounced();
	};

	const load = () => {
		handleFetchData(table.current ? table.current.state : undefined);
	};

	useEffect(() => {
		dispatch(clearValues());
		load();
	}, []);

	return (
		<Container className="dashboard">
			<div className="tabs tabs--justify tabs--bordered-bottom">
				<div className="tabs__wrap">
					<Nav tabs>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggleTabs("1");
								}}
							>
								CONTRATADOS{" "}
								{items && items.hirer ? (
									<Badge color={activeTab === "1" ? "success" : "secondary"}>
										{items.hirer.length}
									</Badge>
								) : null}
							</NavLink>
						</NavItem>

						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggleTabs("2");
								}}
							>
								CONTRATANTES{" "}
								{items && items.hired ? (
									<Badge color={activeTab === "2" ? "success" : "secondary"}>
										{items.hired.length}
									</Badge>
								) : null}
							</NavLink>
						</NavItem>
					</Nav>
				</div>
			</div>

			<Row>
				<Col xs={12}>
					<ReportTable
						manual
						title={"Contratos"}
						data={activeTab === "1" ? items.hirer : items.hired}
						pageSize={5}
						pages={pages}
						loading={loading.getAll}
						onFetchData={handleFetchData}
						filterable
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						headerRightComponent={
							<Button
								onClick={() => history.push(`/contracts/new`)}
								className="mr-2"
								outline
							>
								<MdAdd />
							</Button>
						}
						onRowClicked={row => history.push(`/contracts/${row._id}`)}
						columns={[
							{
								Header: "Data Criação",
								id: "createdAt",
								accessor: ({ createdAt }) =>
									createdAt
										? dayjs(createdAt).format(ls.dateFormatWithoutSeconds)
										: "--",
								width: 140
							},
							{
								Header: "Template",
								id: "template.name",
								filterable: true,
								accessor: ({ template }) =>
									template.name ? template.name : "Não definido",
								width: 180
							},
							{
								Header: "Prestador",
								id: "hired",
								filterable: true,
								show: activeTab === "1",
								accessor: ({ hired }) =>
									hired.businessName ? `${hired.businessName}` : "Não definido"
							},
							{
								Header: "Contratante",
								id: "mei",
								filterable: true,
								show: activeTab === "2",
								accessor: ({ mei }) =>
									mei.businessName
										? `${mei.businessName} - ${mei.cnpj}`
										: "Não definido"
							},
							{
								Header: "ID Externo",
								id: "template.externalId",
								filterable: true,
								accessor: ({ template }) =>
									template.externalId ? template.externalId : "Não definido",
								width: 260
							},
							{
								Header: ls.statusContractTemplate,
								id: "status",
								show: true,
								accessor: c => {
									return (
										<Badge
											style={{
												fontSize: 12,
												backgroundColor: contractColors[c.status]
											}}
										>
											{ls[contractStatus[c.status]]}
										</Badge>
									);
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(contractStatus)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[contractStatus[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 140
							},
							{
								Header: "Vigência",
								id: "due",
								accessor: ({ due }) =>
									due ? dayjs(due).format(ls.dateFormatShort) : "Não definida",
								width: 100
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default ContractsInCompany;
