import React from "react";
import { Route, Switch } from "react-router-dom";
import Report from "./containers/Report";
import Create from "./containers/Create";
import Edit from "./containers/Edit";
import Details from "./containers/Details";
import DetailCompanies from "./containers/Details/Companies";

const routes = () => {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/client" component={Report} />
				<Route exact path="/client/new" component={Create} />
				<Route exact path="/client/:id/edit" component={Edit} />
				<Route exact path="/client/:id" component={Details} />
				<Route
					exact
					path="/client/:id/companies/:business"
					component={DetailCompanies}
				/>
			</Switch>
		</React.Fragment>
	);
};

export default routes;
