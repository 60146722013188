import * as api from "services/api";
import { getErrors } from "helpers/error";

import LogRocket from "logrocket";
import ls from "Localization";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN = "LOGIN";

export const VALIDATE_TOKEN_ERROR = "VALIDATE_TOKEN_ERROR";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";

export const PASSWORD_RECOVERY = "PASSWORD_RECOVERY";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAILED = "PASSWORD_RECOVERY_FAILED";

export const SET_USER_DATA = "[USER] SET DATA";
export const GET_USER_DATA = "[USER] GET DATA";
export const GET_USER_DATA_ERROR = "[USER] GET DATA ERROR";

export const GET_USER_PERMISSIONS = "[USER] GET_USER_PERMISSIONS";
export const GET_USER_PERMISSIONS_SUCCESS =
	"[USER] GET_USER_PERMISSIONS_SUCCESS";
export const GET_USER_PERMISSIONS_ERROR = "[USER] GET_USER_PERMISSIONS_ERROR";

export const RESET_PASSWORD_SUCCESS = "[USER] RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "[USER] RESET_PASSWORD_FAILED";

export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const SET_ERROR = "[USER] SER_ERROR";

export const SET_VALUE = "[LOGIN] SET_VALUE";
export const CLEAR_VALUES = "[LOGIN] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function submitRecoverPassword(email, callback) {
	return async dispatch => {
		dispatch({ type: PASSWORD_RECOVERY });

		const response = await api.sendPost(`/Account/Recovery/${email}`);

		if (response.ok) {
			dispatch({ type: PASSWORD_RECOVERY_SUCCESS });

			dispatch(
				setValue({
					username: email,
					message: ls.newPasswordSentForEmail
				})
			);

			callback && callback();
		} else {
			const json = await response.json();

			if (json.errors.default && json.errors.default.includes("User is null")) {
				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { email: "E-mail inválido" }
				});
				callback && callback({ email: "E-mail inválido" });
			} else {
				let error = "Ocorreu um erro";

				dispatch({
					type: PASSWORD_RECOVERY_FAILED,
					payload: { email: error }
				});
				callback && callback({ email: error });
			}
		}
	};
}

export function submitResetPassword(data, token, callback) {
	return async dispatch => {
		dispatch({ type: PASSWORD_RECOVERY });

		const { password, confirmPassword } = data;

		const response = await api.sendPost(`/account/reset-password`, {
			password,
			confirmPassword,
			token
		});

		if (response.ok) {
			dispatch({ type: RESET_PASSWORD_SUCCESS });
			dispatch(
				setValue({
					message: ls.passwordChanged
				})
			);

			callback && callback();
		} else {
			const json = await response.json();

			if (json.errors.default && json.errors.default.includes("User is null")) {
				dispatch({
					type: RESET_PASSWORD_FAILED,
					payload: { email: "E-mail inválido" }
				});
				callback && callback({ email: "E-mail inválido" });
			} else {
				let error = "Ocorreu um erro";

				dispatch({
					type: RESET_PASSWORD_FAILED,
					payload: { email: error }
				});
				callback && callback({ email: error });
			}
		}
	};
}

export function submitResetDefaultPassword(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: PASSWORD_RECOVERY });
		try {
			const { login } = getState();

			const response = await api.sendPut(`/account/update-password/${id}`, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				callback && callback();
			} else {
				const json = await response.json();

				if (
					json.errors.default &&
					json.errors.default.includes("User is null")
				) {
					dispatch({
						type: PASSWORD_RECOVERY_FAILED,
						payload: { email: "E-mail inválido" }
					});
					callback && callback({ email: "E-mail inválido" });
				} else {
					let error = "Ocorreu um erro";

					dispatch({
						type: PASSWORD_RECOVERY_FAILED,
						payload: { email: error }
					});
					callback && callback({ email: error });
				}
			}
		} catch (error) {
			return dispatch({
				type: PASSWORD_RECOVERY_FAILED,
				payload: {
					error: error
				}
			});
		}
	};
}

export function validateToken(token, callback) {
	return async dispatch => {
		const response = await api.sendGet(`/account/verify-token/${token}`);

		if (response.ok) {
			dispatch({ type: VALIDATE_TOKEN_SUCCESS });

			callback && callback();
		} else {
			const json = await response.json();

			dispatch(
				setValue({
					message: "Token inválido ou expirado, solicite uma nova senha!"
				})
			);

			if (json.errors.default) {
				dispatch({
					type: VALIDATE_TOKEN_ERROR,
					payload: { token: "Token inválido ou expirado" }
				});

				callback && callback({ email: "Token inválido ou expirado" });
			} else {
				let error = "Ocorreu um erro";

				dispatch({
					type: VALIDATE_TOKEN_ERROR,
					payload: { token: error }
				});

				callback && callback({ token: error });
			}
		}
	};
}

export function handleHttpResponse(response) {
	if (response.ok) return response.json();

	if (response.status === 401) {
		throw new Error("Http Response is Unauthorized");
	}

	throw new Error("Http Response is not ok");
}

export function handleHttpError(error) {
	return dispatch => {
		if (error.message === "Http Response is Unauthorized") {
			dispatch(logoutUser());
		}

		dispatch({
			type: "ERROR"
		});
	};
}

export function logoutUser() {
	return {
		type: USER_LOGGED_OUT
	};
}

export function refreshToken(dispatch, getState) {
	let {
		login: { token, user }
	} = getState();

	try {
		let refreshTokenPromise = api
			.sendPost("/account/refreshToken?token=" + token + "&userId=" + user._id)
			.then(handleHttpResponse)
			.then(json => {
				if (json.isValid && json.model) {
					let token = json.model;

					dispatch({
						type: LOGIN_SUCCESS,
						payload: token
					});

					dispatch({
						type: REFRESH_TOKEN_SUCCESS
					});
					Promise.resolve(token);
				} else {
					const error = {
						username: json.errors.includes("Invalid email.")
							? "Invalid email."
							: json.errors.includes("Account disabled.")
							? "Account disabled."
							: null,
						password: json.errors.includes("Invalid password.")
							? "Invalid password."
							: null
					};

					dispatch({
						type: REFRESH_TOKEN_ERROR,
						payload: error
					});

					Promise.reject(error);
				}
			})
			.catch(err => {
				dispatch(handleHttpError(err));
			});

		dispatch({
			type: REFRESH_TOKEN,
			payload: refreshTokenPromise
		});

		return refreshTokenPromise;
	} catch (error) {
		dispatch({
			type: REFRESH_TOKEN_ERROR,
			payload: {
				error: "Connection error"
			}
		});
	}
}

export function getUserData(callback) {
	return async (dispatch, getState) => {
		const { token, loading } = getState().login;

		if (loading.user) {
			return;
		}

		dispatch({
			type: GET_USER_DATA
		});

		try {
			let response = await api.sendGet("/user/me", {
				Authorization: "Bearer " + token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SET_USER_DATA,
					payload: result.model
				});

				if (process.env.REACT_APP_LOG_ROCKET)
					LogRocket.identify(result.model._id, {
						name: result.model.name + result.model.lastName,
						email: result.model.email
					});

				callback && callback();
			} else {
				dispatch({
					type: GET_USER_DATA_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_DATA_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getUserPermissions(callback) {
	console.log("init getUserPermissions");
	return async (dispatch, getState) => {
		console.log("inner getUserPermissions");
		const { token } = getState().login;

		dispatch({
			type: GET_USER_PERMISSIONS
		});

		try {
			let response = await api.sendGet("/user/permission/me", {
				Authorization: "Bearer " + token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_PERMISSIONS_SUCCESS,
					payload: result.model
				});

				callback && callback();
			} else {
				dispatch({
					type: GET_USER_PERMISSIONS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_PERMISSIONS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function submitLogin(data, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: LOGIN
		});

		const { email: username, password } = data;

		try {
			let response = await api.sendPost("/account/token", {
				username,
				password
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: result.model
				});

				console.log("getUser");
				dispatch(getUserData(callback));
				console.log("getUserPermission");
				dispatch(
					getUserPermissions(err => {
						console.log(err);
					})
				);
				console.log("after getUserPermission");
			} else {
				let errors;
				if (result.errors) {
					errors = getErrors(result.errors);

					dispatch({
						type: LOGIN_ERROR,
						payload: errors
					});

					callback && callback(errors);
				} else {
					if (!result.user) {
						dispatch({
							type: LOGIN_ERROR,
							payload: errors
						});

						callback && callback(result);
					}
				}
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: LOGIN_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
