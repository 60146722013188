import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CREATE_COMPANY_PROVIDER =
	"[COMPANY_PROVIDER CREATE] CREATE_COMPANY_PROVIDER";
export const CREATE_COMPANY_PROVIDER_ERROR =
	"[COMPANY_PROVIDER CREATE] CREATE_COMPANY_PROVIDER_ERROR";
export const CREATE_COMPANY_PROVIDER_SUCCESS =
	"[COMPANY_PROVIDER CREATE] CREATE_COMPANY_PROVIDER_SUCCESS";

export const CHECK_PROVIDER_BENEFITS =
	"[PROVIDER_BENEFITS CREATE] CHECK_PROVIDER_BENEFITS";
export const CHECK_PROVIDER_BENEFITS_ERROR =
	"[PROVIDER_BENEFITS CREATE] CHECK_PROVIDER_BENEFITS_ERROR";
export const CHECK_PROVIDER_BENEFITS_SUCCESS =
	"[PROVIDER_BENEFITS CREATE] CHECK_PROVIDER_BENEFITS_SUCCESS";

export const SET_VALUE = "[COMPANY_PROVIDER CREATE] SET_VALUE";
export const CLEAR_VALUES = "[COMPANY_PROVIDER CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createCompanyProvider(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_COMPANY_PROVIDER
		});

		try {
			const { login } = getState();

			let response = await api.sendPost("/companyprovider", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();
			if (response.ok) {
				dispatch({
					type: CREATE_COMPANY_PROVIDER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_COMPANY_PROVIDER_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: CREATE_COMPANY_PROVIDER_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function checkProviderBenefits(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CHECK_PROVIDER_BENEFITS
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(
				"/companyprovider/check-provider-benefits",
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();
			if (response.ok) {
				dispatch({
					type: CHECK_PROVIDER_BENEFITS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CHECK_PROVIDER_BENEFITS_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: CHECK_PROVIDER_BENEFITS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
