import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import { stringToMask } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, Container, Row } from "reactstrap";
import { companySituationConst } from "types/companySituation";
import * as actions from "./actions";

dayjs.extend(customDateFormat);

let getCompaniesDebounced = null;

function ReportAllCompanies() {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.company);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getCompaniesDebounced) {
			getCompaniesDebounced.cancel();
		}

		if (!sorted) sorted = [];

		getCompaniesDebounced = _.debounce(
			() =>
				dispatch(
					actions.getCompanies(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getCompaniesDebounced();
	};

	const statusCnpj = status => {
		if (status == "ATIVO") {
			return (
				<Badge className="w-100" color="primary">
					{status}
				</Badge>
			);
		} else if (status == "BAIXADA") {
			return (
				<Badge className="w-100" color="warning">
					{status}
				</Badge>
			);
		} else if (status == "INAPTA") {
			return (
				<Badge className="w-100" color="danger">
					{status}
				</Badge>
			);
		} else {
			return (
				<Badge className="w-100" color="secondary">
					{"PENDENTE"}
				</Badge>
			);
		}
	};

	const statusActive = status => {
		if (status) {
			return (
				<Badge className="w-100" color="success">
					ATIVO
				</Badge>
			);
		} else if (!status) {
			return (
				<Badge className="w-100" color="warning">
					INATIVO
				</Badge>
			);
		} else {
			return (
				<Badge className="w-100" color="secondary">
					{"PENDENTE"}
				</Badge>
			);
		}
	};
	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Relatórios de Empresas"}
						count={state.count}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						onRowClicked={row => {
							let win = window.open(`/companies/${row._id}`, "_blank");
							win.focus();
						}}
						columns={[
							{
								Header: "Data de Criação",
								id: "createdAt",
								accessor: ({ createdAt }) =>
									createdAt
										? dayjs(createdAt).format("DD/MM/YY hh:mm:ss")
										: "--",
								width: 120
							},
							{
								Header: "Razão Social",
								id: "businessName",
								accessor: "businessName",
								className: "bold-text"
							},
							{
								Header: "CNPJ",
								id: "cnpj",
								accessor: ({ cnpj }) =>
									cnpj ? stringToMask(cnpj, "##.###.###/####-##") : "Pendente",
								width: 135
							},
							{
								Header: "Situação Cadastral",
								id: "situation",
								accessor: c => statusCnpj(c.situation),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(companySituationConst).map(c => (
												<option key={c} value={c}>
													{c}
												</option>
											))}
										</select>
									);
								},
								width: 85
							},
							{
								Header: "Data de Início",
								id: "startDate",
								accessor: "startDate",
								show: false
							},
							{
								Header: "Capital Social",
								id: "shareCapital",
								accessor: "shareCapital",
								show: false
							},
							{
								Header: "Registo Estadual",
								id: "stateRegistration",
								accessor: "stateRegistration",
								show: false
							},
							{
								Header: "Registo Municipal",
								id: "countyRegistration",
								accessor: "countyRegistration",
								show: false
							},
							{
								Header: "Operação",
								id: "operationMode",
								accessor: "operationMode",
								show: false
							},
							{
								Header: "Atividade",
								id: "activity",
								accessor: "activity",
								show: false
							},
							{
								Header: "Atividade secundárias",
								id: "secondaryActivities",
								accessor: ({ secondaryActivities }) =>
									secondaryActivities?.map((acc, e) => acc + e + "; "),
								show: false
							},
							{
								Header: "Última Alteração",
								id: "updatedAt",
								accessor: ({ updatedAt }) =>
									updatedAt
										? dayjs(updatedAt).format(ls.dateFormatShort)
										: "--",
								width: 85
							},
							{
								Header: "Status",
								id: "isActive",
								accessor: c => statusActive(c.isActive),
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{["Ativo", "Inativo"].map(c => (
												<option key={c} value={c}>
													{c}
												</option>
											))}
										</select>
									);
								},
								width: 85
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default ReportAllCompanies;
