import React, { useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { Tooltip } from "reactstrap";

export function TooltipComp({ id, message, placement = "top", icon, ...rest }) {
	const [tooltip, setTooltip] = useState(false);
	const toggletooltip = () => setTooltip(!tooltip);

	return (
		<>
			<span id={id} style={{ fontSize: "1rem" }} {...rest}>
				{icon || <BiInfoCircle />}
			</span>
			<Tooltip
				isOpen={tooltip}
				toggle={() => toggletooltip()}
				target={id}
				placement={placement}
			>
				{message}
			</Tooltip>
		</>
	);
}
