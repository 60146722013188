import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ThemeProps } from "../../shared/prop-types/ReducerProps";

class MainWrapper extends PureComponent {
	static propTypes = {
		theme: ThemeProps.isRequired,
		children: PropTypes.element.isRequired,
		location: PropTypes.shape({}).isRequired
	};

	render() {
		const { theme, children } = this.props;

		const direction = "ltr";

		return (
			<div
				style={{ height: "100%" }}
				className={`${theme.className} ${direction}-support`}
				dir={direction}
			>
				<div className="wrapper" style={{ height: "100%" }}>
					{children}
				</div>
			</div>
		);
	}
}

export default withRouter(
	connect(state => ({
		theme: state.theme
	}))(MainWrapper)
);
