import React, { useRef, useState } from "react";

import XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../containers/Report/actions";
import dayjs from "dayjs";
import ls from "Localization";
import ReactExport from "react-export-excel";

import { Button, Spinner } from "reactstrap";
import { FaFileImport } from "react-icons/fa";

import { toStringCurrency } from "helpers/string";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function ImportTransfer({ load }) {
	const dispatch = useDispatch();

	const [exportComponent, setExport] = useState(null);

	const { loading } = useSelector((s) => s.transfer);

	const inputFile = useRef(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const handleExport = (data) => {
		const columns = ["Data criação", "Valor"];

		const excelData = data.map((c) => [
			dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds),
			toStringCurrency(+c.value / 100.0),
		]);

		setExport(
			<ExcelFile
				filename={"Boletos" + "_" + dayjs().format("YYYY-MM-DD-kk-mm-ss")}
				hideElement
			>
				<ExcelSheet dataSet={[{ columns, data: excelData }]} name="Boletos" />
			</ExcelFile>
		);

		setTimeout(() => {
			setExport(null);
		}, 1000);
	};

	const handleFileSelected = (e) => {
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const sheet = wb.Sheets[wsname];

			const data = XLSX.utils.sheet_to_json(sheet);

			dispatch(
				actions.importTransfers(
					data.map((c) => ({
						payer: c["Pagador"],
						to: c["Recebedor"],
						value: Math.round(c["Valor"] * 100),
					})),
					(err, data) => {
						if (err) {
							if (typeof err !== "string") err = JSON.stringify(err);
							alert(err);
						} else {
							load();
							handleExport(data);
						}
					}
				)
			);
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	return (
		<div style={{ marginRight: 6 }}>
			{loading.import ? (
				<Spinner size='sm' />
			) : (
					<Button outline onClick={openSelectFile}>
						<FaFileImport />
					</Button>
				)}
			<input
				ref={inputFile}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileSelected}
			/>
			{exportComponent}
		</div>
	);
}

export default ImportTransfer;
