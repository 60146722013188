import Input from "components/ReduxForm/Input";
import React from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import phoneNumber from "helpers/phoneNumber";
import { PHONE } from "components/NumberFormat";

const ContactCompanyEdit = ({ handleSubmit, initialValues, reset }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="form__form-group">
						<span className="form__form-group-label">Email Financeiro</span>
						<div className="form__form-group-field">
							<Field
								name="email"
								placeholder="financeiro@email.com.br"
								component={Input}
								type="text"
								defaultChecked={initialValues.email}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="form__form-group">
						<span className="form__form-group-label">
							Email Assinatura de Contratos
						</span>
						<div className="form__form-group-field">
							<Field
								name="contractSignerEmail"
								placeholder="contratos@email.com.br"
								component={Input}
								type="text"
								defaultChecked={initialValues.contractSignerEmail}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="form__form-group">
						<span className="form__form-group-label">Telefone</span>
						<div className="form__form-group-field">
							<Field
								name="phone"
								component={PHONE}
								type="text"
								placeholder="Ex.: (41)99999-9999"
								defaultChecked={phoneNumber.format(initialValues.phone)}
							/>
						</div>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					// disabled={submitting || isInvalid || loading.create}
				>
					Salvar
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "contact_company_edit"
})(ContactCompanyEdit);
