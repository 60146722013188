import React, { useEffect, useRef } from "react";

import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { clearValues, getVersionReport } from "./actions";
import _ from "lodash";
import ls from "Localization";
import { stringSort } from "helpers/string";
import dayjs from "dayjs";

import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";

let getVersionReportDebounded = null;

function Marketing({ history, location }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const query = queryString.parse(location.search);

	const { items, pages, loading } = useSelector(s => s.versionReport);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch]);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getVersionReportDebounded) {
			getVersionReportDebounded.cancel();
		}

		if (!sorted) sorted = [];

		getVersionReportDebounded = _.debounce(
			() =>
				dispatch(
					getVersionReport(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getVersionReportDebounded();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	useEffect(() => {
		load();
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title="Relatório de Versões"
						data={items}
						pages={pages}
						filterable
						loading={loading.getAll}
						onFetchData={handleFetchData}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id])
								.toLowerCase()
								.indexOf(filter.value.toLowerCase()) > -1
						}
						onRowClicked={row => history.push(`/version-report/` + row._id)}
						defaultSorted={[
							{
								id: "version",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Versão",
								accessor: "version",
								sortMethod: stringSort,
								width: 80
							},
							{
								Header: ls.creationDate,
								id: "createdAt",
								accessor: c =>
									dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds),
								sortMethod: (a, b, desc) => {
									let aD = dayjs(a, ls.dateFormatWithoutSeconds);
									let bD = dayjs(b, ls.dateFormatWithoutSeconds);

									return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
								},
								width: 130
							},
							{
								Header: "Título",
								accessor: "title",
								sortMethod: stringSort,
								width: 150
							},
							{
								Header: "Texto",
								accessor: "text",
								sortMethod: stringSort
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Marketing;
