import * as actions from "./actions";

const initialState = {
	item: null,
	collection: [],
	loading: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_CONTRACT_DETAIL: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_CONTRACT_DETAIL_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: { get: false }
			};
		}
		case actions.GET_CONTRACT_DETAIL_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}

		case actions.DELETE_CONTRACT: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_CONTRACT_SUCCESS: {
			return {
				...state,
				loading: { delete: false }
			};
		}
		case actions.DELETE_CONTRACT_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.GET_CONTRACT_COLLECTION: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_CONTRACT_COLLECTION_SUCCESS: {
			return {
				...state,
				collection: payload,
				loading: { get: false }
			};
		}
		case actions.GET_CONTRACT_COLLECTION_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
