import React from "react";

// Styles
import { TextWrapper } from "./styles";

import PropTypes from "prop-types";

export const Text = ({ as = "p", color, children, ...rest }) => {
	return (
		<TextWrapper as={as} color={color} {...rest}>
			{children}
		</TextWrapper>
	);
};

Text.propTypes = {
	as: PropTypes.string,
	fontSize: PropTypes.string,
	fontWeight: PropTypes.oneOfType([
		PropTypes.oneOf(["normal", "bold", "bolder", "lighter"]),
		PropTypes.number,
		PropTypes.string
	]),
	fontStyle: PropTypes.oneOf(["normal", "italic", "oblique"]),
	lineHeight: PropTypes.string,
	textAlign: PropTypes.oneOf([
		"left",
		"right",
		"center",
		"justify",
		"justify-all",
		"start",
		"end",
		"match-parent"
	]),
	textTransform: PropTypes.oneOf([
		"none",
		"capitalize",
		"uppercase",
		"lowercase"
	]),
	textDecoration: PropTypes.oneOf([
		"none",
		"underline",
		"overline",
		"line-through",
		"blink"
	]),
	alignSelf: PropTypes.oneOf([
		"auto",
		"stretch",
		"center",
		"flex-start",
		"flex-end",
		"baseline"
	]),
	color: PropTypes.string,
	children: PropTypes.node
};
