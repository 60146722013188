import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_TAXES = "[TAX] GET_TAXES";
export const GET_TAXES_SUCCESS = "[TAX] GET_TAXES_SUCCESS";
export const GET_TAXES_FAILED = "[TAX] GET_TAXES_FAILED";

export const IMPORT_TAXES = "[TAX] IMPORT_TAXES";
export const IMPORT_TAXES_SUCCESS = "[TAX] IMPORT_TAXES_SUCCESS";
export const IMPORT_TAXES_FAILED = "[TAX] IMPORT_TAXES_FAILED";

export const SET_VALUE = "[TAX] SET_VALUE";
export const CLEAR_VALUES = "[TAX] CLEAR_VALUES";

export function setValue(payload) {
  return { type: SET_VALUE, payload };
}

export function clearValues() {
  return { type: CLEAR_VALUES };
}

export function importTaxes(files, callback) {
  return async (dispatch, getState) => {
    dispatch({ type: IMPORT_TAXES });

    try {
      const { login } = getState();

      var response = await api.sendPostFiles("/Tax/Import", files, {
        Authorization: "Bearer " + login.token
      });

      let result = await response.json();

      if (result.isValid) {
        dispatch({
          type: IMPORT_TAXES_SUCCESS,
          payload: result.model
        });

        callback && callback(null, result.model);
      } else {
        const errors = getErrors(result.errors);

        dispatch({
          type: IMPORT_TAXES_FAILED,
          payload: errors
        });
        callback && callback(errors);
      }
    } catch (error) {
      return dispatch({
        type: IMPORT_TAXES_FAILED,
        payload: {
          error: "Connection error"
        }
      });
    }
  };
}

export function getTaxes(skip, take, filter, sort, desc, toExport, callback) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_TAXES });

    try {
      const { login } = getState();

      var response = await api.sendGet(
        `/Tax?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
          "&filters=null"}`,
        {
          Authorization: "Bearer " + login.token
        }
      );

      let result = await response.json();

      if (result.isValid) {
        if (toExport) {
          dispatch(setValue({ loading: false }));
        } else {
          dispatch({
            type: GET_TAXES_SUCCESS,
            payload: result.model
          });

          if (result.params)
            dispatch(
              setValue({
                pages: Math.ceil(result.params.count / take),
                ...result.params
              })
            );
        }

        callback && callback(null, result.model);
      } else {
        const errors = getErrors(result.errors);

        dispatch({
          type: GET_TAXES_FAILED,
          payload: errors
        });
        callback && callback(errors);
      }
    } catch (error) {
      return dispatch({
        type: GET_TAXES_FAILED,
        payload: {
          error: "Connection error"
        }
      });
    }
  };
}
