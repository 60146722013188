import {
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	MenuItem,
	Select,
	Tooltip
} from "@material-ui/core";
import ls from "Localization";
import LoadingComponent from "components/LoadingComponent";
import BasicModal from "components/StyledModal";
import { StyledTable } from "components/StyledTable";
import dayjs from "dayjs";
import ExportExcel from "helpers/exportExcel";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { BiTrash } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as api from "../../../../services/api";
import {
	ActionCellButton,
	ActionCellContainer,
	ButtonSC,
	CellText,
	DateRangePickerContainer,
	SubTitleSC,
	TitleSC
} from "./styled";
moment.locale("pt-br");

const CompanyGroups = () => {
	const history = useHistory();
	const { token } = useSelector(state => state.login);

	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(10);
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [focusedInput, setFocusedInput] = useState(null);

	const [businessName, setBusinessName] = useState("");
	const [cnpj, setCnpj] = useState("");
	const [status, setStatus] = useState(null);

	const [data, setData] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () =>
		setIsOpen(prevState => {
			console.log("prevState", prevState);
			return !prevState;
		});

	const [currentCompanyGroup, setCurrentCompanyGroup] = useState({});

	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () => setModalExportExcel(!modalExportExcel);
	const [progress, setProgress] = useState(0);
	const [exportType, setExportType] = useState("xlsx");

	const columns = [
		{
			field: "createdAt",
			headerName: (
				<span>
					Data de
					<br />
					Cadastro
				</span>
			),
			align: "center",
			alignContent: "center",
			width: 80,
			content: value => dayjs(value).format(ls.dateFormatShort)
		},
		{
			field: "",
			headerName: "Nome do Cliente",
			align: "left",
			alignContent: "left",
			width: 500,
			content: value => {
				return value.isCompanyGroup ? (
					<Tooltip
						title={
							<ul>
								{value.companies?.map(c => (
									<li key={c._id}>{c.businessName}</li>
								))}
							</ul>
						}
						placement="top"
					>
						<CellText>{value.businessName || ""}</CellText>
					</Tooltip>
				) : (
					<CellText>{value.businessName || ""}</CellText>
				);
			}
		},
		{
			field: "",
			headerName: (
				<span>
					Quatidade
					<br />
					de Empresas
				</span>
			),
			align: "center",
			alignContent: "center",
			content: value => {
				return value.isCompanyGroup ? (
					<Tooltip
						title={
							<ul>
								{value.companies?.map(c => (
									<li key={c._id}>{c.businessName}</li>
								))}
							</ul>
						}
						placement="top"
					>
						<CellText>{value.companies?.length || 1}</CellText>
					</Tooltip>
				) : (
					<CellText>{value.companies?.length || 1}</CellText>
				);
			}
		},
		{
			field: "status",
			headerName: (
				<span>
					Tem Contratantes
					<br /> Ativos?
				</span>
			),
			align: "center",
			alignContent: "center",
			content: value => {
				return (
					<span
						style={{
							backgroundColor: value ? "green" : "red",
							padding: "2px 16px",
							borderRadius: "5px",
							color: "#fff",
							fontWeight: 700
						}}
					>
						{value ? "Sim" : "Não"}
					</span>
				);
			}
		},
		{
			field: "",
			headerName: "Ações",
			align: "center",
			alignContent: "center",
			width: 70,
			content: value => {
				return (
					<ActionCellContainer>
						<Tooltip title="Detalhes" placement="top">
							<ActionCellButton
								onClick={() =>
									history.push(
										`/companies${
											value.isCompanyGroup ? "/company-group" : ""
										}/${value._id}`
									)
								}
							>
								<BsEye />
							</ActionCellButton>
						</Tooltip>

						{value.isCompanyGroup && (
							<>
								<Tooltip title="Editar" placement="top">
									<ActionCellButton
										onClick={() =>
											history.push(`/companies/company-group/${value._id}/edit`)
										}
									>
										<MdEdit />
									</ActionCellButton>
								</Tooltip>
								<Tooltip title="Excluir" placement="top">
									<ActionCellButton
										onClick={() => {
											toggle();
											setCurrentCompanyGroup(() =>
												handleFetchById(
													data.find(item => item._id === value._id)
												)
											);
										}}
									>
										<BiTrash />
									</ActionCellButton>
								</Tooltip>
							</>
						)}
					</ActionCellContainer>
				);
			}
		}
	];

	function handleChangeSkip(value) {
		setSkip(value);
	}

	function handleChangeTake({ target }) {
		setTake(target.value);

		setSkip(0);
	}

	const handleFetchData = async () => {
		try {
			setIsLoading(true);

			let filter = "";

			if (startDate && endDate) {
				filter += `&filters[createdAtInitDate]=${moment(startDate).format(
					"DD/MM/YYYY"
				)}`;
				filter += `&filters[createdAtEndDate]=${moment(endDate).format(
					"DD/MM/YYYY"
				)}`;
			} else if (startDate) {
				filter += `&filters[createdAtInitDate]=${moment(startDate).format(
					"DD/MM/YYYY"
				)}`;
			} else if (endDate) {
				filter += `&filters[createdAtEndDate]=${moment(endDate).format(
					"DD/MM/YYYY"
				)}`;
			}

			if (businessName) filter += `&filters[businessName]=${businessName}`;
			if (cnpj) filter += `&filters[cnpj]=${cnpj}`;
			if (status !== null && status !== "Todos")
				filter += `&filters[status]=${status}`;

			const response = await api.sendGet(
				`/company-group?skip=${skip *
					take}&take=${take}&sort=businessName&desc=false${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setData(result.model);
				setCount(result.params.count);
			}

			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleFetchById = async company => {
		try {
			const response = await api.sendGet(`/company-group/${company._id}`, {
				Authorization: "Bearer " + token
			});

			const result = await response.json();

			if (result.isValid) {
				setCurrentCompanyGroup(result.model);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleRemove = async () => {
		try {
			const response = await api.sendDelete(
				`/company-group/${currentCompanyGroup._id}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				toggle();

				toast.warning("Cliente removido com sucesso");

				handleFetchData();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const clearFilters = () => {
		window.location.reload();
	};

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				Cliente: item.businessName,
				"Quantidade de Empresa do Cliente": item.companies?.length || 0,
				"Cliente Ativo": item.status ? "SIM" : "NAO",
				Empresas: item.companies.map(item => item.businessName).join(", "),
				"Data de Criação": item.createdAt
					? dayjs(item.createdAt).format("DD/MM/YYYY")
					: ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = async type => {
		const dataTmp = [];
		const takeTmp = count;

		for (let skipTmp = 0; skipTmp < count; skipTmp += takeTmp) {
			const response = await api.sendGet(
				`/company-group?skip=${skipTmp}&take=${takeTmp}&sort=createdAt&desc=true&filters=null`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				dataTmp.push(...result.model);
				setProgress(Math.round((skipTmp / count) * 100));
			} else {
				console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
				break;
			}
		}

		ExportExcel(customData(dataTmp), "Lista_Contratantes", type);
		toggleModalExportExcel();
	};

	const onSubmitExportExcel = () => {
		if (count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (exportType === "xlsx") {
			handleExport("xlsx");
		}

		if (exportType === "csv") {
			handleExport("csv");
		}
	};

	useEffect(() => {
		handleFetchData();
	}, [skip, take]);

	return (
		<Container>
			<Grid container style={{ marginBottom: "16px" }}>
				<Grid item style={{ marginBottom: "16px" }}>
					<TitleSC id="company-group-title">Relatório de Clientes</TitleSC>
				</Grid>

				<Grid
					item
					container
					spacing={2}
					style={{
						background: "#fff",
						borderRadius: "5px",
						padding: "16px"
					}}
				>
					<Grid
						id="company-group-filter-header"
						item
						style={{ borderBottom: "1px solid #ccc", width: "100%" }}
					>
						<SubTitleSC>Filtros</SubTitleSC>
					</Grid>

					<Grid id="company-group-filter-body" item container spacing={2}>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<span>Data de Cadastro</span>
							<DateRangePickerContainer>
								<DateRangePicker
									startDatePlaceholderText="Data Inicial"
									startDate={startDate}
									startDateId="your_unique_start_date_id"
									endDatePlaceholderText="Data Final"
									endDate={endDate}
									endDateId="your_unique_end_date_id"
									onDatesChange={({ startDate, endDate }) => {
										setStartDate(startDate);
										setEndDate(endDate);
									}}
									focusedInput={focusedInput}
									onFocusChange={focusedInput => setFocusedInput(focusedInput)}
									small={true}
									isOutsideRange={() => false}
									noBorder={true}
									showClearDates
								/>
							</DateRangePickerContainer>
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={3}>
							<span>Nome do Cliente</span>
							<input
								name="businessName"
								type="text"
								value={businessName}
								onChange={e => setBusinessName(e.target.value)}
								style={{
									padding: "8px 14px",
									borderRadius: "5px",
									border: "1px solid rgb( 0, 0, 0, 0.2 )",
									width: "100%",
									lineHeight: 1.6
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={3}>
							<p>Status</p>
							<FormControl size="small" style={{ width: "100%" }}>
								<Select
									onChange={e => setStatus(e.target.value)}
									defaultValue={"Todos"}
									variant="outlined"
								>
									{[
										{ label: "Todos", value: "Todos" },
										{ label: "Inativos", value: 0 },
										{ label: "Ativos", value: 1 }
									].map(item => (
										<MenuItem key={item.label} value={item.value}>
											{item.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>

					<Grid
						id="company-group-filter-footer"
						item
						container
						spacing={1}
						style={{ display: "flex", justifyContent: "flex-end" }}
					>
						<Grid item>
							<ButtonSC
								background="#fff"
								color="#584c9c"
								border="1px solid #584c9c"
								onClick={clearFilters}
								type="button"
							>
								Limpar
							</ButtonSC>
						</Grid>

						<Grid item>
							<ButtonSC border="1px solid #584c9c" onClick={handleFetchData}>
								Aplicar
							</ButtonSC>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					container
					style={{
						marginTop: "32px",
						display: "flex",
						justifyContent: "space-between"
					}}
				>
					<Grid item>
						<ButtonSC
							onClick={() => history.push("/companies/company-group/new")}
						>
							Novo Cliente
						</ButtonSC>
					</Grid>
					<Grid item>
						<ButtonSC onClick={toggleModalExportExcel}>
							<FiDownload />
						</ButtonSC>
					</Grid>
				</Grid>
			</Grid>

			{isLoading ? (
				<LoadingComponent />
			) : (
				<StyledTable
					data={data}
					columns={columns}
					handleChangeSkip={handleChangeSkip}
					handleChangeTake={handleChangeTake}
					skip={skip}
					take={take}
					count={count}
					showHeader={true}
					rowClick={false}
				/>
			)}

			<BasicModal open={isOpen} handleClose={toggle}>
				<Grid
					container
					direction="column"
					spacing={2}
					style={{ marginBottom: "16px" }}
				>
					<Grid item style={{ borderBottom: "1px solid #ccc", width: "100%" }}>
						<SubTitleSC>
							Deseja remover esse Cliente e suas Empresas?
						</SubTitleSC>
					</Grid>

					<Grid item container direction="column">
						<Grid item>
							<p>
								<SubTitleSC>Cliente: </SubTitleSC>
								{currentCompanyGroup.businessName}
							</p>
						</Grid>

						{currentCompanyGroup.companies?.length > 0 &&
							currentCompanyGroup.companies.map(company => (
								<Grid item key={company._id}>
									<p>- {company.businessName}</p>
								</Grid>
							))}
					</Grid>

					<Grid item style={{ borderBottom: "1px solid #ccc", width: "100%" }}>
						<SubTitleSC style={{ color: "red" }}>
							Essa operação não pode ser desfeita!
						</SubTitleSC>
					</Grid>
				</Grid>

				<Grid
					container
					spacing={1}
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Grid item>
						<ButtonSC
							background="#fff"
							color="#584c9c"
							border="1px solid #584c9c"
							onClick={toggle}
							type="button"
						>
							Cancelar
						</ButtonSC>
					</Grid>

					<Grid item>
						<ButtonSC border="1px solid #584c9c" onClick={() => handleRemove()}>
							Remover
						</ButtonSC>
					</Grid>
				</Grid>
			</BasicModal>

			<BasicModal open={modalExportExcel} handleClose={toggleModalExportExcel}>
				<Grid
					container
					direction="column"
					spacing={2}
					style={{ marginBottom: "16px" }}
				>
					<Grid item style={{ borderBottom: "1px solid #ccc", width: "100%" }}>
						<SubTitleSC>Exportar Relatório</SubTitleSC>
					</Grid>
				</Grid>

				<Grid container justifyContent="space-between">
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									defaultChecked
									onChange={() => setExportType("xlsx")}
									color="primary"
								/>
							}
							label="Excel"
						/>
					</FormGroup>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									onChange={() => setExportType("csv")}
									color="primary"
								/>
							}
							label="Csv"
						/>
					</FormGroup>
				</Grid>

				<div className="progress" style={{ margin: "16px 0" }}>
					<div
						className="progress-bar progress-bar-striped progress-bar-animated"
						role="progressbar"
						aria-valuenow={progress}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{ width: progress + "%" }}
					>
						{progress}%
					</div>
				</div>

				<Grid
					container
					spacing={1}
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Grid item>
						<ButtonSC
							background="#fff"
							color="#584c9c"
							border="1px solid #584c9c"
							onClick={toggleModalExportExcel}
							type="button"
						>
							Cancelar
						</ButtonSC>
					</Grid>

					<Grid item>
						<ButtonSC
							border="1px solid #584c9c"
							onClick={() => onSubmitExportExcel()}
						>
							Exportar
						</ButtonSC>
					</Grid>
				</Grid>
			</BasicModal>
		</Container>
	);
};

export default CompanyGroups;
