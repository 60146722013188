import Input from "components/ReduxForm/Input";

import React from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { MONEY } from "components/NumberFormat";

const BenefitsCreate = ({
	handleSubmit,
	loading,
	submitting,
	isInvalid,
	change,
	reset
}) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={6} lg={8} className="form__form-group">
						<span className="form__form-group-label">Nome</span>
						<div className="form__form-group-field">
							<Field
								name="name"
								component={Input}
								type="text"
								placeholder="Digite o nome do beneficio"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={3} lg={2} className="form__form-group">
						<span className="form__form-group-label">Preço</span>
						<div className="form__form-group-field">
							<Field
								name="price"
								component={MONEY}
								type="text"
								placeholder="Ex.: R$ 19,90"
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={3} lg={2} className="form__form-group">
						<span className="form__form-group-label">Desconto</span>
						<div className="form__form-group-field">
							<Field
								name="discount"
								component={MONEY}
								type="text"
								placeholder="Ex.: R$ 5,00"
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={9} lg={10} className="form__form-group">
						<span className="form__form-group-label">Descrição</span>
						<div className="form__form-group-field">
							<Field
								name="description"
								component={Input}
								type="text"
								multiple
								rows={3}
								placeholder="Informe uma descrição sobre o beneficio"
							/>
						</div>
					</Col>

					<Col xs={12} md={3} lg={2}>
						<h6 className="bold-text">Ícone</h6>
						<Input
							accept="image/png"
							type="file"
							name="icon"
							onChange={f => change("icon", f.target.files[0])}
						/>
					</Col>
				</Row>
			</Container>
			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || isInvalid || loading}
				>
					{loading ? <Spinner type="grow" size="sm" color="dark" /> : "Salvar"}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	form: "benefit_create",
	validate
})(BenefitsCreate);
