import Report from "components/Report";
import ls from "Localization";
import _ from "lodash";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Access } from "types/Access";
import { getUserPermissions, setValue } from "./actions";
import AddPermission from "../../Create/Permission";

let getChargesDebounced = null;

function Permission() {
	const dispatch = useDispatch();

	const {
		params: { id }
	} = useRouteMatch();

	const state = useSelector(s => s.userPermission);

	const table = useRef(null);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getChargesDebounced) {
			getChargesDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate && creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate && creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getChargesDebounced = _.debounce(
			() =>
				dispatch(
					getUserPermissions(
						page * pageSize,
						10,
						filtered.reduce(
							(p, c) => p + `&filters[${c.id}]=${c.value}`,
							`&filters[user]=${id}`
						),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getChargesDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	return (
		<Report
			manual
			tableRef={table}
			title={"Permissões"}
			headerRightComponent={<AddPermission load={load} />}
			onRowClicked={p => {
				dispatch(setValue({ showCreateModal: true }));
				dispatch(setValue({ userPermission: p }));
			}}
			data={state.items}
			pages={state.pages}
			loading={state.loading.getAll}
			onFetchData={handleFetchData}
			defaultSorted={[
				{
					id: "createdAt",
					desc: true
				}
			]}
			pageSize={
				state.items.length > 10
					? 10
					: state.items.length <= 0
					? 1
					: state.items.length + 1
			}
			columns={[
				{
					Header: "Nome",
					id: "name",
					width: 250,
					accessor: "permission.name"
				},
				{
					Header: "Usuário",
					id: "user",
					accessor: c => ls[Access[c.access[0]]]
				},
				{
					Header: "Empresa",
					id: "group",
					accessor: c => ls[Access[c.access[1]]]
				},
				{
					Header: "Geral",
					id: "all",
					accessor: c => ls[Access[c.access[2]]]
				}
			]}
		/>
	);
}

export default Permission;
