import * as actions from "./actions";

const initialState = {
	items: [],
	installments: [],
	filter: [],
	pages: null,
	count: 0,
	loading: { getAll: false, import: false, pay: false },
	errors: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_SUBSCRIPTIONS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors,
			};
		}
		case actions.GET_SUBSCRIPTIONS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false },
			};
		}
		case actions.GET_SUBSCRIPTIONS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload,
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload,
			};
		default: {
			return state;
		}
	}
};

export default reducer;
