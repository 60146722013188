import React, { useState } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import getIcon from "./Icon";
import { isInRole } from "helpers/auth";

export function SidebarCategory({ title, icon, isNew, children, user, roles }) {
	const [collapse, setCollapse] = useState(false);

	const toggle = () => {
		setCollapse(s => !s);
	};

	const categoryClass = classNames({
		"sidebar__category-wrap": true,
		"sidebar__category-wrap--open": collapse
	});

	if (roles && roles.length) {
		if (!isInRole(user, roles)) return null;
	}

	return (
		<div className={categoryClass}>
			<button
				type="button"
				className="sidebar__link sidebar__category"
				onClick={toggle}
			>
				{getIcon(icon)}
				<p className="sidebar__link-title">
					{title}
					{isNew && <span className="sidebar__category-new" />}
				</p>
				<span className="sidebar__category-icon lnr lnr-chevron-right" />
			</button>
			<Collapse isOpen={collapse} className="sidebar__submenu-wrap">
				<ul className="sidebar__submenu">
					<div>{children}</div>
				</ul>
			</Collapse>
		</div>
	);
}

SidebarCategory.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.any,
	isNew: PropTypes.bool,
	roles: PropTypes.array,
	user: PropTypes.any,
	children: PropTypes.arrayOf(PropTypes.element).isRequired
};

SidebarCategory.defaultProps = {
	icon: "",
	roles: [],
	user: null,
	isNew: false
};

export default SidebarCategory;
