export const contractTemplateStatus = {
	0: "Active",
	1: "Inactive"
};

export const contractTemplateStatusConst = {
	Active: 0,
	Inactive: 1
};

export const contractTemplateTypes = {
	0: "Others",
	1: "Nda",
	2: "Confidentiality",
	3: "Lending",
	4: "ServicesProvision",
	5: "ContractAmendment",
	6: "Dissolution"
};

export const contractTemplateTypesConst = {
	Others: 0,
	Nda: 1,
	Confidentiality: 2,
	Lending: 3,
	ServicesProvision: 4,
	ContractAmendment: 5,
	Dissolution: 6
};

export const contractStatus = {
	0: "Pending",
	1: "Signed",
	2: "OverDue",
	3: "Cancelled",
	4: "Rescind",
	5: "hirerSigned",
	6: "hiredSigned",
	7: "Excluded",
	8: "NonOpting",
	9: "AwaitingSignature"
};

export const contractStatusConst = {
	Default: -1,
	Pending: 0,
	Signed: 1,
	Due: 2,
	Cancelled: 3,
	Rescind: 4,
	hirerSigned: 5,
	hiredSigned: 6,
	Excluded: 7,
	NonOpting: 8,
	AwaitingSignature: 9
};

export const signerStatus = {
	0: "Pending",
	1: "Signed"
};

export const signerStatusConst = {
	Pending: 0,
	Signed: 1
};
