import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import EditForm from "../../../components/EditForm/editForm";
import { getContractTemplateById } from "../Detail/actions";
import { clearValues, updateTemplateContract } from "./actions";
import ls from "Localization";
import _ from "lodash";
import {
	contractTemplateStatus,
	contractTemplateTypes
} from "types/contractStatus";
import { getCompanies } from "../../../../Company/containers/Report/actions";
import { getDepartmentList } from "../../ReportDepartment/actions";

let findCompanyDebounced = null;
let getDepartmentDebounced = null;

function Edit({
	history,
	match: {
		params: { id }
	}
}) {
	const { item } = useSelector(s => s.contractTemplateDetail);
	const { items: companies } = useSelector(s => s.company);
	const { loading, errors } = useSelector(s => s.contractTemplateEdit);
	const { items: departmentList } = useSelector(s => s.departmentList);
	const contract_edit = useSelector(s => s.form.contract_edit);

	const dispatch = useDispatch();

	const handleFetchDepartmentData = name => {
		if (getDepartmentDebounced) {
			getDepartmentDebounced.cancel();
		}

		let filterStr = "";

		if (name) filterStr += `&filters[name]=${name}`;

		getDepartmentDebounced = _.debounce(
			() =>
				dispatch(
					getDepartmentList(0, 10, filterStr, "createdAt", true, false, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: JSON.stringify(err)
							);
					})
				),
			500
		);

		getDepartmentDebounced();
	};

	const handleFindCompany = company => {
		if (findCompanyDebounced) {
			findCompanyDebounced.cancel();
		}

		findCompanyDebounced = _.debounce(
			() =>
				dispatch(
					getCompanies(
						0,
						10,
						`&filters[hasMei]=true${
							company ? `&filters[searchText]=${company}` : null
						}`,
						"createdAt",
						true,
						false,
						err => {
							if (err)
								alert(
									"Não foi possível carregar os usuários, erro: " +
										typeof err ===
										"string"
										? err
										: JSON.stringify(err)
								);
						}
					)
				),
			500
		);

		findCompanyDebounced();
	};

	if (!item) return null;

	const statusData = Object.keys(contractTemplateStatus).map(c => ({
		value: c,
		label: ls[contractTemplateStatus[c]]
	}));

	const onSubmit = data => {
		dispatch(
			updateTemplateContract(
				id,
				{
					...item,
					name: data.name,
					type: data.type ? data.type.value : 0,
					externalId: data.externalId,
					status: data.status && data.status.value ? data.status.value : null,
					mei: data.mei ? data.mei.value : null,
					department: data.department ? data.department.value : null
				},
				(err, model) => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push(`/contracts/template/${model._id}`);
					}
				}
			)
		);
	};

	const onChange = values => {
		if (values.meiSearch) {
			handleFindCompany(values.meiSearch);
		}
	};

	const companiesValues = companies.map(c => ({
		value: c._id,
		label: c.businessName
	}));

	const typeData = Object.keys(contractTemplateTypes).map(c => ({
		value: c,
		label: ls[contractTemplateTypes[c]]
	}));

	const departmentData = departmentList.map(c => ({
		value: c._id,
		label: c.name
	}));

	if (item.mei && !companiesValues.find(c => c.value === item.mei._id)) {
		companiesValues.push({
			value: item.mei._id,
			label: item.mei.businessName
		});
	}

	const initialValues = {
		name: item.name,
		type: typeData.find(c => c.value === String(item.type)),
		externalId: item.externalId,
		status: statusData.find(c => c.value === String(item.status)),
		mei: item.mei ? companiesValues.find(c => c.value === item.mei._id) : null,
		department: departmentData.find(
			c => c.value === String(item.department?._id)
		)
	};

	useEffect(() => {
		if (!companies) dispatch(clearValues());
		dispatch(getContractTemplateById(id));
	}, [dispatch]);

	useEffect(() => {
		handleFindCompany();
		handleFetchDepartmentData();
	}, [dispatch]);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">
									Alterar dados do modelo de contrato
								</h5>
							</div>
							<EditForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={contract_edit && !!contract_edit.syncErrors}
								errors={errors}
								typeData={typeData}
								typeStatus={statusData}
								companiesValues={companiesValues}
								onChange={onChange}
								departmentData={departmentData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
