import React from "react";

import { useSelector } from "react-redux";

import { Col, Row } from "reactstrap";
import { Field } from "redux-form";
import Input from "components/ReduxForm/Input";
import renderDateTimePickerField from "shared/components/form/DateTimePicker";

const AboutForm = () => {
	const { errors } = useSelector(s => s.marketingCreate);

	return (
		<Row>
			<Col xs={12} sm={12} md={6} xl={6} className="form__form-group">
				<span className="form__form-group-label">Nome da campanha*</span>
				<div className="form__form-group-field">
					<Field
						name="name"
						component={Input}
						type="text"
						placeholder="Ex.: Conheça nossos benefícios"
						props={{ errors: errors.name }}
					/>
				</div>
			</Col>
			<Col xs={12} sm={12} md={6} xl={6} className="form__form-group">
				<span className="form__form-group-label">Disparar em</span>
				<div className="form__form-group-field">
					<Field
						name="startAt"
						component={renderDateTimePickerField}
						type="text"
						placeholder="EX.: 10/10/2020 09:50"
						props={{ errors: errors.startAt }}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default AboutForm;
