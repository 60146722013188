const validate = (values = {}) => {
	const errors = {};

	if (!values.name) errors.name = "Informe nome do atendente";
	if (!values.email) errors.email = "Informe e-mail do atendente";
	if (!values.digisacId)
		errors.digisacId = "Informe ID do atendente no Digisac";

	return errors;
};

export default validate;
