import React from "react";

import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CreateForm from "../../components/CreateForm";
import { createVersionReport } from "./actions";

dayjs.extend(customParseFormat);

function Create({ history }) {
	const dispatch = useDispatch();

	const onSubmit = data => {
		dispatch(
			createVersionReport(data, (err, model) => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					history.push(`/version-report/`);
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Criar novo relatório de versão</h5>
							</div>
							<CreateForm onSubmit={onSubmit} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
