import GlobalModal from "components/Modal";
import { useGlobalContext } from "contexts/GlobalContext";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import DetailCompanyProviderForm from "../../../../components/DetailForm/CompanyProvider";
import {
	deleteCompanyProvider,
	deleteProviderBenefits,
	getCompanyProviderById
} from "./actions";

function DetailCompanyProvider({
	match: {
		params: { id, business }
	},
	history
}) {
	const { showModal, hideModal } = useGlobalContext();
	const { item, loading } = useSelector(state => state.companyProviderDetail);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompanyProviderById(id));
	}, [dispatch]);

	if (!item) return null;

	const handleDelete = () => {
		dispatch(
			deleteCompanyProvider(id, err => {
				if (err) {
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					dispatch(
						deleteProviderBenefits(id, err => {
							if (err) {
								if (err.code) {
									alert(err.code);
								} else if (typeof err === "string") alert(err);
								else alert(JSON.stringify(err));
							} else {
								history.push(`/companies/${business}`);
							}
						})
					);
				}

				hideModal();
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">
										Detalhe do prestador de serviços
									</h5>
								</div>
								{/* <div>
									<Button color="danger" onClick={showModal}>
										Excluir
									</Button>
								</div> */}
							</Row>
							<DetailCompanyProviderForm
								companyProvider={{
									...item
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<GlobalModal
				title="Deletar prestador de serviço"
				onOk={handleDelete}
				loading={loading.delete}
			>
				<p>
					Tem certeza que deseja excluir este prestador de serviço? Essa ação é
					irreversível
				</p>
			</GlobalModal>
		</Container>
	);
}

export default DetailCompanyProvider;
