import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CREATE_VERSION_REPORT =
	"[VERSION_REPORT CREATE] CREATE_VERSION_REPORT";
export const CREATE_VERSION_REPORT_SUCCESS =
	"[VERSION_REPORT CREATE] CREATE_VERSION_REPORT_SUCCESS";
export const CREATE_VERSION_REPORT_FAILED =
	"[VERSION_REPORT CREATE] CREATE_VERSION_REPORT_FAILED";

export const SET_VALUE = "[VERSION_REPORT CREATE] SET_VALUE";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function createVersionReport(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_VERSION_REPORT });

		try {
			const { login } = getState();

			const response = await api.sendPost("/v2/version-report", data, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_VERSION_REPORT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_VERSION_REPORT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_VERSION_REPORT_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
