import dayjs from "dayjs";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const NoteCard = ({ authorName, title, postDate, noteText, id }) => {
	const renderDate = () => {
		const diffHours = dayjs().diff(postDate, "hours");

		if (diffHours < 1) {
			if (dayjs().diff(postDate, "minutes") < 1)
				return (
					<>
						<span className="note-date-card" id={`tooltip1-${id}`}>
							há poucos segundos
						</span>
						<UncontrolledTooltip target={`tooltip1-${id}`}>
							{dayjs(postDate).format("DD/MM/YYYY - HH:mm")}
						</UncontrolledTooltip>
					</>
				);
			else if (dayjs().diff(postDate, "minutes") == 1)
				return (
					<>
						<span className="note-date-card" id={`tooltip1-${id}`}>
							há 1 minuto
						</span>
						<UncontrolledTooltip target={`tooltip1-${id}`}>
							{dayjs(postDate).format("DD/MM/YYYY - HH:mm")}
						</UncontrolledTooltip>
					</>
				);
			else
				return (
					<>
						<span className="note-date-card" id={`tooltip1-${id}`}>
							{`há ${dayjs().diff(postDate, "minutes")} minutos`}
						</span>
						<UncontrolledTooltip target={`tooltip1-${id}`}>
							{dayjs(postDate).format("DD/MM/YYYY - HH:mm")}
						</UncontrolledTooltip>
					</>
				);
		} else if (diffHours >= 1 && diffHours < 24) {
			return (
				<>
					<span className="note-date-card" id={`tooltip1-${id}`}>
						{`há ${diffHours} hora${diffHours == 1 ? "" : "s"}`}
					</span>
					<UncontrolledTooltip target={`tooltip1-${id}`}>
						{dayjs(postDate).format("DD/MM/YYYY - HH:mm")}
					</UncontrolledTooltip>
				</>
			);
		}

		return (
			<span className="note-date-card">
				{dayjs(postDate).format("DD/MM/YYYY - HH:mm")}
			</span>
		);
	};

	return (
		<div className="note-container border-left  border-primary">
			<span>
				<strong className="note-title">{title} </strong>
				por {authorName || "Desconhecido"}
				{renderDate()}
			</span>
			<span className="note-text-card">{noteText}</span>
		</div>
	);
};

export default NoteCard;
