import * as actions from "./actions";

const initialState = {
	loading: false,
	item: null,
	errors: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_SUBSCRIPTION: {
			return {
				...state,
				loading: true,
				errors: initialState.errors,
			};
		}
		case actions.GET_SUBSCRIPTION_SUCCESS: {
			return {
				...initialState,
				...payload,
				value: payload.value / 100.0,
				discount: payload.discount / 100.0,
				installment: payload.installment,
				installmentInterval: payload.installmentInterval,
				description: payload.description,
				user: payload.user,
				plan: payload.plan,
				paymentMethod: payload.paymentMethod,
				firstPayment: payload.firstPayment,
				item: payload,
			};
		}
		case actions.GET_SUBSCRIPTION_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload,
			};
		}
		case actions.EDIT_SUBSCRIPTION: {
			return {
				...state,
				loading: true,
				errors: initialState.errors,
			};
		}
		case actions.EDIT_SUBSCRIPTION_SUCCESS: {
			return {
				...initialState,
				item: payload,
			};
		}
		case actions.EDIT_SUBSCRIPTION_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload,
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload,
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
			};
		default: {
			return state;
		}
	}
};

export default reducer;
