import React from "react";
import { Badge } from "reactstrap";

export const FinancialStatusBadge = status => {
	if (status == 0) {
		return (
			<Badge
				style={{ width: "6rem", textTransform: "uppercase" }}
				color={"success"}
			>
				Em dia
			</Badge>
		);
	} else if (status == 1) {
		return (
			<Badge
				style={{ width: "6rem", textTransform: "uppercase" }}
				color={"warning"}
			>
				Atrasado
			</Badge>
		);
	}
};

// 	Em dia = 0,
// 	Atrasado = 1,
