import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getMarketing, clearValues, resendMarketing } from "./actions";
import { Link } from "react-router-dom";

import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import DetailsMarketing from "../../components/DetailsMarketing";
import Actions from "../../components/DetailsMarketing/Actions";
import Users from "containers/Marketing/components/Users";

function Details({
	match: {
		params: { id }
	}
}) {
	const { item } = useSelector(state => state.marketingDetail);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getMarketing(id));
	}, [dispatch]);

	const handleResend = () => {
		dispatch(
			resendMarketing(id, err => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					alert("Campanha reenviada");
				}
			})
		);
	};

	if (!item) return null;

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Dados do usuário</h5>
								</div>

								<div className="d-flex align-items-center">
									<Link to={`/marketing/${item._id}/edit`}>
										<Button className="mb-0" color="primary">
											Editar
										</Button>
									</Link>

									<Actions
										buttons={[
											{
												label: "Reenviar",
												action: handleResend
											}
										]}
									/>
								</div>
							</Row>

							<DetailsMarketing marketing={item} />
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Users users={item.users} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Details;
