import styled from "styled-components";

export const LicenseCardContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	background-color: #fcfbfb;
	border: 1px solid #eeefff;
	border-radius: 10px;
	padding: 24px 16px;
`;

export const UsedLicensesBarContainer = styled.div`
	width: 100%;
	height: 10px;
	border: 1px solid #eeefff;
	border-radius: 8px;
	background-color: #e6e6e6;
	position: relative;
`;

export const UsedLicensesColoredBar = styled.div`
	width: ${({ percentage }) => `${percentage}%`};
	height: 100%;
	background-color: #7f26f2;
	border-radius: 8px;
	position: absolute;
	top: 0;
	bottom: 0;
`;
