import dayjs from "dayjs";
import ls from "Localization";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
	ObligationPeriods,
	ObligationStatusDASN
} from "types/obligationStatus";
import EditForm from "../../../../components/EditObligation";
import { getObligationById } from "../../Detail/obligation/actions";
import { getObligations } from "../../Obligation/actions";
import { clearValues, updateObligation } from "./actions";

function EditObligation({
	history,
	match: {
		params: { id, business }
	}
}) {
	const { item } = useSelector(s => s.obligationDetail);
	const { loading, errors } = useSelector(s => s.obligationEdit);
	const obligation_edit = useSelector(s => s.form.obligation_edit);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getObligationById(id));
	}, [dispatch]);

	if (!item) return null;

	const periodStatusData = Object.keys(ObligationPeriods).map(c => ({
		value: c,
		label: ls[ObligationPeriods[c]]
	}));

	const obligationStatusData = Object.keys(ObligationStatusDASN).map(c => ({
		value: c,
		label: ObligationStatusDASN[c]
	}));

	const onSubmit = data => {
		console.log(data);
		dispatch(
			updateObligation(
				id,
				{
					...item,
					due: data.due,
					name: data.name,
					interval: data.interval,
					period: data.period ? data.period.value : 0,
					status: data.status ? data.status.value : 0
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(getObligations(0, 10, `&filters[business]=${business}`));
						history.push(`/companies/${business}/obligations/${id}`);
					}
				}
			)
		);
	};

	const initialValues = {
		name: item.name,
		interval: item.interval,
		due: item.due ? dayjs(item.due).toDate() : null,
		period: periodStatusData.find(c => c.value === String(item.period)),
		status: obligationStatusData.find(c => c.value === String(item.status))
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Editar Obrigação</h5>
							</div>
							<EditForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={obligation_edit && !!obligation_edit.syncErrors}
								errors={errors}
								periodStatusData={periodStatusData}
								obligationStatusData={obligationStatusData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default EditObligation;
