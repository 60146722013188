import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EditForm from "../../../components/EditForm";
import { setValue, updatePermission } from "./actions";

function Edit({ load }) {
	const dispatch = useDispatch();

	const { item, loading, errors } = useSelector(s => s.permissionEdit);
	const permission_edit = useSelector(s => s.form.permission_edit);

	const closeModal = () => {
		dispatch(setValue({ item: null }));
	};

	if (!item) return null;

	const onSubmit = data => {
		dispatch(
			updatePermission(
				item._id,
				{
					...item,
					name: data.name,
					description: data.description
				},
				err => {
					if (err) {
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						load();
						closeModal();
					}
				}
			)
		);
	};

	const initialValues = {
		name: item.name,
		description: item.description
	};

	return (
		<Modal
			toggle={closeModal}
			className="theme-light ltr-support login-modal-form"
			isOpen={Boolean(item)}
			size="md"
		>
			<ModalHeader>Editar permissão</ModalHeader>
			<ModalBody>
				<EditForm
					onSubmit={onSubmit}
					initialValues={initialValues}
					loading={loading}
					isInvalid={permission_edit && !!permission_edit.syncErrors}
					errors={errors}
				/>
			</ModalBody>
		</Modal>
	);
}

export default Edit;
