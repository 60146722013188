import React, { useEffect, useState } from "react";

import ls from "Localization";
import { bankStatus } from "types/bankBilletStatus";
import { getClients } from "../../../Client/containers/Report/actions";
import { setValue } from "../../containers/Create/actions";
import { useDispatch, useSelector } from "react-redux";

import { Col, Row, Button, ListGroup, ListGroupItem } from "reactstrap";
import { Field } from "redux-form";
import ArrowCollapseLeft from "mdi-react/ArrowCollapseLeftIcon";
import ArrowCollapseRight from "mdi-react/ArrowCollapseRightIcon";
import Input from "components/ReduxForm/Input";
import renderSelectField from "shared/components/form/Select";
import { FixedSizeList } from "react-window";

const SendToForm = ({ change }) => {
	const dispatch = useDispatch();

	const [searchIn, setSearchIn] = useState("");
	const [searchOut, setSearchOut] = useState("");
	const [bankStatusData, setBankStatusData] = useState("");

	const { users } = useSelector(s => s.marketingCreate);
	const clientsState = useSelector(s => s.clients);

	useEffect(() => {
		let filters = "&filters[hasPush]=true";

		if (bankStatusData) filters += `&filters[bankStatus]=${bankStatusData}`;

		dispatch(getClients(0, 1000, filters));
	}, [bankStatusData]);

	const handleChange = (name, value) => {
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const handleFilterUsers = (c, value) => {
		let fullName = "";
		if (c.user) {
			const user = c.user;
			fullName = `${user.name ? user.name.toLowerCase() : ""} ${
				user.lastname ? user.lastname.toLowerCase() : ""
			}`;
		} else {
			fullName = `${c.name ? c.name.toLowerCase() : ""} ${
				c.lastname ? c.lastname.toLowerCase() : ""
			}`;
		}

		return fullName.indexOf(value ? value.toLowerCase() : "") !== -1;
	};

	const handleRight = newUsers => {
		handleChange("users", [...users, ...newUsers.map(user => ({ user }))]);

		setSearchOut("");
		change("search", "");
	};

	const handleLeft = () => {
		let keep = [];

		if (searchIn) {
			let filtered = (clientsState.items || []).filter(c =>
				handleFilterUsers(c, searchIn)
			);

			keep = users.filter(
				u => !filtered.find(c => c._id === (u.user ? u.user._id : undefined))
			);
		}

		handleChange("users", keep);
		setSearchIn("");
		change("filter", "");
	};

	const handleUserChange = (items, index) => {
		let item = items[index];

		if (item && !item.user) {
			let user = users.find(
				c => (c.user ? c.user._id : undefined) === (item ? item._id : undefined)
			);

			if (user) item = user;
		}

		if (item && item.user) {
			handleChange(
				"users",
				users.filter(c => (c.user ? c.user._id : undefined) !== item.user._id)
			);
		} else {
			handleChange("users", [...users, { user: items[index] }]);
		}
	};

	const renderRow = (items, props) => {
		const { index, style } = props;

		if (!items.length)
			return (
				<ListGroupItem tag="button" style={style} key={index}>
					Nenhum item
				</ListGroupItem>
			);

		let item = items[index];

		if (item.user) item = item.user;

		return (
			<ListGroupItem
				tag="button"
				style={style}
				key={index}
				onClick={() => handleUserChange(items, index)}
			>
				{`${item.name} ${item.lastname}`}
			</ListGroupItem>
		);
	};

	let filteredIn = (users || []).filter(c => handleFilterUsers(c, searchIn));

	let filteredOut = (clientsState.items || []).filter(c =>
		handleFilterUsers(c, searchOut)
	);

	let usersIn = filteredIn;

	let usersNotIn = filteredOut.filter(
		c => !users.find(u => (u.user ? u.user._id : undefined) === c._id)
	);

	return (
		<>
			<Row>
				<Col xs={12} sm={12} md={5} className="form__form-group">
					<span className="form__form-group-label">Pesquisar</span>
					<div className="form__form-group-field">
						<Field
							name="search"
							component={Input}
							type="text"
							placeholder="Pesquisar"
							onChange={newValue => setSearchOut(newValue.target.value)}
						/>
					</div>
				</Col>
				<Col xs={2} className="mt-5" />

				<Col xs={12} sm={12} md={5} xl={5} className="form__form-group">
					<span className="form__form-group-label">Enviar para:</span>
					<div className="form__form-group-field">
						<Field
							name="filter"
							component={Input}
							type="text"
							placeholder="Filtro"
							onChange={newValue => setSearchIn(newValue.target.value)}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={12} md={5} xl={5} className="form__form-group">
					<ListGroup>
						<FixedSizeList
							height={400}
							itemSize={36}
							itemCount={usersNotIn.length}
						>
							{props => renderRow(usersNotIn, props)}
						</FixedSizeList>
					</ListGroup>
				</Col>

				<Col xs={12} sm={12} md={2} xl={2} className="mt-5">
					<Row className="mt-5 d-flex justify-content-center">
						<Button
							onClick={() => handleRight(usersNotIn)}
							className="icon"
							outline
							disabled={!usersNotIn.length}
						>
							<ArrowCollapseRight />
						</Button>
					</Row>
					<Row className="d-flex justify-content-center">
						<Button
							onClick={handleLeft}
							className="icon"
							outline
							disabled={!usersIn.length}
						>
							<ArrowCollapseLeft />
						</Button>
					</Row>
				</Col>

				<Col xs={12} sm={12} md={5} xl={5} className="form__form-group">
					<ListGroup>
						<FixedSizeList
							height={400}
							itemSize={36}
							itemCount={usersIn.length || 1}
						>
							{props => renderRow(usersIn, props)}
						</FixedSizeList>
					</ListGroup>
				</Col>
			</Row>
		</>
	);
};

export default SendToForm;
