import React, { useEffect } from "react";

import subscriptionStatus from "types/subscriptionStatus";
import ls from "Localization";
import { getClients } from "containers/Client/containers/Report/actions";
import { useDispatch, useSelector } from "react-redux";
import {
	getSubscription,
	clearValues as clearValuesSubscription,
	updateSubscription
} from "./actions";
import { clearValues as clearValuesClient } from "containers/Client/containers/Report/actions";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import EditForm from "../../components/EditForm";

let debounceFindUser = null;

function Create({
	match: {
		params: { id }
	},
	history
}) {
	const dispatch = useDispatch();
	const { items: users } = useSelector(s => s.clients);
	const subscription = useSelector(s => s.subscriptionEdit);

	useEffect(() => {
		dispatch(clearValuesSubscription());
		dispatch(clearValuesClient());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getSubscription(id));
	}, [dispatch, id]);

	const onSubmit = data => {
		dispatch(
			updateSubscription(
				{
					...data,
					user: data.user ? data.user.value : undefined,
					status: data.status ? data.status.value : undefined
				},
				err => {
					if (err) {
						console.log(err);
						if (err.code) {
							alert(err.code);
						} else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push("/subscriptions");
					}
				}
			)
		);
	};

	const handleChangeUser = name => {
		if (name.length >= 3) {
			if (debounceFindUser) debounceFindUser.cancel();

			debounceFindUser = _.debounce(() => {
				dispatch(
					getClients(
						0,
						10,
						`&filters[searchText]=${name}&filters[roles]=mei`,
						undefined,
						false,
						false,
						err => {
							if (err) {
								alert("Não foi possível carregar os usuários");
							}
						}
					)
				);
			}, 1000);

			debounceFindUser();
		}
	};

	const initialValues = {
		user: subscription.user
			? {
					value: subscription.user._id,
					label: `${subscription.user.name} ${subscription.user.lastname}`
			  }
			: undefined,
		value: subscription.value,
		status: {
			value: subscription.status,
			label: ls[subscriptionStatus[subscription.status]]
		},
		description: subscription.description
	};

	const subscriptionStatusData = Object.keys(subscriptionStatus).map(c => ({
		value: c,
		label: ls[subscriptionStatus[c]]
	}));

	if (subscription.loading) {
		return null;
	}

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Editar assinatura</h5>
							</div>
							<EditForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								subscriptionStatusData={subscriptionStatusData}
								onChangeUser={handleChangeUser}
								users={users}
								subscription={subscription}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
