import dayjs from "dayjs";
import { stringToMask } from "helpers/string";
import ls from "Localization";
import React from "react";
import { MdOpenInNew } from "react-icons/md";
import { Badge, Col, Row } from "reactstrap";
import { businessTypeTranslate } from "types/BusinessType";

const CompanyDetails = ({ companyProvider }) => {
	const companyBadge = provider => {
		return (
			<>
				<Badge
					style={{
						cursor: "pointer"
					}}
					color={"primary"}
					target={"_blank"}
					onClick={() => {
						let win = window.open(`/companies/${provider._id}`, "_blank");
						win.focus();
					}}
					className={"mr-1"}
				>
					<MdOpenInNew size={16} />
				</Badge>
				{provider?.businessName || ""}
			</>
		);
	};

	return (
		<>
			<h5 className="bold-text card__title">Detalhes da Empresa</h5>

			<Row>
				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Nome empresárial: </strong>
						<br />
						<span>{companyBadge(companyProvider.provider)}</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Nome fantasia: </strong>
						<br />
						<span>
							{companyProvider.provider?.socialName
								? companyProvider.provider?.socialName
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>CNPJ: </strong>
						<br />
						<span>
							{companyProvider.provider?.cnpj
								? stringToMask(
										companyProvider.provider?.cnpj,
										"##.###.###/####-##"
								  )
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Data de abertura: </strong>
						<br />
						<span>
							{companyProvider?.provider?.startDate
								? dayjs(companyProvider?.provider?.startDate).format(
										ls.dateFormatShort
								  )
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Tipo de Empresa: </strong>
						<br />
						<span>
							{companyProvider.provider?.businessType >= 0
								? businessTypeTranslate[companyProvider.provider?.businessType]
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Atividade Principal (Cnae): </strong>
						<br />
						<span>
							{companyProvider?.provider?.activity
								? companyProvider?.provider?.activity
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Situação: </strong>
						<br />
						<span>
							{companyProvider.provider?.situation != undefined
								? companyProvider.provider?.situation
								: ""}
						</span>
					</p>
				</Col>

				<Col className="mb-3" xs={12} sm={12} md={6} xl={4}>
					<p>
						<strong>Data criação: </strong>
						<br />
						<span>
							{dayjs(companyProvider.provider?.createdAt).format(
								ls.dateFormatWithoutSeconds
							)}
						</span>
					</p>
				</Col>
			</Row>
		</>
	);
};

export default CompanyDetails;
