import * as api from "services/api";
import { getErrors } from "helpers/error";

export const UPDATE_PERMISSION = "[PERMISSION UPDATE] UPDATE_PERMISSION";
export const UPDATE_PERMISSION_ERROR =
	"[PERMISSION UPDATE] UPDATE_PERMISSION_ERROR";
export const UPDATE_PERMISSION_SUCCESS =
	"[PERMISSION UPDATE] UPDATE_PERMISSION_SUCCESS";

export const SET_VALUE = "[PERMISSION UPDATE] SET_VALUE";
export const CLEAR_VALUES = "[PERMISSION UPDATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function updatePermission(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPDATE_PERMISSION
		});

		try {
			const { login } = getState();

			let response = await api.sendPut(`/permission/${id} `, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: UPDATE_PERMISSION_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_PERMISSION_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_PERMISSION_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
