import React from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

import { Text } from "components/Text";

import { StatusLabel } from "./components/StatusLabel";

import {
	LicenseCardContainer,
	UsedLicensesBarContainer,
	UsedLicensesColoredBar
} from "./styles";

export function InfoCard({ type, title, totalValue, totalUsed, status }) {
	return (
		<LicenseCardContainer>
			{type === "basic" ? (
				<>
					<Text fontSize="16px" color="#31475a" fontWeight={700}>
						{title}
					</Text>
					<Text fontSize="24px" fontWeight={700}>
						{totalValue}
					</Text>
				</>
			) : type === "used" ? (
				<>
					<Text fontSize="16px" color="#31475a" fontWeight={700}>
						{title}
					</Text>
					<UsedLicensesBarContainer>
						<UsedLicensesColoredBar
							percentage={
								(totalUsed * 100) / totalValue > 100
									? 100
									: (totalUsed * 100) / totalValue
							}
						/>
					</UsedLicensesBarContainer>
					<Text fontSize="24px" fontWeight={700}>
						{totalUsed} de {totalValue}
					</Text>
				</>
			) : (
				<>
					<Text fontSize="16px" color="#31475a" fontWeight={700}>
						{title}
					</Text>

					<StatusLabel
						text={status === 0 ? "Em dia" : "Atrasado"}
						backgroundColor={status === 0 ? "#2e8e00" : "#dcc600"}
						icon={
							status === 0 ? (
								<FaCheckCircle size={16} color="#ffffff" />
							) : (
								<FaExclamationCircle size={16} color="#ffffff" />
							)
						}
					/>
				</>
			)}
		</LicenseCardContainer>
	);
}
