import React, { useState } from "react";

import { setValue } from "../../containers/Create/actions";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import validate from "./validate";

import {
	Button,
	ButtonToolbar,
	Nav,
	NavItem,
	NavLink,
	Spinner,
	TabContent,
	TabPane,
	Container
} from "reactstrap";
import AboutForm from "./AboutForm";
import PushForm from "./PushForm";
import SendToForm from "./SendToForm";

const CreateForm = ({ reset, handleSubmit, change }) => {
	const dispatch = useDispatch();
	const { loading } = useSelector(s => s.marketingCreate);

	const [activeTab, setActiveTab] = useState("1");

	const toggle = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	return (
		<div className="tabs tabs--justify tabs--bordered-bottom">
			<div className="tabs__wrap">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === "1" })}
							onClick={() => {
								toggle("1");
							}}
						>
							SOBRE
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === "2" })}
							onClick={() => {
								toggle("2");
							}}
						>
							PUSH
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === "3" })}
							onClick={() => {
								toggle("3");
							}}
						>
							ENVIAR PARA
						</NavLink>
					</NavItem>
				</Nav>
				<form className="form" onSubmit={handleSubmit}>
					<Container>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<AboutForm />
							</TabPane>
							<TabPane tabId="2">
								<PushForm />
							</TabPane>
							<TabPane tabId="3">
								<SendToForm change={change} />
							</TabPane>
						</TabContent>
					</Container>

					<ButtonToolbar className="form__button-toolbar">
						<Button
							color={activeTab === "3" ? "primary" : "secondary"}
							type={activeTab === "3" ? "submit" : "button"}
							onClick={
								activeTab === "3"
									? undefined
									: () => toggle(String(Number(activeTab) + 1))
							}
							disabled={loading.create}
						>
							{loading.create ? (
								<Spinner type="grow" size="sm" color="dark" />
							) : activeTab === "3" ? (
								"Salvar"
							) : (
								"Proximo"
							)}
						</Button>
						<Button
							type="button"
							onClick={() => {
								dispatch(
									setValue({
										users: [],
										errors: {}
									})
								);
								reset();
							}}
						>
							Limpar
						</Button>
					</ButtonToolbar>
				</form>
			</div>
		</div>
	);
};

export default reduxForm({
	form: "marketing_create",
	validate
})(CreateForm);
