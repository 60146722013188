import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_SUBSCRIPTION = "[SUBSCRIPTION DETAIL] GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS =
	"[SUBSCRIPTION DETAIL] GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILED =
	"[SUBSCRIPTION DETAIL] GET_SUBSCRIPTION_FAILED";

export const SET_VALUE = "[SUBSCRIPTION DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[SUBSCRIPTION DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getSubscription(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_SUBSCRIPTION });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Subscription/" + id, {
				Authorization: "Bearer " + login.token,
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SUBSCRIPTION_SUCCESS,
					payload: result.model,
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_SUBSCRIPTION_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: GET_SUBSCRIPTION_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}
