import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_USER_GROUP_PERMISSIONS =
	"[GROUPS DETAIL] GET_USER_GROUP_PERMISSIONS";
export const GET_USER_GROUP_PERMISSIONS_ERROR =
	"[GROUPS DETAIL] GET_USER_GROUP_PERMISSIONS_ERROR";
export const GET_USER_GROUP_PERMISSIONS_SUCCESS =
	"[GROUPS DETAIL] GET_USER_GROUP_PERMISSIONS_SUCCESS";

export const ADD_GROUPS = "[GROUPS DETAIL] ADD_GROUPS";
export const ADD_GROUPS_ERROR = "[GROUPS DETAIL] ADD_GROUPS_ERROR";
export const ADD_GROUPS_SUCCESS = "[GROUPS DETAIL] ADD_GROUPS_SUCCESS";

export const SET_VALUE = "[GROUPS DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[GROUPS DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getGroupsPerUser(
	skip,
	take,
	filter,
	sort,
	desc,
	toExport,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({
			type: GET_USER_GROUP_PERMISSIONS
		});

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/group/per-user?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${filter ||
					"&filters=null"}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_GROUP_PERMISSIONS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				dispatch({
					type: GET_USER_GROUP_PERMISSIONS_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_GROUP_PERMISSIONS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function addPermissionGroups(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: ADD_GROUPS
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/user/group`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ADD_GROUPS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result);

				dispatch({
					type: ADD_GROUPS_ERROR,
					payload: errors
				});

				callback && callback(errors.errors);
			}
		} catch (error) {
			return dispatch({
				type: ADD_GROUPS_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
