import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import ptBt from "dayjs/locale/pt-br";
import ls from "Localization";

import { uploadTaxBillet } from "../Create/actions";
import { clearValues, updateTax } from "./actions";
import { dasStatus } from "types/dasStatus";
import { getTax } from "../Details/actions";
import {
	replaceStringCurrencyToNumber,
	toStringCurrency
} from "helpers/string";
import TaxEdit from "../../../components/EditForm/TaxEdit";
import { getCompanies } from "containers/Company/containers/Report/actions";

import _ from "lodash";

dayjs.locale(ptBt);

let getCompaniesDebounced = null;

const Edit = ({
	history,
	match: {
		params: { id }
	}
}) => {
	const { item } = useSelector(state => state.taxDetails);
	const { loading, errors } = useSelector(state => state.taxEdit);
	const companyState = useSelector(s => s.company);
	const tax_edit = useSelector(s => s.form.tax_edit);
	const dispatch = useDispatch();

	const companiesData = companyState.items.map(c => ({
		value: c._id,
		label: c.businessName
	}));

	if (item.mei && !companiesData.find(c => c.value === item.mei._id)) {
		companiesData.push({ value: item.mei._id, label: item.mei.businessName });
	}

	const onSubmit = data => {
		const model = {
			value: replaceStringCurrencyToNumber("R$", data.value || ""),
			fine: replaceStringCurrencyToNumber("R$", data.fine || ""),
			interest: replaceStringCurrencyToNumber("R$", data.interest || ""),
			dueDate: data.dueDate,
			bankBilletDueDate: data.bankBilletDueDate,
			documentNumber: data.documentNumber,
			barCode: data.barCode,
			status: data.status.value,
			periodDate: data.periodDate,
			total:
				replaceStringCurrencyToNumber("R$", data.value || "") +
				replaceStringCurrencyToNumber("R$", data.fine || "") +
				replaceStringCurrencyToNumber("R$", data.interest || ""),
			period: dayjs(data.periodDate).format("MMMM/YYYY"),
			mei: data.company.value
		};

		dispatch(
			updateTax(id, model, (err, model) => {
				if (err) {
					console.log(err);
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					if (data.billetFile) {
						dispatch(
							uploadTaxBillet(model._id, data.billetFile, err => {
								if (err) {
									if (typeof err === "string") {
										alert(err);
									} else {
										alert(JSON.stringify(err));
									}
								} else {
									history.push("/tax");
								}
							})
						);
					} else {
						history.push("/tax");
					}
				}
			})
		);
	};

	const onCompanyChange = name => {
		if (name.length >= 3) {
			if (getCompaniesDebounced) {
				getCompaniesDebounced.cancel();
			}

			getCompaniesDebounced = _.debounce(() => {
				dispatch(
					getCompanies(
						0,
						10,
						`&filters[searchText]=${name}&filters[hasMei]=true`,
						null,
						null,
						false,
						err => {
							if (err) {
								alert("Não foi possível carregar as empresas");
							}
						}
					)
				);
			}, 500);

			getCompaniesDebounced();
		}
	};

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getTax(id));
	}, [dispatch]);

	if (!item) return null;

	const dasStatusData = Object.keys(dasStatus).map(c => ({
		value: c,
		label: ls[dasStatus[c]]
	}));

	let initialValues = {};

	if (item) {
		initialValues = {
			value: toStringCurrency(item.value / 100),
			fine: toStringCurrency(item.fine / 100),
			interest: toStringCurrency(item.interest / 100),
			dueDate: item.dueDate || null,
			bankBilletDueDate: item.bankBilletDueDate || null,
			documentNumber: item.documentNumber,
			barCode: item.barCode,
			periodDate: item.periodDate,
			period: item.period,
			status: dasStatusData.find(s => s.value == item.status),
			company: companiesData.find(c => {
				if (item.mei) {
					if (item.mei._id === c.value || item.mei === c.value) return true;
				}

				if (item.user && item.user.mei) {
					if (item.user.mei._id === c.value || item.user.mei === c.value)
						return true;
				}

				return false;
			})
		};
	}

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Alterar dados do imposto</h5>
							</div>
							<TaxEdit
								errors={errors}
								onSubmit={onSubmit}
								dasStatusData={dasStatusData}
								onCompanyChange={onCompanyChange}
								companiesData={companiesData}
								initialValues={initialValues}
								loading={loading}
								isInvalid={tax_edit && !!tax_edit.syncErrors}
								billetLink={item.link}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Edit;
