import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_CONTACT_COMPANY = "[CONTACT_COMPANY] GET_CONTACT_COMPANY";
export const GET_CONTACT_COMPANY_SUCCESS =
	"[CONTACT_COMPANY] GET_CONTACT_COMPANY_SUCCESS";
export const GET_CONTACT_COMPANY_FAILED =
	"[CONTACT_COMPANY] GET_CONTACT_COMPANY_FAILED";

export const SET_VALUE = "[CONTACT_COMPANY] SET_VALUE";
export const CLEAR_VALUES = "[CONTACT_COMPANY] CLEAR_VALUES";

export const UPDATE_CONTACT_COMPANY =
	"[CONTACT_COMPANY] UPDATE_CONTACT_COMPANY";
export const UPDATE_CONTACT_COMPANY_SUCCESS =
	"[CONTACT_COMPANY] UPDATE_CONTACT_COMPANY_SUCCESS ";
export const UPDATE_CONTACT_COMPANY_FAILED =
	"[CONTACT_COMPANY] UPDATE_CONTACT_COMPANY_FAILED";

export const CREATE_CONTACT_COMPANY =
	"[CONTACT_COMPANY] CREATE_CONTACT_COMPANY";
export const CREATE_CONTACT_COMPANY_SUCCESS =
	"[CONTACT_COMPANY] CREATE_CONTACT_COMPANY_SUCCESS ";
export const CREATE_CONTACT_COMPANY_FAILED =
	"[CONTACT_COMPANY] CREATE_CONTACT_COMPANY_FAILED";

export function setValue(payload) {
	return { type: SET_VALUE, payload };
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getContactCompanys(_id) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONTACT_COMPANY });

		try {
			const { login } = getState();
			var response = await api.sendGet(`/contact-company/find-one/${_id}`, {
				Authorization: "Bearer " + login.token
			});
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONTACT_COMPANY_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);
				dispatch({
					type: GET_CONTACT_COMPANY_FAILED,
					payload: errors
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_CONTACT_COMPANY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function createContactCompanyByMei(data) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_CONTACT_COMPANY });

		try {
			const { login } = getState();
			var response = await api.sendPost(
				`/contact-company/create-by-mei`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CONTACT_COMPANY_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);
				dispatch({
					type: CREATE_CONTACT_COMPANY_FAILED,
					payload: errors
				});
			}
		} catch (error) {
			return dispatch({
				type: CREATE_CONTACT_COMPANY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateContactCompany(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CONTACT_COMPANY });

		try {
			const { login } = getState();
			var response = await api.sendPut(`/contact-company/update`, data, {
				Authorization: "Bearer " + login.token
			});
			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CONTACT_COMPANY_SUCCESS,
					payload: result.model
				});

				callback && callback();
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CONTACT_COMPANY_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_CONTACT_COMPANY_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
