import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Spinner,
	Tooltip
} from "reactstrap";
import dayjs from "dayjs";
import { MdEdit } from "react-icons/md";
import { BiTrash } from "react-icons/bi";

import * as api from "services/api";

import ls from "Localization";

import ReportTable from "components/Report";

import CreateAttendantModal from "../components/CreateAttendantModal";
import EditAttendantModal from "../components/EditAttendantModal";

export function DigisacIntegration() {
	const [attendants, setAttendants] = useState([]);
	const [isLoadingDataFetch, setIsLoadingDataFetch] = useState(true);
	const [isCreatingAttendant, setIsCreatingAttendant] = useState(false);
	const [
		isCreateNewAttendantModalOpen,
		setIsCreateNewAttendantModalOpen
	] = useState(false);
	const [isEditingAttendant, setIsEditingAttendant] = useState(false);
	const [isEditAttendantModalOpen, setIsEditAttendantModalOpen] = useState(
		false
	);
	const [attendantToEdit, setAttendantToEdit] = useState({});
	const [isDeletingAttendant, setIsDeletingAttendant] = useState(false);
	const [isDeleteAttendantModalOpen, setIsDeleteAttendantModalOpen] = useState(
		false
	);
	const [attendantToDelete, setAttendantToDelete] = useState({});

	const { token } = useSelector(s => s.login);

	async function getAllAttendants() {
		setIsLoadingDataFetch(true);

		try {
			const response = await api.sendGet("/digisacAttendants", {
				Authorization: "Bearer " + token
			});

			const result = await response.json();

			if (result.isValid) {
				setAttendants(result.model);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoadingDataFetch(false);
		}
	}

	async function handleCreateDigisacAttendant(data) {
		setIsCreatingAttendant(true);

		try {
			await api.sendPost(
				"/digisacAttendants/create",
				{
					name: data.name,
					email: data.email,
					digisacId: data.digisacId
				},
				{
					Authorization: "Bearer " + token
				}
			);

			getAllAttendants();

			setIsCreateNewAttendantModalOpen(false);
		} catch (err) {
			console.log(err);
		} finally {
			setIsCreatingAttendant(false);
		}
	}

	async function handleEditDigisacAttendant(data) {
		setIsEditingAttendant(true);

		try {
			await api.sendPatch(
				"/digisacAttendants/update-attendant",
				{
					_id: attendantToEdit?._id,
					name: data.name,
					email: data.email,
					digisacId: data.digisacId
				},
				{
					Authorization: "Bearer " + token
				}
			);

			getAllAttendants();

			setIsEditAttendantModalOpen(false);
		} catch (err) {
			console.log(err);
		} finally {
			setIsEditingAttendant(false);
		}
	}

	async function handleDeleteDigisacAttendant() {
		setIsDeletingAttendant(true);

		try {
			await api.sendDelete(`/digisacAttendants/${attendantToDelete._id}`, {
				Authorization: "Bearer " + token
			});

			getAllAttendants();

			setIsDeleteAttendantModalOpen(false);
		} catch (err) {
			console.log(err);
		} finally {
			setIsDeletingAttendant(false);
		}
	}

	function handleCreateNewAttendantModalOpen() {
		setIsCreateNewAttendantModalOpen(prevState => !prevState);
	}

	function handleEditAttendantModalOpen(attendant) {
		setIsEditAttendantModalOpen(prevState => !prevState);

		if (attendant) {
			setAttendantToEdit(attendant);
		} else {
			setAttendantToEdit({});
		}
	}

	function handleDeleteAttendantModalOpen(attendant) {
		setIsDeleteAttendantModalOpen(prevState => !prevState);

		if (attendant) {
			setAttendantToDelete(attendant);
		} else {
			setAttendantToDelete({});
		}
	}

	useEffect(() => {
		getAllAttendants();
	}, []);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title={"Lista Atendentes"}
						data={attendants}
						loading={isLoadingDataFetch}
						noExport={true}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						headerRightComponent={
							<div>
								<Button
									className={"mr-2"}
									color={"primary"}
									onClick={handleCreateNewAttendantModalOpen}
									id="tooltipNewAttendant"
								>
									Novo Atendente
								</Button>
								<Tooltip placement="top" target="tooltipNewAttendant">
									Cadastrar novo atendente
								</Tooltip>
							</div>
						}
						columns={[
							{
								Header: "Data Cadastro",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatShort)
										: "",
								width: 150
							},
							{
								Header: "Nome",
								id: "name",
								accessor: c => (c.name ? c.name : "Não definido")
							},
							{
								Header: "E-mail",
								id: "email",
								accessor: c => (c.email ? c.email : "Não definido")
							},
							{
								Header: "ID do Digisac",
								id: "digisacId",
								accessor: c => (c.digisacId ? c.digisacId : "Não definido")
							},
							{
								Header: "Ações",
								id: "actions",
								filterable: false,
								Cell: props => (
									<ButtonToolbar>
										<ButtonGroup className="btn-group--icons">
											<Button
												outline
												onClick={() =>
													handleEditAttendantModalOpen(props.original)
												}
											>
												<MdEdit />
											</Button>
											<Button
												outline
												onClick={() =>
													handleDeleteAttendantModalOpen(props.original)
												}
											>
												<BiTrash />
											</Button>
										</ButtonGroup>
									</ButtonToolbar>
								),
								width: 114
							}
						]}
					/>
				</Col>
			</Row>

			<Modal
				isOpen={isCreateNewAttendantModalOpen}
				toggle={handleCreateNewAttendantModalOpen}
			>
				<ModalHeader toggle={handleCreateNewAttendantModalOpen}>
					Cadastrar novo atendente
				</ModalHeader>
				<ModalBody>
					<CreateAttendantModal
						onSubmit={handleCreateDigisacAttendant}
						loading={isCreatingAttendant}
					/>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={isEditAttendantModalOpen}
				toggle={handleEditAttendantModalOpen}
			>
				<ModalHeader toggle={handleEditAttendantModalOpen}>
					Editar Atendente
				</ModalHeader>
				<ModalBody>
					<EditAttendantModal
						onSubmit={handleEditDigisacAttendant}
						loading={isEditingAttendant}
						initialValues={attendantToEdit}
					/>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={isDeleteAttendantModalOpen}
				toggle={handleDeleteAttendantModalOpen}
			>
				<ModalHeader toggle={handleDeleteAttendantModalOpen}>
					Deseja excluir este atendente?
				</ModalHeader>
				<ModalBody>
					<span>{attendantToDelete?.name}</span>
				</ModalBody>
				<ModalFooter>
					<ButtonToolbar className="form__button-toolbar">
						<Button
							color="primary"
							onClick={handleDeleteDigisacAttendant}
							disabled={isDeletingAttendant}
						>
							{isDeletingAttendant ? (
								<Spinner type="grow" size="sm" color="dark" />
							) : (
								"Excluir"
							)}
						</Button>
						<Button
							type="button"
							onClick={() => handleDeleteAttendantModalOpen()}
						>
							Cancelar
						</Button>
					</ButtonToolbar>
				</ModalFooter>
			</Modal>
		</Container>
	);
}
