import * as actions from "./actions";

const initialState = {
  loading: false,
  errors: {},
};

const reducer = function(state = initialState, { type, payload }) {
  switch (type) {
    case actions.UPDATE_CLIENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.UPDATE_CLIENT_ERROR: {
      return {
        ...state,
        errors: payload,
        loading: false,
      };
    }
    case actions.SET_VALUE:
      return {
        ...state,
        ...payload,
      };
    case actions.CLEAR_VALUES:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
