import React, { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import ls from "Localization";
import { toStringCurrency } from "helpers/string";
import bankBilletStatus from "types/bankBilletStatus";
import { dateSort } from "components/Report/helpers";

import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { FaExternalLinkAlt } from "react-icons/fa";
import ImportModal from "../../components/Import";

let getBankBilletsDebounced = null;

function BankBillet() {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.bankBillets);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBankBilletsDebounced) {
			getBankBilletsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getBankBilletsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getBankBillets(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getBankBilletsDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						headerRightComponent={<ImportModal load={load} />}
						title="Boletos"
						data={state.items}
						pages={state.pages}
						filterable
						defaultFiltered={state.filter}
						loading={state.loading.getAll}
						onFetchData={handleFetchData}
						// onRowClicked={(row) => history.push(`/client/${row.id}`)}
						onRowClicked={row => {}}
						columns={[
							{
								Header: "Data criação",
								accessor: "createdAt",
								id: "createdAt"
							},
							{
								Header: "Nº Documento",
								accessor: "referenceNumber",
								id: "document"
							},
							{
								Header: "Nome",
								accessor: "name",
								id: "name"
							},
							{
								Header: "email",
								accessor: "email",
								id: "email"
							},
							{
								Header: "Valor",
								accessor: c => toStringCurrency(+c.value / 100.0),
								filterable: false,
								id: "value"
							},
							{
								Header: "Status",
								accessor: c => ls[bankBilletStatus[c.status]],
								id: "status"
							},
							{
								Header: "Vencimento",
								accessor: "dueDate",
								sortMethod: dateSort,
								id: "dueDate"
							},
							{
								Header: "Ações",
								accessor: c => (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flex: 1
										}}
									>
										<a href={c.url} target="_blank">
											<FaExternalLinkAlt />
										</a>
									</div>
								),
								show: true,
								filterable: false,
								id: "actions"
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default BankBillet;
