import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_MARKETING = "[MARKETING DETAIL] GET_MARKETING";
export const GET_MARKETING_SUCCESS = "[MARKETING DETAIL] GET_MARKETING_SUCCESS";
export const GET_MARKETING_FAILED = "[MARKETING DETAIL] GET_MARKETING_FAILED";

export const UPDATE_MARKETING = "[MARKETING DETAIL] UPDATE_MARKETING";
export const UPDATE_MARKETING_SUCCESS = "[MARKETING DETAIL] UPDATE_MARKETING_SUCCESS";
export const UPDATE_MARKETING_FAILED = "[MARKETING DETAIL] UPDATE_MARKETING_FAILED";

export const CLEAR_VALUES = "[MARKETING DETAIL] CLEAR_VALUES";

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getMarketing(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_MARKETING });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Marketing/${id}`, {
				Authorization: "Bearer " + login.token,
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_MARKETING_SUCCESS,
					payload: result.model,
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_MARKETING_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_MARKETING_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}

export function resendMarketing(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_MARKETING });

		try {
			const { login } = getState();

			const response = await api.sendPut("/marketing/" + id + "/Resend", null, {
				Authorization: "Bearer " + login.token,
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_MARKETING_SUCCESS,
				});

				callback && callback(null);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_MARKETING_FAILED,
					payload: errors,
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_MARKETING_FAILED,
				payload: {
					error: "Connection error",
				},
			});
		}
	};
}
