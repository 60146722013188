const validate = values => {
	const errors = {};

	if (!values.password) errors.password = "Nova senha obrigatória";
	if (!values.confirmPassword)
		errors.confirmPassword = "Confirmação de senha obrigatória";
	return errors;
};

export default validate;
