import React, { useRef } from "react";
import ls from "Localization";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCharges } from "../../../../redux/actions/charges";
import _ from "lodash";
import { InstallmentStatus } from "types/installmentStatus";
import { dateSort } from "components/Report/helpers";
import Report from "components/Report";

let getDataDebounced = null;

function Charges() {
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		params: { id }
	} = useRouteMatch();

	const state = useSelector(s => s.charge);

	const table = useRef(null);

	const handleFetchData = (tableState, instance) => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getDataDebounced) {
			getDataDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate && creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate && creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getDataDebounced = _.debounce(
			() =>
				dispatch(
					getCharges(
						page * pageSize,
						pageSize,
						filtered.reduce(
							(p, c) => p + `&filters[${c.id}]=${c.value}`,
							`&filters[user]=${id}`
						),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getDataDebounced();
	};

	return (
		<Report
			manual
			tableRef={table}
			title={ls.charges}
			data={state.items}
			pages={state.pages}
			loading={state.loading.getAll}
			onFetchData={handleFetchData}
			filterable
			defaultSorted={[
				{
					id: "createdAt",
					desc: true
				}
			]}
			pageSize={
				state.items.length > 10
					? 10
					: state.items.length <= 0
					? 1
					: state.items.length
			}
			onRowClicked={data => {
				// history.push(`/Dashboard/Charges/${data._id}`)
			}}
			columns={[
				{
					Header: ls.paymentDate,
					id: "paymentDate",
					accessor: c => dayjs(c.paymentDate).format(ls.dateFormatShort),
					show: true,
					sortMethod: dateSort,
					width: 150
				},
				{
					Header: ls.description,
					id: "description",
					show: true,
					accessor: c => `Parcela ${c.number} de ${c.subscription.installment}`
				},
				{
					Header: ls.value,
					id: "value",
					show: true,
					filterable: false,
					accessor: c => toStringCurrency(+c.value / 100),
					width: 150
				},
				{
					Header: "Status",
					id: "status",
					show: true,
					accessor: c => ls[InstallmentStatus[c.status]],
					width: 140
				}
			]}
		/>
	);
}

export default Charges;
