/* eslint-disable no-unused-vars */
import React from "react";
import validate from "./validate";

import { reduxForm, Field } from "redux-form";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import renderCheckBoxField from "shared/components/form/CheckBox";

const ExportExcelForm = ({ handleSubmit, progress = 0 }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">
							<Field
								label={"Exportar Excel"}
								name="exportExcel"
								component={renderCheckBoxField}
							/>
						</span>
					</Col>
					<Col className="form__form-group">
						<span className="form__form-group-label bold-text">
							<Field
								label={"Exportar CSV"}
								name="exportCsv"
								component={renderCheckBoxField}
							/>
						</span>
					</Col>
				</Row>

				<div className="progress">
					<div
						className="progress-bar progress-bar-striped progress-bar-animated"
						role="progressbar"
						aria-valuenow={progress}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{ width: progress + "%" }}
					>
						{progress}%
					</div>
				</div>

				<ButtonToolbar className="form__button-toolbar mt-5">
					<Button color="primary" type="submit">
						Enviar
					</Button>
				</ButtonToolbar>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "export_excel_form",
	validate
})(ExportExcelForm);
