export const MarketingStatus = {
	0: 'pending',
	1: 'sending',
	2: 'done'
};

export const MarketingUserStatus = {
	'-1': 'noMessage',
	0: 'pending',
	1: 'sent',
	2: 'received',
	3: 'read',
	5: 'noToken',
	9: 'failed'
};