import React, { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import ls from "Localization";
import { toStringCurrency } from "helpers/string";
import dayjs from "dayjs";
import { dateSort } from "components/Report/helpers";
import { TransferStatus, TransferType } from "types/Transfer";

import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { FaExternalLinkAlt } from "react-icons/fa";
import ImportModal from "../../components/Import";

let getTransfersDebounded = null;

function Transfer({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.transfer);

	const handleFetchData = (tableState, instance) => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getTransfersDebounded) {
			getTransfersDebounded.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate && creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate && creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getTransfersDebounded = _.debounce(
			() =>
				dispatch(
					actions.getTransfers(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getTransfersDebounded();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined, null);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						headerRightComponent={<ImportModal load={load} />}
						title={ls.transfers}
						data={state.items}
						pages={state.pages}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						getTdProps={() => {
							return {
								style: {
									display: "flex",
									alignItems: "center"
								}
							};
						}}
						// onRowClicked={(data) => history.push(`/Dashboard/Transfer/${data._id}`)}
						columns={[
							{
								Header: ls.creationDate,
								id: "createdAt",
								accessor: c =>
									dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds),
								sortMethod: dateSort
							},
							{
								Header: ls.client,
								id: "user",
								filterable: false,
								accessor: c => `${c.user.name} ${c.user.lastname}`
							},
							{
								Header: "Documento",
								id: "to",
								accessor: c => c.to,
								filterable: false
							},
							{
								Header: ls.value,
								id: "value",
								filterable: false,
								accessor: c => toStringCurrency(+c.value / 100.0)
							},
							{
								Header: ls.type,
								id: "type",
								accessor: c => ls[TransferType[c.type]]
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[TransferStatus[c.status]]
							},
							{
								Header: ls.actions,
								id: "actions",
								accessor: c => (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flex: 1
										}}
									>
										<a href={c.url} target="_blank">
											<FaExternalLinkAlt />
										</a>
									</div>
								),
								filterable: false
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Transfer;
