import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import _ from "lodash";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import userHistoryType from "types/userHistory";
import * as actions from "./actions";

dayjs.extend(customDateFormat);

let getBenefitsDebounced = null;

function UserHistory({ user_id }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { items, pages, count, loading } = useSelector(
		state => state.userHistory
	);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBenefitsDebounced) {
			getBenefitsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getBenefitsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getUserHistory(
						page * pageSize,
						pageSize,
						`&filters[user_id]=${user_id}` +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);
		getBenefitsDebounced();
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Histórico"}
						data={items}
						pageSize={5}
						pages={pages}
						loading={loading.getAll}
						count={count}
						filterable
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "createdAt",
								desc: true
							}
						]}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds)
										: "--",
								width: 130
							},
							{
								Header: "Criado por",
								id: "createdByName",
								accessor: "createdByName",
								width: 130
							},
							{
								Header: "Nota",
								id: "note",
								accessor: "note"
							},
							{
								Header: "Type",
								id: "type",
								show: true,
								accessor: c => Object.keys(userHistoryType)[c.type],
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(userHistoryType).map((c, i) => (
												<option key={c} value={i}>
													{c}
												</option>
											))}
										</select>
									);
								},
								width: 100
							},
							{
								Header: "doc",
								id: "docString",
								accessor: "docString",
								show: false
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default UserHistory;
