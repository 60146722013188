import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import EditForm from "../../../components/EditForm/companyEdit";
import { getCompanyById } from "../Detail/actions";
import { updateCompany, uploadBusinessLicenseFile } from "./actions";
import ls from "Localization";
import { businessLicenseStatus } from "types/BusinessLicenseStatus";
import { nfeBusinessStatus } from "types/NFeBusinessStatus";
import dayjs from "dayjs";
import { businessTypeTranslate } from "types/BusinessType";
import { companySituationStr } from "types/companySituation";
import { updatePurchasedProviders } from "../Detail/OnBoard/actions";
import { ditStatus } from "types/DITStatus";
import insuranceStatus from "types/insuranceStatus";

function Edit({
	history,
	match: {
		params: { id }
	}
}) {
	const { item } = useSelector(s => s.companyDetail);
	const { item: onboard } = useSelector(s => s.onBoardDetail);
	const { loading, errors } = useSelector(s => s.companyEdit);
	const company_edit = useSelector(s => s.form.company_create);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompanyById(id));
	}, [dispatch]);

	if (!item) return null;

	const businessTypeData = Object.keys(businessTypeTranslate).map(c => ({
		value: c,
		label: ls[businessTypeTranslate[c]]
	}));

	const businessLicenseStatusData = Object.keys(businessLicenseStatus).map(
		c => ({
			value: c,
			label: ls[businessLicenseStatus[c]]
		})
	);

	const nfeBusinessStatusData = Object.keys(nfeBusinessStatus).map(c => ({
		value: c,
		label: ls[nfeBusinessStatus[c]]
	}));

	const companySituationData = Object.keys(companySituationStr).map(c => ({
		value: c,
		label: companySituationStr[c]
	}));

	const ditData = Object.keys(ditStatus).map(c => ({
		value: c === "None" ? "" : c,
		label: ls[ditStatus[c]]
	}));

	const ditStatusData = Object.keys(insuranceStatus).map(c => ({
		value: c,
		label: ls[insuranceStatus[c]]
	}));

	const lifeInsuranceStatusData = Object.keys(insuranceStatus).map(c => ({
		value: c,
		label: ls[insuranceStatus[c]]
	}));

	const onSubmit = data => {
		const toUpdate = {
			...item,
			startDate: data.startDate,
			businessLicenseEmission: data.businessLicenseEmission,
			countyRegistration: data.countyRegistration,
			stateRegistration: data.stateRegistration,
			businessLicenseDue: data.businessLicenseDue,
			businessLicenseStatus: data.businessLicenseStatus
				? data.businessLicenseStatus.value
				: 0,
			businessType: data.businessType ? data.businessType.value : 0,
			nfeStatus: data.nfeStatus ? data.nfeStatus.value : 0,
			isHired: data.isHired,
			isActive: data.isActive,
			useOwnContracts: data.useOwnContracts,
			byMeasurement: data.byMeasurement,
			hasActiveDebit: data.hasActiveDebit,
			situation: data.situation?.label,
			purchasedProviders: data.purchasedProviders,
			dit: data.dit?.value,
			ditStatus: data.ditStatus?.value,
			lifeInsurance: data.lifeInsurance?.value,
			lifeInsuranceStatus: data.lifeInsuranceStatus?.value
		};

		dispatch(
			updateCompany(id, toUpdate, err => {
				if (err) {
					if (err.code) {
						alert(err.code);
					} else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					if (data.businessLicenseFile) {
						dispatch(
							uploadBusinessLicenseFile(
								item._id,
								data.businessLicenseFile,
								err => {
									if (err) {
										if (typeof err === "string") {
											alert(err);
										} else {
											alert(JSON.stringify(err));
										}
									} else {
										history.push(`/companies/${item._id}`);
									}
								}
							)
						);
					} else {
						history.push(`/companies/${item._id}`);
					}
				}
			})
		);

		if (data.isHired && data.purchasedProviders) {
			dispatch(
				updatePurchasedProviders(
					{
						_id: onboard._id,
						purchasedProviders: data.purchasedProviders
					},
					(err, model) => {
						if (err) {
							console.log("error", err);
							return;
						}
					}
				)
			);
		}
	};

	const initialValues = {
		countyRegistration: item.countyRegistration,
		stateRegistration: item.stateRegistration,
		businessLicenseEmission: item.businessLicenseEmission
			? dayjs(item.businessLicenseEmission).toDate()
			: null,
		businessLicenseDue: item.businessLicenseDue
			? dayjs(item.businessLicenseDue).toDate()
			: null,
		businessLicenseStatus: businessLicenseStatusData.find(
			c => c.value === String(item.businessLicenseStatus)
		),
		businessType: businessTypeData.find(
			c => c.value === String(item.businessType)
		),
		nfeStatus: nfeBusinessStatusData.find(
			c => c.value === String(item.nfeStatus)
		),
		dit: ditData.find(c => c.value === String(item?.dit)),
		ditStatus: ditStatusData.find(c => c.value === String(item?.ditStatus)),
		lifeInsurance: ditData.find(c => c.value === String(item?.lifeInsurance)),
		lifeInsuranceStatus: lifeInsuranceStatusData.find(
			c => c.value === String(item?.lifeInsuranceStatus)
		),
		isHired: item.isHired,
		isActive: item.isActive,
		useOwnContracts: item.useOwnContracts,
		byMeasurement: item.byMeasurement,

		situation: companySituationData.find(
			c => c.label === String(item.situation)
		),

		cnpj: item.cnpj,
		hasActiveDebit: item.hasActiveDebit,
		purchasedProviders: onboard?.purchasedProviders || 0
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Alterar dados da Empresa</h5>
							</div>
							<EditForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={company_edit && !!company_edit.syncErrors}
								errors={errors}
								businessLicenseStatusData={businessLicenseStatusData}
								nfeBusinessStatusData={nfeBusinessStatusData}
								businessTypeData={businessTypeData}
								companySituationData={companySituationData}
								ditData={ditData}
								ditStatusData={ditStatusData}
								lifeInsuranceData={ditData}
								lifeInsuranceStatusData={lifeInsuranceStatusData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Edit;
