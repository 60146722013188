import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";

import { createContract } from "./actions";
import { replaceStringCurrencyToNumber } from "helpers/string";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import periodType from "types/periodType";
import ls from "Localization";
import { getContractRegularById } from "../DetaillAll/actions";
import {
	contractStatus,
	contractStatusConst,
	contractTemplateTypes,
	signerStatus
} from "types/contractStatus";
import NewLinkedContractForm from "containers/Contracts/components/NewLinkedContractForm";
import ContractList from "containers/Contracts/components/ContractList";
import { uploadFile } from "../EditAll/actions";

dayjs.extend(customParseFormat);

const NewLinkedContract = ({
	history,
	match: {
		params: { id }
	}
}) => {
	const dispatch = useDispatch();
	const { loading, errors } = useSelector(s => s.newContract);
	const { items: contractList } = useSelector(s => s.contracts);
	const { item: contract } = useSelector(s => s.contractsDetail);

	const linked_contract_create = useSelector(
		s => s.form.linked_contract_create
	);

	const onSubmit = async data => {
		dispatch(
			createContract(
				{
					hired: contract.hired,
					mei: contract.mei,

					name: data.name,
					type: data.type?.value,
					department: contract.department,

					externalId: data.externalId,
					status:
						data.status && data.status.value ? data.status.value : undefined,
					hirerToken: data.hirerToken,
					hirerStatus: data.hirerStatus ? data.hirerStatus.value : undefined,
					hiredToken: data.hiredToken,
					hiredStatus: data.hiredStatus ? data.hiredStatus.value : undefined,

					startDate: dayjs(data.startDate, "DD/MM/YYYY"),
					selfRenewable: data.selfRenewable,
					lifespan: data.lifespan,
					due: dayjs(data.due, "DD/MM/YYYY"),

					generateFinancial: data.generateFinancial,
					paymentValue: replaceStringCurrencyToNumber(
						"R$",
						data.paymentValue || "0"
					),
					paymentPeriod: data.paymentPeriod.value,
					paymentDate: dayjs(data.paymentDate, "DD/MM/YYYY"),
					paymentInterval: data.paymentInterval,

					mainContract: contract.mainContract || contract._id,
					isMainContract: false,
					contractId: contract._id,

					note: data.note
				},
				(err, model) => {
					if (err) {
						if (err.code) {
							alert("1" + err.code);
						} else if (typeof err === "string") alert("2" + err);
						else alert(JSON.stringify("3" + err));
					} else {
						if (data.file) {
							dispatch(
								uploadFile(model._id, data.file, err => {
									if (err) {
										if (typeof err === "string") {
											alert("4" + err);
										} else {
											alert("5" + JSON.stringify(err));
										}
									} else {
										history.push("/contracts");
									}
								})
							);
						} else {
							history.push("/contracts");
						}
					}
				}
			)
		);
	};

	const periodTypeData = Object.keys(periodType).map(c => ({
		value: c,
		label: ls[periodType[c]]
	}));

	const typeData = Object.keys(contractTemplateTypes).map(c => ({
		value: c,
		label: ls[contractTemplateTypes[c]]
	}));

	const statusData = Object.keys(contractStatus).map(c => ({
		value: c,
		label: ls[contractStatus[c]]
	}));

	const signerStatusData = Object.keys(signerStatus).map(c => ({
		value: c,
		label: ls[signerStatus[c]]
	}));

	const initialValues = {
		mei: {
			_id: contract?.mei?._id,
			businessName: contract?.mei?.businessName
		},
		hired: {
			_id: contract?.hired?._id,
			businessName: contract?.hired?.businessName
		},
		department: {
			_id: contract?.department?._id,
			name: contract?.department?.name
		},
		paymentInterval: "1",
		paymentPeriod: periodTypeData[0]
	};

	useEffect(() => {
		dispatch(getContractRegularById(id));
	}, []);

	return (
		<Container className="dashboard">
			{contract && (
				<Row>
					<Col>
						<Card>
							<CardBody>
								<div className="card__title flex-column">
									<h5 className="bold-text">Contrato Selecionado</h5>
								</div>

								<Row>
									<Col
										xs={12}
										sm={12}
										md={4}
										lg={3}
										className="form__form-group"
									>
										<span>
											<strong>Nome Contrato: </strong>
											{contract.template?.name || ""}
										</span>
										<br />
										<span>
											<strong>Empresa: </strong>
											{contract.mei?.businessName || ""}
										</span>
										<br />
										<span>
											<strong>Prestador: </strong>
											{contract.hired?.businessName || ""}
										</span>
									</Col>

									<Col
										xs={12}
										sm={12}
										md={4}
										lg={3}
										className="form__form-group"
									>
										<span>
											<strong>Status: </strong>

											{ContractStatusBadge(
												contract.status,
												contract.mei?.useOwnContracts
											)}
										</span>
										<br />
										<span>
											<strong>Tipo Contrato: </strong>
											{ls[contractTemplateTypes[contract.template?.type]] || ""}
										</span>
										<br />
										<span>
											<strong>Data de Cadastro: </strong>
											{contract.createdAt
												? moment(contract.createdAt).format(ls.dateFormatShort)
												: ""}
										</span>
									</Col>

									<Col
										xs={12}
										sm={12}
										md={4}
										lg={3}
										className="form__form-group"
									>
										<span>
											<strong>Sequência: </strong>
											{contract.sequence || ""}
										</span>
										<br />
										<span>
											<strong>Vigência (em meses): </strong>
											{contract.lifespan || ""}
										</span>
										<br />
										<span>
											<strong>Autorrenovável: </strong>
											{`${contract.selfRenewable ? "Sim" : "Não"}` || ""}
										</span>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}

			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<NewLinkedContractForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={
									linked_contract_create && !!linked_contract_create.syncErrors
								}
								errors={errors}
								contractList={contractList}
								statusData={statusData}
								signerStatus={signerStatusData}
								periodTypeData={periodTypeData}
								typeData={typeData}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col>
					<ContractList
						title={"Lista Contratos Vinculados"}
						contractId={id}
						mainContractId={contract?.mainContract?._id}
						isMainContract={contract?.isMainContract}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default NewLinkedContract;

export const ContractStatusBadge = (status, useOwnContracts) => {
	if (useOwnContracts) {
		return <Badge color="info">NÃO OPTANTE</Badge>;
	}

	switch (status) {
		case contractStatusConst.Signed:
			return <Badge color="success">ASSINADO</Badge>;
		case contractStatusConst.Pending:
			return <Badge color="secondary">PENDENTE</Badge>;
		case contractStatusConst.Due:
			return <Badge color="danger">VENCIDO</Badge>;
		case contractStatusConst.Cancelled:
			return <Badge style={{ backgroundColor: "orange" }}>CANCELADO</Badge>;
		case contractStatusConst.Rescind:
			return <Badge style={{ backgroundColor: "brown" }}>RESCINDIDO</Badge>;
		case contractStatusConst.Excluded:
			return <Badge style={{ backgroundColor: "red" }}>EXCLUÍDO</Badge>;
		case contractStatusConst.AwaitingSignature:
			return (
				<Badge style={{ backgroundColor: "yellow", color: "black" }}>
					AGUARDANDO ASSINATURA
				</Badge>
			);

		default:
			return <Badge color="secondary">Pendente</Badge>;
	}
};
