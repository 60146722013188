import React, { useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import ReportTable from "components/Report";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./actions";
import _ from "lodash";
import ls from "Localization";
import { toStringCurrency } from "helpers/string";
import dayjs from "dayjs";
import taxTransfer from "types/taxStatus";
import ImportModal from "../../components/Import";

let getTaxesDebounced = null;

function Tax({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector(s => s.tax);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getTaxesDebounced) {
			getTaxesDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getTaxesDebounced = _.debounce(
			() =>
				dispatch(
					actions.getTaxes(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);

		getTaxesDebounced();
	};

	const load = () =>
		handleFetchData(table.current ? table.current.state : undefined);

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						title={"Impostos"}
						data={state.items}
						pages={state.pages}
						tableRef={table}
						headerRightComponent={<ImportModal load={load} />}
						defaultSorted={[
							{
								id: "periodDate",
								desc: true
							}
						]}
						loading={state.loading.getAll}
						filterable
						onFetchData={handleFetchData}
						onRowClicked={row => history.push(`/tax/${row._id}`)}
						columns={[
							{
								Header: ls.client,
								id: "mei.businessName",
								accessor: c =>
									c.mei
										? c.mei.businessName
										: c.user
										? `${c.user.name} ${c.user.lastname}`
										: "Não definido",
								width: 250
							},
							{
								Header: "Período",
								id: "period",
								accessor: "period",
								width: 120
							},
							{
								Header: "Valor",
								id: "value",
								accessor: c =>
									c.total ? toStringCurrency(c.total / 100.0) : "",
								width: 100
							},
							{
								Header: "Vencimento",
								id: "dueDate",
								accessor: c =>
									c.dueDate ? dayjs(c.dueDate).format("DD/MM/YYYY") : "",
								width: 100
							},
							{
								Header: "Cód. barras",
								accessor: "barCode"
							},
							{
								Header: "Boleto",
								id: "boleto",
								accessor: c =>
									c.link ? (
										<a href={c.link} target="_blank" without rel="noreferrer">
											Abrir boleto
										</a>
									) : null,
								width: 100
							},
							{
								Header: "Status",
								id: "status",
								accessor: c => ls[taxTransfer[c.status]],
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : ""}
										>
											<option value="">Todos</option>
											{Object.keys(taxTransfer)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[taxTransfer[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 120
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default Tax;
