import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
	Tooltip,
	NavItem,
	NavLink,
	Nav
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import DetailsClient from "../../components/detailsClient";
import { getClient, queryMei } from "./actions";
import { MdMoreVert } from "react-icons/md";
import Subscriptions from "containers/Client/components/Subscriptions";
import Charges from "containers/Client/components/Charges";
import { Link } from "react-router-dom";
import CompaniesListView from "../../components/Companies";
import Permissions from "./Permissions";
import PermissionGroups from "./PermissionGroups";
import { BusinessType } from "../../../../types/BusinessType";
import GlobalModal from "components/Modal";
import { useGlobalContext } from "contexts/GlobalContext";
import {
	submitRecoverPassword,
	submitResetDefaultPassword
} from "redux/actions/login";
import { SubmissionError } from "redux-form";
import BenefitListView from "containers/Client/containers/Details/Benefit";
import classNames from "classnames";
import CompanyProviderListView from "./CompanyProvider";
import { fetchCompanyProviders } from "./CompanyProvider/actions";
import UserHistory from "./UserHistory";

function Details({
	match: {
		params: { id }
	},
	history
}) {
	const dispatch = useDispatch();
	const { item } = useSelector(state => state.clientDetail);
	const { items: hirers } = useSelector(state => state.companyProviderList);

	const { showModal, hideModal } = useGlobalContext();

	const [openDropdown, setOpenDropdown] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [queryError, setQueryError] = useState(false);
	const [openResetPasswordTooltip, setOpenResetPasswordTooltip] = useState(
		false
	);

	const [
		openResetDefaultPasswordTooltip,
		setOpenResetDefaultPasswordTooltip
	] = useState(false);

	const [activeTab, setActiveTab] = useState("details");
	const toggleTabs = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const toggle = () => setOpenDropdown(!openDropdown);
	const toggleTooltip = () => setOpenTooltip(!openTooltip);
	const toggleResetPasswordTooltip = () =>
		setOpenResetPasswordTooltip(!openResetPasswordTooltip);
	const toggleResetDefaultPasswordTooltip = () =>
		setOpenResetDefaultPasswordTooltip(!openResetDefaultPasswordTooltip);

	const handleQueryMei = () => {
		setOpenTooltip(false);

		if (!item.cpf || !item.birthDate) {
			alert("Preencha o CPF e a data de nascimento");
			return false;
		}

		dispatch(
			queryMei(id, err => {
				if (err) {
					setQueryError(true);
					showModal();
				} else {
					showModal();
				}
			})
		);
	};

	const handleResetPassword = () => {
		setOpenResetPasswordTooltip(false);

		dispatch(
			submitRecoverPassword(item.email, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					alert(
						`Instruções para criar uma nova senha enviadas para o e-mail ${item.email}.`
					);
				}
			})
		);
	};

	const handleResetDefaultPassword = () => {
		setOpenResetDefaultPasswordTooltip(false);

		dispatch(
			submitResetDefaultPassword(id, err => {
				if (err) {
					throw new SubmissionError(err);
				} else {
					alert(`A senha padrão foi atualizada no e-mail ${item.email}.`);
				}
			})
		);
	};

	useEffect(() => {
		dispatch(getClient(id));
		dispatch(
			fetchCompanyProviders(
				0,
				10,
				`&filters[providerUser]=${id}`,
				"createdAt",
				true,
				null,
				null
			)
		);
	}, [dispatch]);

	if (!item) return "No user";

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="justify-content-between align-items-center">
								<div className="card__title">
									<h5 className="bold-text">Detalhes do usuário</h5>
								</div>

								<div
									style={{
										display: "flex",
										flexDirection: "row",
										flexWrap: "nowrap"
									}}
								>
									<div>
										<Link
											to={`/client/${item._id}/edit`}
											style={{
												margin: 0,
												padding: 0,
												backgroundColor: "yellow"
											}}
										>
											<Button color="primary" style={{ margin: 0 }}>
												Editar
											</Button>
										</Link>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginLeft: "10px",
											cursor: "pointer"
										}}
									>
										<Dropdown
											isOpen={openDropdown}
											toggle={() => toggle()}
											direction="right"
										>
											<DropdownToggle tag="div" data-toggle="dropdown">
												<MdMoreVert size={24} color="#646777" />
											</DropdownToggle>
											<DropdownMenu>
												{item?.mei?.businessType === BusinessType.MEI && (
													<div id="queryButton">
														<DropdownItem
															onClick={() => handleQueryMei()}
															disabled={!item.cpf && !item.birthDate}
															id="queryButton"
														>
															Consultar MEI
														</DropdownItem>

														{!item.cpf || !item.birthDate ? (
															<Tooltip
																isOpen={openTooltip}
																toggle={() => toggleTooltip()}
																target="queryButton"
																placement="left"
															>
																Preencha o CPF e a data de nascimento
															</Tooltip>
														) : (
															<Tooltip
																isOpen={openTooltip}
																toggle={() => toggleTooltip()}
																target="queryButton"
																placement="left"
															>
																Executar consulta do MEI
															</Tooltip>
														)}
													</div>
												)}

												<div id="resetPasswordTooltip">
													<DropdownItem
														onClick={() => handleResetPassword()}
														id="resetPasswordTooltip"
													>
														Reset de senha do usuário
													</DropdownItem>

													<Tooltip
														isOpen={openResetPasswordTooltip}
														toggle={() => toggleResetPasswordTooltip()}
														target="resetPasswordTooltip"
														placement="left"
													>
														Enviar o reset de senha para o email do usuário
													</Tooltip>
												</div>

												<div id="resetDefaultPasswordTooltip">
													<DropdownItem
														onClick={() => handleResetDefaultPassword()}
														id="resetDefaultPasswordTooltip"
													>
														Restaurar senha padrão
													</DropdownItem>

													<Tooltip
														isOpen={openResetDefaultPasswordTooltip}
														toggle={() => toggleResetDefaultPasswordTooltip()}
														target="resetDefaultPasswordTooltip"
														placement="left"
													>
														Restaurar a senha para o padrão (senhapadrao@email)
													</Tooltip>
												</div>
											</DropdownMenu>
										</Dropdown>
									</div>
								</div>
							</Row>
							<DetailsClient
								client={{
									...item,
									role: item.roles
										? item.roles.find(c => c !== "user")
										: undefined
								}}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Nav tabs className="ml-1" style={{ cursor: "pointer" }}>
					<NavItem>
						<NavLink
							className={classNames({ active: activeTab === "details" })}
							onClick={() => {
								toggleTabs("details");
							}}
						>
							Detalhes{" "}
						</NavLink>
					</NavItem>

					{hirers?.length > 0 && (
						<NavItem>
							<NavLink
								className={classNames({ active: activeTab === "hirers" })}
								onClick={() => {
									toggleTabs("hirers");
								}}
							>
								Contratantes
							</NavLink>
						</NavItem>
					)}

					<NavItem>
						<NavLink
							className={classNames({ active: activeTab === "userHistory" })}
							onClick={() => {
								toggleTabs("userHistory");
							}}
						>
							Histórico
						</NavLink>
					</NavItem>
				</Nav>
			</Row>

			{activeTab == "details" && (
				<div>
					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<CompaniesListView user={item} history={history} />
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<Subscriptions />
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<Charges />
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<Permissions />
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<PermissionGroups />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			)}

			{activeTab == "hirers" && hirers?.length > 0 && (
				<div>
					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<CompanyProviderListView history={history} userId={id} />
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<BenefitListView history={history} userId={id} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			)}

			{activeTab == "userHistory" && (
				<div>
					<Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<UserHistory user_id={id} />
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			)}

			<GlobalModal
				title={!queryError ? "Sucesso" : "Erro"}
				labelButton="ok"
				onOk={hideModal}
			>
				<p>
					{!queryError
						? "Consulta agendada com sucesso!"
						: "Erro ao agendar consulta!"}
				</p>
			</GlobalModal>
		</Container>
	);
}

export default Details;
