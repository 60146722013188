import * as actions from "./actions";

const initialState = {
	items: [],
	history: [],
	loading: { get: false },
	count: 0,
	errors: {},
	filter: [],
	pages: null
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_COMPANY_PROVIDER: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_COMPANY_PROVIDER_SUCCESS: {
			return {
				...state,
				item: payload,
				loading: { get: false }
			};
		}
		case actions.GET_COMPANY_PROVIDER_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}

		case actions.GET_COMPANY_PROVIDER_HISTORY: {
			return {
				...state,
				loading: { get: true }
			};
		}
		case actions.GET_COMPANY_PROVIDER_HISTORY_SUCCESS: {
			return {
				...state,
				history: payload || [],
				loading: { get: false }
			};
		}
		case actions.GET_COMPANY_PROVIDER_HISTORY_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { get: false }
			};
		}

		case actions.DELETE_COMPANY_PROVIDER: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_COMPANY_PROVIDER_SUCCESS: {
			return {
				...state,
				loading: { delete: false }
			};
		}
		case actions.DELETE_COMPANY_PROVIDER_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.DELETE_PROVIDER_BENEFITS: {
			return {
				...state,
				loading: { delete: true }
			};
		}
		case actions.DELETE_PROVIDER_BENEFITS_SUCCESS: {
			return {
				...state,
				loading: { delete: false }
			};
		}
		case actions.DELETE_PROVIDER_BENEFITS_ERROR: {
			return {
				...state,
				errors: payload,
				loading: { delete: false }
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
