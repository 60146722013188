const validate = values => {
	const errors = {};

	// if (!values.name) {
	// 	errors.name = "Informe o Nome";
	// }

	// if (!values.phone) {
	// 	errors.phone = "Informe o Telefone";
	// }

	// if (!values.email) {
	// 	errors.email = "Informe o Email";
	// }

	// if (!values.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
	// 	errors.email = "Email incorreto";
	// }

	return errors;
};

export default validate;
