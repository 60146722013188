import * as api from "services/api";
import { getErrors } from "helpers/error";

export const CREATE_TAX = "[TAX CREATE] CREATE_TAX";
export const CREATE_TAX_ERROR = "[TAX CREATE] CREATE_TAX_ERROR";
export const CREATE_TAX_SUCCESS = "[TAX CREATE] CREATE_TAX_SUCCESS";

export const UPLOAD_TAX_BILLET =
	"[CREATE_COMPANY] UPLOAD_TAX_BILLET";
export const UPLOAD_TAX_BILLET_ERROR =
	"[CREATE_COMPANY] UPLOAD_TAX_BILLET_ERROR";
export const UPLOAD_TAX_BILLET_SUCCESS =
	"[CREATE_COMPANY] UPLOAD_TAX_BILLET_SUCCESS";

export const SET_VALUE = "[TAX CREATE] SET_VALUE";
export const CLEAR_VALUES = "[TAX CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createTax(model, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_TAX
		});

		try {
			const { login } = getState();

			let response = await api.sendPost(`/das/create`, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				let result = await response.json();
				dispatch({
					type: CREATE_TAX_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				let result = await response.json();
				const errors = getErrors(result);

				dispatch({
					type: CREATE_TAX_ERROR,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);
			callback && callback(error);

			return dispatch({
				type: CREATE_TAX_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function uploadTaxBillet(id, file, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: UPLOAD_TAX_BILLET
		});

		try {
			const { login } = getState();

			if (!id) {
				id = login.user.id;
			}

			let response = await api.sendPostFile(
				`/tax/${id}/billet/upload`,
				file,
				{
					Authorization: "Bearer " + login.token
				}
			);

			if (response.ok) {
				dispatch({
					type: UPLOAD_TAX_BILLET_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();
				dispatch({
					type: UPLOAD_TAX_BILLET_ERROR,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPLOAD_TAX_BILLET_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
