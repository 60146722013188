import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import login from "./login";
import theme from "./theme";
import sidebar from "./sidebar";
import register from "containers/Register/reducer";
import charge from "./charges";
import plan from "./plan";
import paymentMethod from "./paymentMethod";

import clients from "containers/Client/containers/Report/reducer";
import clientDetail from "containers/Client/containers/Details/reducer";
import clientCreate from "containers/Client/containers/Create/reducer";
import clientEdit from "containers/Client/containers/Edit/reducer";

import bankBillets from "containers/BankBillet/containers/Report/reducer";

import company from "containers/Company/containers/Report/reducer";
import companyCreate from "containers/Company/containers/Page/Create/reducer";
import companyDetail from "containers/Company/containers/Page/Detail/reducer";
import companyEdit from "containers/Company/containers/Page/Edit/reducer";
import companiesDashboard from "containers/Company/containers/Dashboard/reducer";
import contactCompanyDetail from "containers/Company/containers/Page/ContactCompany/reducer";
import onBoardDetail from "containers/Company/containers/Page/Detail/OnBoard/reducer";
import notesDetail from "containers/Company/containers/Page/Detail/Notes/reducer";

import nfse from "containers/NFSe/containers/Report/reducer";
import nfseDetail from "containers/NFSe/containers/Page/Detail/reducer";
import nfseEdit from "containers/NFSe/containers/Page/Edit/reducer";

import marketing from "containers/Marketing/containers/Report/reducer";
import marketingCreate from "containers/Marketing/containers/Create/reducer";
import marketingDetail from "containers/Marketing/containers/Details/reducer";

import subscription from "containers/Subscription/containers/Report/reducer";
import subscriptionCreate from "containers/Subscription/containers/Create/reducer";
import subscriptionDetail from "containers/Subscription/containers/Details/reducer";
import subscriptionEdit from "containers/Subscription/containers/Edit/reducer";

import newContract from "containers/Contracts/containers/Page/New/reducer";
import contractCreate from "containers/Contracts/containers/Page/Create/reducer";
import contracts from "containers/Contracts/containers/ReportAll/reducer";
import contractsDetail from "containers/Contracts/containers/Page/DetaillAll/reducer";
import contractEdit from "containers/Contracts/containers/Page/EditAll/reducer";
import contractTemplateList from "containers/Contracts/containers/Report/reducer";
import contractTemplateEdit from "containers/Contracts/containers/Page/Edit/reducer";
import contractTemplateDetail from "containers/Contracts/containers/Page/Detail/reducer";

import obligationCreate from "containers/Company/containers/Page/Create/Obligation/reducer";
import obligationList from "containers/Company/containers/Page/Obligation/reducer";
import obligationDetail from "containers/Company/containers/Page/Detail/obligation/reducer";
import obligationEdit from "containers/Company/containers/Page/Edit/Obligation/reducer";
import transfer from "containers/Transfer/containers/Report/reducer";

import companyProviderList from "containers/Company/containers/Page/CompanyProvider/reducer";
import companyProviderUser from "containers/Client/containers/Details/Benefit//reducer";
import companyProviderDetail from "containers/Company/containers/Page/Detail/CompanyProvider/reducer";

import tax from "containers/Tax/containers/Report/reducer";
import taxCreate from "containers/Tax/containers/Page/Create/reducer";
import taxDetails from "containers/Tax/containers/Page/Details/reducer";
import taxEdit from "containers/Tax/containers/Page/Edit/reducer";

import benefit from "containers/Benefit/containers/Report/reducer";
import benefitCreate from "containers/Benefit/containers/Page/Create/reducer";
import benefitDetails from "containers/Benefit/containers/Page/Details/reducer";
import benefitEdit from "containers/Benefit/containers/Page/Edit/reducer";

import companyBenefitCreate from "containers/Company/containers/Page/Create/Benefit/reducer";
import companyBenefitList from "containers/Company/containers/Page/Benefit/reducer";
import companyBenefitDetail from "containers/Company/containers/Page/Detail/Benefit/reducer";
import companyBenefitEdit from "containers/Company/containers/Page/Edit/Benefit/reducer";

import companyBenefitListTable from "containers/Benefit/containers/CompanyBenefits/reducer";

import companyTaxList from "containers/Company/containers/Page/Taxes/reducer";
import companyNfseList from "containers/Company/containers/Page/NFS-e/reducer";
import companyContractList from "containers/Company/containers/Page/Contract/reducer";

import changePassword from "containers/Profile/containers/ChangePassword/reducers";

import dashboard from "containers/Dashboard/containers/Page/Index/reducer";

import permissions from "containers/Permission/containers/Report/reducer";
import permissionCreate from "containers/Permission/containers/Page/Create/reducer";
import permissionEdit from "containers/Permission/containers/Page/Edit/reducer";

import userPermission from "containers/Client/containers/Details/Permissions/reducer";

import versionReportCreate from "containers/VersionReport/containers/Create/reducer";
import versionReport from "containers/VersionReport/containers/Report/reducer";
import versionReportDetail from "containers/VersionReport/containers/Details/reducer";

import groups from "containers/PermissionGroups/containers/Report/reducer";
import groupCreate from "containers/PermissionGroups/containers/Create/reducer";
import groupEdit from "containers/PermissionGroups/containers/Edit/reducer";

import permissionGroups from "containers/PermissionGroups/containers/PermissionsReport/reducer";
import userPermissionGroups from "containers/Client/containers/Details/PermissionGroups/reducer";

import departmentList from "containers/Contracts/containers/ReportDepartment/reducer";

import userHistory from "containers/Client/containers/Details/UserHistory/reducer";

import { USER_LOGGED_OUT } from "redux/actions/login";

const appReducer = combineReducers({
	form,
	changePassword,
	companyNfseList,
	companyTaxList,
	companyContractList,
	clients,
	taxDetails,
	clientCreate,
	clientDetail,
	clientEdit,
	bankBillets,
	marketing,
	marketingCreate,
	marketingDetail,
	subscription,
	subscriptionCreate,
	subscriptionDetail,
	subscriptionEdit,
	benefit,
	benefitCreate,
	benefitDetails,
	benefitEdit,
	transfer,
	tax,
	charge,
	login,
	theme,
	sidebar,
	company,
	companyCreate,
	companyDetail,
	companyEdit,
	companiesDashboard,
	register,
	plan,
	paymentMethod,
	nfse,
	nfseDetail,
	nfseEdit,
	newContract,
	contractCreate,
	contracts,
	contractsDetail,
	contractEdit,
	contractTemplateList,
	contractTemplateEdit,
	contractTemplateDetail,
	obligationList,
	obligationDetail,
	obligationEdit,
	obligationCreate,
	companyProviderList,
	companyProviderUser,
	companyProviderDetail,
	taxCreate,
	taxEdit,
	companyBenefitList,
	companyBenefitListTable,
	companyBenefitCreate,
	companyBenefitDetail,
	companyBenefitEdit,
	dashboard,
	permissions,
	permissionCreate,
	permissionEdit,
	userPermission,
	versionReportCreate,
	versionReport,
	versionReportDetail,
	groups,
	groupCreate,
	groupEdit,
	permissionGroups,
	userPermissionGroups,
	contactCompanyDetail,
	onBoardDetail,
	notesDetail,
	departmentList,
	userHistory
});

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === USER_LOGGED_OUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
