import * as actions from "./actions";

const initialState = {
	loading: {},
	showModal: false,
	errors: {}
};

const reducer = function(state = initialState, { type, payload }) {
	switch (type) {
		case actions.UPDATE_PROFILE_PASSWORD: {
			return {
				...state,
				errors: payload,
				loading: { create: false }
			};
		}
		case actions.RESET_PROFILE_PASSWORD_SUCCESS: {
			return {
				...state,
				errors: initialState.errors
			};
		}
		case actions.RESET_PROFILE_PASSWORD_ERROR: {
			return {
				...state,
				loading: { ...state.loading, login: false },
				user: initialState.user,
				token: initialState.token,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
