import React from "react";
import { Badge } from "reactstrap";
import { onboardPhaseType } from "types/onboardPhase";

export const PhaseBadge = status => {
	switch (status) {
		case onboardPhaseType.Onboard:
			return (
				<Badge
					style={{ width: "6rem", textTransform: "uppercase" }}
					color={"warning"}
				>
					OnBoard
				</Badge>
			);
		case onboardPhaseType.Ongoing:
			return (
				<Badge
					style={{ width: "6rem", textTransform: "uppercase" }}
					color={"success"}
				>
					OnGoing
				</Badge>
			);
		default:
			return (
				<Badge
					style={{ width: "7rem", textTransform: "uppercase" }}
					color={"secondary"}
				>
					Não informado
				</Badge>
			);
	}
};
