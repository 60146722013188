import React from "react";

import ls from "Localization";
import { MarketingUserStatus } from "types/Marketing";

import Report from "components/Report";

function Users({ users }) {
	return (
		<Report
			title={ls.users}
			data={users}
			filterable
			defaultFilterMethod={(filter, row) =>
				String(row[filter.id])
					.toLowerCase()
					.indexOf(filter.value.toLowerCase()) > -1
			}
			defaultSorted={[
				{
					id: "name",
					desc: true,
				},
			]}
			columns={[
				{
					Header: ls.name,
					id: "name",
					accessor: (c) => `${c.user.name} ${c.user.lastname}`,
				},
				{
					Header: "Push",
					id: "push",
					accessor: (c) => ls[MarketingUserStatus[c.pushStatus]],
				},
			]}
		/>
	);
}

export default Users;
