import React from "react";

import dayjs from "dayjs";
import ls from "Localization";
import { MarketingStatus } from "types/Marketing";

import { Card, Row, Col, Container } from "reactstrap";

const DetailsMarketing = ({ marketing }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Data criação: </strong>
										<br />
										<span>
											{marketing.createdAt
												? dayjs(marketing.createdAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: ""}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>{marketing.name ? marketing.name : ""}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Começa em: </strong>
										<br />
										<span>
											{marketing.startAt
												? dayjs(marketing.startAt).format(
														ls.dateFormatWithoutSeconds
												  )
												: ""}
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Status: </strong>
										<br />
										<span>
											{marketing.status !== undefined
												? ls[MarketingStatus[marketing.status]]
												: ""}
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailsMarketing;
