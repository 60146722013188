import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";

export const GET_SUBSCRIPTION = "[SUBSCRIPTION EDIT] GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS =
	"[SUBSCRIPTION EDIT] GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILED =
	"[SUBSCRIPTION EDIT] GET_SUBSCRIPTION_FAILED";

export const EDIT_SUBSCRIPTION = "[SUBSCRIPTION EDIT] EDIT_SUBSCRIPTION";
export const EDIT_SUBSCRIPTION_SUCCESS =
	"[SUBSCRIPTION EDIT] EDIT_SUBSCRIPTION_SUCCESS";
export const EDIT_SUBSCRIPTION_FAILED =
	"[SUBSCRIPTION EDIT] EDIT_SUBSCRIPTION_FAILED";

export const SET_VALUE = "[SUBSCRIPTION EDIT] SET_VALUE";
export const CLEAR_VALUES = "[SUBSCRIPTION EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}
export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getSubscription(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_SUBSCRIPTION });

		try {
			const { login } = getState();

			var response = await api.sendGet("/Subscription/" + id, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SUBSCRIPTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_SUBSCRIPTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: GET_SUBSCRIPTION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function updateSubscription(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_SUBSCRIPTION });

		try {
			const state = getState();

			const { login, subscriptionEdit } = state;

			const { description, status, value, user } = data;

			const {
				item,
				discount,
				installment,
				installmentInterval,
				plan,
				paymentMethod,
				firstPayment
			} = subscriptionEdit;

			const errors = {};
			if (!user) errors.user = ls.required;
			if (!value) errors.value = ls.required;
			if (!installment) errors.installment = ls.required;
			if (!plan) errors.plan = ls.required;
			if (!paymentMethod) errors.paymentMethod = ls.required;
			if (!firstPayment) errors.firstPayment = ls.required;

			dispatch(setValue({ errors }));

			if (Object.keys(errors).length)
				return dispatch({ type: SET_VALUE, payload: { loading: false } });

			const model = {
				...item,
				value:
					`${value}`.indexOf("R") !== 0
						? Math.round(+value * 100)
						: Math.round(+value.slice(3).replace(",", ".") * 100),
				discount: Math.round(+discount * 100),
				installment,
				installmentInterval,
				description,
				status,
				plan,
				paymentMethod,
				firstPayment
			};

			var response = await api.sendPut("/Subscription/" + item._id, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: EDIT_SUBSCRIPTION_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_SUBSCRIPTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);
			return dispatch({
				type: EDIT_SUBSCRIPTION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
