import * as actions from "../../actions/paymentMethod";

const initialState = {
	loading: {},
	items: [],
	errors: {},
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.GET_PAYMENT_METHODS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors,
			};
		}
		case actions.GET_PAYMENT_METHODS_SUCCESS: {
			return {
				...initialState,
				items: payload || [],
			};
		}
		case actions.GET_PAYMENT_METHODS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload,
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
