import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
	Button,
	ButtonGroup,
	ButtonToolbar,
	Card,
	CardBody,
	Spinner
} from "reactstrap";

import { FaRegFileExcel } from "react-icons/fa";

import { exportExcel } from "./export";

function Report({
	title,
	data,
	columns,
	tableRef,
	onRowClicked,
	headerRightComponent,
	noExport,
	count,
	noTitle = false,
	...rest
}) {
	const table = useRef(null);
	const [rColumns, setRColumns] = useState(columns);
	const [exportComponent, setExport] = useState(null);

	const handleExport = () => {
		exportExcel(tableRef || table, rColumns, title, setExport);
	};

	const renderHeaderRightComponent = () => {
		// if (headerRightComponent) {
		//   if (!visibleColumns || !visibleColumns.length)
		//     return headerRightComponent;
		// }

		return (
			<div
				style={{
					alignItems: "center",
					display: "flex"
				}}
			>
				<ButtonToolbar>
					<ButtonGroup className="btn-group--icons" dir="ltr">
						{headerRightComponent}
						{!noExport && (
							<Button outline onClick={handleExport}>
								<FaRegFileExcel style={{ margin: 0 }} />
							</Button>
						)}
					</ButtonGroup>
				</ButtonToolbar>
			</div>
		);
	};

	return (
		<Card>
			<CardBody style={{ padding: 0 }}>
				<div
					className="card__title"
					style={{
						marginBottom: 0,
						padding: 16,
						justifyContent: "space-between"
					}}
				>
					{!noTitle && (
						<h5 className="bold-text">
							{title || "Relatório"}
							{count ? ` - (${count})` : ""}
						</h5>
					)}
					<div style={{ display: "flex", alignItems: "flex-center" }}>
						{renderHeaderRightComponent()}
					</div>
				</div>
				<ReactTable
					loadingText={
						<div>
							<Spinner color={"primary"} size={"sm"} /> Loading...
						</div>
					}
					className="-striped -highlight"
					data={data}
					ref={tableRef || table}
					columns={columns}
					defaultPageSize={10}
					pageSizeOptions={[5, 10, 15, 20, 25, 50, 100, 500, 1000]}
					getTdProps={(state, rowInfo, column) => {
						return {
							style: {
								display: "flex",
								alignItems: "center",
								align: "align",
								cursor:
									onRowClicked && !(column && column.id === "actions")
										? "pointer"
										: undefined
							},
							onClick: (e, handleOriginal) => {
								if (rowInfo && !(column && column.id === "actions")) {
									if (onRowClicked) {
										onRowClicked(rowInfo.original);
									} else {
										handleOriginal && handleOriginal(e);
									}
								}
							}
						};
					}}
					{...rest}
				/>
				{exportComponent}
			</CardBody>
		</Card>
	);
}

Report.propTypes = {
	title: PropTypes.string,
	data: PropTypes.array,
	columns: PropTypes.array,
	tableRef: PropTypes.any
};

export default Report;
