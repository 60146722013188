import dayjs from "dayjs";
import ls from "Localization";
import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import {
	contractTemplateStatus,
	contractTemplateTypes
} from "types/contractStatus";

const DetailContract = ({ contract }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} lg={3}>
									<p>
										<strong>Nome: </strong>
										<br />
										<span>
											<span>{contract.name ? contract.name : ""}</span>
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>Empresa: </strong>
										<br />
										<span>
											{contract.mei && contract.mei.businessName
												? `${contract.mei.businessName}`
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={4} lg={3}>
									<p>
										<strong>Status: </strong>
										<br />
										<span>
											{contract.status >= 0
												? ls[contractTemplateStatus[contract.status]]
												: ""}
										</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={6} md={4} lg={3} xl={3}>
									<p>
										<strong>Data Criação: </strong>
										<br />
										<span>
											{contract.createdAt
												? dayjs(contract.createdAt).format(ls.dateFormatShort)
												: ""}
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} lg={3}>
									<p>
										<strong>Tipo: </strong>
										<br />
										<span>
											<span>
												{ls[contractTemplateTypes[contract.type || "0"]]}
											</span>
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} lg={3}>
									<p>
										<strong>Departamento: </strong>
										<br />
										<span>
											<span>{contract.department?.name || ""}</span>
										</span>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailContract;
