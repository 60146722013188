export function toStringCurrency(value) {
	return value.toLocaleString("pt-BR", {
		currency: "BRL",
		currencyDisplay: "symbol",
		style: "currency",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

export function toStringPercentage(value, value2, decimals = 2) {
	let v = (value * 100) / value2;
	if (isNaN(v)) v = 0;
	return `${v.toFixed(decimals)}%`;
}

export const replaceStringCurrencyToNumber = (symbol, value) => {
	if (value) {
		if (typeof value === "number") value = value.toFixed(2);

		let v = String(value).replace(symbol, "");

		v = v
			.replace(",", "")
			.replace(".", "")
			.trim();
		return Number(v);
	}
	return value;
};

export function stringSort(a, b, desc) {
	// force null and undefined to the bottom
	a = a === null || a === undefined ? "" : a;
	b = b === null || b === undefined ? "" : b;
	// force any string values to lowercase
	a = typeof a === "string" ? a.toLowerCase() : a;
	b = typeof b === "string" ? b.toLowerCase() : b;
	// Return either 1 or -1 to indicate a sort priority
	if (a > b) {
		return 1;
	}
	if (a < b) {
		return -1;
	}
	// returning 0, undefined or any falsey value will use subsequent sorts or
	// the index as a tiebreaker
	return 0;
}

export function stringToMask(str, pattern) {
	if (!str) return "";

	if (pattern == "cnpj") {
		if (str.length == 14) {
			pattern = "##.###.###/####-##";
		} else {
			return "CNPJ inválido";
		}
	}

	if (pattern == "cpf") {
		if (str.length == 11) {
			pattern = "###.###.###-##";
		} else {
			return "Cpf inválido";
		}
	}

	str = "" + str;
	let i = 0;
	return pattern.replace(/#/g, () => {
		return str[i++] || "";
	});
}

export function stringToPascalCase(str) {
	if (!str) return "";

	return str.replace(
		/([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ])([A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]*)/g,
		function(g0, g1, g2) {
			return g1.toUpperCase() + g2.toLowerCase();
		}
	);
}

export function removeDiacritics(string) {
	return String(string)
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");
}

export function getValue(c) {
	let value = String(c);

	if (value.indexOf("R$") !== -1) {
		value = value.replace("R$", "").trim();
	}

	if (value) {
		while (value.split(/[.,]/).length > 2) {
			value = value.replace(".", "");
		}

		while (value.indexOf(",") !== -1) {
			let index = value.indexOf(",");

			if (index >= value.length - 3) {
				value = value.replace(",", ".");
			} else {
				value = value.replace(",", "");
			}
		}
	}

	if (Number.isNaN(+value)) {
		console.log("Value is NaN", value, c);

		throw new Error(`Value ${c} is NaN`);
	}

	return Number(value);
}

export function getUserFullName({ name, lastname }) {
	if (lastname) {
		return `${name} ${lastname}`;
	}

	return name;
}
