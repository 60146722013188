import { getUserData } from "redux/actions/login";
import * as api from "services/api";

export const UPDATE_PROFILE_PASSWORD =
	"[UPDATE PROFILE PASSWORD] UPDATE_PROFILE_PASSWORD";

export const RESET_PROFILE_PASSWORD_ERROR =
	"[RESET PROFILE PASSWORD ERROR] RESET_PROFILE_PASSWORD_ERROR";

export const RESET_PROFILE_PASSWORD_SUCCESS =
	"[RESET PROFILE PASSWORD SUCCESS] RESET_PROFILE_PASSWORD_SUCCESS";

export const SET_VALUE = "[CLIENT CREATE] SET_VALUE";
export const CLEAR_VALUES = "[CLIENT CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function updateProfilePassword(model, callback) {
	return async (dispatch, getState) => {
		try {
			let {
				login: { token, user }
			} = getState();

			const data = {
				oldPassword: model.password,
				password: model.newPassword,
				confirmPassword: model.confirmPassword
			};

			const response = await api.sendPut(
				`/user/${user._id}/ChangePassword`,
				data,
				{
					Authorization: "Bearer " + token
				}
			);

			if (response.ok) {
				dispatch({
					type: RESET_PROFILE_PASSWORD_SUCCESS,
					payload: {
						ok: true
					}
				});

				dispatch(getUserData(callback));
			} else {
				const json = await response.json();

				if (json.errors && json.errors.password) {
					dispatch({
						type: RESET_PROFILE_PASSWORD_ERROR,
						payload: { password: json.errors.password }
					});

					callback && callback({ password: json.errors.password });
				} else {
					let error = "Ocorreu um erro";

					dispatch({
						type: RESET_PROFILE_PASSWORD_ERROR,
						payload: { password: error }
					});
					callback && callback({ password: error });
				}
			}
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: RESET_PROFILE_PASSWORD_ERROR,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
